import { Box, Button, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import Loader from "components/Loader/Loader";
import { useUserContext } from "components/common/contexts";
import { useData } from "components/common/hooks/useData";
import { AsyncResult } from "components/common/infractructure";
import { ActionContextMenu } from "components/common/ui-kit/components/ActionContextMenu";
import { noop } from "components/common/utils";
import { formatForView } from "components/common/utils/numberUtils";
import { BillingData, BillingSummary, CostBillingData, OtherCost, RegisteredWork } from "modules/Billing/types";
import {
    BILLING_ACCEPTED,
    BILLING_POSITIONS_SAVE_FAILED,
    BILLING_SENT_TO_TAXXO,
    CANNOT_RETRIEVE_BILLING,
    MARKING_BILLING_AS_DONE_FAILED,
    REQUEST_FAILED,
    SENDING_BILLING_TO_TAXXO_FAILED,
} from "modules/Billing/messages";
import { calculateBillingTotal } from "modules/Billing/service/billingService";
import { BillingActionButton } from "modules/Billing/view/components/BillingActionButton";
import { BillingDetailsDataView } from "modules/Billing/view/components/BillingDetailsDataView";
import { BillingPositionsTable } from "modules/Billing/view/components/BillingPositionsTable";
import { BillingStateInfo } from "modules/Billing/view/components/BillingStateInfo";
import { RegisteredWorkStatus } from "modules/Billing/view/components/RegisteredWorkStatus";
import { RegisteredWorkTable } from "modules/Billing/view/components/RegisteredWorkTable";
import * as React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { useEffectOnce } from "usehooks-ts";
import { OtherCostTable } from "./OtherCostsTable";

interface Props {
    getBillingSummary: (billingId: string) => AsyncResult<BillingSummary>;
    saveCostBillings: (billingId: string, costBillings: CostBillingData[]) => AsyncResult<void>;
    sendBilling: (billingId: string) => AsyncResult<void>;
    acceptBilling: (billingId: string) => AsyncResult<void>;
    markBillingAsDone: (billingId: string) => AsyncResult<void>;
    recalculateAssigmentBillings: (billingId: string) => AsyncResult<BillingData>;
}

export const BillingDetailsView: React.FC<Props> = ({
    getBillingSummary,
    saveCostBillings,
    sendBilling,
    acceptBilling,
    markBillingAsDone,
    recalculateAssigmentBillings,
}) => {
    const [registeredWork, setRegisteredWork] = useState<Maybe<RegisteredWork[]>>(Maybe.nothing());
    const [otherCosts, setOtherCosts] = useState<Maybe<OtherCost[]>>(Maybe.nothing());

    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();
    const isAccountant = roles.includes("ROLE_ACCOUNTANT_READ") && roles.includes("ROLE_ACCOUNTANT_WRITE");

    const {
        data: billing,
        isLoading,
        loadData: getBillingData,
        setData: setBilling,
    } = useData<BillingData, string>({
        loader: async (billingIdValue: string) => {
            const billingSummaryResult = await getBillingSummary(billingIdValue);
            billingSummaryResult.match({
                Ok: ({ registeredWork, otherCosts }) => {
                    setRegisteredWork(Maybe.of(registeredWork));
                    setOtherCosts(Maybe.of(otherCosts));
                },
                Err: () => {
                    toast.error(CANNOT_RETRIEVE_BILLING);
                },
            });

            return billingSummaryResult.map(({ billingData }) => billingData);
        },
        onError: () => toast.error(CANNOT_RETRIEVE_BILLING),
    });

    const navigate = useNavigate();
    const { billingId } = useParams<{ billingId: string }>();
    const readOnly = billing.map(({ done }) => done).unwrapOr(false);
    const billingTotal = billing.map(value => calculateBillingTotal(value).toNumber()).unwrapOr(0);
    const billingCurrency = billing.map(({ billingDetailsData }) => billingDetailsData.currency).unwrapOr("");

    useEffectOnce(() => {
        billingId && getBillingData(billingId);
    });

    const onUpdateCosts =
        (billingIdValue: string) =>
        async (costBilling: CostBillingData[]): Promise<Result<void, AxiosError>> => {
            const saveCostBillingsPromise = saveCostBillings(billingIdValue, costBilling);
            (await saveCostBillingsPromise).match({
                Ok: () => {
                    billing
                        .map(value => ({ ...value, costBilling }))
                        .match({
                            Just: setBilling,
                            Nothing: noop,
                        });
                },
                Err: () => {
                    toast.error(BILLING_POSITIONS_SAVE_FAILED);
                },
            });
            return saveCostBillingsPromise;
        };

    const onSendBilling = (billingIdValue: string) => async () => {
        (await sendBilling(billingIdValue)).match({
            Ok: () => {
                toast.success(BILLING_SENT_TO_TAXXO);
                getBillingData(billingIdValue);
            },
            Err: () => toast.error(SENDING_BILLING_TO_TAXXO_FAILED),
        });
    };

    const onAcceptBilling = (billingIdValue: string) => async () => {
        (await acceptBilling(billingIdValue)).match({
            Ok: () => {
                toast.success(BILLING_ACCEPTED);
                getBillingData(billingIdValue);
            },
            Err: () => toast.error(SENDING_BILLING_TO_TAXXO_FAILED),
        });
    };

    const onMarkBillingAsDone = (billingIdValue: string) => async () => {
        (await markBillingAsDone(billingIdValue)).match({
            Ok: () => {
                getBillingData(billingIdValue);
            },
            Err: () => toast.error(MARKING_BILLING_AS_DONE_FAILED),
        });
    };

    const onRecalculateAssignmentBillings = (billingIdValue: string) => async () => {
        (await recalculateAssigmentBillings(billingIdValue)).match({
            Ok: setBilling,
            Err: () => {
                toast.error(REQUEST_FAILED);
            },
        });
    };

    const registeredWorkComponent = registeredWork
        .map(rw => <RegisteredWorkTable key={0} registeredWork={rw} />)
        .unwrapOr(<></>);

    const registeredWorkStatusComponent = registeredWork
        .map(rw => <RegisteredWorkStatus key={0} registeredWork={rw} />)
        .unwrapOr(<></>);

    const billingDetailsComponent = billing
        .map(b => <BillingDetailsDataView key={0} billingDetailsData={b.billingDetailsData} />)
        .unwrapOr(<></>);

    const billingPositionsComponent = Maybe.all(billing, Maybe.of(billingId))
        .map(values => {
            const [billingValue, billingIdValue] = values as [BillingData, string];
            return (
                <BillingPositionsTable
                    accepted={billingValue.accepted}
                    readOnly={billingValue.done}
                    key={billingIdValue}
                    assignmentBillings={billingValue.assignmentBilling}
                    costBillings={billingValue.costBilling}
                    onUpdateCosts={onUpdateCosts(billingIdValue)}
                />
            );
        })
        .unwrapOr(<></>);

    const billingActionButton = Maybe.all(billing, Maybe.of(billingId))
        .map(values => {
            const [billingValue, billingIdValue] = values as [BillingData, string];
            return (
                <BillingActionButton
                    key={billingIdValue}
                    onAcceptBilling={onAcceptBilling(billingIdValue)}
                    onSendBilling={onSendBilling(billingIdValue)}
                    accepted={billingValue.accepted}
                    isAccountant={isAccountant}
                />
            );
        })
        .unwrapOr(<></>);

    const actionsComponent = Maybe.of(billingId)
        .map(billingIdValue => (
            <ActionContextMenu
                key={billingIdValue}
                items={[
                    {
                        name: "Oznacz draft jako zakończony",
                        onClick: onMarkBillingAsDone(billingIdValue),
                    },
                    {
                        name: "Przelicz pozycje pracowników ponownie",
                        onClick: onRecalculateAssignmentBillings(billingIdValue),
                    },
                ]}
            />
        ))
        .unwrapOr(<></>);

    const onBackToBillings = () => navigate("/billing/all");

    const otherCostsComponent = otherCosts
        .map(oc =>
            oc.length > 0 ? (
                <>
                    <Grid item xs={12} sx={{ mb: "1rem", mt: "1rem" }}>
                        <Typography variant="h5">Pozostałe koszty projektowe</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: "2rem" }}>
                        <Box>
                            {" "}
                            <OtherCostTable key={0} otherCosts={oc} />
                        </Box>
                    </Grid>
                </>
            ) : (
                <></>
            ),
        )
        .unwrapOr(<></>);

    return isLoading ? (
        <Loader />
    ) : (
        <div>
            <Button size="medium" color="primary" onClick={onBackToBillings} sx={{ mb: "1rem" }}>
                &#10094; Wszystkie
            </Button>
            <Grid container spacing={0}>
                <Grid container item xs={12} sx={{ mb: "1rem" }}>
                    <Grid item xs={6}>
                        <Typography variant="h5">
                            Draft faktury <b>{billing.map(b => `${b.projectName}  ${b.billingMonth}`).unwrapOr("")}</b>
                        </Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent={"flex-end"} alignContent="center">
                        {readOnly ? <></> : actionsComponent}
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: "2rem" }}>
                    <Box>{billingDetailsComponent}</Box>
                </Grid>
                <Grid item xs={12} sx={{ mb: "1rem" }}>
                    <Typography variant="h5">Zarejestrowany czas {registeredWorkStatusComponent}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: "1rem" }}>
                    <Box>{registeredWorkComponent}</Box>
                </Grid>
                {otherCostsComponent}
                <Grid item xs={12} sx={{ mb: "2rem" }}>
                    {billingPositionsComponent}
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        Łączna wartość faktury:
                        <Box sx={{ display: "inline", fontWeight: "bold" }}>
                            {" "}
                            {formatForView(billingTotal)} {billingCurrency}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {billing
                        .map(value => (
                            <Box key={0}>
                                <BillingStateInfo key={0} {...value} />
                            </Box>
                        ))
                        .unwrapOr(<></>)}
                </Grid>
                <Grid item xs={12}>
                    {!readOnly && billingActionButton}
                </Grid>
            </Grid>
        </div>
    );
};
