import { Box } from "@mui/material";
import { MUI_INPUT_YEAR_MONTH_STRING_FORMAT, Nullable } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect, OnChangeFn } from "components/common/ui-kit/components/GenericSelect";
import { dateToYearMonthString, doIfPresent } from "components/common/utils";
import { Dayjs } from "dayjs";
import { EMPTY_SELECT_VALUE } from "modules/AccountingManagement/service/constants";
import { getPaymentStatusTranslation } from "modules/AccountingManagement/service/moneyTransferService";
import { PaymentStatus } from "modules/types/types";
import * as React from "react";
import { Maybe } from "true-myth";

interface Props {
    companyOwner: Maybe<string>;
    companyOwners: string[];
    yearMonth: string;
    paymentStatus: Maybe<string | PaymentStatus>;
    onChangeYearMonth: (date: Date) => void;
    onChangeCompanyOwner: OnChangeFn;
    onChangePaymentStatus: OnChangeFn;
}

const getPaymentStatusValues = () => {
    return [
        {
            value: "WAITING_FOR_PAYMENT",
            display: getPaymentStatusTranslation("WAITING_FOR_PAYMENT"),
        },
        {
            value: "PAID",
            display: getPaymentStatusTranslation("PAID"),
        },
        {
            value: "PAYMENT_BOOKED",
            display: getPaymentStatusTranslation("PAYMENT_BOOKED"),
        },
        {
            value: "INVOICE_BOOKED",
            display: getPaymentStatusTranslation("INVOICE_BOOKED"),
        },
    ];
};

const emptySelectValue = {
    value: EMPTY_SELECT_VALUE,
};

export const PaymentsFilter: React.FC<Props> = ({
    companyOwner,
    companyOwners,
    yearMonth,
    paymentStatus,
    onChangeYearMonth,
    onChangeCompanyOwner,
    onChangePaymentStatus,
}) => {
    const handleChangeDate = (date: Nullable<Dayjs>) =>
        doIfPresent(
            Maybe.of(date).map(dayJsDate => dayJsDate.toDate()),
            newDate => {
                if (dateToYearMonthString(newDate) !== yearMonth) {
                    onChangeYearMonth(newDate);
                }
            },
        );

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: "0.5rem" }}>
                <CommonDatePicker
                    datePicker={{
                        disableFuture: true,
                        views: ["year", "month"],
                        label: "Rok i miesiąc",
                        value: yearMonth,
                        inputFormat: MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
                        onChange: handleChangeDate,
                    }}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Pracownik"
                    values={[emptySelectValue, ...companyOwners.map(value => ({ value }))]}
                    value={companyOwner.unwrapOr(EMPTY_SELECT_VALUE)}
                    onChange={onChangeCompanyOwner}
                />
            </Box>
            <GenericSelect
                onChange={onChangePaymentStatus}
                value={paymentStatus.unwrapOr(EMPTY_SELECT_VALUE)}
                label="Status płatności"
                values={[emptySelectValue, ...getPaymentStatusValues()]}
            />
        </Box>
    );
};
