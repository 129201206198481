import * as React from "react";
import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect } from "react";
import { useData } from "../../../../components/common/hooks/useData";
import { dateToYearMonthString } from "../../../../components/common/utils";
import { toast } from "react-hot-toast";
import { CANNOT_RETRIEVE_PENDINGS } from "../../messages";
import { useEffectOnce } from "usehooks-ts";
import { AsyncResult } from "../../../../components/common/infractructure";
import { Employee, formatEmployeeLabelWithSpec } from "../../../Projects/types";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import RBAC from "../../../../components/RBAC/RBAC";
import { differenceInMonths } from "date-fns";

interface Props {
    currentDate: Date;
    getMissingMonthlySettlements: (yearMonth: string) => AsyncResult<Employee[]>;
    sendMissingSettlementNotification: (userId: number, yearMonth: string) => AsyncResult<void>;
}

export const MissingMonthlyStatements: React.FC<Props> = ({
    currentDate,
    getMissingMonthlySettlements,
    sendMissingSettlementNotification,
}) => {
    const {
        data: billings,
        isLoading,
        loadData,
    } = useData<Employee[]>({
        loader: () => getMissingMonthlySettlements(dateToYearMonthString(currentDate)),
        onError: () => toast.error(CANNOT_RETRIEVE_PENDINGS),
    });

    const handleIconClick = async (id: number) => {
        (await sendMissingSettlementNotification(id, dateToYearMonthString(currentDate))).match({
            Err: err => toast.error(`Nie udało się wysłać powiadomienia: ${err.message}`),
            Ok: () => toast.success("Powiadomienie wysłane!"),
        });
    };

    useEffectOnce(() => {
        loadData();
    });

    useEffect(() => {
        loadData();
    }, [currentDate]);

    const today = new Date();
    const shouldShowList =
        differenceInMonths(today, currentDate) >= 1 ||
        (differenceInMonths(today, currentDate) == 0 && today.getDate() >= 25);
    const employees = billings.unwrapOr([]);
    const list =
        employees.length > 0 ? (
            <Grid item>
                {employees
                    .map(employee => ({
                        ...employee,
                        label: formatEmployeeLabelWithSpec(employee),
                    }))
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map(employee => (
                        <Box
                            key={employee.id}
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                marginBottom: "15px",
                                "&:hover .hoverIcon": {
                                    opacity: 1,
                                },
                            }}
                        >
                            <EmployeeAvatar sx={{ width: "35px", height: "35px" }} employee={employee} />
                            <Box sx={{ ml: "0.5rem" }}>{employee.label}</Box>
                            <RBAC requiredRoles={["ROLE_ACCOUNTANT_WRITE", "ROLE_ADMIN"]}>
                                <NotificationsIcon
                                    className="hoverIcon"
                                    sx={{
                                        opacity: 0,
                                        transition: "opacity 0.3s",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleIconClick(employee.id)}
                                />
                            </RBAC>
                        </Box>
                    ))}
            </Grid>
        ) : (
            <>Wszystkie rozliczenia uzupełnione.</>
        );

    return shouldShowList ? (
        <Grid container justifyContent={"flex-start"} sx={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    Oczekujące rozliczenia podwykonawców {!isLoading ? `(${employees.length})` : ``}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
                {isLoading ? (
                    <Box display={"flex"} alignItems={"center"}>
                        <Box>
                            <Skeleton variant="circular" width={35} height={35} sx={{ mr: "5px" }} />
                        </Box>
                        <Box>
                            <Skeleton variant="text" width={200} height={30} />
                            <Skeleton variant="text" width={100} height={20} />
                        </Box>
                    </Box>
                ) : (
                    list
                )}
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};
