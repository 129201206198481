import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    MenuItem,
} from "@mui/material";
import { formatMoneyForView, parseNumber, sum } from "components/common/utils/numberUtils";
import debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";
import * as React from "react";
import { DateRangePicker } from "components/common/ui-kit/components/DateRangePicker/DateRangePicker";
import { filterPanel } from "components/common/ui-kit/styles/utils";
import { useTransactionFiltersActionDispatcher, useTransactionFiltersState } from "./TransactionFiltersContext";
import { DateRange } from "../../../../components/common/types";
import { useTransactionsData } from "./TransactionsDataContext";

const DEBOUNCE_TIME_MS = 300;

export const TransactionFilter = () => {
    const transactionFiltersState = useTransactionFiltersState();
    const dispatchTransactionFiltersAction = useTransactionFiltersActionDispatcher();
    const transactionsDataState = useTransactionsData();
    const bankAccounts = React.useMemo(
        () => (transactionsDataState.name === "data-loaded" ? transactionsDataState.bankAccounts : []),
        [transactionsDataState],
    );

    const balanceSumInPln = sum(bankAccounts.map(c => c.latestBalanceInPln));

    const setSmlAccounts = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        const accountsToSet = typeof value === "string" ? value.split(",") : value;

        dispatchTransactionFiltersAction({
            action: "setSmlAccounts",
            smlAccountIds: accountsToSet,
        });
    };

    const searchTransactionDescription = useMemo(
        () =>
            debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                dispatchTransactionFiltersAction({
                    action: "setTransactionDescription",
                    transactionDescription: e.target.value,
                });
            }, DEBOUNCE_TIME_MS),
        [],
    );

    const handleChangeMinAmount = useMemo(
        () =>
            debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                const minAmount = parseNumber(event.target.value).unwrapOr(null);
                if (minAmount === null) {
                    dispatchTransactionFiltersAction({ action: "clearMinAmount" });
                } else {
                    dispatchTransactionFiltersAction({
                        action: "setMinAmount",
                        minAmount,
                    });
                }
            }, DEBOUNCE_TIME_MS),
        [],
    );

    const handleChangeMaxAmount = useMemo(
        () =>
            debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                const maxAmount = parseNumber(event.target.value).unwrapOr(null);
                if (maxAmount === null) {
                    dispatchTransactionFiltersAction({ action: "clearMaxAmount" });
                } else {
                    dispatchTransactionFiltersAction({
                        action: "setMaxAmount",
                        maxAmount,
                    });
                }
            }, DEBOUNCE_TIME_MS),
        [],
    );

    const handleChangeFullyPaidOnly = (_: React.ChangeEvent, checked: boolean) => {
        dispatchTransactionFiltersAction({
            action: "changeShowNotFullyPaidOnly",
            showNotFullyPaidOnly: checked,
        });
    };

    const onDateRangeChange = (range: DateRange) => {
        dispatchTransactionFiltersAction({
            action: "setDateRange",
            range,
        });
    };

    useEffect(() => {
        return () => {
            searchTransactionDescription.cancel();
            handleChangeMinAmount.cancel();
            handleChangeMaxAmount.cancel();
        };
    }, []);

    return (
        <Paper sx={filterPanel}>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateAreas: `
                    "sml_account sml_account desc desc desc desc"
                    "date_range_select date_range_select min_amount  max_amount  fully_paid fully_paid"
                    "date_range_from  date_range_to .  . .  ."
                `,
                    gridTemplateColumns: "repeat(6, 1fr)",
                    gap: "1rem",
                    maxWidth: "60rem",
                }}
            >
                <Box sx={{ gridArea: "sml_account" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel variant="standard" id="demo-multiple-name-label">
                            Konto SML
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={transactionFiltersState.smlAccountIds}
                            onChange={setSmlAccounts}
                        >
                            {bankAccounts.map(({ id, iban, currency, bankName, latestBalance }) => (
                                <MenuItem key={id} value={id.toString()}>
                                    {bankName} ...{iban.slice(-4)}, saldo:{" "}
                                    {formatMoneyForView(latestBalance, currency.name)}
                                </MenuItem>
                            ))}
                            <MenuItem key={6} value={"ddd"} disabled={true}>
                                Saldo łączne: {formatMoneyForView(balanceSumInPln.toNumber(), "PLN")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TextField
                    sx={{ gridArea: "desc" }}
                    type="text"
                    label="Szukaj"
                    size="medium"
                    onChange={searchTransactionDescription}
                    defaultValue={transactionFiltersState.transactionDescription}
                    variant="standard"
                />
                <TextField
                    sx={{ gridArea: "min_amount" }}
                    type="number"
                    label="Minimalna kwota"
                    defaultValue={transactionFiltersState.minAmount}
                    size="medium"
                    onChange={handleChangeMinAmount}
                    variant="standard"
                />
                <TextField
                    sx={{ gridArea: "max_amount" }}
                    type="number"
                    label="Maksymalna kwota"
                    defaultValue={transactionFiltersState.maxAmount}
                    size="medium"
                    onChange={handleChangeMaxAmount}
                    variant="standard"
                />
                <FormControlLabel
                    sx={{ p: 1, gridArea: "fully_paid" }}
                    control={
                        <Switch
                            checked={transactionFiltersState.showNotFullyPaidOnly}
                            onChange={handleChangeFullyPaidOnly}
                            name="Pokaż tylko nieopłacone faktury"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            color="primary"
                        />
                    }
                    label="Pokaż tylko nieopłacone"
                />
                <DateRangePicker
                    initialRange={transactionFiltersState.dateRange}
                    onDateRangeChange={onDateRangeChange}
                    today={new Date()}
                />
            </Box>
        </Paper>
    );
};
