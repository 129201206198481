import React from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { CommonDatePicker } from "../../../../components/common/ui-kit/components/DatePicker/DatePicker";
import { MUI_INPUT_DATE_STRING_FORMAT, Nullable, toDateString } from "../../../../components/common/types";
import { Dayjs } from "dayjs";

const DatePickerEditCell: React.FC<GridRenderEditCellParams<string>> = props => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (date: Nullable<Dayjs>) => {
        const val = date;
        await apiRef.current.setEditCellValue({
            id,
            field,
            value: val != null ? toDateString(val.toDate()) : toDateString(new Date()),
        });
    };

    return (
        <CommonDatePicker
            datePicker={{
                disableFuture: true,
                views: ["year", "month", "day"],
                value: value || toDateString(new Date()),
                inputFormat: MUI_INPUT_DATE_STRING_FORMAT,
                onChange: handleChange,
            }}
        />
    );
};

export { DatePickerEditCell };
