import { Box } from "@mui/material";
import { AnyObject } from "components/common/types";
import { formatForView, parseNumber } from "components/common/utils/numberUtils";
import { Maybe } from "true-myth";
import { SimpleTableProps } from "./types";

export const SimpleTableMini = ({ columnDefs, rowData }: SimpleTableProps<AnyObject>) => {
    return (
        <Box
            sx={{
                table: {
                    width: "100%",
                    fontSize: "12px",

                    "& th": {
                        borderBottom: "none",
                    },
                    "& tr": {
                        "&:last-child": {
                            fontWeight: "fontWeightBold",
                        },
                    },
                    td: {
                        borderTop: "1px solid",
                        borderColor: "primary.light",
                    },
                    "& td, th": {
                        padding: "4px 2px 4px 2px",
                    },
                },
            }}
        >
            <table>
                <tbody>
                    <tr>
                        {columnDefs.map((colDef, index) =>
                            Maybe.of(colDef.header)
                                .map(header => <th key={index}>{header}</th>)
                                .unwrapOr(<th key={index} />),
                        )}
                    </tr>
                    {rowData.map((row, index) => (
                        <tr key={index}>
                            {columnDefs.map((colDef, index) =>
                                Maybe.of(row[colDef.rowDataKey])
                                    .map(cellValue =>
                                        parseNumber(cellValue)
                                            .map(cellValue => (
                                                <td key={index} style={{ textAlign: "right" }}>
                                                    {formatForView(cellValue)}
                                                </td>
                                            ))
                                            .unwrapOr(<td key={index}>{cellValue}</td>),
                                    )
                                    .unwrapOr(<td key={index} />),
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    );
};
