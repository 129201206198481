export const KNOWN_SPECIALIZATIONS = [
    "DEV_BACKEND_SCALA",
    "DEV_BACKEND_JAVA",
    "DEV_FRONTEND",
    "DEVOPS_AS_A_SERVICE",
    "PRIVATE_BLOCKCHAIN",
    "MACHINE_LEARNING",
    "DEV_QA",
    "UI_UX",
    "ADMINISTRACJA",
    "BIZDEV",
    "MARKETING",
    "HIRING",
    "DEV_DEVOPS",
    "PUBLIC_BLOCKCHAIN",
] as const;

export type Specialization = typeof KNOWN_SPECIALIZATIONS[number];

export const SPECIALIZATIONS_WITH_LEVELS: Specialization[] = [
    "DEV_BACKEND_SCALA",
    "DEV_BACKEND_JAVA",
    "DEV_FRONTEND",
    "DEVOPS_AS_A_SERVICE",
    "DEV_DEVOPS",
    "PUBLIC_BLOCKCHAIN",
    "PRIVATE_BLOCKCHAIN",
    "MACHINE_LEARNING",
    "ADMINISTRACJA",
    "HIRING",
];

export const MISSING_SPECIALIZATION_LABEL = "Brak specjalizacji";

export const getSpecializationTranslation = (type: Specialization): string => {
    switch (type) {
        case "DEV_BACKEND_SCALA":
            return "Developer - Scala";
        case "DEV_BACKEND_JAVA":
            return "Developer - Java";
        case "DEVOPS_AS_A_SERVICE":
            return "DevOps";
        case "DEV_DEVOPS":
            return "DevOps";
        case "DEV_FRONTEND":
            return "Developer - frontend";
        case "MACHINE_LEARNING":
            return "Developer - Machine Learning";
        case "PUBLIC_BLOCKCHAIN":
            return "Developer - blockchain";
        case "PRIVATE_BLOCKCHAIN":
            return "Developer - blockchain";
        case "DEV_QA":
            return "Q&A";
        case "UI_UX":
            return "UI/UX";
        case "ADMINISTRACJA":
            return "Administracja";
        case "BIZDEV":
            return "BizDev";
        case "MARKETING":
            return "Marketing";
        case "HIRING":
            return "Hiring";
        default:
            return `Nieznana specjalizacja: ${type}`;
    }
};

export const KNOWN_DEPARTMENTS = [
    "DEVELOPMENT",
    "BIZ_DEV",
    "MARKETING",
    "HIRING",
    "SZKOLENIA",
    "ADMINISTRACJA",
    "HR",
    "RND",
] as const;

export type Department = typeof KNOWN_DEPARTMENTS[number];

export const MISSING_DEPARTMENT_LABEL = "Brak przypisania do cechu";

export const getDepartmentTranslation = (type: Department): string => {
    switch (type) {
        case "DEVELOPMENT":
            return "Development";
        case "BIZ_DEV":
            return "Bizdev";
        case "MARKETING":
            return "Marketing";
        case "HIRING":
            return "Hiring";
        case "SZKOLENIA":
            return "Szkolenia";
        case "ADMINISTRACJA":
            return "Administracja i finanse";
        case "HR":
            return "People";
        case "RND":
            return "R&D";
        default:
            return `Nieznany cech: ${type}`;
    }
};
