import { Column } from "@material-table/core";
import { DocumentTableItem, PaymentTableItem } from "modules/Bank/types";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    SxProps,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { removeDocumentPayment } from "modules/Bank/service/apiService";
import { apiDelete } from "components/common/infractructure";
import { toast } from "react-hot-toast";
import { localizationMaterialTable } from "components/common/localization/localization";
import { Table } from "components/common/ui-kit/components/Table";
import BigNumber from "bignumber.js";
import { useTransactionsDataApi } from "./TransactionsDataContext";

const detailsView: SxProps<Theme> = {
    backgroundColor: "main.background",
};

interface Props {
    payment: PaymentTableItem;
    removeDocumentAssignment: (paymentId: number, documentPaymentId: number) => void;
    onRemoveDraftDocument: (paymentId: number, documentId: number) => void;
    handleSubmitDocument: (payment: PaymentTableItem, document: DocumentTableItem) => Promise<void>;
}

const useConfirmationDialog = () => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    return { showConfirmationDialog, setShowConfirmationDialog };
};

export const TransactionsDetailsPanel = ({
    payment,
    removeDocumentAssignment,
    handleSubmitDocument,
    onRemoveDraftDocument,
}: Props) => {
    const { paymentId, paidDocuments } = payment;
    const transactionsApi = useTransactionsDataApi();

    const tableColumns: Column<DocumentTableItem>[] = useMemo(
        () => [
            { title: "Symbol faktury", field: "documentSymbol", width: "15%" },
            { title: "Kontrahent", field: "companyName", width: "15%" },
            {
                title: "Wartość brutto dokumentu",
                type: "numeric",
                align: "right",
                headerStyle: { textAlign: "right" },
                width: "18%",
                field: "documentGrossAmount",
                render: document => document.documentGrossAmount.toFixed(2),
            },
            {
                title: "Powiązana kwota",
                type: "numeric",
                align: "right",
                headerStyle: { textAlign: "right" },
                width: "15%",
                render: documentPayment => {
                    const [warning, setWarning] = useState(false);

                    return (
                        <div>
                            {!documentPayment.draft ? (
                                <>
                                    <span>{documentPayment.paymentPartAmount.toFixed(2)}</span>
                                </>
                            ) : (
                                <>
                                    <TextField
                                        type={"number"}
                                        variant="standard"
                                        onBlur={() => setWarning(true)}
                                        sx={{
                                            backgroundColor: "#fff",
                                            "& input": {
                                                textAlign: "end",
                                            },
                                        }}
                                        defaultValue={documentPayment.paymentPartAmount}
                                        onChange={event => {
                                            const amount = parseFloat(event.target.value);
                                            if (!isNaN(amount)) {
                                                documentPayment.paymentPartAmount = new BigNumber(amount);
                                                setWarning(false);
                                            }
                                        }}
                                    />
                                    {!!warning && (
                                        <Typography variant="caption" sx={{ color: "error.light" }}>
                                            pamietaj aby zapisać zmiany
                                        </Typography>
                                    )}
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                title: "Pozostało do zapłaty",
                type: "numeric",
                align: "right",
                headerStyle: { textAlign: "right" },
                width: "15",
                render: document => document.documentUnpaidAmount.toFixed(2),
            },
            { title: "Waluta", field: "currency", width: "4%" },
            {
                title: "",
                width: "18%",
                render: documentPayment => {
                    const { showConfirmationDialog, setShowConfirmationDialog } = useConfirmationDialog();

                    const handleRemoveClick = () => {
                        setShowConfirmationDialog(true);
                    };

                    const handleRemoveDraftDocument = () => {
                        if (documentPayment.draft) {
                            onRemoveDraftDocument(paymentId, documentPayment.documentId);
                        }
                    };

                    const handleDeleteClick = async () => {
                        const { documentPaymentId } = documentPayment;
                        if (documentPaymentId) {
                            const result = await removeDocumentPayment(apiDelete)(documentPaymentId);
                            result.match({
                                Ok: async () => {
                                    setShowConfirmationDialog(false);
                                    removeDocumentAssignment(paymentId, documentPaymentId);
                                    await transactionsApi.refreshData();
                                    toast.success("Usunięto powiązanie.");
                                },
                                Err: ({ message }) => {
                                    toast.error(`Nie udało się usunąć powiązania: ${message}`);
                                    return Promise.resolve();
                                },
                            });
                        } else {
                            onRemoveDraftDocument(paymentId, documentPayment.documentId);
                        }
                    };

                    return (
                        <Box sx={{ textAlign: "right", paddingRight: "0.5rem" }}>
                            <Button
                                onClick={documentPayment.draft ? handleRemoveDraftDocument : handleRemoveClick}
                                variant={documentPayment.draft ? "outlined" : "contained"}
                                sx={{ marginX: "0.5rem" }}
                                color={"error"}
                            >
                                <ClearIcon />
                            </Button>
                            {documentPayment.draft && (
                                <Button
                                    onClick={() => handleSubmitDocument(payment, documentPayment)}
                                    variant="contained"
                                >
                                    <DoneIcon />
                                </Button>
                            )}

                            {showConfirmationDialog && (
                                <Dialog open={showConfirmationDialog}>
                                    <DialogContent>
                                        <DialogContentText>Czy na pewno chcesz usunąć płatność?</DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={() => setShowConfirmationDialog(false)} variant="outlined">
                                            Anuluj
                                        </Button>
                                        <Button onClick={handleDeleteClick} variant="contained" autoFocus>
                                            Usuń
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </Box>
                    );
                },
            },
        ],
        [paymentId, payment],
    );

    return (
        <Box sx={detailsView}>
            <Table
                components={{
                    Container: props => <Box {...props} elevation={0} />,
                }}
                columns={tableColumns}
                data={paidDocuments}
                options={{
                    tableLayout: "fixed",
                    search: false,
                    grouping: false,
                    selection: false,
                    showTextRowsSelected: false,
                    paging: false,
                    toolbar: false,
                    headerStyle: {
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "left",
                    },
                    idSynonym: "documentId",
                }}
                localization={localizationMaterialTable}
            />
        </Box>
    );
};
