import { Box, Typography } from "@mui/material";
import { logError } from "components/common/infractructure/logger";
import { isError } from "components/common/utils";
import * as React from "react";
import { useRouteError } from "react-router-dom";
import { Maybe } from "true-myth";

interface ErrorInfoProps {
    error: Error;
    errorInfo?: string;
}

const ErrorInfo: React.FC<ErrorInfoProps> = ({ error, errorInfo = "N/A" }) => (
    <Box sx={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box
            sx={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "> img": {
                    borderRadius: "1rem",
                },
            }}
        >
            <img src="/images/sad_mckwacz.png" alt="no-i-klops" width="35%" />
        </Box>
        <Typography variant="h5">Coś poszło grubo nie tak.</Typography>
        <Typography variant="subtitle1">
            <b>Komunikat błędu:</b> {error.message}
        </Typography>
        <br />
        <Typography variant="subtitle1">
            <b>Szczegóły:</b> {errorInfo}
        </Typography>
    </Box>
);

export const RouterErrorBoundary: React.FC = () => {
    const error = useRouteError();
    logError(error);

    return <ErrorInfo error={isError(error) ? error : new Error(`An unexpected exception was caught: ${error}`)} />;
};

interface InterceptedError {
    error: Error;
    errorInfo: React.ErrorInfo;
}

interface ErrorBoundaryState {
    interceptedError: Maybe<InterceptedError>;
}

export class ErrorBoundary extends React.PureComponent<React.PropsWithChildren, ErrorBoundaryState> {
    public readonly state = {
        interceptedError: Maybe.nothing<InterceptedError>(),
    };

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ interceptedError: Maybe.of({ error, errorInfo }) });
    }

    public render(): React.ReactNode {
        return this.state.interceptedError
            .map(({ error, errorInfo }) => (
                <ErrorInfo key={error.message} error={error} errorInfo={errorInfo.componentStack} />
            ))
            .unwrapOr(this.props.children);
    }
}
