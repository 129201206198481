import BigNumber from "bignumber.js";
import { Maybe } from "true-myth";

export const parseNumber = (value: string): Maybe<number> => {
    const parsed = new BigNumber(value).toNumber();
    return isNaN(parsed) ? Maybe.nothing() : Maybe.of(parsed);
};

const viewFormatter = new Intl.NumberFormat("pl-Pl", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const formatForView = (value: number): string => viewFormatter.format(value);

export const formatMoneyForView = (value: number, currency: string): string => `${formatForView(value)} ${currency}`;

export const formatMaybeMoneyForView = (value: Maybe<number>, currency: Maybe<string>): string =>
    value.flatMap(v => currency.map(c => formatMoneyForView(v, c))).unwrapOr("");

const ratesFormatter = new Intl.NumberFormat("pl-Pl", {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
});

export const formatRate = (value: number): string => ratesFormatter.format(value);

const dtoFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
});

export const formatForDataTransfer = (value: number): string => dtoFormatter.format(value);

export const times = (a: number, b: number) => new BigNumber(a).times(new BigNumber(b));
export const dividedBy = (a: number, b: number) => new BigNumber(a).dividedBy(new BigNumber(b));
export const plus = (a: number, b: number) => new BigNumber(a).plus(new BigNumber(b));
export const minus = (a: number, b: number) => new BigNumber(a).minus(new BigNumber(b));
export const sum = (arr: number[]): BigNumber =>
    arr.map(num => new BigNumber(num)).reduce((acc, next) => acc.plus(next), BigNumber(0));

export const sumWithPrecision = (arr: number[], precision: number): BigNumber =>
    arr
        .map(num => new BigNumber(new BigNumber(num).toFixed(precision)))
        .reduce((acc, next) => acc.plus(next), BigNumber(0));

export const calculateTax = (value: number, taxRate: number) =>
    times(value, taxRate).decimalPlaces(2, BigNumber.ROUND_HALF_UP);
