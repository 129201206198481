import { CircularProgress, Box, SxProps, Theme } from "@mui/material";

interface Props {
    fullScreen?: boolean;
    sx?: SxProps<Theme>;
}

const Loader: React.FC<Props> = ({ fullScreen = false, sx = {} }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(fullScreen ? { height: "100vh" } : { margin: "5rem 0" }),
            ...sx,
        }}
    >
        <CircularProgress color="primary" sx={{ minWidth: 100, minHeight: 100 }} data-testid="fullScreenLoader" />
    </Box>
);

export default Loader;
