import { Column } from "@material-table/core";
import { AnyObject } from "components/common/types";

export const boldHeaderStyle = <T extends AnyObject>(): Column<T>["headerStyle"] => ({
    fontWeight: 600,
    fontSize: 16,
    textAlign: "left",
    height: "3rem",
});

export const withBoldHeader = <T extends AnyObject>(columns: Column<T>[]): Column<T>[] =>
    columns.map(columnDef => ({ ...columnDef, headerStyle: { ...columnDef.headerStyle, ...boldHeaderStyle() } }));
