export const PAYMENT_DOWNLOAD_FAILED = "Pobieranie płatności nie powiodło się";
export const TRIGGERING_BATCH_UPLOAD_FAILED = "Uruchomienie procesu uploadu faktur z załącznikami nie powiodło się";
export const PAYMENT_DATA_CHANGE_FAILED = "Zapis zmian w dacie / nr faktury nie powiódł się";
export const MONEY_TRANSFER_FAILED = "Błąd przy wysyłaniu paczki";
export const MOVING_INVOICES_FAILED = "Błąd przy przenoszeniu faktur";
export const PAYMENTS_WITHOUT_INVOICES = "Zadne z wybranych płatności nie ma załącznika";
export const INVOICES_CANNOT_BE_MOVED =
    "Przenosić można tylko płatności z załącznikami i w statusach opłacone i zaksięgowane";

export const PAYMENT_SAVED = "Zapisano płatność";
export const MONEY_TRANSFER_SUCCEDED = "Wysłano paczkę przelewów";
export const TRIGGERING_BATCH_UPLOAD_SUCCEDED =
    "Uruchomiono proces uploadu faktur z załącznikami " +
    "- plik pojawi się na Google Drive w zależności od ilości faktur proces może trwać do 2 minut";

export const MOVING_INVOICES_SUCCEDED = "Przeniesiono pliki faktur";
export const INVOICE_EXPORT_SUCCEDED = "Wszystkie faktury pomyślnie wysłano do Taxxo";
export const INVOICE_EXPORT_FAILED = "Nie udało się wysłać wszystkich faktur do Taxxo";
export const INVOICES_EXPORT_PARTIALLY_FAILED = "Nie udało się wysłać części faktur do Taxxo";
export const UNLOCK_CONFIRM = "Czy napewno chcesz odwiązać płatność i przenieść fakturę do folderu do zapłaty?";
