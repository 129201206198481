import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";

interface Props {
    layOffs: number;
    newJoiners: number;
    totalAtEndOfMonth: number;
}
export const EmployeeStats: React.FC<Props> = (props: Props) => {
    return (
        <Box display="flex" justifyContent={"flex-end"}>
            <Typography variant={"subtitle1"}>
                Zatrudnienia <b>{props.newJoiners}</b>
            </Typography>
            <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
            <Typography variant={"subtitle1"}>
                Odejścia <b>{props.layOffs}</b>
            </Typography>
            <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
            <Typography variant={"subtitle1"}>
                Łącznie <b>{props.totalAtEndOfMonth}</b>
            </Typography>
        </Box>
    );
};
