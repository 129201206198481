import { Action } from "redux";

import { GroupDef, MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { MonthlySettlement, YearMonthWithRate } from "modules/Invoices/views/MonthlySettlement/domain/types";

export enum ActionType {
    SET_FORM_DEFINITION = "setFormDefinition",
    RESET_FORM_DEFINITION = "resetFormDefinition",
    SET_INITIAL_FORM_DATA = "setInitialFormData",
    RESET_INITIAL_FORM_DATA = "resetInitialFormData",
    SET_ACTIVE_FORM_GROUP = "setActiveFormGroup",
    CLEAR_ACTIVE_FORM_GROUP = "clearActiveFormGroup",
    SET_DATE_AND_RATE = "setDateAndRate",
    SET_REPORTED_DAYS = "setReportedDays",
}
type ActionWithPayload<T extends string, P> = Action<T> & {
    payload: P;
};

type ActionCreator<T extends string> = () => Action<T>;
type ActionWithPayloadCreator<T extends string, P> = (payload: P) => ActionWithPayload<T, P>;

const actionWithPayloadCreator =
    <T extends string, P>(type: T): ActionWithPayloadCreator<T, P> =>
    (payload: P) => ({
        type,
        payload,
    });

const actionCreator =
    <T extends string>(type: T): ActionCreator<T> =>
    () => ({ type });

export const AppActions = {
    setFormDefinition: actionWithPayloadCreator<ActionType.SET_FORM_DEFINITION, MonthlySettlementFormDef>(
        ActionType.SET_FORM_DEFINITION,
    ),
    resetFormDefinition: actionCreator<ActionType.RESET_FORM_DEFINITION>(ActionType.RESET_FORM_DEFINITION),
    setInitialFormData: actionWithPayloadCreator<ActionType.SET_INITIAL_FORM_DATA, MonthlySettlement>(
        ActionType.SET_INITIAL_FORM_DATA,
    ),
    resetInitialFormData: actionCreator<ActionType.RESET_INITIAL_FORM_DATA>(ActionType.RESET_INITIAL_FORM_DATA),
    setActiveFormGroup: actionWithPayloadCreator<ActionType.SET_ACTIVE_FORM_GROUP, GroupDef>(
        ActionType.SET_ACTIVE_FORM_GROUP,
    ),
    clearActiveFormGroup: actionCreator<ActionType.CLEAR_ACTIVE_FORM_GROUP>(ActionType.CLEAR_ACTIVE_FORM_GROUP),
    setDateAndRate: actionWithPayloadCreator<ActionType.SET_DATE_AND_RATE, YearMonthWithRate>(
        ActionType.SET_DATE_AND_RATE,
    ),
    setReportedDays: actionWithPayloadCreator<ActionType.SET_REPORTED_DAYS, number>(ActionType.SET_REPORTED_DAYS),
};

export type AppActions = { [K in keyof typeof AppActions]: ReturnType<typeof AppActions[K]> };
export type AllAppActions = ReturnType<typeof AppActions[keyof typeof AppActions]>;
