import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
    AsyncResult,
    KnownMimeTypes,
    isValidationErrorResponseBody,
    isHttp4xxErrorResponseBody,
} from "components/common/infractructure/types";
import { saveAs } from "file-saver";
import { mergeRight } from "ramda";
import { Maybe, Result } from "true-myth";

export const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL,
});

const isCreateUserRequest = (config: AxiosRequestConfig) =>
    config.url?.includes("userProfile/create") && config.method?.toLowerCase() === "post";

// adds access token in all api requests
// this interceptor is added only when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"]) => {
    api.interceptors.request.use(async config => {
        const token = await getAccessTokenSilently();
        if (isCreateUserRequest(config)) {
            // for POST /user/create, we don't attach the Authorization header, but instead put the token in the body (backend API requirement)
            config.data = { ...config.data, token };
        } else {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
};

export default api;

type ResponseType = "data-only" | "full-response";
type Response<T extends ResponseType, Data> = T extends "data-only" ? Data : AxiosResponse<Data>;
const executeApiAction = async <T, R extends ResponseType = "data-only">(
    action: () => Promise<AxiosResponse<T>>,
    responseType: R = "data-only" as R,
): Promise<Result<Response<R, T>, AxiosError>> => {
    try {
        const response = await action();
        return (responseType === "data-only" ? Result.ok(response.data) : Result.ok(response)) as Result<
            Response<R, T>,
            AxiosError
        >;
    } catch (error) {
        const axiosError = error as AxiosError;
        const body = axiosError.response?.data;

        if (isHttp4xxErrorResponseBody(body)) {
            axiosError.message = body?.messagePL ?? body.message;

            if (isValidationErrorResponseBody(body)) {
                const validationErrors = body.validationErrors
                    .map(({ message, messagePL }) => messagePL ?? message)
                    .join("; ");
                axiosError.message = `${axiosError.message}: ${validationErrors}`;
            }
        }

        return Result.err(axiosError);
    }
};

export const apiGet = <T>(url: string, config?: AxiosRequestConfig): AsyncResult<T> =>
    executeApiAction(() => api.get<T>(url, config));

export const apiGetWithResponseHeaders = <T>(url: string, config?: AxiosRequestConfig): AsyncResult<AxiosResponse<T>> =>
    executeApiAction(() => api.get<T>(url, config), "full-response");

const apiUpdate =
    (method: typeof api["post"] | typeof api["put"] | typeof api["patch"]) =>
    <T, R>(url: string, data?: T, config?: AxiosRequestConfig): AsyncResult<R> => {
        const options = Maybe.of(config).unwrapOr({});
        return executeApiAction(() =>
            method<R>(url, data, {
                ...options,
                headers: mergeRight(
                    { nopage: true }, // 401 instead of 302 Spring Security
                    Maybe.of((options as AxiosRequestConfig).headers).unwrapOr({}),
                ),
            }),
        );
    };

export const apiPost = apiUpdate(api.post);
export const apiPut = apiUpdate(api.put);
export const apiPatch = apiUpdate(api.patch);
export const apiDelete = apiUpdate(api.delete);

export const healthCheck = (): AsyncResult<string> => apiGet<string>("/healthCheck/ping");

export const downloadFile = async (
    getter: () => AsyncResult<Blob>,
    onError: (err: Error) => void,
    fileName: string,
    mimeType: KnownMimeTypes,
) => {
    (await getter()).match({
        Ok: data => saveAs(new Blob([data], { type: mimeType }), fileName),
        Err: error => onError(error),
    });
};
