import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { MUI_INPUT_DATE_STRING_FORMAT, Nullable } from "components/common/types";
import { format } from "date-fns";
import { Dayjs } from "dayjs";
import { isNil, omit } from "ramda";
import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Maybe } from "true-myth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import en from "dayjs/locale/pl";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface DatePickerWrapperProps extends WrappedFieldProps {
    storeFormat: string;
    label: string;
    disableFuture?: boolean;
    required?: boolean;
}

const DatePickerWrapper = ({
    input,
    storeFormat,
    label,
    disableFuture = false,
    required = false,
    meta: { error },
}: DatePickerWrapperProps) => {
    const { value, onChange } = input;

    const handleChange = (date: Nullable<Dayjs>) =>
        Maybe.of(date).match({
            Just: dayJsDate => {
                if (dayJsDate.isValid()) {
                    onChange(format(dayJsDate.toDate(), storeFormat));
                } else {
                    onChange(date ?? "");
                }
            },
            Nothing: () => onChange(null),
        });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={en}>
            <DatePicker
                {...input}
                label={label}
                value={value ? new Date(value) : null}
                onChange={handleChange}
                disableFuture={disableFuture}
                inputFormat={MUI_INPUT_DATE_STRING_FORMAT}
                renderInput={params => (
                    <TextField {...params} error={!isNil(error)} required={required} variant="standard" />
                )}
            />
        </LocalizationProvider>
    );
};

export interface WrappedDatePickerProps extends BaseFieldProps<DatePickerProps<unknown, unknown>> {
    name: string;
    storeFormat: string;
    label: string;
    disableFuture?: boolean;
    required?: boolean;
}

export const WrappedDatePicker = React.memo((props: WrappedDatePickerProps) => {
    const { validate, name, required = false, storeFormat, label, disableFuture } = props;
    return (
        <Field
            validate={validate}
            name={name}
            component={DatePickerWrapper}
            required={required}
            props={{
                storeFormat,
                label,
                disableFuture,
                required,
            }}
            {...(omit(["datePickerProps", "name", "component"], props) as Omit<
                WrappedDatePickerProps,
                "datePickerProps" | "name" | "component"
            >)}
        />
    );
});
