import { sectionFormInputStyles } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/styles";
import { GenericFormInputProps } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { WrappedTextField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/WrappedTextField";
import {
    fieldNotEmpty,
    numberFieldGreaterThanOrEqualZero,
} from "modules/Invoices/views/MonthlySettlement/view/form-fields/fieldHelpers";
import { ConstantField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/ConstantField";
import { Box } from "@mui/material";
import { fieldNumberParser } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/numberParser";

export interface WorkSectionFormInputProps extends GenericFormInputProps {
    sectionDefId: number;
}

export const WorkSectionFormInput = ({ name, sectionDefId }: WorkSectionFormInputProps) => (
    <Box sx={sectionFormInputStyles.root}>
        <div>
            <WrappedTextField
                name={`${name}.quantity`}
                parse={fieldNumberParser}
                textFieldProps={{
                    type: "number",
                    label: "Liczba dni",
                    required: true,
                    size: "small",
                    variant: "standard",
                }}
                validate={[fieldNotEmpty, numberFieldGreaterThanOrEqualZero]}
            />
        </div>
        <Box sx={sectionFormInputStyles.wideFieldWrapper}>
            <WrappedTextField
                name={`${name}.comment`}
                textFieldProps={{
                    type: "text",
                    label: "Komentarz",
                    fullWidth: true,
                    size: "small",
                    variant: "standard",
                }}
            />
        </Box>
        <ConstantField fieldName={`${name}.sectionDefId`} fieldValue={sectionDefId} />
    </Box>
);
