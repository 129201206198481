import { Box, CircularProgress, TextField } from "@mui/material";
import * as React from "react";
import { Column } from "@material-table/core";
import { PaymentSearchResult } from "../../types";
import { Table, TableActionIconButton } from "../../../../components/common/ui-kit/components/Table";
import TaskIcon from "@mui/icons-material/Task";
import { boldHeaderStyle } from "../../../../components/common/ui-kit/components/MaterialTable/utils";
import { localizationMaterialTable } from "../../../../components/common/localization/localization";
import { useState, useCallback, useEffect } from "react";
import { EmptyListInfo } from "../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";
import { AsyncResult } from "../../../../components/common/infractructure";
import { toast } from "react-hot-toast";
import debounce from "lodash.debounce";

interface Props {
    onSelected: (selected: PaymentSearchResult) => Promise<void>;
    getSearchResult: (query: string) => AsyncResult<PaymentSearchResult[]>;
}

export const PaymentSelection: React.FC<Props> = (props: Props) => {
    const [payments, setPayments] = useState<PaymentSearchResult[]>([]);
    const [loading, setLoading] = useState(false);

    const columns: Column<PaymentSearchResult>[] = [
        { field: "bookedDate", title: "Data księgowania" },
        { field: "description", title: "Opis przelewu" },
        { field: "totalAmount", title: "Kwota całkowita", render: data => data.totalAmount.toString() },
        { field: "unusedAmount", title: "Kwota wolna", render: data => data.unusedAmount.toString() },
        { field: "currency", title: "Waluta" },
    ];

    const table = (
        <Table
            columns={columns}
            actionsColumn={document => {
                return (
                    <>
                        <TableActionIconButton
                            tooltip="Wybierz"
                            Icon={TaskIcon}
                            onClick={() => props.onSelected(document)}
                        />
                    </>
                );
            }}
            data={payments}
            components={{
                Container: props => <Box {...props} elevation={0} />,
            }}
            options={{
                showTitle: false,
                search: false,
                grouping: false,
                selection: false,
                showTextRowsSelected: true,
                paging: payments.length > 6,
                pageSize: 6,
                idSynonym: "documentId",
                headerStyle: boldHeaderStyle(),
            }}
            localization={localizationMaterialTable}
            isLoading={loading}
        />
    );

    const handleSearchDocuments = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        setLoading(true);
        const res = await props.getSearchResult(e.target.value);
        if (res.isOk()) {
            setPayments(res.unsafelyUnwrap());
        } else {
            toast.error("Pobieranie transkacji nie powiodło się");
        }
        setLoading(false);
    };

    const debounceHandleSearchDocuments = useCallback(debounce(handleSearchDocuments, 300), []);

    useEffect(() => {
        return () => {
            debounceHandleSearchDocuments.cancel();
        };
    }, [handleSearchDocuments]);

    const spinner = (
        <Box sx={{ height: "200px" }}>
            <CircularProgress sx={{ maxHeight: "100px", marginTop: "50px" }} />
        </Box>
    );

    return (
        <>
            <Box sx={{ textAlign: "center", maxWidth: "20rem" }}>
                <TextField
                    sx={{ marginTop: 2 }}
                    type="text"
                    label="Szukaj płatności"
                    size="medium"
                    fullWidth
                    onChange={debounceHandleSearchDocuments}
                    variant="standard"
                />
            </Box>
            <Box>{loading ? spinner : payments.length > 0 ? table : <EmptyListInfo />}</Box>
        </>
    );
};
