import { User as Auth0User } from "@auth0/auth0-react";
import { Currency, DateString, Department, NamedEnumeration, Nullable, Specialization } from "components/common/types";
import { Role } from "components/RBAC/RBACutils";
import { Maybe } from "true-myth";

export interface UserDTO {
    firstName: string;
    isEmployee: boolean;
    lastName: string;
    roles: Role[];
    username: string;
    needsOnboarding: boolean;
    dashboardId: string;
    avatarUrl: string | null;
    gmailTokens: GmailToken[];
}

export interface GmailToken {
    id: number;
    email: string;
    created: string;
}

export interface User {
    firstName: string;
    isEmployee: boolean;
    lastName: string;
    roles: Role[];
    username: string;
    needsOnboarding: boolean;
    dashboardId: string;
    avatarUrl: Maybe<string>;
    gmailTokens: GmailToken[];
}

export const mapUserDTO = (dto: UserDTO): User => ({
    firstName: dto.firstName,
    isEmployee: dto.isEmployee,
    lastName: dto.lastName,
    roles: dto.roles,
    username: dto.username,
    needsOnboarding: dto.needsOnboarding,
    dashboardId: dto.dashboardId,
    gmailTokens: dto.gmailTokens,
    avatarUrl: Maybe.of(dto.avatarUrl),
});

export interface ContractorDTO {
    accountNumber: Nullable<string>;
    addressLine1: Nullable<string>;
    addressLine2: Nullable<string>;
    companyName: Nullable<string>;
    country: Nullable<string>;
    currency: Currency;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    isVatPayer: boolean;
    nip: Nullable<string>;
    ownerFullName: Nullable<string>;
    swift: Nullable<string>;
    contactAddressLine1: Nullable<string>;
    contactAddressLine2: Nullable<string>;
    parcelLocker: Nullable<string>;
    passportExpirationDate: Nullable<string>;
    gitHubHandle: Nullable<string>;
    twitterHandle: Nullable<string>;
    discordHandle: Nullable<string>;
}

export const defaultContractorData: Contractor = {
    accountNumber: Maybe.nothing(),
    addressLine1: Maybe.nothing(),
    addressLine2: Maybe.nothing(),
    companyName: Maybe.nothing(),
    country: Maybe.nothing(),
    currency: {
        name: "PLN",
    },
    isInvoiceAttached: true,
    isMonthlyCharged: false,
    isVatPayer: true,
    nip: Maybe.nothing(),
    ownerFullName: Maybe.nothing(),
    swift: Maybe.nothing(),
    contactAddressLine1: Maybe.nothing(),
    contactAddressLine2: Maybe.nothing(),
    parcelLocker: Maybe.nothing(),
    passportExpirationDate: Maybe.nothing(),
    gitHubHandle: Maybe.nothing(),
    twitterHandle: Maybe.nothing(),
    discordHandle: Maybe.nothing(),
};

export interface Contractor {
    accountNumber: Maybe<string>;
    addressLine1: Maybe<string>;
    addressLine2: Maybe<string>;
    companyName: Maybe<string>;
    country: Maybe<string>;
    currency: Currency;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    isVatPayer: boolean;
    nip: Maybe<string>;
    ownerFullName: Maybe<string>;
    swift: Maybe<string>;
    contactAddressLine1: Maybe<string>;
    contactAddressLine2: Maybe<string>;
    parcelLocker: Maybe<string>;
    passportExpirationDate: Maybe<string>;
    gitHubHandle: Maybe<string>;
    twitterHandle: Maybe<string>;
    discordHandle: Maybe<string>;
}

export const mapContractorDTO = (contractorDTO: ContractorDTO): Contractor => ({
    ...contractorDTO,
    contactAddressLine1: Maybe.of(contractorDTO.contactAddressLine1),
    contactAddressLine2: Maybe.of(contractorDTO.contactAddressLine2),
    parcelLocker: Maybe.of(contractorDTO.parcelLocker),
    passportExpirationDate: Maybe.of(contractorDTO.passportExpirationDate),
    gitHubHandle: Maybe.of(contractorDTO.gitHubHandle),
    twitterHandle: Maybe.of(contractorDTO.twitterHandle),
    discordHandle: Maybe.of(contractorDTO.discordHandle),
    accountNumber: Maybe.of(contractorDTO.accountNumber),
    addressLine1: Maybe.of(contractorDTO.addressLine1),
    addressLine2: Maybe.of(contractorDTO.addressLine2),
    companyName: Maybe.of(contractorDTO.companyName),
    country: Maybe.of(contractorDTO.country),
    nip: Maybe.of(contractorDTO.nip),
    ownerFullName: Maybe.of(contractorDTO.ownerFullName),
    swift: Maybe.of(contractorDTO.swift),
});

export interface EmploymentDataDTO {
    dateHired: DateString;
    department: Nullable<NamedEnumeration<Department>>;
    initialRate: Nullable<number>;
    level: Nullable<string>; // TODO eventually consider creating a union type for levels, eg type Level = "S3" | "M2" etc
    positionId: number;
    rate: number;
    specialization: Nullable<NamedEnumeration<Specialization>>;
}

export const defaultEmploymentData = (dateHired: Date): EmploymentData => ({
    dateHired,
    department: Maybe.nothing(),
    initialRate: Maybe.nothing(),
    level: Maybe.nothing(),
    positionId: 0,
    rate: 0,
    specialization: Maybe.nothing(),
});

export interface EmploymentData {
    dateHired: Date;
    department: Maybe<Department>;
    initialRate: Maybe<number>;
    level: Maybe<string>;
    positionId: number;
    rate: number;
    specialization: Maybe<Specialization>;
}

export const mapEmploymentDataDTO = (employmentDataDTO: EmploymentDataDTO): EmploymentData => ({
    ...employmentDataDTO,
    dateHired: new Date(employmentDataDTO.dateHired),
    department: Maybe.of(employmentDataDTO.department).map(({ name }) => name),
    initialRate: Maybe.of(employmentDataDTO.initialRate),
    level: Maybe.of(employmentDataDTO.level),
    specialization: Maybe.of(employmentDataDTO.specialization).map(({ name }) => name),
});

export interface CreateUserPayload {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
}

type Auth0UserFieldSelection = Pick<Auth0User, "given_name" | "family_name" | "nickname" | "email">;

export const mapAuth0UserToCreateUserPayload = (user: Auth0UserFieldSelection | undefined): CreateUserPayload => ({
    firstName: user?.given_name ?? "unknown",
    lastName: user?.family_name ?? "unknown",
    username: user?.nickname ?? "unknown",
    email: user?.email ?? "unknown",
});

export interface CreatedUserDTO {
    user: UserDTO;
}

export interface UserProfileDataDTO {
    user: UserDTO;
    contractorData: Nullable<ContractorDTO>;
    employmentData: Nullable<EmploymentDataDTO>;
}

export interface UserProfileData {
    user: User;
    contractorData: Maybe<Contractor>;
    employmentData: Maybe<EmploymentData>;
}

export const mapUserProfileDataDTO = ({
    user,
    contractorData,
    employmentData,
}: UserProfileDataDTO): UserProfileData => ({
    user: mapUserDTO(user),
    contractorData: Maybe.of(contractorData).map(mapContractorDTO),
    employmentData: Maybe.of(employmentData).map(mapEmploymentDataDTO),
});

export const mapCreatedUserDTO = (user: UserDTO, dateHired: Date): UserProfileData => ({
    user: mapUserDTO(user),
    contractorData: Maybe.of(defaultContractorData),
    employmentData: Maybe.of(defaultEmploymentData(dateHired)),
});

export interface ContractorInput {
    contactAddressLine1: Nullable<string>;
    contactAddressLine2: Nullable<string>;
    parcelLocker: Nullable<string>;
    passportExpirationDate: Nullable<Date>;
    gitHubHandle: Nullable<string>;
    twitterHandle: Nullable<string>;
    discordHandle: Nullable<string>;
    companyName: Nullable<string>;
    addressLine1: Nullable<string>;
    addressLine2: Nullable<string>;
    nip: Nullable<string>;
    accountNumber: Nullable<string>;
    isVatPayer: boolean;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
}

export const updateUserProfileData = (
    userProfileData: UserProfileData,
    {
        contactAddressLine1,
        contactAddressLine2,
        parcelLocker,
        passportExpirationDate,
        gitHubHandle,
        twitterHandle,
        discordHandle,
        companyName,
        addressLine1,
        addressLine2,
        nip,
        accountNumber,
        isVatPayer,
        isInvoiceAttached,
        isMonthlyCharged,
    }: ContractorInput,
): UserProfileData => ({
    ...userProfileData,
    contractorData: Maybe.of({
        ...userProfileData.contractorData.unwrapOr(defaultContractorData),
        companyName: Maybe.of(companyName),
        addressLine1: Maybe.of(addressLine1),
        addressLine2: Maybe.of(addressLine2),
        nip: Maybe.of(nip),
        accountNumber: Maybe.of(accountNumber),
        isVatPayer,
        isInvoiceAttached,
        isMonthlyCharged,
        contactAddressLine1: Maybe.of(contactAddressLine1),
        contactAddressLine2: Maybe.of(contactAddressLine2),
        parcelLocker: Maybe.of(parcelLocker),
        passportExpirationDate: Maybe.of(passportExpirationDate).map(e => e.toDateString()),
        gitHubHandle: Maybe.of(gitHubHandle),
        twitterHandle: Maybe.of(twitterHandle),
        discordHandle: Maybe.of(discordHandle),
    }),
});
