import { noop } from "components/common/utils";
import { GroupDef, MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import {
    GlobalState,
    MonthlySettlement,
    MONTHLY_SETTLEMENT_FORM,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import { monthlySettlementToFormValues } from "modules/Invoices/views/MonthlySettlement/service/formService";
import { GroupForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GroupForm";
import { SUMMARY_PAGE_ID } from "modules/Invoices/views/MonthlySettlement/view/WizardNavigation/WizardNavigation";
import { indexOf } from "ramda";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { submit } from "redux-form";
import { Maybe } from "true-myth";

interface Props {
    valid: boolean;
    dirty: boolean;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}
export const MonthlySettlementForm = ({ valid, dirty, handleSubmit }: Props) => {
    const dispatch = useDispatch();
    const triggerSubmit = () => {
        dispatch(submit(MONTHLY_SETTLEMENT_FORM));
    };
    const formDefinition = useSelector<GlobalState, Maybe<MonthlySettlementFormDef>>(state => state.app.formDefinition);
    const initialFormData = useSelector<GlobalState, Maybe<MonthlySettlement>>(state => state.app.initialFormData);
    const navigate = useNavigate();
    const { activeWizardPageId } = useParams<{
        activeWizardPageId: string;
    }>();

    const createNextPageClick = (groups: GroupDef[], group: GroupDef) => {
        const currentGroupIndex = indexOf(group, groups);
        const isLastGroup = currentGroupIndex === groups.length - 1;
        return isLastGroup
            ? () => {
                  triggerSubmit();
                  navigate(`../${SUMMARY_PAGE_ID}`);
              }
            : () => {
                  triggerSubmit();
                  navigate(`../form/${groups[currentGroupIndex + 1].groupDefId}`);
              };
    };

    const createOnPreviousPageClick = (groups: GroupDef[], group: GroupDef) => {
        const currentGroupIndex = indexOf(group, groups);
        return currentGroupIndex === 0
            ? noop
            : () => {
                  triggerSubmit();
                  navigate(`../form/${groups[currentGroupIndex - 1].groupDefId}`);
              };
    };

    return Maybe.tuple([formDefinition, initialFormData])
        .map(([{ groups, settlementDefId }, data]) => {
            const groupDef = Maybe.find<GroupDef>(
                ({ groupDefId }) => groupDefId === parseInt(activeWizardPageId ?? "", 10),
                groups,
            ).match({
                Just: group => group,
                Nothing: () => {
                    throw new Error(
                        `Unknown group id parameter was supplied: ${activeWizardPageId}. Check the URL and make sure there's a valid id (number) supplied at the end (eg. "form/6").`,
                    );
                },
            });
            const onNextPageClick = createNextPageClick(groups, groupDef);
            const onPreviousPageClick = createOnPreviousPageClick(groups, groupDef);

            return (
                <GroupForm
                    key={groupDef.groupDefId}
                    {...groupDef}
                    monthlySettlementDefId={settlementDefId}
                    monthlySettlementInitialFormValues={monthlySettlementToFormValues(data)}
                    onPreviousPageClick={onPreviousPageClick}
                    onNextPageClick={onNextPageClick}
                    handleSubmit={handleSubmit}
                    valid={valid}
                    dirty={dirty}
                />
            );
        })
        .unwrapOr(<></>);
};
