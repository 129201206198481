import { AsyncResult } from "components/common/infractructure";
import { Dialog, DialogContent, Button, Typography, Grid, TextField, CircularProgress } from "@mui/material";
import { GenericSelect, OnChangeFn } from "../../../../components/common/ui-kit/components/GenericSelect";
import * as React from "react";
import { SelectValues } from "../../../../components/common/ui-kit/components/GenericSelect/GenericSelect";
import { useEffect, useState } from "react";
import { Maybe } from "true-myth";
import { PrefilledEmail, PromotionEmailNotification, SendPromotionEmailRequest } from "../../types";
import { toast } from "react-hot-toast";
import { POSITION_ID_INITIAL_STATE } from "./PromotionsList";

interface Props {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    availableEmails: SelectValues[];
    notifyAboutPromotion: (requestBody: SendPromotionEmailRequest) => AsyncResult<PromotionEmailNotification>;
    getPrefilledEmail: (positionAssignmentId: number, promotionMonth: string) => AsyncResult<PrefilledEmail>;
    positionAssignmentId: number;
    promotionMonth: string;
    onNotificationSent: (newNotification: PromotionEmailNotification, positionId: number) => void;
}

export const SendEmailModal = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [subject, setSubject] = useState<Maybe<string>>(Maybe.nothing());
    const [body, setBody] = useState<Maybe<string>>(Maybe.nothing());
    const [isValidCC, setIsValidCC] = useState(true);
    const [isValidTo, setIsValidTo] = useState(true);

    const [ccValue, setCCValue] = useState<Maybe<string>>(Maybe.nothing);
    const [toValue, setToValue] = useState<Maybe<string>>(Maybe.nothing);
    const [currentFrom, setCurrentFrom] = useState<Maybe<SelectValues>>(
        Maybe.of(props.availableEmails.length > 0 ? props.availableEmails[0] : null),
    );
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const positionIdAlreadyKnown = props.positionAssignmentId !== POSITION_ID_INITIAL_STATE;
        if (positionIdAlreadyKnown) {
            setLoading(true);
            setHasError(false);
            const abortController = new AbortController();
            const fetchData = async () => {
                try {
                    const res = await props.getPrefilledEmail(props.positionAssignmentId, props.promotionMonth);
                    res.match({
                        Ok: resolvedData => {
                            setBody(Maybe.of(resolvedData.bodyContent));
                            setSubject(Maybe.of(resolvedData.subjectContent));
                            setCCValue(Maybe.of(resolvedData.cc));
                            setToValue(Maybe.of(resolvedData.to));
                            setLoading(false);
                            setHasError(false);
                        },
                        Err: () => {
                            setLoading(false);
                            setHasError(true);
                        },
                    });
                } catch (err) {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }
                }
            };

            fetchData();

            return () => {
                abortController.abort();
            };
        }
    }, [props.positionAssignmentId, props.promotionMonth]);

    const handleCCChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cc = e.currentTarget.value;
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cc) || cc === "";
        setIsValidCC(isValid);
        setCCValue(Maybe.of(cc));
    };

    const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const to = e.currentTarget.value;
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(to) || to === "";
        setIsValidTo(isValid);
        setToValue(Maybe.of(to));
    };

    const onChangeFrom: OnChangeFn = ({ target }, _child) => {
        const value = target.value as string;
        setCurrentFrom(Maybe.of({ display: value, value: value }));
    };

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const subject = e.currentTarget.value;
        setSubject(Maybe.of(subject));
    };

    const handleBodyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const body = e.currentTarget.value;
        setBody(Maybe.of(body));
    };

    const handleNotify = async () => {
        props.setShowDialog(false);
        (
            await props.notifyAboutPromotion({
                positionAssignmentId: props.positionAssignmentId,
                yearMonth: props.promotionMonth,
                cc: ccValue.unsafelyUnwrap(),
                from: currentFrom.unsafelyUnwrap().value as string,
                to: toValue.unsafelyUnwrap(),
                subject: subject.unsafelyUnwrap(),
                body: body.unsafelyUnwrap(),
            })
        ).match({
            Ok: res => {
                props.onNotificationSent(res, props.positionAssignmentId);
                toast.success("Pomyślnie powiadomiono pracownika o awansie!");
            },
            Err: () => {
                toast.error("Nie udało się wysłać powiadomienia o awansie!");
            },
        });
    };

    return (
        <Dialog open={props.showDialog} maxWidth={"md"} fullWidth={true} onClose={() => props.setShowDialog(false)}>
            <Typography variant="h4" sx={{ textAlign: "center", fontSize: 24 }}>
                Wyślij email
            </Typography>

            {!hasError && !loading && body.isJust() && subject.isJust() && currentFrom.isJust() ? (
                <>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item>
                                <GenericSelect
                                    label="Od"
                                    value={currentFrom.unsafelyUnwrap().value}
                                    values={props.availableEmails}
                                    onChange={onChangeFrom}
                                    sx={{ width: "100%", marginTop: "30px" }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    error={!isValidTo}
                                    variant="standard"
                                    label="Do"
                                    size="small"
                                    fullWidth
                                    sx={{ width: "100%", marginTop: "30px" }}
                                    value={toValue.unwrapOr("")}
                                    onChange={handleToChange}
                                />
                            </Grid>
                            <Grid item sx={{ width: "100%" }}>
                                <TextField
                                    error={!isValidCC}
                                    variant="standard"
                                    label="CC"
                                    size="small"
                                    fullWidth
                                    sx={{ width: "100%", marginTop: "30px" }}
                                    value={ccValue.unwrapOr("")}
                                    onChange={handleCCChange}
                                />
                            </Grid>
                            <Grid item sx={{ width: "100%" }}>
                                <TextField
                                    variant="standard"
                                    label="Temat"
                                    size="small"
                                    fullWidth
                                    sx={{ width: "100%", marginTop: "30px" }}
                                    value={subject.unsafelyUnwrap()}
                                    onChange={handleSubjectChange}
                                />
                            </Grid>
                            <Grid item sx={{ width: "100%" }}>
                                <TextField
                                    variant="standard"
                                    label="Treść"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={body.unsafelyUnwrap()}
                                    sx={{ width: "100%", marginTop: "30px" }}
                                    onChange={handleBodyChange}
                                />
                            </Grid>
                            <Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Button
                                    disabled={!isValidCC || !isValidTo}
                                    sx={{ marginTop: "25px" }}
                                    onClick={() => handleNotify()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Wyślij e-mail
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            ) : (
                <Grid container direction="column" alignItems={"center"}>
                    <Grid item>
                        <>
                            {hasError ? (
                                <p style={{ color: "red" }}>Nie udało się przygotwać treści maila!</p>
                            ) : (
                                <CircularProgress sx={{ maxHeight: "100px", marginTop: "50px" }} />
                            )}
                        </>
                    </Grid>
                </Grid>
            )}
        </Dialog>
    );
};
