import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { formatMoneyForView } from "components/common/utils/numberUtils";

interface Props {
    sumInPln: number;
    sumOfDays: number;
}
export const BillingStats: React.FC<Props> = ({ sumInPln, sumOfDays }) => {
    return (
        <Box display="flex" justifyContent={"flex-end"}>
            <Typography variant={"subtitle1"}>
                Suma sprzedaży <b>{formatMoneyForView(sumInPln, "PLN")}</b>
            </Typography>
            <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
            <Typography variant={"subtitle1"}>
                Suma dniówek <b>{sumOfDays}</b> dni
            </Typography>
        </Box>
    );
};
