import { ThemeOptions } from "@mui/material";
import { custom, invoices, navigate, home } from "./colors";

export const lightTheme: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#319db5",
            light: "#d5d5d5",
        },
        secondary: {
            main: "#c75757",
        },
        main: {
            background: "#f4f4f4",
        },
        navigate,
        custom,
        home,
        invoices: {
            ...invoices,
            summaryRow: "#121826",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};
