import { Paper, Typography } from "@mui/material";
import { apiPost, AsyncResult } from "components/common/infractructure";
import { logError } from "components/common/infractructure/logger";
import { createProject } from "modules/Projects/service/apiService";
import { Employee, getNewProjectFormDataDefaults, ProjectConfig } from "modules/Projects/types";
import ProjectForm from "modules/Projects/views/components/ProjectForm";
import toast from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Result } from "true-myth";

interface Props {
    fetchActiveUsers: () => AsyncResult<Employee[]>;
}
export const AddProject = (props: Props) => {
    const loaderResult = useLoaderData() as Result<ProjectConfig, Error>;
    const now = new Date();
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
                Nowy projekt
            </Typography>
            <Paper sx={{ padding: "1rem" }}>
                {loaderResult
                    .map(projectConfig => (
                        <ProjectForm
                            fetchActiveUsers={props.fetchActiveUsers}
                            key={0}
                            mode="CREATE"
                            projectConfig={projectConfig}
                            projectFormData={getNewProjectFormDataDefaults(now, projectConfig)}
                            onSubmit={async data => {
                                const result = await createProject(apiPost)(data);
                                return result.match({
                                    Ok: project => {
                                        toast.success("Utworzono projekt");
                                        navigate(`/projects/show/${project.id}`);
                                        return Promise.resolve();
                                    },
                                    Err: err => {
                                        toast.error(`Nie udało się utworzyć projektu: ${err.message}`);
                                        logError(err);
                                        return Promise.reject(err);
                                    },
                                });
                            }}
                        />
                    ))
                    .unwrapOrElse(err => (
                        <Typography variant="body2" color="error">
                            coś poszło nie tak: {err.message}
                        </Typography>
                    ))}
            </Paper>
        </>
    );
};
