import {
    compose,
    concat,
    curry,
    filter,
    flatten,
    groupBy,
    isEmpty,
    isNil,
    keys,
    map,
    mergeAll,
    mergeRight,
    omit,
    reduce,
    values,
} from "ramda";
import { Maybe } from "true-myth";

import {
    Activity,
    ActivityIdentifier,
    ClientCostPosition,
    CostPosition,
    instanceOfProjectActivity,
    MonthlySettlement,
    PendingPayment,
    PendingPaymentInput,
    MonthlySettlementPendingPaymentPosition,
    PendingPaymentPositionInput,
    Position,
    ProjectActivity,
    SectionIdentifier,
    YearMonthDto,
    YearMonthWithRate,
} from "modules/Invoices/views/MonthlySettlement/domain/types";

import { TAX_RATE } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { parseMonth } from "components/common/utils";
import { calculateTax, parseNumber } from "components/common/utils/numberUtils";

export const PROJECT_ACTIVITY_KEY = "projectActivity";
export const PROJECT_ACTIVITIES_KEY = "projectActivities";
export const ACTIVITY_KEY = "activity";
export const ACTIVITIES_KEY = "activities";

export type MonthlySettlementFormValues = ReturnType<typeof monthlySettlementToFormValues>;
export type ActivityFormValue = ReturnType<typeof mapActivityToFormValue>;
export type ProjectActivityFormValue = ReturnType<typeof mapProjectActivityToFormValue>;

export interface KeyActivityFormValuePair {
    [key: string]: ActivityFormValue | null;
}
export interface KeyProjectActivityFormValuePair {
    [key: string]: ProjectActivityFormValue | null;
}

export const getKeyForActivity = <T extends ActivityIdentifier>(activity: T): string =>
    `${instanceOfProjectActivity(activity) ? PROJECT_ACTIVITY_KEY + `-${activity.assignmentId}` : ACTIVITY_KEY}-${
        activity.activityDefId
    }`;

export const getKeyForSection = <T extends SectionIdentifier>(section: T): string =>
    `positions-${section.sectionDefId}`;

export const groupPositionsBySectionDefId = <T extends Position = Position | CostPosition | ClientCostPosition>(
    positions: T[],
): { [index: string]: T[] } => groupBy<T>(p => getKeyForSection(p))(positions);

export type AnyPosition = Position | CostPosition | ClientCostPosition;
export interface GroupedPositions<T extends AnyPosition> {
    [key: string]: T[];
}

export const mergePositionsGroupedBySectionDefId = <T extends AnyPosition>(groupedPositions: GroupedPositions<T>) =>
    flatten(map<string, T[]>((key: string) => groupedPositions[key], keys(groupedPositions) as string[]));

export const mapActivityToFormValue = (activity: Activity) => ({
    id: activity.id,
    activityDefId: activity.activityDefId,
    workPositions: groupPositionsBySectionDefId(activity.workPositions),
    costPositions: groupPositionsBySectionDefId(activity.costPositions),
});

export const mapProjectActivityToFormValue = (activity: ProjectActivity) =>
    mergeRight(mapActivityToFormValue(activity), {
        id: activity.id,
        assignmentId: activity.assignmentId,
        clientCostPositions: groupPositionsBySectionDefId(activity.clientCostPositions),
    });

export const mapToActivity = (activityFormValue: ActivityFormValue): Activity => ({
    ...activityFormValue,
    workPositions: mergePositionsGroupedBySectionDefId(activityFormValue.workPositions),
    costPositions: mergePositionsGroupedBySectionDefId(activityFormValue.costPositions),
});

export const mapToProjectActivity = (activityFormValue: ProjectActivityFormValue): ProjectActivity => ({
    ...activityFormValue,
    workPositions: mergePositionsGroupedBySectionDefId(activityFormValue.workPositions),
    costPositions: mergePositionsGroupedBySectionDefId(activityFormValue.costPositions),
    clientCostPositions: mergePositionsGroupedBySectionDefId(activityFormValue.clientCostPositions),
});

const mapToKeyActivityFormValuePair = (activity: Activity) => ({
    [getKeyForActivity(activity)]: mapActivityToFormValue(activity),
});

const mapToKeyProjectActivityFormValuePair = (activity: ProjectActivity): KeyProjectActivityFormValuePair => ({
    [getKeyForActivity(activity)]: mapProjectActivityToFormValue(activity),
});

export const monthlySettlementToFormValues = (monthlySettlement: MonthlySettlement) => ({
    ...monthlySettlement,
    [PROJECT_ACTIVITIES_KEY]: compose(
        (list: KeyProjectActivityFormValuePair[]) => mergeAll(list),
        map<ProjectActivity, KeyProjectActivityFormValuePair>(mapToKeyProjectActivityFormValuePair),
    )(monthlySettlement.projectActivities),
    [ACTIVITIES_KEY]: compose(
        (list: KeyActivityFormValuePair[]) => mergeAll(list),
        map<Activity, KeyActivityFormValuePair>(mapToKeyActivityFormValuePair),
    )(monthlySettlement.activities),
});

export const projectActivitiesFormValuesToProjectActivities = (projectActivities: KeyProjectActivityFormValuePair) =>
    map<ProjectActivityFormValue, ProjectActivity>(
        mapToProjectActivity,
        filter(el => !isNil(el), values(projectActivities)) as ProjectActivityFormValue[],
    );

export const activitiesFormValuesToActivities = (activities: KeyActivityFormValuePair) =>
    map<ActivityFormValue, Activity>(
        mapToActivity,
        filter(el => !isNil(el), values(activities)) as ActivityFormValue[],
    );

export const formValuesToMonthlySettlement = (formValues: MonthlySettlementFormValues): MonthlySettlement => ({
    ...formValues,
    projectActivities: projectActivitiesFormValuesToProjectActivities(formValues[PROJECT_ACTIVITIES_KEY]),
    activities: activitiesFormValuesToActivities(formValues[ACTIVITIES_KEY]),
});

const flattenValues = <T>(obj: { [key: string]: T[] }) => flatten(values(obj));
const concatAll = <T>(arrays: T[][]) => reduce<T[], T[]>((acc, elem) => concat(acc, elem), [])(arrays);

export const isActivityEmpty = (activity: ProjectActivityFormValue | ActivityFormValue) => {
    const { costPositions, workPositions } = activity;
    const clientCostPositions = Maybe.of((activity as ProjectActivityFormValue).clientCostPositions).unwrapOr({});
    return isEmpty(
        concatAll([flattenValues(clientCostPositions), flattenValues(costPositions), flattenValues(workPositions)]),
    );
};

export const positionToPositionInput = (
    position: MonthlySettlementPendingPaymentPosition,
): PendingPaymentPositionInput => ({
    ...omit(["vacancy"], position),
    projectAssignmentId: position.vacancy ? position.vacancy.id : null,
});

export const pendingPaymentToPendingPaymentInput = (pendingPayment: PendingPayment): PendingPaymentInput => ({
    ...pendingPayment,
    contractor: pendingPayment.contractorData,
    positions: pendingPayment.positions.map(positionToPositionInput),
});

export const updatePositionPrices = curry(
    (
        taxRate: number,
        unitPriceInPLN: number,
        rate: number,
        position: MonthlySettlementPendingPaymentPosition,
    ): MonthlySettlementPendingPaymentPosition => {
        const netAmountInPLN = unitPriceInPLN * position.quantity;
        const taxAmountInPLN = calculateTax(netAmountInPLN, taxRate);
        const grossAmountInPLN = taxAmountInPLN.plus(netAmountInPLN);
        const unitPrice = unitPriceInPLN / rate;
        const netAmount = unitPrice * position.quantity;
        const taxAmount = calculateTax(netAmount, taxRate);
        const grossAmount = taxAmount.plus(netAmount);
        return {
            ...position,
            unitPrice,
            unitPriceInPLN,
            netAmount,
            taxAmount: taxAmount.toNumber(),
            grossAmount: grossAmount.toNumber(),
            netAmountInPLN,
            taxAmountInPLN: taxAmountInPLN.toNumber(),
            grossAmountInPLN: grossAmountInPLN.toNumber(),
        };
    },
);

export const updatePositionPricesIncludingTax = updatePositionPrices(TAX_RATE);

export const parseYearMonth = (a: YearMonthDto): YearMonthWithRate => {
    const separatedYearMonth = a.yearMonth.split("-");
    return {
        month: parseMonth(parseNumber(separatedYearMonth[1]).unwrapOr(1)).unwrapOr(1),
        year: parseNumber(separatedYearMonth[0]).unwrapOr(1),
        rate: a.rate,
        workingDays: a.workingDays,
        hasInvoice: a.hasInvoice,
        rateRaise: a.rateRaise,
    };
};

const instanceOfPosition = (object: any): object is Position => {
    return "quantity" in object;
};

export const sumQuantityByName = (obj: { [key: string]: any }, fieldName: string): number => {
    let sum = 0;

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (key === fieldName) {
                const quantity = flattenValues(value)
                    .filter(item => instanceOfPosition(item))
                    .map(item => item as Position)
                    .reduce((sum, current) => sum + current.quantity, 0);
                sum += quantity;
            }

            if (typeof value === "object" && value !== null) {
                sum += sumQuantityByName(value, fieldName);
            }
        }
    }
    return sum;
};
