import { SectionDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { Position } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { GenericSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GenericSection";
import { SectionBaseProps } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/types";
import * as React from "react";
import { WorkSectionFormInput, WorkSectionFormInputProps } from "./WorkSectionFormInput";

type Props = SectionDef & SectionBaseProps;

export const WorkSection: React.FC<Props> = ({ sectionName, question, instructions, sectionDefId }) => (
    <GenericSection<WorkSectionFormInputProps, Position>
        title={question}
        instructions={instructions}
        fieldArrayName={sectionName}
        sectionRowProps={{
            inputProps: {
                sectionDefId,
            },
            Component: WorkSectionFormInput,
        }}
    />
);
