import { Grid, Paper } from "@mui/material";
import { User } from "modules/UserProfile/types";
import * as React from "react";
import { DetailRow } from "components/common/ui-kit-business/InvoiceSummary/DetailRow";

interface Props {
    userData: User;
}

export const UserInfo: React.FC<Props> = ({ userData: { firstName, lastName, username } }) => (
    <Paper data-testid="UserInfo">
        <Grid container sx={{ padding: "1rem 1.5rem" }}>
            <Grid item xs={6}>
                <DetailRow label="Imię" text={firstName} />
                <DetailRow label="Nazwisko" text={lastName} />
            </Grid>
            <Grid item xs={6}>
                <DetailRow label="Użytkownik" text={username} />
                <DetailRow label="Pracownik" text={"Tak"} />
            </Grid>
        </Grid>
    </Paper>
);
