import { Box } from "@mui/material";
import { Vacancy } from "../../../Projects/types";

import React from "react";

import { SingleVacancy } from "./SingleVacancy";

interface Props {
    vacancies: Vacancy[];
    onlyCommercial: boolean;
}

type GroupKey = string;
type GroupedVacancies = Record<GroupKey, [Vacancy[], Vacancy[]]>;

const groupVacancies = (vacancies: Vacancy[]): GroupedVacancies => {
    return vacancies.reduce((grouped: GroupedVacancies, vacancy: Vacancy) => {
        const key = `${vacancy.projectId}-${vacancy.role.id}`;

        if (!grouped[key]) {
            grouped[key] = [[], []];
        }
        const endDate = vacancy.end.unwrapOr(null);
        const now = new Date();
        if (!endDate || endDate >= now) {
            grouped[key][0].push(vacancy);
        } else {
            grouped[key][1].push(vacancy);
        }
        return grouped;
    }, {});
};

function compareVacancies(vacancyA: Vacancy, vacancyB: Vacancy): number {
    const endAIsNothing = vacancyA.end.isNothing();
    const endBIsNothing = vacancyB.end.isNothing();

    if (endAIsNothing && endBIsNothing) {
        return vacancyB.start.getTime() - vacancyA.start.getTime();
    }

    if (endAIsNothing) return -1;
    if (endBIsNothing) return 1;

    return vacancyB.end.unsafelyUnwrap().getTime() - vacancyA.end.unsafelyUnwrap().getTime();
}

const sortGroupedVacancies = (groupedVacancies: GroupedVacancies): [GroupKey, [Vacancy[], Vacancy[]]][] => {
    return Object.entries(groupedVacancies).sort(
        (
            [, [futureOrNoEndDateVacanciesA, pastEndDateVacanciesA]],
            [, [futureOrNoEndDateVacanciesB, pastEndDateVacanciesB]],
        ) => {
            const hasFutureOrNoEndDateA = futureOrNoEndDateVacanciesA.length > 0;
            const hasFutureOrNoEndDateB = futureOrNoEndDateVacanciesB.length > 0;

            if (hasFutureOrNoEndDateA === hasFutureOrNoEndDateB) {
                if (!hasFutureOrNoEndDateA && pastEndDateVacanciesA.length > 0 && pastEndDateVacanciesB.length > 0) {
                    return (
                        new Date(pastEndDateVacanciesB[0].start).getTime() -
                        new Date(pastEndDateVacanciesA[0].start).getTime()
                    );
                }
                return 0;
            }

            return hasFutureOrNoEndDateA ? -1 : 1;
        },
    );
};

export const EmployeeVacanciesList: React.FC<Props> = (props: Props) => {
    const vacancies = props.vacancies.filter(v => (props.onlyCommercial ? v.isCommercial : !v.isCommercial));

    vacancies.sort(compareVacancies);
    let groupedVacancies = groupVacancies(vacancies);
    const sortedGroupedVacancies = sortGroupedVacancies(groupedVacancies);

    groupedVacancies = Object.fromEntries(sortedGroupedVacancies);

    const x = (
        <>
            {Object.entries(groupedVacancies).map(([key, [futureOrNoEndDateVacancies, pastEndDateVacancies]]) => {
                return <SingleVacancy key={key} active={futureOrNoEndDateVacancies} inactive={pastEndDateVacancies} />;
            })}
        </>
    );

    return vacancies.length > 0 ? (
        x
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", m: "15px" }}>Brak etatów</Box>
    );
};
