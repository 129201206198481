import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { CssBaseline } from "@mui/material";
import ColorModeContextProvider from "components/common/contexts/useAppTheme";
import { addAccessTokenInterceptor } from "components/common/infractructure";
import Loader from "components/Loader/Loader";
import AuthenticatedApp from "modules/AuthenticatedApp/AuthenticatedApp";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

const App = () => {
    const { isLoading, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        addAccessTokenInterceptor(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    if (isLoading) {
        return <Loader fullScreen />;
    }

    const AppProtected = withAuthenticationRequired(AuthenticatedApp, {
        onRedirecting: () => <Loader fullScreen />,
    });

    return (
        <ColorModeContextProvider>
            <AppProtected />
            <CssBaseline enableColorScheme />
            <Toaster
                position="top-right"
                toastOptions={{
                    error: {
                        style: {
                            background: "red",
                            color: "#fff",
                        },
                    },
                }}
            />
        </ColorModeContextProvider>
    );
};

export default App;
