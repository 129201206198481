import * as React from "react";
import { BillingDetailsData } from "modules/Billing/types";
import { Box, Grid, Typography } from "@mui/material";

interface Props {
    billingDetailsData: BillingDetailsData;
}

const unwrap = (value: string) =>
    value && value.trim() ? (
        <Grid item xs={12}>
            <Typography variant={"h6"}>{value}</Typography>
        </Grid>
    ) : (
        <></>
    );

const unwrapTaxCode = (value: string) =>
    value && value.trim() ? (
        <Grid item xs={12} sx={{ m: "1rem 0" }}>
            <b>NIP </b> {value}
        </Grid>
    ) : (
        <></>
    );

export const BillingDetailsDataView: React.FC<Props> = ({ billingDetailsData }) => (
    <Box>
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <Typography variant={"h2"}>{billingDetailsData.companyName}</Typography>
                </Grid>
                {unwrap(billingDetailsData.postCode)}
                {unwrap(billingDetailsData.city)}
                {unwrap(billingDetailsData.country)}
                {unwrapTaxCode(billingDetailsData.taxCode)}
            </Grid>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant={"caption"}>Waluta</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {billingDetailsData.currency}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant={"caption"}>Numer rachunku bankowego</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {billingDetailsData.account}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant={"caption"}>Notatka</Typography>
                </Grid>
                <Grid item xs={12}>
                    {billingDetailsData.notes}
                </Grid>
            </Grid>
        </Grid>
    </Box>
);
