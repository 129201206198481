import { BankAccount, Payment } from "../../types";
import { getAccountsConfig, getTransactions } from "../../service/apiService";
import React from "react";
import { Result } from "true-myth";
import { apiGet } from "../../../../components/common/infractructure";
import { useTransactionSearchParams } from "./useTransactionSearchParams";

type TransactionsDataState =
    | {
          name: "loading";
      }
    | {
          name: "error";
          errors: Error[];
      }
    | {
          name: "data-loaded";
          bankAccounts: BankAccount[];
          payments: Payment[];
      };

interface TransactionsDataApi {
    refreshData: () => Promise<void>;
}

type TransactionsDataContextValue = [TransactionsDataState, TransactionsDataApi];

const TransactionsDataContext = React.createContext<TransactionsDataContextValue>(
    null as unknown as TransactionsDataContextValue,
);

export const TransactionsDataContextProvider = ({ children }: React.PropsWithChildren) => {
    const [state, setState] = React.useState<TransactionsDataState>({ name: "loading" });

    const transactionSearchParams = useTransactionSearchParams();

    const loadData = React.useCallback(async () => {
        setState({ name: "loading" });
        const [paymentsResults, bankAccountsResult] = await Promise.all([
            getTransactions(apiGet)(transactionSearchParams),
            getAccountsConfig(apiGet)(),
        ]);

        paymentsResults
            .flatMap(payments =>
                bankAccountsResult.map(
                    bankAccounts =>
                        ({
                            name: "data-loaded",
                            bankAccounts,
                            payments,
                        } as const),
                ),
            )
            .match({
                Ok: setState,
                Err: () => {
                    const paymentsError = Result.isErr(paymentsResults) ? [paymentsResults.error] : [];
                    const bankAccountsError = Result.isErr(bankAccountsResult) ? [bankAccountsResult.error] : [];
                    setState({
                        name: "error",
                        errors: [...paymentsError, ...bankAccountsError],
                    });
                },
            });
    }, [transactionSearchParams]);

    const transactionDataApi: TransactionsDataApi = React.useMemo(
        () => ({
            refreshData: async () => {
                await loadData();
            },
        }),
        [loadData],
    );

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <TransactionsDataContext.Provider value={[state, transactionDataApi]}>
            {children}
        </TransactionsDataContext.Provider>
    );
};

export const useTransactionsData = () => {
    const context = React.useContext(TransactionsDataContext);

    if (context === null) {
        throw new Error("useTransactionsData must be used within TransactionsDataContextProvider");
    }

    const [state] = context;

    return React.useMemo(() => state, [state]);
};

export const useTransactionsDataApi = () => {
    const context = React.useContext(TransactionsDataContext);

    if (context === null) {
        throw new Error("useTransactionsDataApi must be used within TransactionsDataContextProvider");
    }

    const [, api] = context;

    return React.useMemo(() => api, [api]);
};
