import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AppConfig, AppConfigContext } from "config";
import React from "react";
import { useContext } from "react";

const Auth0ProviderWithHistory: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { auth0 }: AppConfig = useContext(AppConfigContext);

    const onRedirectCallback = (appState?: AppState) =>
        window.location.replace(appState?.returnTo || window.location.pathname);

    return (
        <Auth0Provider
            cacheLocation="localstorage"
            domain={auth0.domain}
            clientId={auth0.clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={auth0.audience}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
