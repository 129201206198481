import Box from "@mui/material/Box";
import { AnyObject } from "components/common/types";
import { NonVatPayer } from "components/common/ui-kit-business/types";
import { ColumnDef, SimpleTable, SimpleTableProps } from "components/common/ui-kit/components/SimpleTable";
import { MonthlySettlementPendingPaymentPosition } from "modules/Invoices/views/MonthlySettlement/domain/types";
import * as React from "react";

export interface WorkSummaryTable {
    isVatPayer: boolean;
    rowData: WorkSummaryTableRow[];
    sumNet: number;
    sumTax: number;
    sumGross: number;
    sumDays: number;
    notVatPayerData: NonVatPayer;
    isMonthlyRate: boolean;
    getMonthlyRateData: <T extends AnyObject>(dataTable: ColumnDef<T>[]) => ColumnDef<T>[];
}

export type WorkRelevantPendingPaymentFields = Pick<
    MonthlySettlementPendingPaymentPosition,
    "unitPrice" | "amountOfDays" | "netAmount" | "taxAmount" | "grossAmount" | "note"
>;
export type WorkSummaryTableRow = WorkRelevantPendingPaymentFields & {
    category: string;
    projectName: string;
};

type RowData = AnyObject;

const colDefs: SimpleTableProps<RowData>["columnDefs"] = [
    {
        header: "Projekt",
        rowDataKey: "projectName",
    },
    {
        header: "Opis",
        rowDataKey: "note",
    },
    {
        header: "Stawka dzienna",
        rowDataKey: "unitPrice",
    },
    {
        header: "Liczba dni",
        rowDataKey: "amountOfDays",
    },
    {
        header: "Netto",
        rowDataKey: "netAmount",
    },
    {
        header: "VAT",
        rowDataKey: "taxAmount",
    },
    {
        header: "Brutto",
        rowDataKey: "grossAmount",
    },
];

export const WorkSummaryTable: React.FC<WorkSummaryTable> = ({
    isVatPayer,
    rowData,
    sumDays,
    sumGross,
    sumNet,
    sumTax,
    notVatPayerData,
    isMonthlyRate,
    getMonthlyRateData,
}) => {
    const lastRow = {
        amountOfDays: sumDays,
        netAmount: sumNet,
        taxAmount: sumTax,
        grossAmount: sumGross,
        projectName: "Razem",
    };

    const { definition, data } = isVatPayer
        ? { definition: colDefs, data: [...rowData, lastRow] }
        : notVatPayerData<typeof lastRow, RowData>(colDefs, [...rowData, lastRow]);

    return (
        <Box
            sx={{
                "& table th": {
                    paddingTop: 1,
                    paddingLeft: 1,
                },

                "& table tr:last-child": {
                    backgroundColor: "primary.light",
                    border: 0,
                    color: "#6e6e6e",
                    borderRadius: "10px",
                },

                "& table tr:last-child td:first-of-type": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                },

                "& table tr:last-child td:last-child": {
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                },
            }}
        >
            <SimpleTable columnDefs={isMonthlyRate ? getMonthlyRateData(definition) : definition} rowData={data} />
        </Box>
    );
};
