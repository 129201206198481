import { Column } from "@material-table/core";
import { CallSplit, MoneyOff, Paid, Print } from "@mui/icons-material";
import TagIcon from "@mui/icons-material/Tag";
import WarningIcon from "@mui/icons-material/Warning";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useData } from "components/common/hooks/useData";
import { apiGetWithResponseHeaders, AsyncResult } from "components/common/infractructure";
import { Nullable, toDateString, YearMonth } from "components/common/types";
import { OnChangeFn } from "components/common/ui-kit/components/GenericSelect";
import { dateToYearMonthString, strToYearMonth } from "components/common/utils";
import { formatForView } from "components/common/utils/numberUtils";
import Loader from "components/Loader/Loader";
import {
    PAYMENT_DOWNLOAD_FAILED,
    TRIGGERING_BATCH_UPLOAD_FAILED,
    TRIGGERING_BATCH_UPLOAD_SUCCEDED,
} from "modules/AccountingManagement/messages";
import { DocumentSpecifications, downloadDocumentWithSpecification } from "modules/AccountingManagement/service";
import { EMPTY_SELECT_VALUE } from "modules/AccountingManagement/service/constants";
import { getDocumentTypeTranslation } from "modules/AccountingManagement/service/documentService";
import { DocumentsFilter } from "modules/AccountingManagement/views/Documents/DocumentsFilter";
import { DocumentSummary, OperationType } from "modules/types/types";
import { uniq } from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Maybe } from "true-myth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { localizationMaterialTable } from "components/common/localization/localization";
import { compareDesc, isAfter, isSameMonth, isSameYear } from "date-fns";
import { Box } from "@mui/system";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import { filterPanel } from "components/common/ui-kit/styles/utils";
import { Table, TableActionIconButton } from "components/common/ui-kit/components/Table";
import { EmptyListInfo } from "../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";

const byIssueDateDesc = (doc1: DocumentSummary, doc2: DocumentSummary) => compareDesc(doc1.issueDate, doc2.issueDate);

interface Props {
    currentDate: Date;
    getDocuments: (yearMonth: YearMonth) => AsyncResult<DocumentSummary[]>;
    uploadDocumentsWithSpecifications: (documentSpecifications: DocumentSpecifications) => AsyncResult<void>;
}

const PARAM_YEAR_MONTH = "yearMonth";
const PARAM_OPERATION_TYPE = "operationType";
const PARAM_COUNTRY = "country";

export const DocumentView: React.FC<Props> = ({ currentDate, getDocuments, uploadDocumentsWithSpecifications }) => {
    const now = new Date();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDocuments, setSelectedDocuments] = useState<DocumentSummary[]>([]);
    const currentYearMonth = dateToYearMonthString(currentDate);

    const [yearMonth, setYearMonth] = useState<string>(searchParams.get(PARAM_YEAR_MONTH) || currentYearMonth);

    const [country, setCountry] = useState<Maybe<string>>(() => {
        const value = searchParams.get(PARAM_COUNTRY) || EMPTY_SELECT_VALUE;
        return value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value);
    });
    const [countries, setCountries] = useState<string[]>([]);
    const [operationType, setOperationType] = useState<Maybe<OperationType>>(() => {
        const value = searchParams.get(PARAM_OPERATION_TYPE) || EMPTY_SELECT_VALUE;
        return value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value as OperationType);
    });

    const [showOnlyWithoutReport, setShowOnlyWithoutReport] = useState(false);

    const [generatedReportIds, setGeneratedReportIds] = useState<number[]>([]);

    const navigate = useNavigate();

    const {
        data: documents,
        isLoading,
        loadData,
    } = useData<DocumentSummary[]>({
        loader: () =>
            getDocuments(
                strToYearMonth(yearMonth).unwrapOrElse(() => {
                    throw new Error("Invalid Date");
                }),
            ),
        onDataLoaded: (documents: DocumentSummary[]) => {
            const availableCountries = uniq(documents.map(({ contractorAddressCountry }) => contractorAddressCountry));
            setCountries(availableCountries);
            setCountry(
                country.flatMap(selectedCountry =>
                    Maybe.find(countryName => countryName === selectedCountry, availableCountries),
                ),
            );
        },
        onError: () => toast.error(PAYMENT_DOWNLOAD_FAILED),
    });

    const tableColumns: Column<DocumentSummary>[] = [
        {
            field: "missingTagsValues",
            title: "Uwagi",
            render: ({ missingTagsValues, inconsistentTagsValues, paid }: DocumentSummary) => (
                <>
                    {missingTagsValues && (
                        <Tooltip title="Brak tagów">
                            <TagIcon fontSize={"medium"} style={{ marginTop: 5 }} color="error" />
                        </Tooltip>
                    )}
                    {inconsistentTagsValues && (
                        <Tooltip title="Dokument ma inne wartości tagów niż dokumenty z ostatnich dwóch miesięcy">
                            <CallSplit fontSize={"medium"} style={{ marginTop: 5 }} color="error" />
                        </Tooltip>
                    )}
                    {!!paid && (
                        <Tooltip title="Zapłacono">
                            <Paid fontSize={"medium"} style={{ marginTop: 5 }} />
                        </Tooltip>
                    )}
                    {!paid && (
                        <Tooltip title="Nie zapłacono">
                            <MoneyOff fontSize={"medium"} style={{ marginTop: 5 }} />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            field: "number",
            title: "Nr faktury",
            render: ({ number }: DocumentSummary) => number,
        },
        {
            field: "contractorName",
            title: "Nazwa firmy / NIP",
            type: "string",
            render: ({ contractorName, contractorIdentifier }: DocumentSummary) => (
                <>
                    <Box>{contractorName}</Box>
                    {contractorIdentifier}
                </>
            ),
        },
        {
            field: "issueDate",
            title: "Data wystawienia",
            type: "date",
            render: ({ issueDate, accountingDate }: DocumentSummary) => (
                <>
                    {toDateString(issueDate)}
                    {!(isSameYear(issueDate, accountingDate) && isSameMonth(issueDate, accountingDate)) && (
                        <WarningIcon style={{ marginTop: 5 }} color="error" />
                    )}
                </>
            ),
        },
        {
            field: "contractorAddressCountry",
            title: "Kraj",
            type: "string",
        },
        {
            field: "taxRates",
            title: "VAT",
            render: ({ taxRates, vatOverridden }: DocumentSummary) => (
                <>
                    {taxRates}{" "}
                    {vatOverridden && (
                        <Tooltip title="Nadpisano wartość VAT poprzez notatki w dokumencie w Taxxo.">
                            <ChangeCircleIcon color="info" />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            field: "totalNet",
            title: "Wartość netto",
            align: "right",
            render: ({ totalNet, currency }: DocumentSummary) => (
                <>
                    {formatForView(totalNet)} {currency}
                </>
            ),
        },
        {
            field: "accountingDescription",
            title: "Opis",
            type: "string",
        },
        {
            field: "type",
            title: "Typ dokumentu",
            render: ({ type }: DocumentSummary) => <>{getDocumentTypeTranslation(type)}</>,
        },
        {
            title: "Akcja",
            render: ({ id }: DocumentSummary) => (
                <TableActionIconButton
                    tooltip="Pobierz specyfikacje"
                    onClick={() =>
                        toast.promise(
                            downloadDocumentWithSpecification(apiGetWithResponseHeaders)(id, "application/pdf").then(
                                () => setGeneratedReportIds([...generatedReportIds, id]),
                            ),
                            {
                                loading: "Pobieranie pliku",
                                success: "Pobrano plik",
                                error: err => `Nie udało się pobrać pliku: ${err.message}`,
                            },
                        )
                    }
                    Icon={Print}
                />
            ),
        },
    ];

    const filteredDocuments = documents
        .unwrapOr([])
        .filter(
            ({ id, contractorAddressCountry, type, reportGenerated }) =>
                country.map(country => contractorAddressCountry === country).unwrapOr(true) &&
                operationType.map(dt => type === dt).unwrapOr(true) &&
                (showOnlyWithoutReport
                    ? showOnlyWithoutReport === !reportGenerated && !generatedReportIds.includes(id)
                    : true),
        )
        .sort(byIssueDateDesc);

    useEffect(() => {
        setSearchParams(params => {
            params.set(PARAM_YEAR_MONTH, yearMonth);
            return params;
        });
        loadData();
    }, [yearMonth]);

    const handleChangeYearMonth = (date: Nullable<Date>) =>
        setYearMonth(Maybe.of(date).map(dateToYearMonthString).unwrapOr(currentYearMonth));
    const handleChangeDocumentType: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as OperationType | typeof EMPTY_SELECT_VALUE;

        setSearchParams(params => {
            if (value === EMPTY_SELECT_VALUE) {
                params.delete(PARAM_OPERATION_TYPE);
            } else {
                params.set(PARAM_OPERATION_TYPE, value);
            }
            return params;
        });
        setOperationType(
            Maybe.of(value).flatMap(selectedValue =>
                selectedValue === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(selectedValue),
            ),
        );
    };

    const handleChangeCountry: OnChangeFn = ({ target: { value } }, _child) => {
        setCountry(Maybe.of(value as string).flatMap(v => Maybe.of(v === EMPTY_SELECT_VALUE ? null : v)));
        setSearchParams(params => {
            if (value === EMPTY_SELECT_VALUE) {
                params.delete(PARAM_COUNTRY);
            } else {
                params.set(PARAM_COUNTRY, value as string);
            }
            return params;
        });
    };

    const handleUploadInvoicesWithAttachments = async () => {
        (
            await uploadDocumentsWithSpecifications({
                accountingMonth: yearMonth,
                operationType: operationType.unwrapOr(null),
                ids: selectedDocuments.map(({ id }) => id),
            })
        ).match({
            Ok: () => {
                setGeneratedReportIds([...generatedReportIds, ...selectedDocuments.map(({ id }) => id)]);
                toast.success(TRIGGERING_BATCH_UPLOAD_SUCCEDED);
            },
            Err: () => toast.error(TRIGGERING_BATCH_UPLOAD_FAILED),
        });
    };

    const onRowClick = (_event?: React.MouseEvent, row?: DocumentSummary) => {
        if (row) {
            navigate("/accounting/docs/show/" + row.id);
        }
    };

    const table = (
        <Table
            columns={tableColumns}
            data={filteredDocuments}
            options={{
                toolbar: false,
                showTitle: false,
                tableLayout: "auto",
                search: false,
                paging: filteredDocuments.length > 50,
                pageSize: 50,
                padding: "dense",
                headerStyle: boldHeaderStyle(),
                selection: true,
                selectionProps: ({ issueDate, currency }) => ({
                    disabled: isAfter(issueDate, now) && currency !== "PLN",
                }),
            }}
            localization={localizationMaterialTable}
            onSelectionChange={setSelectedDocuments}
            onRowClick={onRowClick}
        />
    );

    return (
        <Grid container>
            <Typography variant="h2">Dokumenty księgowe</Typography>
            <Grid item xs={12}>
                <Paper sx={filterPanel}>
                    <DocumentsFilter
                        yearMonth={yearMonth}
                        country={country}
                        countries={countries}
                        documentType={operationType}
                        showOnlyWithoutReport={showOnlyWithoutReport}
                        onChangeShowOnlyWithoutReport={setShowOnlyWithoutReport}
                        onChangeYearMonth={handleChangeYearMonth}
                        onChangeCountry={handleChangeCountry}
                        onChangeDocumentType={handleChangeDocumentType}
                    />
                </Paper>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Typography variant="h5">Dokumenty</Typography>
                </Grid>
                <Grid container item xs={6} justifyContent={"flex-end"} alignContent="center">
                    {!isLoading && (
                        <Button
                            onClick={handleUploadInvoicesWithAttachments}
                            variant="contained"
                            sx={{ mb: "1rem" }}
                            disabled={selectedDocuments.length === 0}
                        >
                            Generuj faktury z załącznikami ({selectedDocuments.length} wybranych)
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div data-testid="DocumentView/table">
                        {filteredDocuments.length > 0 ? table : <EmptyListInfo />}
                    </div>
                )}
            </Grid>
        </Grid>
    );
};
