export const sectionFormInputStyles = {
    root: {
        display: "flex",
        flex: 1,
        "& > *": {
            marginRight: 2,
        },
    },
    wideFieldWrapper: {
        flex: 1,
        maxWidth: "24rem",
        width: "100%",
    },
};
