import { CollapsibleTable } from "components/common/ui-kit/components/CollapsibleTable";
import { PendingPaymentForm } from "modules/AccountingManagement/views/Payments/PendingPaymentForm";
import { PendingPayment, PendingPaymentChangeRequestParams } from "modules/types/types";
import { ReactNode } from "react";
import { InvoiceThumbnail } from "components/common/ui-kit/components/InvoiceThumbnail/InvoiceThumbnail";

export interface RowData extends Pick<PendingPayment, "invoiceNumber" | "invoiceIssueDate" | "sourceDocumentId"> {
    paymentId: number;
    cols: [number, string, string, ReactNode, string, ReactNode];
}

interface Props {
    onSubmitPendingPayment: (pendingPaymentChangeRequest: PendingPaymentChangeRequestParams) => Promise<void>;
    colDefs: string[];
    rowData: RowData[];
}

export const CollapsibleTableWrapper = ({ onSubmitPendingPayment, colDefs, rowData }: Props) => {
    const rowDetails = rowData.map(row => {
        const { invoiceIssueDate, invoiceNumber, paymentId, cols, sourceDocumentId } = row;
        const collapseContent = (
            <>
                <PendingPaymentForm
                    onSubmit={onSubmitPendingPayment}
                    paymentId={paymentId}
                    formValues={{
                        invoiceNumber: invoiceNumber.unwrapOr(""),
                        invoiceIssueDate: invoiceIssueDate.unwrapOr(""),
                    }}
                />
                {sourceDocumentId
                    .map(docId => (
                        <div key={docId}>
                            <InvoiceThumbnail sourceDocumentId={docId} />
                        </div>
                    ))
                    .unwrapOr(<></>)}
            </>
        );

        return {
            id: paymentId,
            cellData: [...cols],
            collapseContent,
        };
    });

    return <CollapsibleTable rows={rowDetails} colDefs={[...colDefs]} />;
};
