import { AxiosError } from "axios";
import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import {
    ContractorInput,
    CreateUserPayload,
    UserProfileData,
    UserProfileDataDTO,
    mapCreatedUserDTO,
    mapUserProfileDataDTO,
    UserDTO,
    GmailToken,
} from "modules/UserProfile/types";
import { Result } from "true-myth";

export const updateContractorData =
    (apiPost: ApiUpdate<ContractorInput, void>) =>
    (data: ContractorInput): AsyncResult<void> =>
        apiPost("/userProfile/updateContractorData", data);

export const getUserProfileData = (apiGet: ApiGet<UserProfileDataDTO>) => (): AsyncResult<UserProfileData> =>
    apiGet("/userProfile/userProfileData").then(result => result.map(mapUserProfileDataDTO));

export const addAuthCode =
    (apiPost: ApiUpdate<string, GmailToken[]>) =>
    (data: string): AsyncResult<GmailToken[]> =>
        apiPost("/employee/gmail/code?code=" + data);

export const deleteGmailConsent =
    (apiDelete: ApiUpdate<number, GmailToken[]>) =>
    (consentId: number): AsyncResult<GmailToken[]> =>
        apiDelete("/employee/gmail/consent/" + consentId);

export const getGmailAuthUrl = (apiGet: ApiGet<string>) => (): AsyncResult<string> => apiGet("/employee/gmail/auth");

export const getOrCreateUser =
    (apiGet: ApiGet<UserProfileDataDTO>) =>
    (apiPost: ApiUpdate<CreateUserPayload, UserDTO>) =>
    async (now: Date, createUserPayload: CreateUserPayload): Promise<Result<UserProfileData, AxiosError>> =>
        (await apiGet("/userProfile/userProfileData")).match({
            Ok: userProfileDataDTO => Promise.resolve(Result.of(mapUserProfileDataDTO(userProfileDataDTO))),
            Err: async err => {
                if (err.response?.status === 404) {
                    const result = await apiPost("/userProfile/create", createUserPayload);
                    return result.map(createdUserDto => mapCreatedUserDTO(createdUserDto, now));
                }
                return Result.err(err);
            },
        });
