import { Box } from "@mui/material";
import * as React from "react";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import { GridRowId, useGridApiContext } from "@mui/x-data-grid";

interface Props {
    value: string | undefined;
    id: GridRowId;
    documentCurrency: string;
    onAmountChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    hasError: boolean;
}

export const AssignedAmountEditableCellWithCurrency: React.FC<Props> = (props: Props) => {
    const apiRef = useGridApiContext();
    const inputStyle = props.hasError ? { color: "red" } : {};

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div>
                <input
                    type={"number"}
                    style={{ width: "85px", border: "none", ...inputStyle }}
                    autoFocus={true}
                    step={"0.01"}
                    value={props.value || ""}
                    onChange={props.onAmountChanged}
                />
                {props.documentCurrency}
            </div>
            <div>
                {" "}
                {formatMoneyForView(
                    props.value != null ? Number(props.value) * apiRef.current.getRow(props.id).exchangeRate : 0,
                    apiRef.current.getRow(props.id).currency,
                )}
            </div>
        </Box>
    );
};
