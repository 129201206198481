import { MonthlySettlementPage } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementPage";
import { SettlementDateSelect } from "modules/Invoices/views/MonthlySettlement/view/SettlementDateSelect/SettlementDateSelect";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { store } from "modules/Invoices/views/MonthlySettlement/service/store";
import NotFoundRoute from "components/common/ui-kit/components/NotFound";
import {
    getAvailableMonthsWithRates,
    getMonthlySettlementForm,
    getMonthlySettlementFormData,
} from "modules/Invoices/views/MonthlySettlement/service/apiService";
import { apiGet } from "components/common/infractructure";
import React, { useEffect, useMemo } from "react";
import { noop, parseMonth } from "../../../../../components/common/utils";
import { AppActions } from "../domain/actions";
import { GlobalState, MONTHLY_SETTLEMENT_FORM } from "../domain/types";
import { FormState } from "redux-form";
import { sumQuantityByName } from "../service/formService";

const calculateReportedDays = (formState: FormState): number => {
    if (!formState || !formState.values) {
        return 0;
    }
    return sumQuantityByName(formState.values, "workPositions");
};

const ContextController = (props: React.PropsWithChildren) => {
    const dispatch = useDispatch();
    const formState = useSelector<GlobalState, FormState>(state => state.form[MONTHLY_SETTLEMENT_FORM]);

    const reportedDays = useMemo(() => calculateReportedDays(formState), [formState]);
    useEffect(() => {
        dispatch(AppActions.setReportedDays(reportedDays));
    }, [reportedDays]);

    const params = useParams<{ year: string; month: string }>();

    useEffect(() => {
        (async () => {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1;
            const months = await getAvailableMonthsWithRates(apiGet)({
                month: parseMonth(currentMonth).unwrapOr(1),
                year: currentYear,
            });
            months.match({
                Ok: availableMonths => {
                    const selectedPeriod = availableMonths.find(
                        m => m.year.toString() === params.year && m.month.toString() === params.month,
                    );
                    if (selectedPeriod) {
                        dispatch(AppActions.setDateAndRate(selectedPeriod));
                    }
                },
                Err: noop,
            });
        })();
    }, []);

    return <>{props.children}</>;
};

export const MonthlySettlementRoutes: React.FC = () => (
    <Provider store={store}>
        <Routes>
            <Route
                path="/"
                element={<SettlementDateSelect getAvailableMonthsWithRates={getAvailableMonthsWithRates(apiGet)} />}
            />
            <Route
                path="/:year/:month/*"
                element={
                    <ContextController>
                        <MonthlySettlementPage
                            getMonthlySettlementForm={getMonthlySettlementForm(apiGet)}
                            getMonthlySettlementFormData={getMonthlySettlementFormData(apiGet)}
                        />
                    </ContextController>
                }
            />
            <Route path="*" element={<NotFoundRoute />} />
        </Routes>
    </Provider>
);
