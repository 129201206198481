import { Tooltip, IconButton, IconButtonProps, SvgIconProps, LinkProps, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

type Props = Omit<IconButtonProps, "children"> & {
    tooltip?: string;
    Icon: React.ComponentType<SvgIconProps>;
    asLink?: LinkProps<typeof Link>;
};

export const TableActionIconButton = ({ Icon, tooltip, asLink, ...props }: Props) => {
    const ButtonChildren = asLink ? (
        <MuiLink
            component={Link}
            {...asLink}
            color={props.color || "inherit"}
            style={{
                textDecoration: "none",
                lineHeight: "0", // Otherwise there is extra vertical space added below the text baseline which causes the parent button to stretch
                ...asLink.style,
            }}
        >
            <Icon fontSize="medium" />
        </MuiLink>
    ) : (
        <Icon fontSize="medium" />
    );

    const onClick: IconButtonProps["onClick"] = event => {
        event.stopPropagation();
        props.onClick && props.onClick(event);
    };

    return tooltip ? (
        <Tooltip title={tooltip}>
            <IconButton size={"small"} {...props} onClick={onClick}>
                {ButtonChildren}
            </IconButton>
        </Tooltip>
    ) : (
        <IconButton size={"small"} {...props} onClick={onClick}>
            {ButtonChildren}
        </IconButton>
    );
};
