import { DateRange, DateString, lastNinetyDays, Nullable } from "../../../../components/common/types";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Maybe } from "true-myth";
import { parseNumber } from "../../../../components/common/utils/numberUtils";

interface TransactionFiltersState {
    showNotFullyPaidOnly: boolean;
    minAmount: Nullable<number>;
    maxAmount: Nullable<number>;
    smlAccountIds: string[];
    transactionDescription: string;
    dateRange: DateRange;
}

const initialState: TransactionFiltersState = {
    showNotFullyPaidOnly: false,
    minAmount: null,
    maxAmount: null,
    smlAccountIds: [],
    transactionDescription: "",
    dateRange: lastNinetyDays(new Date()),
};

type TransactionFiltersAction =
    | {
          action: "changeShowNotFullyPaidOnly";
          showNotFullyPaidOnly: boolean;
      }
    | {
          action: "setMinAmount";
          minAmount: number;
      }
    | {
          action: "setMaxAmount";
          maxAmount: number;
      }
    | {
          action: "clearMinAmount";
      }
    | {
          action: "clearMaxAmount";
      }
    | {
          action: "setSmlAccounts";
          smlAccountIds: string[];
      }
    | {
          action: "setTransactionDescription";
          transactionDescription: string;
      }
    | {
          action: "setDateRange";
          range: DateRange;
      };

const transactionFiltersReducer = (
    state: TransactionFiltersState,
    action: TransactionFiltersAction,
): TransactionFiltersState => {
    switch (action.action) {
        case "changeShowNotFullyPaidOnly":
            return {
                ...state,
                showNotFullyPaidOnly: action.showNotFullyPaidOnly,
            };
        case "setMinAmount":
            return {
                ...state,
                minAmount: action.minAmount,
            };
        case "setMaxAmount":
            return {
                ...state,
                maxAmount: action.maxAmount,
            };
        case "clearMinAmount":
            return {
                ...state,
                minAmount: null,
            };
        case "clearMaxAmount":
            return {
                ...state,
                maxAmount: null,
            };
        case "setSmlAccounts":
            return {
                ...state,
                smlAccountIds: action.smlAccountIds,
            };
        case "setTransactionDescription":
            return {
                ...state,
                transactionDescription: action.transactionDescription,
            };
        case "setDateRange":
            return {
                ...state,
                dateRange: action.range,
            };
    }
};

type TransactionFiltersContextType = [TransactionFiltersState, React.Dispatch<TransactionFiltersAction>];
const TransactionFiltersContext = React.createContext<TransactionFiltersContextType>(
    null as unknown as TransactionFiltersContextType,
);

const PARAM_MIN_AMOUNT = "minAmount";
const PARAM_MAX_AMOUNT = "maxAmount";
const PARAM_SML_ACCOUNTS = "smlAccountIds";
const PARAM_TRANSACTION_DESCRIPTION = "transactionDescription";
const PARAM_FROM_DATE = "fromDate";
const PARAM_TO_DATE = "toDate";

const parseArraySearchParam = (key: string, params: URLSearchParams): string[] => {
    const paramValue = params.get(key);
    return paramValue === null || paramValue.length === 0 ? [] : paramValue.split(",");
};

export const TransactionFiltersContextProvider = ({ children }: React.PropsWithChildren) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, dispatch] = React.useReducer(transactionFiltersReducer, initialState, () => {
        return {
            minAmount: Maybe.of(searchParams.get(PARAM_MIN_AMOUNT)).flatMap(parseNumber).unwrapOr(null),
            maxAmount: Maybe.of(searchParams.get(PARAM_MAX_AMOUNT)).flatMap(parseNumber).unwrapOr(null),
            smlAccountIds: parseArraySearchParam(PARAM_SML_ACCOUNTS, searchParams),
            transactionDescription: searchParams.get(PARAM_TRANSACTION_DESCRIPTION) ?? "",
            bankAccounts: [],
            dateRange: Maybe.all(
                Maybe.of<DateString>(searchParams.get(PARAM_FROM_DATE) as Nullable<DateString>),
                Maybe.of<DateString>(searchParams.get(PARAM_TO_DATE) as Nullable<DateString>),
            )
                .map(([from, to]) => ({
                    from,
                    to,
                }))
                .unwrapOr(lastNinetyDays(new Date())),
            showNotFullyPaidOnly: false,
        };
    });

    React.useEffect(() => {
        setSearchParams({
            [PARAM_MIN_AMOUNT]: state.minAmount?.toString() ?? "",
            [PARAM_MAX_AMOUNT]: state.maxAmount?.toString() ?? "",
            [PARAM_SML_ACCOUNTS]: state.smlAccountIds.join(","),
            [PARAM_TRANSACTION_DESCRIPTION]: state.transactionDescription,
            [PARAM_FROM_DATE]: state.dateRange.from,
            [PARAM_TO_DATE]: state.dateRange.to,
        });
    }, [state]);

    return (
        <TransactionFiltersContext.Provider value={[state, dispatch]}>{children}</TransactionFiltersContext.Provider>
    );
};

export const useTransactionFiltersState = () => {
    const context = React.useContext(TransactionFiltersContext);

    if (context === null) {
        throw new Error("useTransactionFiltersState must be used within a TransactionFiltersProvider");
    }

    const [state] = context;

    return React.useMemo(() => state, [state]);
};

export const useTransactionFiltersActionDispatcher = () => {
    const context = React.useContext(TransactionFiltersContext);

    if (context === null) {
        throw new Error("useTransactionFiltersState must be used within a TransactionFiltersProvider");
    }

    const [_, dispatch] = context;

    return React.useMemo(() => dispatch, [dispatch]);
};

export const useTransactionClientSideFilters = () => {
    const transactionFilters = useTransactionFiltersState();

    return React.useMemo(
        () => ({
            showNotFullyPaid: transactionFilters.showNotFullyPaidOnly,
        }),
        [transactionFilters.showNotFullyPaidOnly],
    );
};
