import { ClientSales, SalesAggregation, SalesPosition } from "modules/SalesSummary/domain/types";
import { ClientSalesDto, SalesAggregationDto, SalesPositionDto } from "./protocol";

export const createProjectSales = (salesPosition: SalesPositionDto): SalesPosition => ({
    ...salesPosition,
    invoicesForMonth: new Map(Object.entries(salesPosition.invoicesForMonth)),
});

export const createClientSales = (clientSalesDto: ClientSalesDto): ClientSales => ({
    ...clientSalesDto,
    salesPositions: clientSalesDto.salesPositions.map(createProjectSales),
});

export const createSalesAggregation = (salesAggregationDto: SalesAggregationDto): SalesAggregation => ({
    ...salesAggregationDto,
    clientSales: salesAggregationDto.clientSales.map(createClientSales),
});
