import { createContext } from "react";

export const appConfig = {
    auth0: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
    },
};

export type AppConfig = typeof appConfig;

export const AppConfigContext = createContext<AppConfig>(appConfig);
