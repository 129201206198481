import {
    Department,
    getDepartmentTranslation,
    getSpecializationTranslation,
    MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
    Nullable,
    Specialization,
} from "../../../../components/common/types";

import { Box } from "@mui/material";
import { Maybe } from "true-myth";
import { GenericSelect, OnChangeFn } from "../../../../components/common/ui-kit/components/GenericSelect";
import * as React from "react";
import { Dayjs } from "dayjs";
import { dateToYearMonthString, doIfPresent } from "../../../../components/common/utils";
import { CommonDatePicker } from "../../../../components/common/ui-kit/components/DatePicker/DatePicker";
import { EMPTY_SELECT_VALUE } from "../../../AccountingManagement/service/constants";
import { SelectValues } from "components/common/ui-kit/components/GenericSelect/GenericSelect";

interface Props {
    yearMonth: string;
    projectType: string;
    statusType: string;
    level: string;
    specialization: Maybe<Specialization>;
    department: Maybe<Department>;
    specializations: Specialization[];
    departments: Department[];
    levels: string[];
    onChangeYearMonth: (date: Nullable<Date>) => void;
    onChangeProjectType: OnChangeFn;
    onChangeSpecialization: OnChangeFn;
    onChangeDepartment: OnChangeFn;
    onChangeLevel: OnChangeFn;
    onChangeStatus: OnChangeFn;
}

const emptySelectValue = {
    value: EMPTY_SELECT_VALUE,
};

const toSpecValues = (specializations: Specialization[]): SelectValues[] => {
    return specializations.map(s => ({ display: getSpecializationTranslation(s), value: s }));
};

const toDepValues = (specializations: Department[]): SelectValues[] => {
    return specializations.map(s => ({ display: getDepartmentTranslation(s), value: s }));
};

const toLevelValues = (specializations: string[]): SelectValues[] => {
    return specializations.map(s => ({ display: s, value: s }));
};

export const EmployeesFilter: React.FC<Props> = ({
    yearMonth,
    statusType,
    level,
    specialization,
    department,
    specializations,
    departments,
    levels,
    onChangeYearMonth,
    onChangeSpecialization,
    onChangeDepartment,
    onChangeLevel,
    onChangeStatus,
}) => {
    const handleChangeDate = (date: Nullable<Dayjs>) =>
        doIfPresent(
            Maybe.of(date).map(dayJsDate => dayJsDate.toDate()),
            newDate => {
                if (dateToYearMonthString(newDate) !== yearMonth) {
                    onChangeYearMonth(newDate);
                }
            },
        );

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: "0.5rem" }}>
                <CommonDatePicker
                    datePicker={{
                        disableFuture: true,
                        views: ["year", "month"],
                        label: "Rok i miesiąc",
                        inputFormat: MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
                        value: yearMonth,
                        onAccept: handleChangeDate,
                        onChange: () => true,
                    }}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Specjalizacja"
                    value={specialization.unwrapOr(EMPTY_SELECT_VALUE)}
                    values={[emptySelectValue, ...toSpecValues(specializations)]}
                    onChange={onChangeSpecialization}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Cech"
                    value={department.unwrapOr(EMPTY_SELECT_VALUE)}
                    values={[emptySelectValue, ...toDepValues(departments)]}
                    onChange={onChangeDepartment}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Poziom"
                    value={level}
                    values={[emptySelectValue, ...toLevelValues(levels)]}
                    onChange={onChangeLevel}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Status"
                    value={statusType}
                    values={[
                        emptySelectValue,
                        ...[
                            { display: "Zwolnieni", value: "Fired" },
                            { display: "Nowo zatrudnieni", value: "Joined" },
                        ],
                    ]}
                    onChange={onChangeStatus}
                />
            </Box>
        </Box>
    );
};
