import { useAuth0 } from "@auth0/auth0-react";
import { useData } from "components/common/hooks/useData";
import { AsyncResult } from "components/common/infractructure";
import { rethrow } from "components/common/utils";
import Loader from "components/Loader/Loader";
import { CreateUserPayload, mapAuth0UserToCreateUserPayload, UserProfileData } from "modules/UserProfile/types";
import * as React from "react";
import { useContext } from "react";
import { useEffectOnce } from "usehooks-ts";

export interface UserContext {
    userProfileData: UserProfileData;
    setUserProfileData: (userProfileData: UserProfileData) => void;
}

export const UserContext = React.createContext<UserContext | null>(null);

interface Props {
    getOrCreateUser: (now: Date, createUserPayload: CreateUserPayload) => AsyncResult<UserProfileData>;
}

export const UserContextProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, getOrCreateUser }) => {
    const now = new Date();
    const { user } = useAuth0();

    const {
        data,
        isLoading,
        error,
        loadData,
        setData: setUserProfileData,
    } = useData<UserProfileData>({ loader: () => getOrCreateUser(now, mapAuth0UserToCreateUserPayload(user)) });

    useEffectOnce(() => {
        loadData();
    });

    return isLoading ? (
        <Loader fullScreen />
    ) : (
        error.map(rethrow).unwrapOr(
            data
                .map(userProfileData => (
                    <UserContext.Provider key={0} value={{ userProfileData, setUserProfileData }}>
                        {children}
                    </UserContext.Provider>
                ))
                .unwrapOr(<></>),
        )
    );
};

export const useUserContext = (): UserContext => {
    const contextValue = useContext(UserContext);

    if (contextValue === null) {
        throw new Error("Illegal State Error: user's profile data is null");
    }

    return contextValue;
};
