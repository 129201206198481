import { Month, Nullable, YEAR_MONTH_STRING_FORMAT, Year, YearMonthString } from "components/common/types";
import { format } from "date-fns";
import { Maybe } from "true-myth";

interface ContractorDataDTO {
    accountNumber: string;
    addressLine1: string;
    addressLine2: Nullable<string>;
    companyName: string;
    country: Nullable<string>;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    isVatPayer: boolean;
    nip: string;
    swift: Nullable<string>;
}

interface ContractorData {
    accountNumber: string;
    addressLine1: string;
    addressLine2: Maybe<string>;
    companyName: string;
    country: Maybe<string>;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    isVatPayer: boolean;
    nip: string;
    swift: Maybe<string>;
}

const mapContractorDataDTO = (contractorDataDTO: ContractorDataDTO): ContractorData => ({
    ...contractorDataDTO,
    addressLine2: Maybe.of(contractorDataDTO.addressLine2),
    country: Maybe.of(contractorDataDTO.country),
    swift: Maybe.of(contractorDataDTO.swift),
});

interface CategoryTypeDTO {
    name: string;
    type: string;
}

type CategoryType = CategoryTypeDTO;

const mapCategoryTypeDTO = (categoryTypeDTO: CategoryTypeDTO): CategoryType => ({
    ...categoryTypeDTO,
});

interface CostDriverDTO {
    name: string;
    type: string;
}

type CostDriver = CostDriverDTO;

const mapCostDriverDTO = (costDriverDTO: CostDriverDTO): CostDriver => ({
    ...costDriverDTO,
});

interface CategoryDTO {
    categoryType: CategoryTypeDTO;
    costDriver: CostDriverDTO;
    group: string;
    id: number;
    name: string;
    projectRelated: boolean;
}

interface Category {
    categoryType: CategoryType;
    costDriver: CostDriver;
    group: string;
    id: number;
    name: string;
    projectRelated: boolean;
}

const mapCategoryDTO = (categoryDTO: CategoryDTO): Category => ({
    ...categoryDTO,
    categoryType: mapCategoryTypeDTO(categoryDTO.categoryType),
    costDriver: mapCostDriverDTO(categoryDTO.costDriver),
});

interface AccountDTO {
    id: number;
    accountNumber: string;
    currency: string;
    bankName: Nullable<string>;
}

interface Account {
    id: number;
    accountNumber: string;
    currency: string;
    bankName: Maybe<string>;
}

const mapAccountDTO = (accountDTO: AccountDTO): Account => ({
    ...accountDTO,
    bankName: Maybe.of(accountDTO.bankName),
});

interface VacancyDTO {
    id: number;
    description: Nullable<string>;
}

interface Vacancy {
    id: number;
    description: Maybe<string>;
}

const mapVacancyDTO = (vacancyDTO: VacancyDTO): Vacancy => ({
    ...vacancyDTO,
    description: Maybe.of(vacancyDTO.description),
});

interface PositionDTO {
    account: Nullable<AccountDTO>;
    amountOfDays: number;
    category: CategoryDTO;
    grossAmount: number;
    id: number;
    isMain: boolean;
    netAmount: number;
    note: Nullable<string>;
    quantity: number;
    taxAmount: number;
    unitPrice: number;
    unitPriceInPLN: number;
    vacancy: Nullable<VacancyDTO>;
}

interface Position {
    account: Maybe<Account>;
    amountOfDays: number;
    category: Category;
    grossAmount: number;
    id: number;
    isMain: boolean;
    netAmount: number;
    note: Maybe<string>;
    quantity: number;
    taxAmount: number;
    unitPrice: number;
    unitPriceInPLN: number;
    vacancy: Maybe<Vacancy>;
}

const mapPositionDTO = (positionDTO: PositionDTO): Position => ({
    ...positionDTO,
    account: Maybe.of(positionDTO.account).map(mapAccountDTO),
    category: mapCategoryDTO(positionDTO.category),
    note: Maybe.of(positionDTO.note),
    vacancy: Maybe.of(positionDTO.vacancy).map(mapVacancyDTO),
});

type InvoiceWizardDateFormat = `${Year}/${Month}`;

const mapInvoiceMonthDTO = (invoiceMonthDTO: YearMonthString): Date => new Date(invoiceMonthDTO);

export const mapInvoiceMonthToWizardFormat = (invoiceMonth: Date): InvoiceWizardDateFormat =>
    format(invoiceMonth, "yyyy/MM") as InvoiceWizardDateFormat;

export const formatInvoiceMonth = (date: Date): YearMonthString =>
    format(date, YEAR_MONTH_STRING_FORMAT) as YearMonthString;

export interface InvoiceDTO {
    contractorData: Nullable<ContractorDataDTO>;
    createdAt: string;
    createdBy: string;
    id: number;
    invoiceIssueDate: Nullable<string>;
    invoiceMonth: YearMonthString;
    invoiceNumber: Nullable<string>;
    positions: PositionDTO[];
    sourceDocumentId: Nullable<string>;
    sourceDocumentMoved: boolean;
    taxxoId: Nullable<string>;
    totalGross: number;
    totalGrossInPLN: number;
    totalNet: number;
    totalNetInPLN: number;
    totalTax: number;
    totalTaxInPLN: number;
    isEditable: boolean;
}

export interface Invoice {
    contractorData: Maybe<ContractorData>;
    createdAt: Date;
    createdBy: string;
    id: number;
    invoiceIssueDate: Maybe<Date>;
    invoiceMonth: Date;
    invoiceNumber: Maybe<string>;
    positions: Position[];
    sourceDocumentId: Maybe<string>;
    sourceDocumentMoved: boolean;
    taxxoId: Maybe<string>;
    totalGross: number;
    totalGrossInPLN: number;
    totalNet: number;
    totalNetInPLN: number;
    totalTax: number;
    totalTaxInPLN: number;
    amountOfDays: number;
    isEditable: boolean;
}

export const mapInvoiceDTO = (invoiceDTO: InvoiceDTO): Invoice => ({
    ...invoiceDTO,
    contractorData: Maybe.of(invoiceDTO.contractorData).map(mapContractorDataDTO),
    createdAt: new Date(invoiceDTO.createdAt),
    invoiceIssueDate: Maybe.of(invoiceDTO.invoiceIssueDate).map(invoiceIssueDate => new Date(invoiceIssueDate)),
    positions: invoiceDTO.positions.map(mapPositionDTO),
    invoiceMonth: mapInvoiceMonthDTO(invoiceDTO.invoiceMonth),
    invoiceNumber: Maybe.of(invoiceDTO.invoiceNumber),
    sourceDocumentId: Maybe.of(invoiceDTO.sourceDocumentId),
    taxxoId: Maybe.of(invoiceDTO.taxxoId),
    amountOfDays: invoiceDTO.positions.reduce((sum, current) => sum + current.amountOfDays, 0),
});

export interface InvoiceOverviewDTO {
    id: number;
    invoiceIssueDate: Nullable<string>;
    invoiceMonth: YearMonthString;
    invoiceNumber: Nullable<string>;
    totalGrossInPln: number;
    totalNetInPln: number;
    totalGross: number;
    totalNet: number;
    isEditable: boolean;
    currency: string;
    amountOfDays: number;
    amountOfDaysOff: number;
}

export interface InvoiceOverview {
    id: number;
    invoiceIssueDate: Maybe<Date>;
    invoiceMonth: Date;
    invoiceNumber: string;
    totalGrossInPln: number;
    totalNetInPln: number;
    totalGross: number;
    totalNet: number;
    isEditable: boolean;
    currency: string;
    amountOfDays: number;
    amountOfDaysOff: number;
}

export const mapInvoiceOverviewDTO = (invoiceOverviewDTO: InvoiceOverviewDTO): InvoiceOverview => ({
    ...invoiceOverviewDTO,
    invoiceMonth: mapInvoiceMonthDTO(invoiceOverviewDTO.invoiceMonth),
    invoiceNumber: Maybe.of(invoiceOverviewDTO.invoiceNumber).unwrapOr("N/A"),
    invoiceIssueDate: Maybe.of(invoiceOverviewDTO.invoiceIssueDate).map(invoiceIssueDate => new Date(invoiceIssueDate)),
});
