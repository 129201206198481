import { useUserContext } from "components/common/contexts";
import { isAllowed, Role } from "components/RBAC/RBACutils";
import React from "react";
import { Navigate } from "react-router-dom";

interface Props {
    requiredRoles: Role[];
}

const RBAC: React.FC<React.PropsWithChildren<Props>> = ({ requiredRoles, children }) => {
    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();

    return isAllowed(roles, requiredRoles) ? <>{children}</> : <Navigate to="/not-allowed" />;
};

export default RBAC;
