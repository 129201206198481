import { Box, Typography } from "@mui/material";
import React from "react";

const NotFoundRoute: React.FC = () => (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box
            sx={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "> img": {
                    borderRadius: "1rem",
                },
            }}
        >
            <img src="/images/mckwacz_liczy.png" alt="tu-nic-nie ma" width="65%" />
        </Box>
        <Typography variant="h2">Strona nie istnieje.</Typography>
        <Typography variant="body1">
            Sprawdź, czy adres URL jest poprawny lub skontaktuj się z zespołem odpowiedzialnym za aplikację Finanse.
        </Typography>
    </Box>
);

export default NotFoundRoute;
