import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import * as React from "react";
import { Maybe } from "true-myth";

export interface SelectValues {
    value: string | number;
    display?: string | number;
}

type Props = SelectProps & { values: SelectValues[]; label: string };

export type OnChangeFn = Props["onChange"];

export const GenericSelect: React.FC<Props> = React.forwardRef(
    ({ values, label, disabled, value, onChange, sx, size, multiple }, ref) => {
        const options = values.map(r => (
            <MenuItem key={r.value} value={r.value}>
                {Maybe.of(r.display).unwrapOr(r.value)}
            </MenuItem>
        ));

        return (
            <>
                <FormControl sx={{ minWidth: "8rem", ...sx }} size={size}>
                    <InputLabel variant="standard" id="label">
                        {label}
                    </InputLabel>
                    <Select
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        multiple={multiple}
                        variant="standard"
                    >
                        {options}
                    </Select>
                </FormControl>
            </>
        );
    },
);
