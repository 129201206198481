import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { MUI_INPUT_YEAR_MONTH_STRING_FORMAT, Nullable } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect, OnChangeFn } from "components/common/ui-kit/components/GenericSelect";
import { dateToYearMonthString, doIfPresent } from "components/common/utils";
import { Dayjs } from "dayjs";
import { EMPTY_SELECT_VALUE } from "modules/AccountingManagement/service/constants";
import { getDocumentTypeTranslation } from "modules/AccountingManagement/service/documentService";
import * as React from "react";
import { Maybe } from "true-myth";

interface Props {
    yearMonth: string;
    country: Maybe<string>;
    countries: string[];
    documentType: Maybe<string | DocumentType>;
    onChangeYearMonth: (date: Nullable<Date>) => void;
    onChangeCountry: OnChangeFn;
    onChangeDocumentType: OnChangeFn;
    showOnlyWithoutReport: boolean;
    onChangeShowOnlyWithoutReport: (newVal: boolean) => void;
}

const getDocumentTypeValues = () => {
    return [
        {
            value: "SALES",
            display: getDocumentTypeTranslation("SALES"),
        },
        {
            value: "SALES_EMPLOYEE",
            display: getDocumentTypeTranslation("SALES_EMPLOYEE"),
        },
        {
            value: "COSTS_EMPLOYEE",
            display: getDocumentTypeTranslation("COSTS_EMPLOYEE"),
        },
        {
            value: "COSTS_DOMESTIC",
            display: getDocumentTypeTranslation("COSTS_DOMESTIC"),
        },
        {
            value: "COSTS_EU_IMPORT",
            display: getDocumentTypeTranslation("COSTS_EU_IMPORT"),
        },
        {
            value: "COSTS_IMPORT",
            display: getDocumentTypeTranslation("COSTS_IMPORT"),
        },
        {
            value: "NON_ACCOUNTING",
            display: getDocumentTypeTranslation("NON_ACCOUNTING"),
        },
    ];
};

const emptySelectValue = {
    value: EMPTY_SELECT_VALUE,
};

export const DocumentsFilter: React.FC<Props> = ({
    yearMonth,
    country,
    countries,
    documentType,
    onChangeYearMonth,
    onChangeCountry,
    onChangeDocumentType,
    showOnlyWithoutReport,
    onChangeShowOnlyWithoutReport,
}) => {
    const handleChangeDate = (date: Nullable<Dayjs>) =>
        doIfPresent(
            Maybe.of(date).map(dayJsDate => dayJsDate.toDate()),
            newDate => {
                if (dateToYearMonthString(newDate) !== yearMonth) {
                    onChangeYearMonth(newDate);
                }
            },
        );

    return (
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Box sx={{ marginRight: "0.5rem" }}>
                <CommonDatePicker
                    datePicker={{
                        disableFuture: true,
                        views: ["year", "month"],
                        label: "Rok i miesiąc",
                        inputFormat: MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
                        value: yearMonth,
                        onChange: handleChangeDate,
                    }}
                />
            </Box>
            <Box sx={{ marginRight: "0.5rem" }}>
                <GenericSelect
                    label="Kraj"
                    value={country.unwrapOr(EMPTY_SELECT_VALUE)}
                    values={[emptySelectValue, ...countries.map(value => ({ value }))]}
                    onChange={onChangeCountry}
                />
            </Box>
            <Box>
                <GenericSelect
                    label="Typ dokumentu"
                    value={documentType.unwrapOr(EMPTY_SELECT_VALUE)}
                    values={[emptySelectValue, ...getDocumentTypeValues()]}
                    onChange={onChangeDocumentType}
                />
            </Box>
            <Box sx={{ marginLeft: "1rem" }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showOnlyWithoutReport}
                            onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                onChangeShowOnlyWithoutReport(checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    label="Tylko bez raportu"
                    sx={{ marginTop: "40px" }}
                />
            </Box>
        </Box>
    );
};
