import { ThemeOptions } from "@mui/material";
import { custom, invoices, navigate, home } from "./colors";

export const darkTheme: ThemeOptions = {
    palette: {
        mode: "dark",
        background: {
            default: "#121826",
            paper: "#121826",
        },
        main: {
            background: "#313a4e",
        },
        navigate,
        custom,
        home,
        invoices: {
            ...invoices,
            summaryRow: "#e6f2fc",
        },
    },
};
