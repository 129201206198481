import * as React from "react";
import { Maybe } from "true-myth";
import { isEmpty } from "ramda";
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    Paper,
    Typography,
    Collapse,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useBoolean } from "usehooks-ts";

export interface Row {
    id?: string | number;
    cellData: any[];
    collapseContent: React.ReactNode;
}

type RowProps = Pick<Row, "cellData" | "collapseContent">;

const Row = ({ cellData, collapseContent }: RowProps) => {
    const { value: open, toggle } = useBoolean(false);

    const cells = cellData.map((cell, idx) => <td key={idx}>{cell}</td>);
    const onClick = () => toggle();
    return (
        <>
            <TableRow sx={{ borderTop: "1px solid", borderColor: "primary.light" }}>
                {cells}
                <td style={{ padding: "0.25rem 0" }}>
                    <IconButton aria-label="expand row" size="small" onClick={onClick}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </td>
            </TableRow>
            <TableRow>
                <td colSpan={cellData.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {collapseContent}
                    </Collapse>
                </td>
            </TableRow>
        </>
    );
};

interface TableProps {
    colDefs: string[];
    rows: Row[];
}

export const CollapsibleTable = ({ colDefs, rows }: TableProps) => {
    const headerCells = colDefs.map((title, idx) => (
        <th key={idx} align="left">
            {title}
        </th>
    ));

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" data-testid="collapsibleTableContainer" align="left">
                <TableHead>
                    <TableRow sx={{ fontWeight: 600, height: "2.5rem", lineHeight: "1rem" }}>
                        {headerCells}
                        <td />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        <Row
                            key={Maybe.of(row.id).unwrapOr(idx)}
                            cellData={row.cellData}
                            collapseContent={row.collapseContent}
                        />
                    ))}
                    {isEmpty(rows) && (
                        <TableRow>
                            <td colSpan={headerCells.length + 1}>
                                <Grid container justifyContent={"center"} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom>
                                            Brak rekordów
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </td>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
