import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { Nullable, YupObjectShape } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { noop } from "components/common/utils";
import { Dayjs } from "dayjs";
import { OnboardingPosition } from "modules/Welcome/types";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { Maybe } from "true-myth";
import * as yup from "yup";
import { useEffect } from "react";

export interface FirstTimeLoginFormData {
    hiringDate: Date;
    onboardingPositionId: Nullable<number>;
    level: string;
    rate: number;
}

interface Props {
    availablePositions: OnboardingPosition[];
    isLoading: boolean;
    onSubmit: (formData: FirstTimeLoginFormData) => void;
}

export const FirstTimeLoginForm: React.FC<Props> = ({ availablePositions, isLoading, onSubmit }) => {
    const schema = yup
        .object<YupObjectShape<FirstTimeLoginFormData>>({
            hiringDate: yup.date().required(),
            onboardingPositionId: yup.number().required(),
            level: yup.string().required(),
            rate: yup.number().positive().integer().required(),
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
    } = useForm<FirstTimeLoginFormData>({
        defaultValues: {
            hiringDate: new Date(),
            onboardingPositionId: availablePositions[0].id,
            level: availablePositions[0].levels[0].name,
            rate: availablePositions[0].levels[0].rate,
        },
        resolver: yupResolver(schema),
        mode: "all",
    });

    const currentOnboardingPositionId = watch("onboardingPositionId");
    const currentLevel = watch("level");

    useEffect(() => {
        if (currentOnboardingPositionId) {
            const newPosition = availablePositions.find(p => p.id === currentOnboardingPositionId);
            if (newPosition) {
                const newLevel = newPosition.levels[0].name;
                const newRate = newPosition.levels[0].rate;
                setValue("level", newLevel);
                setValue("rate", newRate);
            }
        }
    }, [currentOnboardingPositionId, availablePositions, setValue]);

    useEffect(() => {
        if (currentLevel) {
            const newPosition = availablePositions.find(p => p.id === currentOnboardingPositionId);
            const newRate = newPosition?.levels.find(lvl => lvl.name === currentLevel)?.rate;
            if (newRate !== undefined) {
                setValue("rate", newRate);
            }
        }
    }, [currentLevel, currentOnboardingPositionId, availablePositions, setValue]);

    const removeTimezoneOffsetFromDate = (date: Date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    const onDateChange = (hiringDate: Nullable<Dayjs>) =>
        Maybe.of(hiringDate).match({
            Just: date => setValue("hiringDate", removeTimezoneOffsetFromDate(date.toDate())),
            Nothing: noop,
        });

    return (
        <form name="first time login" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} direction={"row"}>
                <Grid item xs>
                    <Controller
                        name="hiringDate"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data zatrudnienia",
                                    views: ["year", "month", "day"],
                                    onChange: onDateChange,
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs>
                    <Controller
                        name="onboardingPositionId"
                        control={control}
                        render={({ field }) => (
                            <GenericSelect
                                {...field}
                                label="Pozycja"
                                values={availablePositions.map(({ id, name }) => ({
                                    value: id,
                                    display: name,
                                }))}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs>
                    <Controller
                        name="level"
                        control={control}
                        render={({ field }) => (
                            <GenericSelect
                                {...field}
                                label="Poziom"
                                values={availablePositions
                                    .filter(pos => pos.id == currentOnboardingPositionId)[0]
                                    .levels.map(({ name }) => ({
                                        value: name,
                                        display: name,
                                    }))}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        name="rate"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                {...field}
                                type="number"
                                label="Stawka (netto/dzień) w PLN"
                                fullWidth
                                placeholder={availablePositions
                                    .filter(pos => pos.id == currentOnboardingPositionId)[0]
                                    .levels.filter(lvl => lvl.name == currentLevel)[0]
                                    .rate.toString()}
                                error={!!errors.rate}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ textAlign: "end" }}>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!isValid}
                            loading={isLoading}
                        >
                            Zaczynamy!
                        </LoadingButton>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};
