import WarningIcon from "@mui/icons-material/Warning";
import { Box, Tooltip } from "@mui/material";
import { formatMoneyForView } from "components/common/utils/numberUtils";
import * as React from "react";
import { Maybe } from "true-myth";
import { InvoiceData, InvoiceState } from "./domain/types";
import { getMessageForCorruptState } from "./service/salesSummaryService";

interface Props {
    invoiceData: InvoiceData[];
    onShowDetails: (invoiceData: InvoiceData) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const invoiceStateToColor = (invoiceState: InvoiceState) => {
    switch (invoiceState) {
        case "BILLING_CREATED":
            return "invoices.billingCreated";
        case "NOT_PAID_VALID":
            return "invoices.notPaidValid";
        case "NOT_PAID_EXPIRED":
            return "invoices.notPaidExpired";
        case "PAID":
        default:
            return "invoices.paid";
    }
};

export const InvoiceInfo = React.memo(({ invoiceData, onShowDetails }: Props) => {
    const invoiceComponents = invoiceData.map((invoiceData, idx) => {
        const { state, invoiceNo, billingId, grossAmount, currency, paidDate } = invoiceData;
        const corruptStateWarnComponent = getMessageForCorruptState(state, paidDate)
            .map(msg => (
                <Tooltip title={msg} key={msg}>
                    <WarningIcon
                        htmlColor="#ffcc00"
                        fontSize={"small"}
                        sx={{
                            width: "25px",
                        }}
                    />
                </Tooltip>
            ))
            .unwrapOr(<></>);

        return (
            <Box
                component="tr"
                key={idx}
                onClick={onShowDetails(invoiceData)}
                sx={{
                    backgroundColor: invoiceStateToColor(state),
                    color: "invoices.contrast",
                    cursor: "pointer",
                    transition: "all 0.1s ease-in-out",

                    "& td": {
                        padding: "0.5rem",
                        textAlign: "center",
                    },
                    "&:hover": {
                        transform: "scale(1.05) translateY(0)",
                        boxShadow: "4px 4px 10px 1px #b4b4b4",
                    },
                }}
            >
                <td>
                    <Box
                        component="span"
                        sx={{
                            fontWeight: "600",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {Maybe.of(invoiceNo).unwrapOr(`B${billingId}`)}
                        {corruptStateWarnComponent}
                    </Box>
                    <Box
                        sx={{
                            fontWeight: "400",
                        }}
                    >
                        {formatMoneyForView(grossAmount, currency)}
                    </Box>
                </td>
            </Box>
        );
    });

    return (
        <Box
            component="table"
            sx={{
                borderCollapse: "separate",
                height: "100%",
                width: "100%",
            }}
        >
            <tbody>{invoiceComponents}</tbody>
        </Box>
    );
});
