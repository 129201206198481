import { strToYearMonth } from "components/common/utils";
import { MonthlySettlementSummary } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { MonthlySettlementSummaryDto } from "./protocol";

export const convertMonthlySettlementSummary = (
    monthlySettlement: MonthlySettlementSummaryDto,
): MonthlySettlementSummary => ({
    ...monthlySettlement,
    yearMonth: strToYearMonth(monthlySettlement.yearMonth).unwrapOrElse(() => {
        throw new Error("Could not parse YearMonth");
    }),
});
