import { ApiGet, AsyncResult } from "components/common/infractructure";
import { DateString } from "components/common/types";
import { InvoiceOverviewDTO, InvoiceOverview, mapInvoiceOverviewDTO } from "modules/Invoices/types";
import { PendingPayment, PendingPaymentDTO, mapPendingPaymentDTO } from "modules/types/types";

export const getOwnInvoices = (apiGet: ApiGet<InvoiceOverviewDTO[]>) => (): AsyncResult<InvoiceOverview[]> =>
    apiGet(`/pendingPayment/invoices`).then(result => result.map(data => data.map(mapInvoiceOverviewDTO)));

export const getInvoiceDetails =
    (apiGet: ApiGet<PendingPaymentDTO>) =>
    (date: DateString): AsyncResult<PendingPayment> =>
        apiGet(`/pendingPaymentSummary/payments?date=${date}`).then(data => data.map(mapPendingPaymentDTO));
