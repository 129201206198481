import { Column } from "@material-table/core";
import WarningAmber from "@mui/icons-material/WarningAmberTwoTone";
import { Box, Typography } from "@mui/material";
import { useData } from "components/common/hooks/useData";
import { apiGet } from "components/common/infractructure";
import { localizationMaterialTable } from "components/common/localization/localization";
import { toDateString } from "components/common/types";
import { withBoldHeader } from "components/common/ui-kit/components/MaterialTable/utils";
import { Table } from "components/common/ui-kit/components/Table";
import { formatForView } from "components/common/utils/numberUtils";
import Loader from "components/Loader/Loader";
import { getVacancies } from "modules/Projects/service/apiService";
import { Employee, filterByPendingCondition, formatEmployeeLabel, Vacancy } from "modules/Projects/types";
import { useEffectOnce } from "usehooks-ts";
import { EmployeeAvatar } from "../components/EmployeeAvatar";

const NOT_AVAILABLE_LABEL = "N/A";

type RowData = Vacancy & { employee: Employee & { label: string } };

const toRowData = (vacancy: Vacancy): RowData => ({
    ...vacancy,
    employee: {
        ...vacancy.employee,
        label: formatEmployeeLabel(vacancy.employee),
    },
});

const tableColumns: Column<RowData>[] = withBoldHeader([
    {
        title: "Stanowisko",
        field: "role.name",
    },
    {
        title: "Osoba",
        field: "employee.label",
        render: ({ employee }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmployeeAvatar employee={employee} />
                <Box sx={{ ml: "1rem" }}>{employee.label}</Box>
            </Box>
        ),
        customSort: (a: RowData, b: RowData) => {
            // Need to cast here because in reality this library sometimes passes
            // these arguments as RowData objects, while other times as strings.
            // The type signature of this function in the library is wrong/misleading.
            const valueA = a as string | RowData;
            const valueB = b as string | RowData;

            if (typeof valueA === "string" && typeof valueB === "string") {
                return valueA.localeCompare(valueB);
            }

            return a.employee.label.localeCompare(b.employee.label);
        },
        width: "500px",
    },
    {
        title: "Data rozpoczęcia",
        field: "start",
        render: ({ start }) => toDateString(start),
        sorting: true,
        defaultSort: "desc",
    },
    {
        title: "Data zakończenia",
        field: "end",
        render: ({ end }) => end.map(toDateString).unwrapOr(""),
    },
    { title: "Stawka", field: "rate", align: "right", render: ({ rate }) => formatForView(rate) },
    {
        title: "FTE",
        field: "fullTimeEquivalent",
        align: "right",
        render: ({ fullTimeEquivalent }) => <span>{formatForView(fullTimeEquivalent)}%</span>,
    },
    {
        title: "Prowizja",
        field: "provision",
        align: "right",
        render: ({ provision }) => (
            <span>
                {provision
                    .map(formatForView)
                    .map(value => `${value}%`)
                    .unwrapOr(NOT_AVAILABLE_LABEL)}
            </span>
        ),
    },
    {
        title: "Rabat",
        field: "discount",
        align: "right",
        render: ({ discount }) => (
            <span>
                {discount
                    .map(formatForView)
                    .map(value => `${value}%`)
                    .unwrapOr(NOT_AVAILABLE_LABEL)}
            </span>
        ),
    },
    {
        title: "Konto",
        field: "account",
        width: "300px",
        render: ({ account }) => account.unwrapOr(NOT_AVAILABLE_LABEL),
    },
]);
interface Props {
    projectId: number;
    showPendingOnly: boolean;
}

export const ProjectVacanciesPanel: React.FC<Props> = ({ projectId, showPendingOnly }) => {
    const now = new Date();
    const {
        data: vacancies,
        loadData,
        isLoading,
        error,
    } = useData<Vacancy[]>({
        loader: () => getVacancies(apiGet, `${projectId}`)(),
    });

    useEffectOnce(() => {
        loadData();
    });

    return isLoading ? (
        <Loader />
    ) : (
        vacancies.match({
            Just: vacanciesData => {
                const filteredData = vacanciesData
                    .filter(({ end }) => filterByPendingCondition(end, now, showPendingOnly))
                    .map(toRowData);
                return (
                    <Box
                        key={0}
                        sx={{
                            padding: "1rem 1rem 1rem 0",
                            backgroundColor: "main.background",
                            th: {
                                backgroundColor: "main.background",
                            },
                        }}
                    >
                        <Table
                            columns={tableColumns}
                            data={filteredData}
                            options={{
                                search: false,
                                selection: false,
                                showTextRowsSelected: false,
                                paging: filteredData.length > 10,
                                pageSize: 10,
                                toolbar: false,
                            }}
                            components={{
                                Container: props => <Box {...props} sx={{ marginLeft: "3rem" }} />,
                            }}
                            localization={localizationMaterialTable}
                        />
                        {showPendingOnly && vacanciesData.length > filteredData.length && (
                            <Box display="flex" sx={{ padding: "1rem 3rem" }}>
                                <WarningAmber color="warning" />
                                <Typography variant="body1">
                                    Wyświetlane są tylko trwające etaty (zob. filtry na panelu u góry strony).
                                </Typography>
                            </Box>
                        )}
                    </Box>
                );
            },
            Nothing: () =>
                error
                    .map(err => (
                        <Typography key={0} variant="body2" color="error">
                            Nie udało się wczytać informacji o etatach: {err.message}
                        </Typography>
                    ))
                    .unwrapOr(<></>),
        })
    );
};
