import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Maybe } from "true-myth";
import { Employee } from "../../../Projects/types";
import { Autocomplete, TextField } from "@mui/material";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import { AsyncResult } from "../../../../components/common/infractructure";
import AddIcon from "@mui/icons-material/Add";
import Popover from "@mui/material/Popover";
import { toast } from "react-hot-toast";

interface Props {
    unassignEmployeeFromPosition: (invoiceEntryId: number) => AsyncResult<void>;
    assignEmployeeToPosition: (invoiceEntryId: number, employeeId: number) => AsyncResult<void>;
    currentEmployee: Maybe<Employee>;
    invoiceEntryId: number;
    getEmployees: () => AsyncResult<Employee[]>;
}

export const toAvatar = (employee: Employee): JSX.Element => {
    return (
        <Box>
            <EmployeeAvatar sx={{ width: "35px", height: "35px" }} employee={employee} />
        </Box>
    );
};
export const AvatarMenu: React.FC<Props> = ({
    currentEmployee,
    invoiceEntryId,
    unassignEmployeeFromPosition,
    assignEmployeeToPosition,
    getEmployees,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedEmployee, setSelectedEmployee] = React.useState<Maybe<Employee>>(currentEmployee);
    const [options, setOptions] = React.useState<Employee[]>([]);
    const open = Boolean(anchorEl);
    const [searchAnchorEl, setSearchAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleSearchClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.currentTarget;
        if (options.length == 0) {
            const newVar = await getEmployees();
            if (newVar.isOk()) {
                setOptions(newVar.unwrapOr([]));
            }
        }

        setSearchAnchorEl(target);
    };

    const handleSearchClose = () => {
        setSearchAnchorEl(null);
    };

    const searchOpen = Boolean(searchAnchorEl);

    const onResultSelectedFunction = async (value: Employee) => {
        const res = await assignEmployeeToPosition(invoiceEntryId, value.id);
        if (res.isOk()) {
            setSelectedEmployee(Maybe.just(value));
        } else {
            toast.error("Wystąpił błąd podczas przypisywania osoby do pozycji!");
        }
    };

    const handleSelect = (newValue: Employee | null) => {
        if (newValue != null) {
            onResultSelectedFunction(newValue);
        }
        handleSearchClose();
    };

    const filterEmployees = (inputValue: string) => {
        return options.filter(
            emp =>
                emp.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
                emp.lastName.toLowerCase().includes(inputValue.toLowerCase()),
        );
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const emp = selectedEmployee.map(e => toAvatar(e));

    const handleUnassignClick = async (invoiceEntryId: number) => {
        const res = await unassignEmployeeFromPosition(invoiceEntryId);
        if (res.isOk()) {
            setSelectedEmployee(Maybe.nothing());
        } else {
            toast("Wystąpił błąd podczas odwiązywania pracownika od pozycji!");
        }
    };

    const addButton = (
        <>
            <IconButton
                onClick={handleSearchClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <AddIcon sx={{ width: 32, height: 32 }}></AddIcon>
            </IconButton>
            {searchOpen && (
                <Popover
                    id={"j"}
                    open={searchOpen}
                    anchorEl={searchAnchorEl}
                    onClose={handleSearchClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Autocomplete
                        options={options}
                        getOptionLabel={option => `${option.id} ${option.firstName} ${option.lastName}`}
                        filterOptions={(_options, { inputValue }) => filterEmployees(inputValue)}
                        renderInput={params => <TextField {...params} label="Wyszukaj pracownika" />}
                        style={{ width: 300 }}
                        onChange={(_event, value) => {
                            handleSelect(value);
                        }}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <ListItemIcon>{toAvatar(option)}</ListItemIcon>
                                {option.firstName} {option.lastName}
                            </li>
                        )}
                    />
                </Popover>
            )}
        </>
    );

    const avatar = (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    {emp.unwrapOr(<></>)}
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={() => handleUnassignClick(invoiceEntryId)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    Odwiąż od pozycji
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
    return selectedEmployee.isJust() ? avatar : addButton;
};
