import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import { SalesAggregation } from "modules/SalesSummary/domain/types";
import { createSalesAggregation } from "modules/SalesSummary/service/factory";
import { SalesAggregationDto } from "modules/SalesSummary/service/protocol";

// TODO define a precise type
export type TaxxoInvoice = unknown;

export const getProjectSales =
    (apiGet: ApiGet<SalesAggregationDto>) =>
    (salesFrom: string, salesTo: string): AsyncResult<SalesAggregation> =>
        apiGet(`/salesSummary/sales?from=${salesFrom}&to=${salesTo}`).then(res => res.map(createSalesAggregation));

export const importTaxxoInvoices =
    (apiPost: ApiUpdate<void, TaxxoInvoice>) =>
    (from: string, to: string): AsyncResult<TaxxoInvoice> =>
        apiPost(`/taxxoInvoice/importTaxxoInvoicesFromTo?from=${from}&to=${to}`);
