import { AsyncResult, ApiGet, ApiUpdate } from "components/common/infractructure";
import {
    EmployeeDetails,
    EmployeePromotion,
    PositionAssignment,
    PrefilledEmail,
    PromotionEmailNotification,
    SendPromotionEmailRequest,
} from "../types";

export const getPositionAssignments = (apiGet: ApiGet<PositionAssignment[]>) => (): AsyncResult<PositionAssignment[]> =>
    apiGet(`/employee/assignments`);
export const getEmployeeDetails =
    (apiGet: ApiGet<EmployeeDetails[]>) =>
    (employeeId: string): AsyncResult<EmployeeDetails[]> =>
        apiGet(`/employee/` + employeeId);
export const notifyContractor =
    (apiPost: ApiUpdate<SendPromotionEmailRequest, PromotionEmailNotification>) =>
    (inputBody: SendPromotionEmailRequest): AsyncResult<PromotionEmailNotification> =>
        apiPost(`/employee/` + inputBody.positionAssignmentId + `/promotions/notifications/send`, inputBody);

export const getPrefilledEmail =
    (apiGet: ApiGet<PrefilledEmail>) =>
    (positionAssignmentId: number, promotionMonth: string): AsyncResult<PrefilledEmail> =>
        apiGet(`/employee/` + positionAssignmentId + "/promotions/email/template?promotionMonth=" + promotionMonth);

export const getPromotions =
    (apiGet: ApiGet<EmployeePromotion[]>) =>
    (promotionMonth: string): AsyncResult<EmployeePromotion[]> =>
        apiGet(`/employee/promotions?promotionMonth=` + promotionMonth);

export const fireContractor =
    (apiPost: ApiUpdate<void, void>) =>
    (username: string, endDate: string): AsyncResult<void> =>
        apiPost(`/employee/fireContractor?username=` + username + "&endDate=" + endDate);

export const promoteContractor =
    (apiPost: ApiUpdate<void, void>) =>
    (username: string, yearMonthFrom: string): AsyncResult<void> =>
        apiPost(`/employee/promoteContractor?username=` + username + "&yearMonthFrom=" + yearMonthFrom);
