import * as React from "react";
import { calculateTax, sum } from "components/common/utils/numberUtils";
import { Maybe } from "true-myth";
import { PaymentsSummary } from "components/common/ui-kit-business/InvoiceSummary/service/types";
import { ColumnDef, SimpleTable } from "components/common/ui-kit/components/SimpleTable";
import { getNonVatPayerData } from "components/common/ui-kit-business/CostsSummaryTable/utils";
import { SumPreview } from "components/common/ui-kit-business/InvoiceSummary/SumPreview";
import { PendingPayment } from "modules/types/types";
import { SimpleTableTypes } from "components/common/ui-kit/components/SimpleTable/types";
import { AnyObject } from "yup/lib/types";
import { TAX_RATE } from "./constants/costs";
import BigNumber from "bignumber.js";

interface InvoiceSummaryTableRow {
    totalNet: number;
    totalTax: number;
    totalGross: React.ReactNode;
    invoicePosition: string;
}

type RowData = Record<keyof InvoiceSummaryTableRow, string>;

const colDefs: SimpleTableTypes<RowData>["columnDefs"] = [
    {
        header: "Pozycja",
        rowDataKey: "invoicePosition",
    },
    {
        header: "Razem netto",
        rowDataKey: "totalNet",
    },
    {
        header: "Razem VAT",
        rowDataKey: "totalTax",
    },
    {
        header: "Razem brutto",
        rowDataKey: "totalGross",
    },
];

const invoiceTable = {
    "tr:last-child": {
        fontWeight: "fontWeightBold",
    },
};

interface Props {
    pendingPayment: PendingPayment;
    mainPositionsSummary: PaymentsSummary;
    additionalPayPositionsSummary: PaymentsSummary;
}

export const InvoiceSummaryTable = ({ pendingPayment, mainPositionsSummary, additionalPayPositionsSummary }: Props) => {
    const isVatPayer = Maybe.of(pendingPayment.contractorData.isVatPayer).unwrapOr(true);
    const totalNet = new BigNumber(mainPositionsSummary.totalNet).plus(additionalPayPositionsSummary.totalNet);
    const totalTax = calculateTax(totalNet.toNumber(), isVatPayer ? TAX_RATE : 0);

    const rowData: InvoiceSummaryTableRow[] = [
        {
            totalNet: totalNet.toNumber(),
            totalTax: totalTax.toNumber(),
            totalGross: new BigNumber(totalNet).plus(totalTax).toNumber(),
            invoicePosition: "Pozycja główna",
        },
    ];

    const summedTotalGross = sum([
        mainPositionsSummary.totalGross,
        additionalPayPositionsSummary.totalGross,
    ]).toNumber();

    const lastRow: InvoiceSummaryTableRow = {
        totalNet: pendingPayment.totalNet,
        totalTax: pendingPayment.totalTax,
        totalGross: <SumPreview actual={pendingPayment.totalGross} expected={summedTotalGross} />,
        invoicePosition: "Razem",
    };

    const { definition, data } = isVatPayer
        ? { definition: colDefs, data: [...rowData, lastRow] }
        : getNonVatPayerData(colDefs, [...rowData, lastRow], true);

    return (
        <div>
            <SimpleTable columnDefs={definition as ColumnDef<AnyObject>[]} rowData={data} sx={invoiceTable} />
        </div>
    );
};
