import * as React from "react";
import { FormSection } from "redux-form";
import { ProjectActivityDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { getKeyForSection } from "modules/Invoices/views/MonthlySettlement/service/formService";
import { GenericActivityForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GenericActivityForm";
import { ConstantField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/ConstantField";
import { WorkSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/WorkSection/WorkSection";
import {
    CLIENT_COSTS_SECTION_NAME,
    COSTS_SECTION_NAME,
    WORK_SECTION_NAME,
} from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/types";
import { CostsSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/CostsSection/CostsSection";
import { ClientCostsSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/ClientCostsSection/ClientCostsSection";

const ProjectActivityFormImpl = ({
    activityDefId,
    assignmentId,
    title,
    instructions,
    workSections,
    costsSections,
    clientCostsSections,
}: ProjectActivityDef) => {
    const workSectionComponents = workSections.map((workSectionDef, index) => (
        <WorkSection key={index} {...workSectionDef} sectionName={getKeyForSection(workSectionDef)} />
    ));
    const costsSectionComponents = costsSections.map((costsSectionDef, index) => (
        <CostsSection key={index} {...costsSectionDef} sectionName={getKeyForSection(costsSectionDef)} />
    ));
    const clientCostsSectionComponents = clientCostsSections.map((clientCostsSectionDef, index) => (
        <ClientCostsSection
            key={index}
            {...clientCostsSectionDef}
            sectionName={getKeyForSection(clientCostsSectionDef)}
        />
    ));

    return (
        <GenericActivityForm title={title} instructions={instructions}>
            <FormSection name={WORK_SECTION_NAME}>{workSectionComponents}</FormSection>
            <FormSection name={COSTS_SECTION_NAME}>{costsSectionComponents}</FormSection>
            <FormSection name={CLIENT_COSTS_SECTION_NAME}>{clientCostsSectionComponents}</FormSection>
            <ConstantField fieldValue={activityDefId} fieldName={"activityDefId"} />
            <ConstantField fieldValue={assignmentId} fieldName={"assignmentId"} />
        </GenericActivityForm>
    );
};

export const ProjectActivityForm = React.memo(ProjectActivityFormImpl);
