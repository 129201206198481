import { Column } from "@material-table/core";
import { Typography } from "@mui/material";
import { apiPost } from "components/common/infractructure";
import { toDateString } from "components/common/types";
import { assignDocumentPayment } from "modules/Bank/service/apiService";
import {
    DocumentTableItem,
    mapDocumentPaymentAssignmentPayload,
    PaymentTableItem,
    Payment,
    DocumentSearchResult,
    mapDocumentPaymentToDocumentTableItem,
    assignDocumentToPayment,
    DocumentPayment,
} from "modules/Bank/types";
import { TransactionsDetailsPanel } from "modules/Bank/views/Transactions/TransactionDetailsPanel";
import * as React from "react";
import { useMemo, useState } from "react";
import { TransactionsModal } from "modules/Bank/views/Transactions/TransactionsModal/TransactionsModal";
import { toast } from "react-hot-toast";
import { localizationMaterialTable } from "components/common/localization/localization";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { Table, TableActionIconButton } from "components/common/ui-kit/components/Table";
import { useTransactionsData, useTransactionsDataApi } from "./TransactionsDataContext";
import { useTransactionClientSideFilters } from "./TransactionFiltersContext";
import Loader from "../../../../components/Loader/Loader";
import { pipe } from "ramda";
import { draftDocumentAssignmentsReducer, DraftDocumentAssignmentsState } from "./draftDocumentAssignmentReducer";
import BigNumber from "bignumber.js";
import { EmptyListInfo } from "../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";

interface Props {
    payments: Payment[];
}

export const applyClientSideFilters = (payments: PaymentTableItem[], showNotFullyPaidOnly: boolean) =>
    payments.filter(({ unusedPaymentAmount }) =>
        showNotFullyPaidOnly ? unusedPaymentAmount.absoluteValue().gt(0) : true,
    );

export const applyDraftStateToServerState = (
    draftState: DraftDocumentAssignmentsState,
    payments: Payment[],
): PaymentTableItem[] =>
    payments.map(payment => {
        const mapPaidDocument = mapDocumentPaymentToDocumentTableItem(payment.currency);
        const removedPaidDocumentIds = draftState[payment.paymentId]?.removed ?? [];
        const hasDocumentBeenRemoved = (document: DocumentPayment) =>
            removedPaidDocumentIds.includes(document.documentPaymentId);
        const documentsRemovedFromPayment = payment.paidDocuments.filter(hasDocumentBeenRemoved);
        const paidDocuments = payment.paidDocuments.filter(document => !hasDocumentBeenRemoved(document));

        const removedDocumentsPaymentPartAmount = documentsRemovedFromPayment.reduce(
            (sum, doc) => sum.plus(doc.paymentPartAmount),
            new BigNumber(0),
        );
        const addedDraftPaidDocuments = draftState[payment.paymentId]?.draftAdded ?? [];
        const paymentTableItem: PaymentTableItem = {
            ...payment,
            unusedPaymentAmount: payment.unusedPaymentAmount.plus(removedDocumentsPaymentPartAmount),
            paidDocuments: paidDocuments.map(mapPaidDocument),
        };

        return addedDraftPaidDocuments.length > 0
            ? {
                  ...paymentTableItem,
                  paidDocuments: [
                      ...paymentTableItem.paidDocuments,
                      ...addedDraftPaidDocuments.map(docSearchResult =>
                          assignDocumentToPayment(paymentTableItem, docSearchResult),
                      ),
                  ],
              }
            : paymentTableItem;
    });

const columns: Column<PaymentTableItem>[] = [
    { title: "", width: "3%" },
    { field: "paymentId", title: "Nr ref", type: "string", width: "10%" },
    {
        field: "bookingDate",
        title: "Data księgowania",
        type: "date",
        width: "10%",
        render: ({ bookingDate }) => toDateString(bookingDate),
    },
    {
        field: "description",
        title: "Tytuł",
        type: "string",
        width: "45%",
        render: ({ description }) => <p>{description}</p>,
    },
    {
        field: "totalPaymentAmount",
        title: "Kwota transakcji",
        align: "right",
        width: "17%",
        render: ({ totalPaymentAmount, unusedPaymentAmount }) => (
            <>
                {totalPaymentAmount.toFixed(2)}
                {!unusedPaymentAmount.eq(0) && (
                    <Typography sx={{ color: "custom.subtitle" }}>{unusedPaymentAmount.toFixed(2)}</Typography>
                )}
            </>
        ),
    },
    { field: "currency", title: "Waluta", type: "string", width: "7.5%" },
];

const TransactionListTable = ({ payments }: Props) => {
    const transactionsApi = useTransactionsDataApi();
    const { showNotFullyPaid } = useTransactionClientSideFilters();
    const [draftAssignments, dispatch] = React.useReducer(draftDocumentAssignmentsReducer, {});

    const addPaidDocument = (paymentId: number, document: DocumentSearchResult) => {
        dispatch({
            action: "addDraftAssignment",
            payload: {
                paymentId,
                document,
            },
        });
    };

    const removeDocumentAssignment = (paymentId: number, documentPaymentId: number) => {
        dispatch({
            action: "removeDocumentAssignment",
            payload: {
                paymentId,
                documentPaymentId,
            },
        });
    };

    const removeDraftDocument = (paymentId: number, documentId: number) => {
        dispatch({
            action: "removeDraftAssignment",
            payload: {
                paymentId,
                documentId,
            },
        });
    };

    const handleSubmitDocumentAssignment = async (payment: PaymentTableItem, document: DocumentTableItem) => {
        const result = await assignDocumentPayment(apiPost)(mapDocumentPaymentAssignmentPayload(payment, document));

        result.match({
            Ok: () => {
                transactionsApi.refreshData().then(() => {
                    dispatch({
                        action: "removeDraftAssignment",
                        payload: {
                            paymentId: payment.paymentId,
                            documentId: document.documentId,
                        },
                    });
                });
                toast.success("Powiązano dokument");
            },
            Err: ({ message }) => toast.error(`Nie udało się powiązać dokumentu, ${message}`),
        });
    };

    const filteredPayments = useMemo(
        pipe(
            () => applyDraftStateToServerState(draftAssignments, payments),
            tableItems => applyClientSideFilters(tableItems, showNotFullyPaid),
        ),
        [payments, draftAssignments, showNotFullyPaid],
    );

    const ActionsCell = (rowData: PaymentTableItem) => {
        const [showDialog, setShowDialog] = useState(false);

        const handleSplitPayment = () => {
            if (rowData.tableData) {
                rowData.tableData.showDetailPanel = true;
                rowData.tableData.isTreeExpanded = true;
                setShowDialog(true);
            }
        };

        return (
            <>
                {!rowData.unusedPaymentAmount.eq(0) && (
                    <>
                        <TableActionIconButton
                            tooltip="Powiąż dokument"
                            Icon={RequestPageIcon}
                            onClick={handleSplitPayment}
                        />

                        {showDialog && (
                            <TransactionsModal
                                payment={rowData}
                                showDialog={showDialog}
                                setShowDialog={setShowDialog}
                                addPaidDocument={doc => addPaidDocument(rowData.paymentId, doc)}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const table = (
        <Table
            columns={columns}
            data={filteredPayments}
            sx={{
                [`tr .${Table.actionsWrapperClassName}`]: {
                    visibility: "visible",
                },
            }}
            actionsColumn={{
                render: ActionsCell,
                width: "7.5%",
            }}
            options={{
                tableLayout: "fixed",
                showTitle: false,
                search: false,
                grouping: false,
                selection: false,
                showTextRowsSelected: true,
                paging: filteredPayments.length > 50,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 50, 100],
                toolbar: false,
                idSynonym: "paymentId",
                padding: "dense",
                headerStyle: boldHeaderStyle(),
            }}
            detailPanel={({ rowData }) => {
                return (
                    <TransactionsDetailsPanel
                        payment={rowData}
                        removeDocumentAssignment={removeDocumentAssignment}
                        handleSubmitDocument={handleSubmitDocumentAssignment}
                        onRemoveDraftDocument={removeDraftDocument}
                    />
                );
            }}
            style={{ padding: "0 1rem" }}
            localization={localizationMaterialTable}
        />
    );

    return filteredPayments.length > 0 ? table : <EmptyListInfo />;
};

export const TransactionList = () => {
    const transactionsDataState = useTransactionsData();

    if (transactionsDataState.name === "error") {
        // Caught by ErrorBoundary
        throw new Error(
            `Failed to load transactions screen due to following errors:\n${transactionsDataState.errors.join("\n")}`,
        );
    }

    return (
        <>
            {transactionsDataState.name === "data-loaded" && (
                <TransactionListTable payments={transactionsDataState.payments} />
            )}
            {/*    TODO make an overlay*/}
            {transactionsDataState.name === "loading" && <Loader />}
        </>
    );
};
