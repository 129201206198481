import { Box, Paper } from "@mui/material";
import { Maybe } from "true-myth";
import { ClientSales, InvoiceData } from "modules/SalesSummary/domain/types";
import { InvoiceInfo } from "modules/SalesSummary/InvoiceInfo";
import { InvoiceStatusColorShowcase } from "./InvoiceStatusColorShowcase";
import * as React from "react";

const EMPTY_ROW_PLACEHOLDER = "-";

const getInvoiceTd = (
    invoicesForMonth: Map<string, InvoiceData[]>,
    yearMonthRange: string[],
    handleShowDetails: (invoiceData: InvoiceData) => (event: React.KeyboardEvent | React.MouseEvent) => void,
) =>
    yearMonthRange.map(yearMonth =>
        Maybe.of(invoicesForMonth.get(yearMonth))
            .map(invoiceInfos => (
                <td key={yearMonth}>
                    <InvoiceInfo invoiceData={invoiceInfos} onShowDetails={handleShowDetails} />
                </td>
            ))
            .unwrapOr(
                <td key={yearMonth} className="center-text">
                    {EMPTY_ROW_PLACEHOLDER}
                </td>,
            ),
    );

const isFirstRow = (idx: number) => idx === 0;
const convertYearMonthsToTableMainHeaders = (yearMonth: string) => <th key={yearMonth}>{yearMonth}</th>;

interface SalesSummaryTableProps {
    salesYearMonthRange: string[];
    clientSales: ClientSales[];
    onShowDetails: (invoiceData: InvoiceData) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const SalesSummaryTable = ({ salesYearMonthRange, clientSales, onShowDetails }: SalesSummaryTableProps) => {
    const yearMonthMainHeaders = salesYearMonthRange.map(convertYearMonthsToTableMainHeaders);

    const bodyRows = clientSales.map(({ companyName, salesPositions }) =>
        salesPositions.map(({ projectName, invoicesForMonth }, idx) => (
            <tr key={idx}>
                {isFirstRow(idx) && (
                    <td className="sticky-col col-0" rowSpan={salesPositions.length}>
                        {companyName}
                    </td>
                )}
                <td className="sticky-col col-1">{Maybe.of(projectName).unwrapOr("-")}</td>
                {getInvoiceTd(invoicesForMonth, salesYearMonthRange, onShowDetails)}
            </tr>
        )),
    );

    return (
        <Paper>
            <Box
                sx={{
                    position: "relative",
                    overflowX: "scroll",
                }}
                key={0}
            >
                <Box
                    component="table"
                    sx={{
                        height: "1px",
                        width: "100%",
                        textAlign: "center",
                        "& th": {
                            fontWeight: 600,
                            textAlign: "center",
                            padding: "5px 20px 5px 4px",
                            border: "none",
                            height: "3rem",
                        },
                        "& >tbody>tr>td": {
                            textAlign: "center",
                            padding: "4px",
                            boxShadow: "inset 0 1px 0 #cdcdcd, inset 0 0px 0 blue;",
                        },
                        "& .sticky-col": {
                            position: "sticky",
                            zIndex: 1,
                        },
                        "& .col-0": {
                            left: "0px",
                            width: "250px",
                            maxWidth: "250px",
                            minWidth: "250px",
                            textAlign: "left",
                        },
                        "& .col-1": {
                            left: "250px",
                            width: "150px",
                            maxWidth: "150px",
                            minWidth: "150px",
                            textAlign: "left",
                        },
                    }}
                >
                    <thead>
                        <tr>
                            <th className="sticky-col col-0">Klient</th>
                            <th className="sticky-col col-1">Projekt</th>
                            {yearMonthMainHeaders}
                        </tr>
                    </thead>
                    <tbody>{bodyRows}</tbody>
                </Box>
            </Box>
            <InvoiceStatusColorShowcase sx={{ marginTop: "1rem" }} />
        </Paper>
    );
};
