import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useUserContext } from "components/common/contexts";
import { AsyncResult } from "components/common/infractructure";
import Loader from "components/Loader/Loader";
import {
    ContractorInput,
    defaultContractorData,
    GmailToken,
    updateUserProfileData,
    User,
    UserProfileData,
} from "modules/UserProfile/types";
import { EmploymentInfo } from "modules/UserProfile/views/components/EmploymentInfo";
import { UserInfo } from "modules/UserProfile/views/components/UserInfo";
import * as React from "react";
import toast from "react-hot-toast";
import { useBoolean } from "usehooks-ts";
import GoogleButton from "react-google-button";
import { format, parseISO } from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";
import { ContractorForm } from "./components/ContractorForm";

interface Props {
    getGmailAuthUrl: () => AsyncResult<string>;
    updateContractorData: (data: ContractorInput) => AsyncResult<void>;
    deleteGmailConsent: (consentId: number) => AsyncResult<GmailToken[]>;
}

function updateGmailTokens(userProfileData: UserProfileData, newTokens: GmailToken[]): UserProfileData {
    const newUser: User = {
        ...userProfileData.user,
        gmailTokens: newTokens,
    };

    const newUserProfileData: UserProfileData = {
        ...userProfileData,
        user: newUser,
    };

    return newUserProfileData;
}

const UserProfile: React.FC<Props> = ({ updateContractorData, getGmailAuthUrl, deleteGmailConsent }) => {
    const { userProfileData, setUserProfileData } = useUserContext();

    const { value: isLoading, setValue: setIsLoading } = useBoolean(false);

    const updateProfile = async (contractorInput: ContractorInput) => {
        setIsLoading(true);

        const result = await updateContractorData(contractorInput);
        result.match({
            Ok: () => {
                setUserProfileData(updateUserProfileData(userProfileData, contractorInput));
                toast.success("Profil został zaktualizowany");
            },
            Err: err => toast.error(`Nie udało się zaktualizować profilu: ${err.message}`),
        });

        setIsLoading(false);
    };

    const handleDeleteEmailClick = async (id: number) => {
        (await deleteGmailConsent(id)).match({
            Ok: res => {
                toast.success("Usunięto połączenie konta email!");
                setUserProfileData(updateGmailTokens(userProfileData, res));
            },
            Err: () => {
                toast.error("Nie udało się usunąć konta email!");
            },
        });
    };

    const handleConnectEmail = async () => {
        (await getGmailAuthUrl()).match({
            Ok: res => {
                window.location.href = res;
            },
            Err: () => {
                toast.error("Nie udało się połączyć konta email!");
            },
        });
    };

    const isEmployee = userProfileData.user.isEmployee;

    const connectedEmails = userProfileData.user.gmailTokens.map((t, index) => {
        return {
            email: t.email,
            idx: index + 1 + ".",
            id: t.id,
            timestamp: format(parseISO(t.created), "yyyy-MM-dd HH:mm"),
        };
    });

    const columns: GridColDef[] = [
        {
            field: "idx",
            headerName: "L.p.",
            width: 100,
        },
        {
            field: "email",
            headerName: "Adres",
            flex: 1,
        },
        {
            field: "timestamp",
            headerName: "Data połączenia",
            flex: 1,
        },
        {
            field: "id",
            headerName: "Akcje",
            renderCell: params => (
                <IconButton onClick={() => handleDeleteEmailClick(params.row.id)} sx={{ color: "red" }}>
                    <ClearIcon />
                </IconButton>
            ),
            flex: 1,
        },
    ];

    return (
        <div data-testid={"userProfileTestId"}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Grid container sx={{ marginBottom: "1rem" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Użytkownik</Typography>
                        </Grid>
                    </Grid>
                    <UserInfo key={0} userData={userProfileData.user} />

                    {isEmployee && (
                        <Box data-testid="employeeTestId">
                            <Grid container sx={{ marginBottom: "1rem", paddingTop: "1rem" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Stanowisko</Typography>
                                </Grid>
                            </Grid>
                            {userProfileData.employmentData
                                .map(employmentData => <EmploymentInfo key={0} employmentData={employmentData} />)
                                .unwrapOr(<></>)}

                            <ContractorForm
                                key={1}
                                contractor={userProfileData.contractorData.unwrapOr(defaultContractorData)}
                                onSubmit={updateProfile}
                            />
                        </Box>
                    )}

                    <Grid container sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Połączone konta email</Typography>
                        </Grid>
                    </Grid>
                    <Paper data-testid="GmailIntegration">
                        <Grid container sx={{}}>
                            <Grid item xs={12}>
                                <DataGrid
                                    localeText={{ noRowsLabel: "Brak połączonych kont" }}
                                    disableColumnMenu={true}
                                    disableColumnFilter={true}
                                    disableColumnSelector={true}
                                    disableDensitySelector={true}
                                    disableSelectionOnClick={true}
                                    hideFooter={true}
                                    hideFooterPagination={true}
                                    hideFooterSelectedRowCount={true}
                                    autoHeight={true}
                                    sx={{
                                        minHeight: 100,
                                        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                                            outline: "transparent",
                                        },
                                        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
                                            {
                                                outline: "none",
                                            },
                                        "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                                            outline: "none",
                                        },
                                        border: 0,
                                    }}
                                    rows={connectedEmails}
                                    columns={columns}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    paddingTop: "1.5rem",
                                    paddingBottom: "1.5rem",
                                }}
                            >
                                <GoogleButton label="Połącz konto gmail" onClick={handleConnectEmail} />
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            )}
        </div>
    );
};

export default UserProfile;
