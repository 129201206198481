import { Employee } from "../Projects/types";
import BigNumber from "bignumber.js";
import { OperationType } from "../types/types";
import { PaymentDTO } from "../Bank/types";

interface VatRate {
    name: string;
    value: number;
}

interface ValueDto {
    value: number;
    plnValue: number;
}

interface Value {
    value: BigNumber;
    plnValue: BigNumber;
}

interface TaxLine {
    vatRate: VatRate;
    netValue: Value;
    taxValue: Value;
    grossValue: Value;
}

interface ExchangeRate {
    currency: string;
    rate: number;
    date: string;
}

interface SummaryDto extends Omit<Summary, "totalNet" | "totalTax" | "totalGross"> {
    totalNet: ValueDto;
    totalTax: ValueDto;
    totalGross: ValueDto;
}

interface Summary {
    exchangeRate: ExchangeRate | null;
    totalNet: Value;
    totalTax: Value;
    totalGross: Value;
    taxLines: TaxLine[];
}

interface Summary {
    exchangeRate: ExchangeRate | null;
    totalNet: Value;
    totalTax: Value;
    totalGross: Value;
    taxLines: TaxLine[];
}

interface Contractor {
    name: string;
    identifier: string;
    addressStreet: string;
    addressCity: string;
    addressZipCode: string;
    addressCountry: string;
}

interface OperationTypeWrapper {
    name: OperationType;
}

interface DocumentType {
    name: string;
}

interface Account {
    tags: string[];
    account: string;
}

export interface Allocation {
    account: Account;
    value: number;
    description: string;
}

export interface Accounting {
    accountingMonth: string;
    accountingCategory: string;
    accountingDescription: string;
    allocations: Allocation[];
}

export interface DocumentDetails {
    taxxoId: number;
    id: number;
    number: string;
    issueDate: string;
    salesDate: string;
    paymentDate: string;
    documentType: DocumentType;
    operationType: OperationTypeWrapper;
    contractor: Contractor;
    summary: Summary;
    accounting: Accounting;
    payments: DocumentPaymentDetails[];
    otherPayments: DocumentOtherPaymentDetails[];
    invoiceEntries: InvoiceEntry[];
    unpaidAmount: number;
}

export interface DocumentDetailsDto extends Omit<DocumentDetails, "summary" | "payments"> {
    summary: SummaryDto;
    payments: DocumentPaymentDetailsDto[];
}

export interface InvoiceEntry {
    id: number;
    description: string;
    unit: {
        name: string;
    };
    quantity: number;
    unitPrice: number;
    netPrice: number;
    taxPrice: number;
    grossPrice: number;
    employee: Employee;
}

export interface DocumentPaymentDetails {
    documentPaymentID: number;
    paymentId: number;
    currency: string;
    assignedAmount: BigNumber;
    totalAmount: BigNumber;
    unusedAmount: BigNumber;
    description: string;
    assignedBy: null | Employee;
    created: string;
    bookedDate: string;
    amountInDocumentCurrency: BigNumber;
}

export interface DocumentOtherPaymentDetails {
    documentPaymentId: number;
    amountInDocumentCurrency: number;
    notes: string;
    date: string;
    assignedBy: Employee;
}

export interface DocumentPaymentDetailsDto
    extends Omit<
        DocumentPaymentDetails,
        "assignedAmount" | "totalAmount" | "unusedAmount" | "amountInDocumentCurrency"
    > {
    assignedAmount: number;
    totalAmount: number;
    unusedAmount: number;
    amountInDocumentCurrency: number;
}

export interface PaymentSearchResult {
    paymentId: number;
    currency: string;
    totalAmount: BigNumber;
    unusedAmount: BigNumber;
    description: string;
    bookedDate: string;
}

export interface ExchangeRateDto {
    baseCurrency: string;
    quoteCurrency: string;
    value: number;
    date: string;
}

export interface UpdateDocumentPaymentRequest {
    documentPaymentId: number;
    amountInDocumentCurrency: string;
}

export interface CreateOtherTypeDocumentPaymentRequest {
    amountInDocumentCurrency: string;
    documentId: number;
    notes: string;
    date: string;
}

export interface UpdateOtherTypeDocumentPaymentRequest {
    documentPaymentId: number;
    amountInDocumentCurrency: string;
    date: string;
    notes: string;
}

function convertValueDtoToValue(dto: ValueDto): Value {
    return {
        value: new BigNumber(dto.value),
        plnValue: new BigNumber(dto.plnValue),
    };
}

function convertSummaryDtoToSummary(dto: SummaryDto): Summary {
    return {
        ...dto,
        totalNet: convertValueDtoToValue(dto.totalNet),
        totalTax: convertValueDtoToValue(dto.totalTax),
        totalGross: convertValueDtoToValue(dto.totalGross),
    };
}

function convertDocumentPaymentDetailsDtoToDocumentPaymentDetails(
    dto: DocumentPaymentDetailsDto,
): DocumentPaymentDetails {
    return {
        ...dto,
        assignedAmount: new BigNumber(dto.assignedAmount),
        totalAmount: new BigNumber(dto.totalAmount),
        unusedAmount: new BigNumber(dto.unusedAmount),
        amountInDocumentCurrency: new BigNumber(dto.amountInDocumentCurrency),
    };
}

export function convertDocumentDetailsDtoToDocumentDetails(dto: DocumentDetailsDto): DocumentDetails {
    return {
        ...dto,
        summary: convertSummaryDtoToSummary(dto.summary),
        payments: dto.payments.map(convertDocumentPaymentDetailsDtoToDocumentPaymentDetails),
    };
}

export const mapPaymentDTOToSearchResult = (transactionDTO: PaymentDTO): PaymentSearchResult => ({
    paymentId: transactionDTO.paymentId,
    currency: transactionDTO.currency,
    totalAmount: new BigNumber(transactionDTO.totalPaymentAmount),
    unusedAmount: new BigNumber(transactionDTO.unusedPaymentAmount),
    description: transactionDTO.description,
    bookedDate: transactionDTO.bookingDate,
});
