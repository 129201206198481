import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { BillingDetailsView } from "./BillingDetailsView";
import { BillingsView } from "./BillingsView";
import NotFoundRoute from "components/common/ui-kit/components/NotFound";
import {
    acceptBilling,
    getBillingSummary,
    markBillingAsDone,
    recalculateAssigmentBillings,
    saveCostBillings,
    sendBilling,
    getBillingsOverview,
    getMissingMonthlySettlements,
    sendMissingSettlementNotification,
} from "modules/Billing/service";
import { apiGet, apiPost, apiPut } from "components/common/infractructure";

export const BillingPage: React.FC = () => (
    <Routes>
        <Route
            path="all"
            element={
                <BillingsView
                    getBillingsOverview={getBillingsOverview(apiGet)}
                    getMissingMonthlySettlements={getMissingMonthlySettlements(apiGet)}
                    sendMissingSettlementNotification={sendMissingSettlementNotification(apiPost)}
                />
            }
        />
        <Route
            path="summary/:billingId"
            element={
                <BillingDetailsView
                    getBillingSummary={getBillingSummary(apiGet)}
                    saveCostBillings={saveCostBillings(apiPut)}
                    sendBilling={sendBilling(apiPost)}
                    acceptBilling={acceptBilling(apiPost)}
                    markBillingAsDone={markBillingAsDone(apiPut)}
                    recalculateAssigmentBillings={recalculateAssigmentBillings(apiPost)}
                />
            }
        />
        <Route path="*" element={<NotFoundRoute />} />
    </Routes>
);
