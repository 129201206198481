import { Alert } from "@mui/material";
import { EligibleToInvoice } from "modules/Invoices/views/MonthlySettlement/domain/types";
import * as React from "react";
import { Result } from "true-myth";

interface Props {
    eligibleToInvoice: Result<EligibleToInvoice, Error>;
}

const EligibilityWarning: React.FC<Props> = ({ eligibleToInvoice }) =>
    eligibleToInvoice
        .map(({ isEligible, message }) =>
            isEligible ? (
                <></>
            ) : (
                <Alert key={0} severity="error">
                    {message}
                </Alert>
            ),
        )
        .unwrapOrElse(({ message }) => (
            <Alert severity="error">
                Podczas sprawdzania, czy podane informacje kwalifikują do wystawienia faktury wystąpił błąd: {message}
            </Alert>
        ));

export default EligibilityWarning;
