import { ProjectDetails, ProjectFormData, Vacancy, VacancyFormData } from "../../types";
import { AsyncReducer } from "../../../useAsyncReducer";
import {
    createProjectVacancy,
    deleteProjectVacancy,
    updateProject,
    updateProjectVacancy,
} from "../../service/apiService";
import { apiDelete, apiPatch, apiPost } from "../../../../components/common/infractructure";
import { findIndex, slice } from "ramda";

interface ProjectState {
    projectDetails: ProjectDetails;
    allVacancies: Vacancy[];
}

type ProjectAction =
    | {
          type: "UPDATE_PROJECT";
          payload: ProjectFormData;
      }
    | {
          type: "CREATE_VACANCY";
          payload: VacancyFormData;
      }
    | {
          type: "UPDATE_VACANCY";
          payload: {
              current: Vacancy;
              updated: VacancyFormData;
          };
      }
    | {
          type: "REMOVE_VACANCY";
          payload: {
              id: Vacancy["id"];
          };
      };

export const projectStateReducer: AsyncReducer<ProjectState, ProjectAction> = (state, action) => {
    switch (action.type) {
        case "UPDATE_PROJECT": {
            const { payload } = action;

            return {
                optimisticState: state,
                syncRemoteState: async () => {
                    const result = await updateProject(apiPatch, `${state.projectDetails.id}`)(payload);
                    return result.map(updatedProject => ({
                        ...state,
                        projectDetails: updatedProject,
                    }));
                },
            };
        }
        case "CREATE_VACANCY": {
            const { payload } = action;
            return {
                optimisticState: state,
                syncRemoteState: async () => {
                    const result = await createProjectVacancy(apiPost, `${state.projectDetails.id}`)(payload);
                    return result.map(createdVacancy => ({
                        ...state,
                        allVacancies: [...state.allVacancies, createdVacancy],
                    }));
                },
            };
        }
        case "UPDATE_VACANCY": {
            const { current, updated } = action.payload;

            return {
                optimisticState: state,
                syncRemoteState: async () => {
                    const result = await updateProjectVacancy(apiPatch, `${current.id}`)(updated);
                    const index = findIndex(({ id }) => current.id === id, state.allVacancies);
                    return result.map(updatedVacancy => ({
                        ...state,
                        allVacancies: [
                            ...slice(0, index, state.allVacancies),
                            updatedVacancy,
                            ...slice(index + 1, Infinity, state.allVacancies),
                        ],
                    }));
                },
            };
        }
        case "REMOVE_VACANCY": {
            const { id } = action.payload;
            const nextState: ProjectState = {
                ...state,
                allVacancies: state.allVacancies.filter(v => id !== v.id),
            };
            return {
                optimisticState: nextState,
                syncRemoteState: async () => {
                    const result = await deleteProjectVacancy(apiDelete)(id);
                    return result.map(() => nextState);
                },
            };
        }
    }
};
