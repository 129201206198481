import { Box } from "@mui/material";
import { noop } from "components/common/utils";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@mui/icons-material";
import { Maybe } from "true-myth";

interface Props {
    onFileUploaded: (acceptedFile: File) => void;
    promptText: string;
    children?: React.ReactNode;
    accept?: React.InputHTMLAttributes<HTMLInputElement>["accept"];
}

export const SingleFileUpload: React.FC<Props> = ({ accept, onFileUploaded, promptText }) => {
    const onDrop = (acceptedFiles: File[]) =>
        Maybe.of(acceptedFiles[0]).match({
            Just: onFileUploaded,
            Nothing: noop,
        });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        multiple: false,
        onDrop,
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    // TODO revisit the vx design of that component, use theme colors, verify commented-out styles
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "2px",
                    border: "2px dashed",
                    transition: "border-color 0.2s",
                    height: "200px",
                    zIndex: 100,
                    outline: "none",
                    color: "#C0C0C0",

                    "& *": {
                        transition: "color 0.2s",
                    },

                    "&:hover": {
                        cursor: "pointer",
                        // border-color: ${({ theme }) => theme.form.field.borderActive};
                        transition: "border-color 0.2s",

                        "& *": {
                            // color: ${({ theme }) => theme.form.field.borderActive};
                            transition: "color 0.2s",
                        },

                        "& i": {
                            // transform: translateY(-0.2rem) scale(1.2);
                            transition: "transform 0.2s",
                        },
                    },

                    "&::before": {
                        content: '""',
                        // backgroundColor: ${({ theme }) => theme.form.field.border};
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        // opacity: ${({ isDragging }) => (isDragging ? "0.2" : "0")};
                        transition: "opacity 0.2s",
                    },

                    i: {
                        fontSize: 3,
                        marginBottom: 1,
                        transition: "transform 0.2s",
                    },

                    "& span": {
                        fontSize: "1.5rem",
                    },
                    ...(isDragActive
                        ? {
                              borderColor: "primary.main",

                              "& *": {
                                  color: "primary.dark",
                                  transition: "color 0.2s",
                              },
                          }
                        : {}),
                }}
                {...getRootProps()}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <>
                        {!isDragActive ? (
                            <>
                                <CloudUploadOutlined sx={{ fontSize: "2.5em" }} />
                                <span>{promptText}</span>
                                <input {...getInputProps()} />
                            </>
                        ) : (
                            <span>Drop the file</span>
                        )}
                    </>
                </Box>
            </Box>
        </Box>
    );
};
