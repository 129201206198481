import { Box, Button, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { AsyncResult } from "components/common/infractructure";
import { MonthsList, YearMonth } from "components/common/types";
import { noop, parseMonth } from "components/common/utils";
import Loader from "components/Loader/Loader";
import { YearMonthWithRate } from "modules/Invoices/views/MonthlySettlement/domain/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { filterPanel } from "components/common/ui-kit/styles/utils";

const isCurrentYearMonth =
    (yearCurrent: number, monthCurrent: number) =>
    ({ year, month }: YearMonthWithRate) =>
        year === yearCurrent && month === monthCurrent;

interface Props {
    getAvailableMonthsWithRates: (yearMonth: YearMonth) => AsyncResult<YearMonthWithRate[]>;
}

export const SettlementDateSelect: React.FC<Props> = ({ getAvailableMonthsWithRates }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDateWithRate, setSelectedDateWithRate] = useState<YearMonthWithRate>({
        year: currentYear,
        month: currentMonth as YearMonth["month"],
        rate: 0,
        workingDays: 0,
        hasInvoice: false,
        rateRaise: false,
    });
    const [availableDatesWithRates, setAvailableDatesWithRates] = useState<Result<YearMonthWithRate[], Error>>(
        Result.err(new Error("Available dates not loaded")),
    );

    useEffect(() => {
        (async () => {
            const months = await getAvailableMonthsWithRates({
                month: parseMonth(currentMonth).unwrapOr(1),
                year: currentYear,
            });
            setAvailableDatesWithRates(months.map(okMonths => okMonths.filter(({ hasInvoice }) => !hasInvoice)));
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        availableDatesWithRates.match({
            Ok: okDates => {
                Maybe.of(okDates.find(isCurrentYearMonth(currentYear, currentMonth))).match({
                    Just: setSelectedDateWithRate,
                    Nothing: () => setSelectedDateWithRate(okDates[0]),
                });
            },
            Err: noop,
        });
    }, [availableDatesWithRates]);

    const handleDateChange = (event: SelectChangeEvent<YearMonthWithRate>) =>
        setSelectedDateWithRate(event.target.value as YearMonthWithRate);

    const handleSetSettlementMonth = () => {
        navigate(`/invoices/add/${selectedDateWithRate.year}/${selectedDateWithRate.month}`);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper sx={filterPanel}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="h6">Wystawiam fakturę za </Typography>

                        <Box margin="0 10px">
                            <Select value={selectedDateWithRate} onChange={handleDateChange} variant="standard">
                                {availableDatesWithRates
                                    .map(dates =>
                                        dates.map(date => (
                                            <MenuItem key={`${date.month}${date.year}`} value={date as any}>
                                                {MonthsList[date.month - 1]} {date.year}
                                            </MenuItem>
                                        )),
                                    )
                                    .unwrapOr(<></>)}
                            </Select>
                        </Box>

                        <Box marginLeft="10px">
                            <Button variant="contained" color="primary" onClick={handleSetSettlementMonth}>
                                Dalej &#10095;
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            )}
        </>
    );
};
