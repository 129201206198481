import { Avatar, Badge, Tooltip } from "@mui/material";
import { Employee, formatEmployeeLabel } from "modules/Projects/types";
import * as Colors from "@mui/material/colors";
import { randomNumberBetween } from "@mui/x-data-grid/utils/utils";
import { Maybe } from "true-myth";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface AvatarData {
    name: string;
    firstName: string;
    lastName: string;
    label: string;
    color: string;
    level: Maybe<string>;
    url: Maybe<string>;
}

const stringToColour = (name: string) => {
    const colors = [
        Colors.amber["800"],
        Colors.blue["800"],
        Colors.blueGrey["800"],
        Colors.brown["800"],
        Colors.cyan["800"],
        Colors.deepOrange["800"],
        Colors.deepPurple["800"],
        Colors.green["800"],
        Colors.indigo["800"],
        Colors.lightBlue["800"],
        Colors.lightGreen["800"],
        Colors.lime["800"],
        Colors.orange["800"],
        Colors.pink["800"],
        Colors.purple["800"],
        Colors.red["800"],
        Colors.teal["800"],
        Colors.yellow["800"],
    ];
    const getHashOfString = (str: string) => {
        const charArray = Array.from(str);
        return charArray.reduce((total, _char, index) => {
            return (total += str.charCodeAt(index) * index);
        }, 0);
    };
    return colors.at(randomNumberBetween(getHashOfString(name), 0, colors.length - 1)()) as string;
};

const toAvatarData = (employee: Employee): AvatarData => ({
    name: formatEmployeeLabel(employee),
    firstName: employee.firstName,
    lastName: employee.lastName,
    label: `${employee.firstName[0]}${employee.lastName[0]}`,
    color: employee.isActive ? stringToColour(formatEmployeeLabel(employee)) : Colors.grey["400"],
    level: Maybe.fromNullable(employee.level),
    url: Maybe.fromNullable(employee.avatarUrl),
});

interface Props {
    employee: Employee;
    sx?: SxProps<Theme>;
}

export const EmployeeAvatar: React.FC<Props> = ({ employee, sx }) => {
    const ad = toAvatarData(employee);
    const alt = ad.level.map(l => `${ad.name} (${l})`).unwrapOr(ad.name);
    const avatar = ad.url
        .map(url => <Avatar key={employee.id} src={url} sx={{ ...sx }} />)
        .unwrapOr(<Avatar sx={{ backgroundColor: ad.color, ...sx }}>{ad.label}</Avatar>);
    return (
        <Tooltip title={alt}>
            {employee.isActive ? (
                avatar
            ) : (
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    badgeContent="-"
                    color="error"
                >
                    {avatar}
                </Badge>
            )}
        </Tooltip>
    );
};
