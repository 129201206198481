import { Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { formatForView } from "components/common/utils/numberUtils";

interface Props {
    actual: number;
    expected: number;
}

export const SumPreview = ({ actual, expected }: Props) => {
    const tootltip = (
        <span>
            <Tooltip title={`Wartość zmodyfikowano ręcznie`}>
                <WarningIcon htmlColor="#ffcc00" fontSize={"small"} />
            </Tooltip>
        </span>
    );

    return (
        <span>
            {actual !== expected ? tootltip : <></>}
            {formatForView(actual)}
        </span>
    );
};
