import { Box, Grid, Paper, Typography } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { PendingPayment } from "modules/types/types";
import { additionalCostsCategoryGroups } from "./constants/costs";
import { CostSummaryTableWrapper } from "./CostSummaryTableWrapper";
import { InvoicePreview } from "./InvoicePreview";
import { InvoiceSummaryTable } from "./InvoiceSummaryTable";
import { getPaymentsSummary } from "./service/summaryService";

export const InvoiceSummary = () => {
    const pendingPayment = useLoaderData() as Result<PendingPayment, Error>;

    const paymentView = pendingPayment
        .map(payment => {
            const isVatPayer = Maybe.of(payment.contractorData.isVatPayer).unwrapOr(true);
            const mainPositions = payment.positions.filter(el => el.isMain);
            const consultingPositions = payment.positions.filter(el => !el.isMain);
            const mainPositionsSummary = getPaymentsSummary(mainPositions, isVatPayer);
            const consultingPositionsSummary = getPaymentsSummary(consultingPositions, isVatPayer);

            const invoiceMainPosition = payment.positions.filter(
                el => !additionalCostsCategoryGroups.includes(el.category.group),
            );
            const invoiceMainPositionSummary = getPaymentsSummary(invoiceMainPosition, isVatPayer);

            const invoiceAdditionalPayPosition = payment.positions.filter(el =>
                additionalCostsCategoryGroups.includes(el.category.group),
            );
            const invoiceAdditionalPayPositionSummary = getPaymentsSummary(invoiceAdditionalPayPosition, isVatPayer);

            return (
                <>
                    <Box sx={{ marginBottom: "1rem" }}>
                        <Typography variant="h5" sx={{ display: "inline-block" }}>
                            Dane faktury: &#8203;
                        </Typography>
                        <Typography variant="h4" sx={{ display: "inline-block" }}>
                            {payment.contractorData.companyName}, {payment.invoiceMonth}
                        </Typography>
                    </Box>

                    <div key={0}>
                        <Grid justifyContent={"space-between"} container>
                            <Grid item sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
                                <Typography variant="h5">Dniówki</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ marginBottom: "2rem" }}>
                            <Paper>
                                <CostSummaryTableWrapper
                                    positions={mainPositions}
                                    isVatPayer={isVatPayer}
                                    summary={mainPositionsSummary}
                                />
                            </Paper>
                        </Box>
                        <Box sx={{ marginBottom: "1rem" }}>
                            <Typography variant="h5">Pozostałe koszty</Typography>
                        </Box>
                        <Box>
                            <Paper>
                                <CostSummaryTableWrapper
                                    positions={consultingPositions}
                                    isVatPayer={isVatPayer}
                                    summary={consultingPositionsSummary}
                                />
                            </Paper>
                        </Box>
                        <br />
                        <Box sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
                            <Typography variant="h4">
                                <b>Podsumowanie</b>
                            </Typography>
                        </Box>
                        <Box>
                            <Paper>
                                <InvoiceSummaryTable
                                    pendingPayment={payment}
                                    mainPositionsSummary={invoiceMainPositionSummary}
                                    additionalPayPositionsSummary={invoiceAdditionalPayPositionSummary}
                                />
                            </Paper>
                        </Box>
                        <br />
                        <InvoicePreview pendingPayment={payment} />
                    </div>
                    <br />
                </>
            );
        })
        .unwrapOrElse(error => <p>An error occurred: {error.message}</p>);

    return paymentView;
};
