import { FormControl, InputLabel, Select, SelectProps } from "@mui/material";
import { isEmpty, isNil, omit } from "ramda";
import * as React from "react";
import { useEffect } from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";

interface Props {
    name: string;
    label?: string;
    options: Array<{ label: string; value: string | number }>;
    selectProps: SelectProps;
}

const SelectWrapper: React.FC<Props & WrappedFieldProps> = props => {
    const { selectProps, label, input, name, options } = props;
    const optionElements = options.map((option, index) => (
        <option key={index} value={option.value}>
            {option.label}
        </option>
    ));

    useEffect(() => input.onChange(options[0].value), []);

    return (
        <FormControl className={selectProps.className} component={"div"}>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <Select native {...selectProps} {...input}>
                {optionElements}
            </Select>
        </FormControl>
    );
};

export type WrappedSelectProps = Partial<BaseFieldProps<SelectProps>> & Props;

export const WrappedSelect: React.FC<WrappedSelectProps> = React.memo(props => {
    const setDefaultValueWhenValueFromStoreUndefined = (val: string | number | undefined) =>
        isNil(val) && !isEmpty(props.options) ? props.options[0].value : val;
    return (
        <Field
            name={props.name}
            component={SelectWrapper}
            format={setDefaultValueWhenValueFromStoreUndefined}
            props={{
                name: props.name,
                label: props.label,
                options: props.options,
                selectProps: props.selectProps,
            }}
            {...(omit(["selectProps", "options", "label", "component", "name"], props) as Omit<
                WrappedSelectProps,
                "selectProps" | "options" | "label" | "component" | "name"
            >)}
        />
    );
});
