import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Maybe } from "true-myth";
import { SingleFileUpload } from "components/common/ui-kit/components/SingleFileUpload";
import { MonthlySettlementSummary } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { PaidInvoiceWarning } from "modules/Invoices/views/MonthlySettlement/view/warnings/Warnings";
import { InvoiceThumbnail } from "components/common/ui-kit/components/InvoiceThumbnail/InvoiceThumbnail";

interface OwnProps {
    monthlySettlementSummary: MonthlySettlementSummary;
    onInvoiceSubmit: (files: File) => void;
    onRemoveInvoice: () => void;
}

type Props = { invoiceUploadProps: OwnProps } & WrappedFieldProps;

const InvoiceUploadAreaImpl = ({ invoiceUploadProps, input }: Props) => {
    const { monthlySettlementSummary, onInvoiceSubmit, onRemoveInvoice } = invoiceUploadProps;
    const invoicePreviewUrl = Maybe.of(input.value.previewUrl);
    const { status } = monthlySettlementSummary.pendingPaymentData;

    const isRemoveAttachmentAllowed = Maybe.of(status)
        .map(({ name }) => name !== "PAID" && name !== "BOOKED")
        .unwrapOr(true);

    useEffect(() => {
        input.onChange(monthlySettlementSummary.invoiceAttachment);
    }, [monthlySettlementSummary]);

    const renderInvoice = (previewUrl: string) => (
        <>
            <Box
                sx={{
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: 5,
                    paddingTop: 5,
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "grey.50",
                }}
            >
                <Paper
                    sx={{
                        backgroundColor: "white",
                        position: "relative",
                        "& > img": {
                            maxWidth: "100%",
                            height: "auto",
                        },
                    }}
                >
                    {previewUrl.startsWith("data:image") ? (
                        <img src={previewUrl} />
                    ) : (
                        <InvoiceThumbnail sourceDocumentId={monthlySettlementSummary.invoiceAttachment.id ?? ""} />
                    )}

                    {isRemoveAttachmentAllowed && (
                        <Tooltip title="Usuń fakturę">
                            <IconButton
                                type="button"
                                onClick={onRemoveInvoice}
                                sx={{
                                    transform: "scale(0.8) translate(24px, -24px)",
                                    boxShadow: 1,
                                    transition: "transform 0.2s",
                                    position: "absolute",
                                    right: "0",
                                    top: "0",

                                    "&:hover": {
                                        backgroundColor: "secondary.main",
                                        transform: "scale(0.9) translate(22px, -22px)",
                                        boxShadow: 3,
                                    },

                                    deleteButton: {
                                        "&, &:hover": {
                                            backgroundColor: "secondary.main",
                                        },
                                    },
                                    iconButtonLabel: {
                                        color: "background.paper",
                                    },
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Paper>
            </Box>
            <Grid container>
                <Grid item xs={12}>
                    {!isRemoveAttachmentAllowed ? <PaidInvoiceWarning /> : <></>}
                </Grid>
            </Grid>
        </>
    );

    const renderDropArea = () => (
        <SingleFileUpload promptText="Przeciągnij i upuść fakturę" onFileUploaded={onInvoiceSubmit} />
    );

    return (
        <>
            {invoicePreviewUrl.match({
                Just: previewUrl => renderInvoice(previewUrl),
                Nothing: renderDropArea,
            })}
        </>
    );
};

interface WrappedInvoiceUploadAreaProps extends Partial<BaseFieldProps<OwnProps>> {
    invoiceUploadProps: OwnProps;
    name: string;
}

export const InvoiceUploadArea: React.FC<WrappedInvoiceUploadAreaProps> = React.memo(props => (
    <Field component={InvoiceUploadAreaImpl} props={props.invoiceUploadProps} {...props} />
));
