import { Currency, DateString, Nullable } from "components/common/types";
import { Maybe } from "true-myth";
import { omit } from "ramda";
import BigNumber from "bignumber.js";

export interface DocumentPaymentDTO {
    documentPaymentId: number;
    documentId: number;
    paymentPartAmount: string;
    documentGrossAmount: string;
    documentUnpaidAmount: BigNumber;
    documentSymbol: string;
    companyName: string;
    documentBusinessId: string;
    saleDate: DateString;
}

export interface DocumentPayment {
    documentPaymentId: number;
    documentId: number;
    paymentPartAmount: BigNumber;
    documentGrossAmount: BigNumber;
    documentUnpaidAmount: BigNumber;
    documentSymbol: string;
    companyName: string;
    documentBusinessId: string;
    saleDate: Date;
}

export const mapDocumentPaymentDTO = (documentPaymentDto: DocumentPaymentDTO): DocumentPayment => ({
    ...documentPaymentDto,
    paymentPartAmount: new BigNumber(documentPaymentDto.paymentPartAmount),
    documentGrossAmount: new BigNumber(documentPaymentDto.documentGrossAmount),
    saleDate: new Date(documentPaymentDto.saleDate),
});

export interface PaymentDTO {
    paymentId: number;
    bookingDate: DateString;
    transactionDate: DateString;
    accountNumber: string;
    description: string;
    currency: string;
    totalPaymentAmount: string;
    unusedPaymentAmount: string;
    paidDocuments: DocumentPaymentDTO[];
}

export interface Payment {
    paymentId: number;
    bookingDate: Date;
    transactionDate: Date;
    accountNumber: string;
    description: string;
    currency: string;
    totalPaymentAmount: BigNumber;
    unusedPaymentAmount: BigNumber;
    paidDocuments: DocumentPayment[];
}

export const mapPaymentDTO = (transactionDTO: PaymentDTO): Payment => ({
    ...transactionDTO,
    totalPaymentAmount: new BigNumber(transactionDTO.totalPaymentAmount),
    unusedPaymentAmount: new BigNumber(transactionDTO.unusedPaymentAmount),
    bookingDate: new Date(transactionDTO.bookingDate),
    transactionDate: new Date(transactionDTO.transactionDate),
    paidDocuments: transactionDTO.paidDocuments.map(mapDocumentPaymentDTO),
});

export const addPaidDocumentToPayment = (payment: PaymentTableItem, document: DocumentTableItem): PaymentTableItem => ({
    ...payment,
    paidDocuments: [...payment.paidDocuments, document],
});

export const removePaidDocumentFromPayment = (payment: PaymentTableItem, documentPaymentId: number) => ({
    ...payment,
    paidDocuments: payment.paidDocuments.filter(document => document.documentPaymentId !== documentPaymentId),
});

export interface DocumentSearchResultDTO {
    documentID: number;
    documentGrossAmount: string;
    documentUnpaidAmount: string;
    documentSymbol: string;
    companyName: string;
    documentBusinessId: string;
    currency: Currency;
    saleDate: string;
    dueDate: Nullable<string>;
    paidDate: Nullable<string>;
}

export interface DocumentSearchResult {
    documentID: number;
    documentGrossAmount: BigNumber;
    documentUnpaidAmount: BigNumber;
    documentSymbol: string;
    companyName: string;
    documentBusinessId: string;
    currency: Currency;
    saleDate: Date;
    dueDate: Maybe<Date>;
    paidDate: Maybe<Date>;
}

export const mapDocumentSearchResultDTO = (documentSearchResultDTO: DocumentSearchResultDTO): DocumentSearchResult => ({
    ...documentSearchResultDTO,
    documentUnpaidAmount: new BigNumber(documentSearchResultDTO.documentUnpaidAmount),
    documentGrossAmount: new BigNumber(documentSearchResultDTO.documentGrossAmount),
    saleDate: new Date(documentSearchResultDTO.saleDate),
    dueDate: Maybe.of(documentSearchResultDTO.dueDate).map(dueDate => new Date(dueDate)),
    paidDate: Maybe.of(documentSearchResultDTO.paidDate).map(paidDate => new Date(paidDate)),
});

export const assignDocumentToPayment = (
    payment: PaymentTableItem,
    document: DocumentSearchResult,
): DocumentTableItem => ({
    ...omit(["documentID"], document),
    draft: true,
    documentPaymentId: null,
    currency: payment.currency,
    paymentPartAmount:
        document.documentUnpaidAmount.absoluteValue() < payment.unusedPaymentAmount.absoluteValue()
            ? document.documentUnpaidAmount
            : payment.unusedPaymentAmount,
    saleDate: document.saleDate,
    documentId: document.documentID,
});

export interface DocumentPaymentAssignmentPayload {
    paymentId: number;
    documentId: number;
    amountToAssignInDocCurrency: string;
}

//TODO: currently amountToAssignInDocCurrency is in transaction currency, so in view "Lista transakcji" user should assign only transactions which has the same currency as document.
export const mapDocumentPaymentAssignmentPayload = (
    payment: PaymentTableItem,
    document: DocumentTableItem,
): DocumentPaymentAssignmentPayload => ({
    paymentId: payment.paymentId,
    documentId: document.documentId,
    amountToAssignInDocCurrency: document.paymentPartAmount.toFixed(2),
});

export interface DocumentTableItem extends Omit<DocumentPayment, "saleDate" | "documentPaymentId"> {
    draft: boolean;
    saleDate: Date;
    documentUnpaidAmount: BigNumber;
    currency: string;
    documentPaymentId: Nullable<number>;
}

export interface PaymentTableItem extends Omit<Payment, "paidDocuments"> {
    tableData?: {
        showDetailPanel: boolean;
        isTreeExpanded: boolean;
    };
    paidDocuments: DocumentTableItem[];
}

export const mapDocumentPaymentToDocumentTableItem =
    (currency: string) =>
    (document: DocumentPayment): DocumentTableItem => ({
        ...document,
        draft: false,
        documentUnpaidAmount: document.documentUnpaidAmount,
        currency,
    });

export const mapPaymentToPaymentTableItem = (payment: Payment): PaymentTableItem => ({
    ...payment,
    paidDocuments: payment.paidDocuments.map(mapDocumentPaymentToDocumentTableItem(payment.currency)),
});

// TODO WIP - these definitions can still change
export interface BankAccountDTO {
    bankName: string;
    currency: Currency;
    iban: string;
    id: number;
    latestBalance: number;
    latestBalanceInPln: number;
}

export type BankAccount = BankAccountDTO;

export const mapBankAccountDTO = (bankAccountDTO: BankAccountDTO): BankAccount => ({
    ...bankAccountDTO,
});

export interface TriggerSyncPayload {
    accountIds: number[];
    fromDate: DateString;
    toDate: DateString;
}

export interface SyncResultDTO {
    importId: string;
    importedPaymentsCount: number;
}

export type SyncResult = SyncResultDTO;

export const mapSyncResultDTO = (syncResultDTO: SyncResultDTO): SyncResult => ({
    ...syncResultDTO,
});
