import { ProjectDetails, rateTypeLabels } from "modules/Projects/types";
import React from "react";
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ArrowRight, DateRange, Edit, WarningAmberRounded } from "@mui/icons-material";
import { formatMaybeMoneyForView } from "components/common/utils/numberUtils";
import { toDateString } from "components/common/types";
import { toAvatar } from "../../../AccountingManagement/views/Documents/AvatarMenu";

interface Props {
    projectDefinition: ProjectDetails;
    hasEditRights: boolean;
    onEdit: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ProjectHeader: React.FC<Props> = ({ projectDefinition, hasEditRights, onEdit }) => {
    return (
        <>
            <Grid container>
                <Grid item xs>
                    <Box display="flex" alignItems={"center"}>
                        <Typography variant={"h1"}>
                            <b>{projectDefinition.name} </b>
                            <IconButton
                                aria-label="edit"
                                disabled={!hasEditRights}
                                sx={{ visibility: hasEditRights ? "visible" : "hidden" }}
                                onClick={onEdit}
                            >
                                <Edit />
                            </IconButton>
                        </Typography>
                        {projectDefinition.client
                            .map(client =>
                                client.companyName !== "brak" ? (
                                    <Typography key={client.id} variant={"subtitle1"}>
                                        {client.companyName}
                                    </Typography>
                                ) : (
                                    <></>
                                ),
                            )
                            .unwrapOr(<></>)}
                    </Box>
                </Grid>
                <Grid item xs>
                    {!projectDefinition.isInternal && (
                        <Box display="flex" justifyContent="flex-end">
                            <Typography variant={"h1"} sx={{ marginLeft: "1rem" }}>
                                {projectDefinition.rateType.map(rt => rateTypeLabels[rt]).unwrapOr("")}
                            </Typography>
                            <Divider
                                orientation="vertical"
                                variant={"middle"}
                                flexItem
                                sx={{ ml: "1rem", mr: "1rem" }}
                            />
                            <Typography variant={"h1"}>
                                {formatMaybeMoneyForView(projectDefinition.rate, projectDefinition.currency)}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container>
                <Grid item xs={4}>
                    <Box display="flex" justifyItems="center">
                        <Typography
                            variant={"subtitle1"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                            <DateRange sx={{ mr: "0.5rem" }} />
                            {toDateString(projectDefinition.start)}
                            <ArrowRight sx={{ ml: "0.5rem", mr: "0.5rem" }} />
                            {projectDefinition.end.map(toDateString).unwrapOr("nieokreślony")}
                        </Typography>
                        <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
                        <Typography
                            variant={"subtitle1"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                            {projectDefinition.accountant
                                .map(pd => (
                                    <>
                                        <Box sx={{ marginRight: "8px" }}>Opiekun</Box>{" "}
                                        <Box sx={{ marginTop: "3px" }}>{toAvatar(pd)}</Box>
                                    </>
                                ))
                                .unwrapOr(<>Brak opiekuna</>)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    {projectDefinition.billingDetails
                        .map(billing => (
                            <Box key={`${projectDefinition.id}-billing`} display="flex" justifyContent="flex-end">
                                <Typography variant={"subtitle1"}>
                                    płatność <b>{billing.paymentDays}</b> dni
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant={"middle"}
                                    flexItem
                                    sx={{ ml: "1rem", mr: "1rem" }}
                                />
                                <Typography variant={"subtitle1"}>
                                    waluta <b>{billing.currency}</b>
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant={"middle"}
                                    flexItem
                                    sx={{ ml: "1rem", mr: "1rem" }}
                                />
                                {billing.currency !== billing.account.currency ? (
                                    <Tooltip
                                        title={"Waluta faktury jest inna niż waluta rachunku bankowego!"}
                                        color={"error"}
                                    >
                                        <WarningAmberRounded />
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                                <Typography variant={"subtitle1"}>
                                    {billing.account.currency} {billing.account.accountNumber}
                                </Typography>
                            </Box>
                        ))
                        .unwrapOr(<></>)}
                </Grid>
            </Grid>
        </>
    );
};

export default ProjectHeader;
