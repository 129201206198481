import { Done, Lock, LockOpen } from "@mui/icons-material";
import { Paper, Tooltip } from "@mui/material";
import { AnyObject } from "components/common/types";
import { SimpleTable, SimpleTableProps } from "components/common/ui-kit/components/SimpleTable";
import { RegisteredWork, RegisteredWorkState } from "modules/Billing/types";
import { sum } from "ramda";

interface RegisteredWorkTable {
    registeredWork: RegisteredWork[];
}

type Props = RegisteredWorkTable;

const colDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Pracownik",
        rowDataKey: "employee",
    },
    {
        header: "Pozycja",
        rowDataKey: "position",
    },
    {
        header: "Dni",
        rowDataKey: "amountOfDays",
    },
    {
        header: "Stawka",
        rowDataKey: "unitPrice",
    },
    {
        header: "Status",
        rowDataKey: "registeredWorkState",
    },
];
const translateRegisteredState = (registeredWorkState: RegisteredWorkState) => {
    switch (registeredWorkState) {
        case "REGISTERED":
            return (
                <Tooltip title="Wysłano rozliczenie" placement="right">
                    <div>
                        <LockOpen />
                        <Done color={"primary"} />
                    </div>
                </Tooltip>
            );
        case "REGISTERED_LOCKED":
            return (
                <Tooltip title="Wysłano rozliczenie - nie można zmodyfikować" placement="right">
                    <div>
                        <Lock color={"primary"} />
                        <Done color={"primary"} />
                    </div>
                </Tooltip>
            );
        case "NOT_REGISTERED":
        default:
            return (
                <Tooltip title="Nie wysłano rozliczenia" placement="right">
                    <LockOpen />
                </Tooltip>
            );
    }
};

const toRows = (
    registeredWork: RegisteredWork,
): Omit<RegisteredWork, "registeredWorkState"> & { registeredWorkState: JSX.Element } => ({
    ...registeredWork,
    registeredWorkState: translateRegisteredState(registeredWork.registeredWorkState),
});

export const RegisteredWorkTable = ({ registeredWork }: Props) => {
    const lastRow = {
        employee: "Razem",
        amountOfDays: sum(registeredWork.map(r => r.amountOfDays)),
    };

    return (
        <Paper>
            <SimpleTable columnDefs={colDefs} rowData={[...registeredWork.map(toRows), lastRow]} />
        </Paper>
    );
};
