import * as React from "react";
import { getNonVatPayerData, pendingPaymentPositionToCostsSummaryTableRowData } from "../CostsSummaryTable/utils";
import { CostsSummaryTable } from "../CostsSummaryTable/CostsSummaryTable";
import { PaymentsSummary } from "./service/types";
import { PendingPaymentPosition } from "modules/types/types";

interface Props {
    isVatPayer: boolean;
    positions: PendingPaymentPosition[];
    summary: PaymentsSummary;
}

export const CostSummaryTableWrapper: React.FC<Props> = ({ positions, summary, isVatPayer }) => {
    return (
        <CostsSummaryTable
            isVatPayer={isVatPayer}
            rowData={pendingPaymentPositionToCostsSummaryTableRowData(positions)}
            sumNet={summary.totalNet}
            sumTax={summary.totalTax}
            sumGross={summary.totalGross}
            nonVatPayerData={getNonVatPayerData}
        />
    );
};
