import {
    CostsRelevantPendingPaymentFields,
    CostsSummaryTableRow,
} from "components/common/ui-kit-business/CostsSummaryTable/CostsSummaryTable";
import { additionalCostsCategoryGroups } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { ColumnDef } from "components/common/ui-kit/components/SimpleTable";
import { sum } from "components/common/utils/numberUtils";
import { MonthlySettlementPendingPaymentPosition } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { filter, map, pick } from "ramda";
import { Maybe } from "true-myth";
import { AnyObject } from "yup/lib/types";
import { WorkRelevantPendingPaymentFields, WorkSummaryTableRow } from "./components/WorkSummaryTable";

export const filterWorkPositions = filter<MonthlySettlementPendingPaymentPosition>(({ isMain }) => isMain);

export const filterCostPositions = filter<MonthlySettlementPendingPaymentPosition>(({ isMain }) => !isMain);

export const filterAdditionalPayPositionCost = filter<MonthlySettlementPendingPaymentPosition>(({ category }) =>
    additionalCostsCategoryGroups.includes(category.group),
);

export const filterMainPositionCost = filter<MonthlySettlementPendingPaymentPosition>(
    ({ category }) => !additionalCostsCategoryGroups.includes(category.group),
);

export const pickCostsRelevantProperties = (
    ppp: MonthlySettlementPendingPaymentPosition,
): CostsRelevantPendingPaymentFields =>
    pick<MonthlySettlementPendingPaymentPosition, keyof MonthlySettlementPendingPaymentPosition>(
        ["unitPrice", "quantity", "netAmount", "taxAmount", "grossAmount", "note"],
        ppp,
    );

export const pickWorkRelevantProperties = (
    ppp: MonthlySettlementPendingPaymentPosition,
): WorkRelevantPendingPaymentFields =>
    pick<MonthlySettlementPendingPaymentPosition, keyof MonthlySettlementPendingPaymentPosition>(
        ["unitPrice", "amountOfDays", "netAmount", "taxAmount", "grossAmount", "note"],
        ppp,
    );

export const pendingPaymentPositionToWorkSummaryRow = (
    ppp: MonthlySettlementPendingPaymentPosition,
): WorkSummaryTableRow => ({
    ...pickWorkRelevantProperties(ppp),
    category: ppp.category.name,
    projectName: Maybe.of(ppp.vacancy)
        .map(v => v.name)
        .unwrapOr("Brak projektu"),
});

export const pendingPaymentPositionToCostsSummaryRow = (
    ppp: MonthlySettlementPendingPaymentPosition,
): CostsSummaryTableRow => ({
    ...pickCostsRelevantProperties(ppp),
    category: ppp.category.name,
    projectName: Maybe.of(ppp.vacancy)
        .map(v => v.name)
        .unwrapOr("Brak projektu"),
});

export const pendingPaymentPositionToWorkSummaryTableRowData = map<
    MonthlySettlementPendingPaymentPosition,
    WorkSummaryTableRow
>(pendingPaymentPositionToWorkSummaryRow);

export const pendingPaymentPositionToCostsSummaryTableRowData = map<
    MonthlySettlementPendingPaymentPosition,
    CostsSummaryTableRow
>(pendingPaymentPositionToCostsSummaryRow);

export const getTotalNetAmount = <T extends Pick<MonthlySettlementPendingPaymentPosition, "netAmount">>(
    arr: T[],
): number => sum(arr.map(({ netAmount }) => netAmount)).toNumber();

export const getTotalAmountOfDays = <T extends Pick<MonthlySettlementPendingPaymentPosition, "amountOfDays">>(
    arr: T[],
): number => sum(arr.map(({ amountOfDays }) => amountOfDays)).toNumber();

export const getMonthlyRateData = <T extends AnyObject>(dataTable: ColumnDef<T>[]): ColumnDef<T>[] =>
    dataTable.filter(t => t.header !== "Stawka dzienna");
