import { Box } from "@mui/material";
import * as React from "react";
interface Props {
    value: string | undefined;
    onAmountChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    hasError: boolean;
}

export const AssignedAmountEditableCell: React.FC<Props> = (props: Props) => {
    const inputStyle = props.hasError ? { color: "red" } : {};

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div>
                <input
                    type={"number"}
                    style={{ width: "85px", border: "none", ...inputStyle }}
                    autoFocus={true}
                    step={"0.01"}
                    value={props.value || ""}
                    onChange={props.onAmountChanged}
                />
            </div>
        </Box>
    );
};
