import { Maybe } from "true-myth";
import { Currency, Nullable } from "components/common/types";

export type PendingPaymentStatus = "WAITING_FOR_PAYMENT" | "PAID" | "PAYMENT_BOOKED" | "INVOICE_BOOKED";

export interface DocumentType {
    name: string;
}

export interface PaymentStatus {
    name: string;
}

export interface ProjectAssignment {
    name: string;
}

export interface PaymentCategory {
    name: string;
}

export interface SummaryPaymentCategory extends PaymentCategory {
    id: number;
    group: string;
    categoryType: string;
    costDriver: string;
    projectRelated: boolean;
}

export interface PendingPaymentDTO {
    id: number;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    totalGrossInPLN: number;
    contractorData: Contractor;
    createdAt: Nullable<string>;
    invoiceMonth: string;
    invoiceIssueDate: Nullable<string>;
    sourceDocumentId: Nullable<string>;
    invoiceNumber: Nullable<string>;
    positions: PendingPaymentPosition[];
    createdBy: string;
    sourceDocumentMoved: boolean;
    status: PaymentStatus;
}

// similar to Invoice, yet different set of fields (eg. 'status')
export interface PendingPayment {
    id: number;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    totalGrossInPLN: number;
    contractorData: Contractor;
    createdAt: Maybe<string>;
    invoiceMonth: string;
    invoiceIssueDate: Maybe<string>;
    invoiceNumber: Maybe<string>;
    sourceDocumentId: Maybe<string>;
    positions: PendingPaymentPosition[];
    createdBy: string;
    sourceDocumentMoved: boolean;
    status: PendingPaymentStatus;
    sendTransfer?: boolean;
    sendToBook: boolean;
    failedBooking: boolean;
}

const getSourceDocumentId = (sourceId: Nullable<string>): Maybe<string> =>
    sourceId === "N/A" ? Maybe.nothing() : Maybe.of(sourceId);

export const mapPendingPaymentDTO = (pendingPaymentDto: PendingPaymentDTO): PendingPayment => ({
    ...pendingPaymentDto,
    invoiceIssueDate: Maybe.of(pendingPaymentDto.invoiceIssueDate),
    createdAt: Maybe.of(pendingPaymentDto.createdAt),
    sourceDocumentId: getSourceDocumentId(pendingPaymentDto.sourceDocumentId),
    invoiceNumber: Maybe.of(pendingPaymentDto.invoiceNumber),
    status: pendingPaymentDto.status.name as PendingPaymentStatus,
    sendToBook: false,
    failedBooking: false,
});

export interface DocumentSummaryDTO {
    id: number;
    type: DocumentType;
    number: string;
    issueDate: string;
    contractorName: string;
    contractorIdentifier: string;
    contractorAddressCountry: string;
    currency: string;
    rate: number;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    vatOverridden: boolean;
    taxRates: string;
    accountingMonth: string;
    accountingCategory: string;
    accountingDescription: string;
    missingTagsValues: boolean;
    inconsistentTagsValues: boolean;
    paid?: boolean;
    reportGenerated: boolean;
}

export interface DocumentSummary {
    id: number;
    type: OperationType;
    number: string;
    issueDate: Date;
    contractorName: string;
    contractorIdentifier: string;
    contractorAddressCountry: string;
    currency: string;
    rate: number;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    vatOverridden: boolean;
    taxRates: string;
    accountingMonth: string;
    accountingDate: Date;
    accountingCategory: string;
    accountingDescription: string;
    missingTagsValues: boolean;
    inconsistentTagsValues: boolean;
    paid?: boolean;
    reportGenerated: boolean;
}

export const mapDocumentSummaryDTO = (documentSummaryDto: DocumentSummaryDTO): DocumentSummary => ({
    ...documentSummaryDto,
    type: documentSummaryDto.type.name as OperationType,
    issueDate: new Date(documentSummaryDto.issueDate),
    accountingDate: new Date(documentSummaryDto.accountingMonth),
});

export interface Contractor {
    accountNumber: string;
    addressLine1: string;
    addressLine2: string;
    companyName: string;
    country: string;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    isVatPayer: boolean;
    nip: string;
    swift: string;
    ownerFullName: string;
    currency: Currency;
}

export interface MoneyTransferRequest {
    yearMonth: string;
    entries: MoneyTransferEntry[];
}

export interface BookPaymentsRequest {
    ids: string[];
}

export interface BookPaymentsResponse {
    successIds: number[];
    failureIds: number[];
}

export interface MoneyTransferEntry {
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    bankAccount: string;
    swift: string;
    pendingPaymentId: number;
    title: string;
    amount: number;
    amountInPLN: number;
    currency: string;
}

export interface PendingPaymentChangeRequestParams {
    paymentId: number;
    invoiceNumber?: Nullable<string>;
    invoiceIssueDate?: Nullable<string>;
}

// TODO similar to PositionDTO/Position type, consider unifying these types
// still different fields present, depending on the endpoint called (either list of invoices, or invoice's details)
export interface PendingPaymentPosition {
    id: number;
    isMain: boolean;
    unitPrice: number;
    quantity: number;
    amountOfDays: number;
    netAmount: number;
    taxAmount: number;
    grossAmount: number;
    note: string;
    category: SummaryPaymentCategory;
    vacancy: Nullable<ProjectAssignment>;
}

// similar to ContractorData type in Invoices module, yet different (eg. 'currency' field)
export interface ContractorData {
    accountNumber: string;
    addressLine1: string;
    addressLine2: string;
    companyName: string;
    nip: string;
    isVatPayer: boolean;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    currency: Currency;
}

export type OperationType =
    | "SALES"
    | "SALES_EMPLOYEE"
    | "COSTS_EMPLOYEE"
    | "COSTS_DOMESTIC"
    | "COSTS_EU_IMPORT"
    | "COSTS_IMPORT"
    | "NON_ACCOUNTING";
