import { Box, Card, CardContent, Collapse, Divider, Grid, Typography } from "@mui/material";
import { getSpecializationFromAccount, specializationAccountLabel, Vacancy } from "modules/Projects/types";
import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import TimelineItem from "@mui/lab/TimelineItem";
import { toDateString } from "../../../../components/common/types";
import { AllInclusive, ArrowDropUp } from "@mui/icons-material";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Colors from "@mui/material/colors";
import { useState } from "react";
import { ExpandMore } from "modules/Projects/views/components/VacancyCard";

interface Props {
    active: Vacancy[];
    inactive: Vacancy[];
}

const convert = (v: Vacancy) => {
    return (
        <Grid container item xs={12}>
            {
                <Timeline
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.09,
                        },
                        margin: 0,
                        paddingLeft: 0,
                    }}
                >
                    <TimelineItem key={`timeline-${v.id}`}>
                        <TimelineOppositeContent
                            sx={{
                                m: "0 0",
                                paddingLeft: 0,
                            }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                }}
                            >
                                {v.end
                                    .map(toDateString)
                                    .map(d => <Box key={`${v.id}-vacancy-end-date`}>{d}</Box>)
                                    .unwrapOr(
                                        <Box>
                                            <AllInclusive />
                                        </Box>,
                                    )}
                                <Box>
                                    <ArrowDropUp />
                                </Box>
                                <Box>{toDateString(v.start)}</Box>
                            </Box>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            {v.end.isJust() ? <TimelineDot color={v.isEditable ? "grey" : "primary"} /> : <></>}
                            <TimelineConnector
                                sx={{
                                    backgroundColor: v.isEditable ? "" : "primary.main",
                                }}
                            />
                            <TimelineDot color={v.isEditable ? "grey" : "primary"} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="h6" component="span">
                                        <span> {v.projectName}</span>
                                        {": "}
                                        {v.role.name}{" "}
                                        {v.description.map(d => (d.length > 0 ? ` - ${d}` : "")).unwrapOr("")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                            <Grid container>
                                <Grid item xs={8}>
                                    <Box display="flex">
                                        <Typography>FTE {v.fullTimeEquivalent}%</Typography>
                                        {v.account
                                            .map(a => (
                                                <>
                                                    <Divider
                                                        orientation="vertical"
                                                        variant={"middle"}
                                                        flexItem
                                                        sx={{
                                                            ml: "1rem",
                                                            mr: "1rem",
                                                        }}
                                                    />
                                                    <Typography>
                                                        <b>
                                                            {
                                                                specializationAccountLabel[
                                                                    getSpecializationFromAccount(a)
                                                                ]
                                                            }
                                                        </b>
                                                    </Typography>
                                                    <Divider
                                                        orientation="vertical"
                                                        variant={"middle"}
                                                        flexItem
                                                        sx={{
                                                            ml: "1rem",
                                                            mr: "1rem",
                                                        }}
                                                    />
                                                    <Typography>{a}</Typography>
                                                </>
                                            ))
                                            .unwrapOr(<></>)}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display="flex" justifyContent="flex-end">
                                        {v.provision
                                            .map(p =>
                                                p > 0 ? (
                                                    <Typography key={`${v.id}-vacancy-provision`}>
                                                        prowizja {p}%
                                                    </Typography>
                                                ) : (
                                                    <></>
                                                ),
                                            )
                                            .unwrapOr(<></>)}
                                        {v.discount
                                            .map(d =>
                                                d > 0 ? (
                                                    <>
                                                        <Divider
                                                            orientation="vertical"
                                                            variant={"middle"}
                                                            flexItem
                                                            sx={{
                                                                ml: "1rem",
                                                                mr: "1rem",
                                                            }}
                                                        />
                                                        <Typography>rabat {d}%</Typography>
                                                    </>
                                                ) : (
                                                    <></>
                                                ),
                                            )
                                            .unwrapOr(<></>)}
                                    </Box>
                                </Grid>
                            </Grid>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            }
        </Grid>
    );
};

export const SingleVacancy: React.FC<Props> = (props: Props) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    if (props.active.length == 0) {
        const x = props.inactive.shift();
        if (x != null) {
            props.active.push(x);
        }
    }

    return (
        <Card sx={{ margin: "1rem 0" }}>
            {props.active.length > 0 && (
                <CardContent
                    sx={{
                        backgroundColor:
                            props.active[0].end.isNothing() ||
                            props.active[0].end.map(a => a.getTime() > new Date().getTime()).unsafelyUnwrap()
                                ? "white"
                                : Colors.grey["100"],
                    }}
                >
                    {props.active.map(v => convert(v))}
                </CardContent>
            )}
            {props.inactive.length > 0 && props.active.length > 0 && (
                <>
                    <ExpandMore
                        sx={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                        }}
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent sx={{ backgroundColor: Colors.grey["100"] }}>
                            {props.inactive.map(v => convert(v))}
                        </CardContent>
                    </Collapse>
                </>
            )}
        </Card>
    );
};
