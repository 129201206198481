import { searchDocuments } from "modules/Bank/service/apiService";
import { apiGet } from "components/common/infractructure";
import { DocumentSearchResult, PaymentTableItem } from "modules/Bank/types";
import { Dialog, DialogActions, DialogContent, Button, Typography, SxProps, Theme, Box } from "@mui/material";
import { DocumentSelection } from "./DocumentSelection";

interface Props {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    addPaidDocument: (document: DocumentSearchResult) => void;
    payment: PaymentTableItem;
}

const description: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-evenly",
    maxWidth: "50rem",
    margin: "auto",
};

export const TransactionsModal = ({ showDialog, setShowDialog, addPaidDocument, payment }: Props) => {
    return (
        <Dialog open={showDialog} maxWidth={"xl"} fullWidth={true}>
            <Typography variant="h4" sx={{ textAlign: "center", p: "2rem", fontSize: 24 }}>
                Powiąż dokument
            </Typography>

            <DialogContent>
                <Box sx={description}>
                    <Typography variant="body1">{payment.description}</Typography>
                    <Typography variant="subtitle1" sx={{ whiteSpace: "pre", pl: "1rem" }}>
                        {payment.totalPaymentAmount.toFixed(2)} {payment.currency}
                    </Typography>
                </Box>

                <DocumentSelection
                    searchDocuments={searchDocuments(apiGet)}
                    addPaidDocument={addPaidDocument}
                    setShowDialog={setShowDialog}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setShowDialog(false)} variant="outlined">
                    Anuluj
                </Button>
            </DialogActions>
        </Dialog>
    );
};
