import { ChangeEvent } from "react";
import { MoneyTransferEntry, MoneyTransferRequest } from "modules/types/types";
import { Box, Button, Grid } from "@mui/material";
import { formatForView, sum } from "components/common/utils/numberUtils";
import { Typography } from "@mui/material";

interface Props {
    yearMonth: string;
    entries: MoneyTransferEntry[];
    onSubmit: (moneyTransferRequest: MoneyTransferRequest) => void;
}

export const MoneyTransferForm = ({ yearMonth, entries, onSubmit }: Props) => {
    const total = sum(entries.map(e => e.amountInPLN)).toNumber();

    const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit({
            yearMonth,
            entries,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="body1">Liczba przelewów</Typography>
                    <Typography variant="body1">
                        <Box fontWeight="bold">{entries.length}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1">Suma przelewów w PLN</Typography>
                    <Typography variant="body1" gutterBottom component="div">
                        <Box fontWeight="bold">{`${formatForView(total)} zł`}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button type={"submit"} variant="contained" color="primary">
                        Wyślij
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
