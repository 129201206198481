import { Nullable } from "components/common/types";
import { isEmpty } from "ramda";
import { Maybe } from "true-myth";
import { ClientSales, InvoiceState } from "modules/SalesSummary/domain/types";

export const getBillingUrl = (billingId: number): string => `/billing/summary/${billingId}`;

export const filterSalesWithoutProjects = (clientsSales: ClientSales[]): ClientSales[] =>
    clientsSales
        .map(clientSales => ({
            ...clientSales,
            salesPositions: clientSales.salesPositions.filter(ps => ps.projectName !== null),
        }))
        .filter(cs => !isEmpty(cs.salesPositions));

export const getMessageForCorruptState = (state: InvoiceState, paidDate?: Nullable<string>): Maybe<string> => {
    switch (state) {
        case "NOT_PAID_VALID":
        case "NOT_PAID_EXPIRED":
            if (paidDate) {
                return Maybe.of(`Nieopłacona faktura posiada datę zapłaty - ${paidDate}`);
            }
            break;
        case "PAID": {
            if (!paidDate) {
                return Maybe.of("Opłacona faktura nie posiada daty zapłaty");
            }
            break;
        }
    }
    return Maybe.nothing();
};
