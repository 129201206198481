import * as React from "react";
import { AppState } from "modules/Invoices/views/MonthlySettlement/service/reducer";
import { Typography } from "@mui/material";

interface Props {
    yearMonthWithRate: AppState["yearMonthWithRate"];
}

const WorkingDays: React.FC<Props> = ({ yearMonthWithRate }) => (
    <>
        {yearMonthWithRate.mapOrElse(
            () => (
                <></>
            ),
            ({ workingDays }) => (
                <Typography variant="subtitle1">
                    dni pracujących: <strong>{workingDays}</strong>
                </Typography>
            ),
        )}
    </>
);

export default WorkingDays;
