import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker as MuiDatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DateString, MUI_INPUT_DATE_STRING_FORMAT } from "components/common/types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import en from "dayjs/locale/pl";
import { Dayjs } from "dayjs";

interface Props<T> {
    datePicker: Omit<DatePickerProps<T, Dayjs>, "renderInput">;
    textField?: TextFieldProps;
}

export const CommonDatePicker = <T extends Date | DateString | string>({ datePicker, textField = {} }: Props<T>) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={en}>
            <MuiDatePicker
                openTo="month"
                views={["year", "month", "day"]}
                {...datePicker}
                inputFormat={datePicker.inputFormat ?? MUI_INPUT_DATE_STRING_FORMAT}
                renderInput={params => <TextField {...params} {...textField} variant="standard" />}
            />
        </LocalizationProvider>
    );
};
