import { Box, Checkbox, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material";
import { noop } from "components/common/utils";
import { ActivityDef, ProjectActivityDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { GlobalState, MONTHLY_SETTLEMENT_FORM } from "modules/Invoices/views/MonthlySettlement/domain/types";
import {
    ACTIVITIES_KEY,
    ActivityFormValue,
    getKeyForActivity,
    isActivityEmpty,
    KeyActivityFormValuePair,
    KeyProjectActivityFormValuePair,
    ProjectActivityFormValue,
    PROJECT_ACTIVITIES_KEY,
} from "modules/Invoices/views/MonthlySettlement/service/formService";
import { addIndex, complement, filter, map, mergeAll, pipe, toPairs } from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Maybe } from "true-myth";

interface OwnProps {
    activities: ProjectActivityDef[] | ActivityDef[];
    initialActivitiesFormValues: KeyProjectActivityFormValuePair | KeyActivityFormValuePair;
    onSelectedActivitiesChange: (activities: ProjectActivityDef[] | ActivityDef[]) => void;
    onActivityDisabled: (id: string) => void;
    onActivityEnabled: (id: string, value: ProjectActivityFormValue | ActivityFormValue) => void;
}

interface StateProps {
    getActivitiesFormValues: () => Maybe<KeyProjectActivityFormValuePair | KeyActivityFormValuePair>;
}

type Props = OwnProps & StateProps;

interface ActivityParams {
    id: string;
    selected: boolean;
    activity: ProjectActivityDef | ActivityDef;
    isDirty: boolean;
}

interface SelectedActivitiesState {
    [key: string]: ActivityParams;
}

const isActivityDirty = complement(isActivityEmpty);

const mapToActivityParams = map<ProjectActivityDef | ActivityDef, ActivityParams>(projectActivity => ({
    id: getKeyForActivity(projectActivity),
    selected: false,
    activity: projectActivity,
    isDirty: false,
}));

const activitiesToSelectedActivitiesState = ({
    activities,
    initialActivitiesFormValues,
}: Pick<OwnProps, "activities" | "initialActivitiesFormValues">) =>
    pipe(
        mapToActivityParams,
        map<ActivityParams, ActivityParams>(p =>
            Maybe.of(initialActivitiesFormValues[p.id])
                /* eslint-disable @typescript-eslint/no-unused-vars */
                .map(_ => ({
                    ...p,
                    selected: true,
                    isDirty: false,
                }))
                .unwrapOr(p),
        ),
        map<ActivityParams, SelectedActivitiesState>(p => ({ [p.id]: p })),
        list => mergeAll(list),
    )(activities);

const selectedActivitiesStateToActivityParamsArray = pipe(
    (selectedProjectsState: SelectedActivitiesState) => toPairs<ActivityParams>(selectedProjectsState),
    map<[string, ActivityParams], ActivityParams>(el => el[1]),
);

const filterSelectedProjects = filter<ActivityParams>(({ selected }) => selected);

const activityParamsToActivityDef = (project: ActivityParams): ProjectActivityDef | ActivityDef => project.activity;

const selectedActivitiesStateToActivityDefArray = pipe(
    selectedActivitiesStateToActivityParamsArray,
    filterSelectedProjects,
    map(activityParamsToActivityDef),
);

const ActivitiesSelectorImpl = ({
    activities,
    initialActivitiesFormValues,
    onSelectedActivitiesChange,
    onActivityDisabled,
    onActivityEnabled,
    getActivitiesFormValues,
}: Props) => {
    const [selectedActivities, setSelectedActivities] = useState<SelectedActivitiesState>(() =>
        activitiesToSelectedActivitiesState({
            activities,
            initialActivitiesFormValues,
        }),
    );

    const [formValuesCache, setFormValuesCache] = useState<KeyProjectActivityFormValuePair | KeyActivityFormValuePair>(
        initialActivitiesFormValues,
    );

    const restoreActivityFormValueIfCached = (key: string): void =>
        Maybe.of(formValuesCache[key]).match({
            Just: cachedFormValue => onActivityEnabled(key, cachedFormValue),
            Nothing: noop,
        });

    const cacheActivityFormValue = (key: string, formValue: Maybe<ProjectActivityFormValue | ActivityFormValue>) =>
        formValue.match({
            Just: formValueToCache =>
                setFormValuesCache({
                    ...formValuesCache,
                    [key]: formValueToCache,
                }),
            Nothing: noop,
        });

    const doesProjectHaveUnsavedChanges = (formValue: Maybe<ProjectActivityFormValue | ActivityFormValue>) =>
        formValue.map(isActivityDirty).unwrapOr(false);

    const handleCheckboxChange = (activityKey: string) => (_event: React.SyntheticEvent, checked: boolean) => {
        const formValue = getActivitiesFormValues().flatMap(justFormValues => Maybe.of(justFormValues[activityKey]));
        if (checked) {
            restoreActivityFormValueIfCached(activityKey);
        } else {
            cacheActivityFormValue(activityKey, formValue);
            onActivityDisabled(activityKey);
        }
        setSelectedActivities({
            ...selectedActivities,
            [activityKey]: {
                ...selectedActivities[activityKey],
                selected: checked,
                isDirty: !checked && doesProjectHaveUnsavedChanges(formValue),
            },
        });
    };

    useEffect(
        () => onSelectedActivitiesChange(selectedActivitiesStateToActivityDefArray(selectedActivities)),
        [selectedActivities],
    );

    const buildCheckboxLabel = (label: string, isDirty: boolean) => (
        <Box
            sx={{
                display: "flex",
            }}
        >
            <Box
                component="span"
                sx={{
                    marginRight: 1,
                }}
            >
                {label}
            </Box>
            {isDirty && (
                <Box
                    component="span"
                    sx={{
                        fontStyle: "italic",
                        color: "error.light",
                        fontWeight: "fontWeightLight",
                    }}
                >
                    <Typography variant={"caption"}>
                        Pozycje dodane dla tej aktywności zostaną utracone po zapisie
                    </Typography>
                </Box>
            )}
        </Box>
    );

    return (
        <Box
            sx={{
                marginBottom: 2,
            }}
        >
            <FormGroup row>
                {addIndex<ActivityParams, React.ReactNode>(map)(
                    (p, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Tooltip placement={"right-end"} title={Maybe.of(p.activity.instructions).unwrapOr("")}>
                                    <Checkbox checked={selectedActivities[p.id].selected} color={"primary"} />
                                </Tooltip>
                            }
                            label={buildCheckboxLabel(p.activity.title, p.isDirty)}
                            onChange={handleCheckboxChange(p.id)}
                            value={selectedActivities[p.id].selected}
                        />
                    ),
                    selectedActivitiesStateToActivityParamsArray(selectedActivities),
                )}
            </FormGroup>
        </Box>
    );
};

const valueSelector = formValueSelector(MONTHLY_SETTLEMENT_FORM, (state: GlobalState) => state.form);

export const ProjectsSelector = connect<StateProps, unknown, OwnProps, GlobalState>(state => ({
    getActivitiesFormValues: () => Maybe.of(valueSelector(state, PROJECT_ACTIVITIES_KEY)),
}))(ActivitiesSelectorImpl);

export const ActivitiesSelector = connect<StateProps, unknown, OwnProps, GlobalState>(state => ({
    getActivitiesFormValues: () => Maybe.of(valueSelector(state, ACTIVITIES_KEY)),
}))(ActivitiesSelectorImpl);
