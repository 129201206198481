import { Column } from "@material-table/core";
import { Search } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Link,
    Paper,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Loader from "components/Loader/Loader";
import { ROLES_PROJECTS_EDIT_CREATE, isAllowed } from "components/RBAC/RBACutils";
import { useUserContext } from "components/common/contexts";
import { useData } from "components/common/hooks/useData";
import { AsyncResult } from "components/common/infractructure";
import { localizationMaterialTable } from "components/common/localization/localization";
import { toDateString } from "components/common/types";
import { withBoldHeader } from "components/common/ui-kit/components/MaterialTable/utils";
import { getValueOrDefault } from "components/common/utils";
import { formatForView } from "components/common/utils/numberUtils";
import { Employee, filterByPendingCondition, Project, rateTypeLabels } from "modules/Projects/types";
import { ProjectVacanciesPanel } from "modules/Projects/views/ProjectsList/ProjectVacanciesPanel";
import { uniq } from "ramda";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Maybe } from "true-myth";
import { filterPanel } from "components/common/ui-kit/styles/utils";
import { Table } from "components/common/ui-kit/components/Table";
import { ProjectTeamAvatars } from "modules/Projects/views/components/ProjectTeamAvatars";
import { EmptyListInfo } from "../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";
import { GenericSelect } from "../../../../components/common/ui-kit/components/GenericSelect";
import * as React from "react";

const NOT_AVAILABLE_LABEL = "N/A";

const getNumber = (value: Maybe<number>) => getValueOrDefault(value, n => `${n}`, NOT_AVAILABLE_LABEL);

const tableColumns: Column<Project>[] = withBoldHeader([
    {
        field: "name",
        title: "Projekt",
        render: ({ id, name }: Project) => (
            <Link component={RouterLink} to={`/projects/show/${id}`}>
                {name}
            </Link>
        ),
        width: "200px",
    },
    {
        field: "start",
        title: "Początek",
        type: "date",
        render: ({ start }: Project) => <span>{toDateString(start)}</span>,
        defaultSort: "desc",
    },
    {
        field: "end",
        title: "Koniec",
        sorting: false,
        render: ({ end }: Project) => (
            <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                {end.map(toDateString).unwrapOr("")}
            </Box>
        ),
    },
    {
        field: "team",
        title: "Zespół",
        render: ({ currentTeam }: Project) => <ProjectTeamAvatars team={currentTeam} />,
    },
    {
        field: "isIpBox",
        title: "IPBox",
        type: "boolean",
    },
    {
        field: "accountingNumber",
        title: "Nr konta",
        type: "numeric",
        render: ({ accountingNumber }: Project) => <span>{getNumber(accountingNumber)}</span>,
    },
]);

const internalProjectColumns: Column<Project>[] = withBoldHeader([
    {
        field: "isAccountable",
        title: "Osobne księgowanie",
        type: "boolean",
    },
]);

const externalProjectColumns: Column<Project>[] = withBoldHeader([
    ...tableColumns,
    {
        field: "rate",
        title: "Stawka",
        type: "numeric",
        align: "right",
        render: ({ rate }: Project) => {
            const value = Number(getNumber(rate));
            return <span>{formatForView(value)}</span>;
        },
    },
    {
        field: "currency",
        title: "Waluta",
        type: "string",
        render: ({ currency }: Project) => <span>{currency.unwrapOr(NOT_AVAILABLE_LABEL)}</span>,
    },
    {
        field: "rateType",
        title: "Sposób rozliczania",
        type: "string",
        render: ({ rateType }: Project) => (
            <span>{rateType.map(value => rateTypeLabels[value]).unwrapOr(NOT_AVAILABLE_LABEL)}</span>
        ),
    },
    {
        field: "paymentDays",
        title: "Termin płatności",
        type: "numeric",
        render: ({ billingDetails }: Project) => <span>{getNumber(billingDetails.map(b => b.paymentDays))}</span>,
    },
]);

const ProjectTypes = ["internal", "external"] as const;
type ProjectType = typeof ProjectTypes[number];

export const PARAM_PROJECT_TYPE = "projectType";
export const PARAM_SHOW_PENDING_ONLY = "showPendingOnly";

interface Props {
    getProjectsList: () => AsyncResult<Project[]>;
}

export const ProjectsList: React.FC<Props> = ({ getProjectsList }) => {
    const now = new Date();
    const [searchParams, setSearchParams] = useSearchParams();
    const [projectType, setProjectType] = useState<ProjectType>(() => {
        const paramValue = searchParams.get(PARAM_PROJECT_TYPE);
        return ProjectTypes.includes(paramValue as ProjectType) ? (paramValue as ProjectType) : "external";
    });

    const [accountantFilter, setAccountantFilter] = useState<Maybe<Employee>>(Maybe.nothing());

    const [showPendingProjects, setShowPendingProjects] = useState<boolean>(
        searchParams.get(PARAM_SHOW_PENDING_ONLY) === "true",
    );
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();

    const { data, isLoading, loadData } = useData<Project[]>({
        loader: getProjectsList,
        onError: ({ message }) => toast.error(`Nie udało się pobrać listy projektów: ${message}`),
    });

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (searchParams.get(PARAM_PROJECT_TYPE) !== projectType) {
            setSearchParams(params => {
                params.set(PARAM_PROJECT_TYPE, projectType);
                return params;
            });
        }
        if (searchParams.get(PARAM_SHOW_PENDING_ONLY) !== showPendingProjects.toString()) {
            setSearchParams(params => {
                params.set(PARAM_SHOW_PENDING_ONLY, showPendingProjects.toString());
                return params;
            });
        }
    }, [projectType, showPendingProjects, searchParams]);

    const hasEditRights = isAllowed(roles, ROLES_PROJECTS_EDIT_CREATE);

    const onTabChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
        setProjectType(target.value as ProjectType);

    const handleChangeShowPendingProjects = (_: React.ChangeEvent, checked: boolean) => setShowPendingProjects(checked);

    const findAccountantByEmployeeId = (projects: Project[], employeeId: number): Maybe<Employee> => {
        const matchingAccountants = projects
            .filter(project => project.accountant.isJust() && project.accountant.unsafelyUnwrap().id === employeeId)
            .map(project => project.accountant);

        return matchingAccountants.length > 0 ? matchingAccountants[0] : Maybe.nothing();
    };

    const onChangeAccountant = (event: SelectChangeEvent<unknown>, projects: Project[]) => {
        setAccountantFilter(findAccountantByEmployeeId(projects, event.target.value as number));
    };

    const filterByAccountant = (accountant: Maybe<Employee>, accountantFilter: Maybe<Employee>): boolean => {
        if (accountantFilter.isJust()) {
            return accountant.isJust() && accountantFilter.unsafelyUnwrap().id === accountant.unsafelyUnwrap().id;
        } else {
            return true;
        }
    };
    return isLoading ? (
        <Loader />
    ) : (
        data
            .map(projectList => {
                const filteredProjectsList = projectList
                    .filter(({ isInternal }) => isInternal === (projectType === "internal"))
                    .filter(({ end }) => filterByPendingCondition(end, now, showPendingProjects))
                    .filter(({ name }) => name.toLowerCase().includes(searchPhrase.toLowerCase()))
                    .filter(({ accountant }) => filterByAccountant(accountant, accountantFilter));

                return (
                    <>
                        <Grid container sx={{ marginBottom: "2rem" }}>
                            <Grid item xs={8}>
                                <Typography variant="h2"> Lista projektów</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                                {hasEditRights ? (
                                    <Button component={RouterLink} to="/projects/create" variant="contained">
                                        Dodaj projekt
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>

                        <Paper sx={filterPanel}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <FormControl>
                                    <FormLabel>Typ projektu</FormLabel>
                                    <RadioGroup row name="projectType" value={projectType} onChange={onTabChange}>
                                        <Tooltip title="Projekty komercyjne to projekty, które są rozliczane z klientem.">
                                            <FormControlLabel value="external" control={<Radio />} label="komercyjne" />
                                        </Tooltip>
                                        <Tooltip title="Projekty wewnętrzne (na potrzeby firmy).">
                                            <FormControlLabel value="internal" control={<Radio />} label="wewnętrzne" />
                                        </Tooltip>
                                    </RadioGroup>
                                </FormControl>

                                <Tooltip
                                    title={
                                        showPendingProjects
                                            ? "Pokazuje tylko trwające projekty (bez ustawionej daty końca)."
                                            : "Pokazuje wszystkie projekty."
                                    }
                                >
                                    <FormControlLabel
                                        sx={{ p: 1, margin: "1.5rem 4rem 0 4rem" }}
                                        control={
                                            <Switch
                                                checked={showPendingProjects}
                                                onChange={handleChangeShowPendingProjects}
                                                name="pendingProjects"
                                                color="primary"
                                            />
                                        }
                                        label="Tylko trwające"
                                    />
                                </Tooltip>

                                <GenericSelect
                                    sx={{ marginRight: "4rem" }}
                                    label="Opiekun"
                                    value={accountantFilter.map(a => a.id).unwrapOr(0)}
                                    values={[
                                        {
                                            value: 0,
                                            display: "Wybierz",
                                        },
                                        ...projectList
                                            .filter(p => p.accountant.isJust())
                                            .map(p => p.accountant.unsafelyUnwrap())
                                            .reduce<{ value: number; display: string }[]>((acc, a) => {
                                                if (!acc.some(item => item.value === a.id)) {
                                                    acc.push({ value: a.id, display: `${a.firstName} ${a.lastName}` });
                                                }
                                                return acc;
                                            }, []),
                                    ]}
                                    onChange={e => onChangeAccountant(e, projectList)}
                                />

                                <Autocomplete
                                    sx={{ flex: "1" }}
                                    options={uniq(filteredProjectsList.map(({ name }) => name))}
                                    freeSolo
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Search sx={{ marginRight: ".5rem" }} /> Szukaj
                                                </Box>
                                            }
                                        />
                                    )}
                                    onChange={(_, value) => setSearchPhrase(value ?? "")}
                                />
                            </Box>
                        </Paper>
                        {filteredProjectsList.length > 0 ? (
                            <Table
                                columns={
                                    projectType === "internal"
                                        ? [...tableColumns, ...internalProjectColumns]
                                        : externalProjectColumns
                                }
                                data={filteredProjectsList}
                                options={{
                                    showTitle: false,
                                    search: false,
                                    grouping: false,
                                    selection: false,
                                    showTextRowsSelected: true,
                                    paging: filteredProjectsList.length > 50,
                                    pageSize: 50,
                                    pageSizeOptions: [20, 50, 100, 200],
                                    toolbar: false,
                                    padding: "dense",
                                }}
                                detailPanel={({ rowData: { id } }) => (
                                    <ProjectVacanciesPanel showPendingOnly={showPendingProjects} projectId={id} />
                                )}
                                localization={localizationMaterialTable}
                            />
                        ) : (
                            <EmptyListInfo />
                        )}
                    </>
                );
            })
            .unwrapOr(
                <Typography variant="body2" color="error">
                    Nie udało się wczytać listy projektów.
                </Typography>,
            )
    );
};
