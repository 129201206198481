import { Employee } from "modules/Projects/types";

export type Mode = "EDIT" | "CREATE" | "SHOW";

export const filterEmployees = (employees: Employee[], mode: Mode) =>
    mode === "CREATE" ? employees.filter(({ isActive }) => isActive) : employees;

export const groupBy = <K, T>(array: Array<T>, property: (x: T) => K): Map<K, T[]> =>
    array.reduce((memo: Map<K, T[]>, currentValue: T) => {
        const key = property(currentValue);
        if (!memo.has(key)) {
            memo.set(key, []);
        }
        memo.get(key)?.push(currentValue);
        return memo;
    }, new Map<K, T[]>());

export const uniqueByProperty = <K, T>(array: T[], property: (x: T) => K): T[] => {
    const map = new Map<K, T>();
    for (const item of array) {
        const key = property(item);
        if (!map.has(key)) {
            map.set(key, item);
        }
    }
    return Array.from(map.values());
};
