import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    DataGrid,
    GridColumns,
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    GridActionsCellItem,
    GridRowId,
    GridRenderEditCellParams,
    useGridApiContext,
    GridColDef,
    gridClasses,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DocumentPayment, DocumentPaymentAssignmentPayload } from "../../../Bank/types";
import {
    DocumentDetails,
    DocumentPaymentDetails,
    PaymentSearchResult,
    UpdateDocumentPaymentRequest,
} from "modules/AccountingManagement/types";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import { WireTransferAmountValueCell } from "./WireTransferAmountValueCell";
import { AsyncResult } from "../../../../components/common/infractructure";
import { PaymentsSearchDialog } from "./PaymentsSearchDialog";
import { toast } from "react-hot-toast";
import { useUserContext } from "../../../../components/common/contexts";
import { User } from "modules/UserProfile/types";
import { Employee } from "../../../Projects/types";
import { Maybe } from "true-myth";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import BigNumber from "bignumber.js";
import { AssignedAmountEditableCellWithCurrency } from "./AssignedAmountEditableCellWithCurrency";
import { AssignedAmountEditableCell } from "./AssignedAmountEditableCell";

export interface DocumentPaymentsHandle {
    addNewRow: () => void;
}

interface EditModeRowValues {
    [key: string]: {
        assignedValue: BigNumber;
        assignedValueInDocCurrency: BigNumber;
    };
}

interface Props {
    document: DocumentDetails;
    assignedPayments: DocumentPaymentDetails[];
    fetchExchangeRate: (date: string, baseCurrency: string, quoteCurrency: string) => AsyncResult<BigNumber>;
    updateDocumentPayment: (updateData: UpdateDocumentPaymentRequest) => AsyncResult<void>;
    createDocumentPayment: (createData: DocumentPaymentAssignmentPayload) => AsyncResult<DocumentPayment>;
    deleteDocumentPayment: (docPaymentId: number) => AsyncResult<void>;
    getPaymentSearchResult: (query: string) => AsyncResult<PaymentSearchResult[]>;
    onSumOfPaymentsChanged: (newAmount: BigNumber) => void;
}

interface Row {
    documentPaymentId: number | null;
    id: number;
    date: string;
    description: string;
    wiredBy: Maybe<Employee>;
    currency: string;
    isNew: boolean;
    assignedAmount: BigNumber;
    amountInDocumentCurrency: BigNumber;
    amountAssignedToOtherDocs: BigNumber;
    totalAmount: BigNumber;
    exchangeRate: BigNumber | null;
}

interface ValidationData {
    [key: GridRowId]: boolean;
}

const toRow = (p: DocumentPaymentDetails, documentCurrency: string): Row => {
    return {
        documentPaymentId: p.documentPaymentID,
        id: p.paymentId,
        date: p.bookedDate,
        description: p.description,
        wiredBy: Maybe.of(p.assignedBy),
        currency: p.currency,
        isNew: false,
        assignedAmount: p.assignedAmount,
        amountInDocumentCurrency: p.amountInDocumentCurrency,
        totalAmount: p.totalAmount,
        amountAssignedToOtherDocs: p.totalAmount.minus(p.unusedAmount).minus(p.assignedAmount),
        exchangeRate: p.currency == documentCurrency ? new BigNumber("1.00") : null,
    };
};

export const userToEmployee = (user: User): Employee => {
    return {
        id: 0,
        firstName: user.firstName,
        lastName: user.lastName,
        specialization: Maybe.nothing(),
        isActive: true,
        avatarUrl: user.avatarUrl.unwrapOr(""),
        level: "",
    };
};

const isAssignedAmountValid = (value: BigNumber, row: Row) => {
    return (
        !value.isZero() &&
        ((row.totalAmount.isNegative() && value.isNegative()) ||
            (row.totalAmount.isPositive() && value.isPositive())) &&
        value.abs().comparedTo(row.totalAmount.minus(row.amountAssignedToOtherDocs).abs()) <= 0
    );
};

const DocumentPaymentsImpl = forwardRef<DocumentPaymentsHandle, Props>((props, ref) => {
    const [rows, setRows] = useState<GridRowsProp<Row>>(
        props.assignedPayments.map(p => toRow(p, props.document.summary.exchangeRate?.currency ?? "PLN")),
    );
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const editModeRowValuesRef = useRef<EditModeRowValues>({});
    const [searchWindowOpened, setSearchWindowOpened] = useState(false);
    const {
        userProfileData: { user },
    } = useUserContext();

    const addNewRow = () => {
        setSearchWindowOpened(true);
    };

    useImperativeHandle(ref, () => ({
        addNewRow: () => {
            addNewRow();
        },
    }));

    const validationErrorsRef = useRef<ValidationData>({});

    const getCurrentSumOfPayments = (): BigNumber => {
        return rows.reduce((sum, row) => {
            const editValue = editModeRowValuesRef.current[row.id]
                ? editModeRowValuesRef.current[row.id].assignedValueInDocCurrency
                : new BigNumber(row.amountInDocumentCurrency);
            return sum.plus(editValue);
        }, new BigNumber(0));
    };

    useEffect(() => {
        props.onSumOfPaymentsChanged(getCurrentSumOfPayments());
    }, [rows]);

    const handleSearchWindowClose = () => {
        setSearchWindowOpened(false);
    };

    const onPaymentSelected = async (res: PaymentSearchResult): Promise<void> => {
        (
            await props.fetchExchangeRate(
                res.bookedDate,
                props.document.summary.exchangeRate?.currency ?? "PLN",
                res.currency,
            )
        ).match({
            Ok: exchangeRate => {
                const r: Row = {
                    documentPaymentId: null,
                    id: res.paymentId,
                    date: res.bookedDate,
                    description: res.description,
                    wiredBy: Maybe.of(userToEmployee(user)),
                    currency: res.currency,
                    isNew: true,
                    assignedAmount: new BigNumber("0"),
                    amountInDocumentCurrency: new BigNumber("0"),
                    totalAmount: res.totalAmount,
                    amountAssignedToOtherDocs: res.totalAmount.minus(res.unusedAmount),
                    exchangeRate: exchangeRate,
                };
                setRows([...rows, r]);
                setRowModesModel(oldModel => ({
                    ...oldModel,
                    [r.id]: { mode: GridRowModes.Edit, fieldToFocus: "assignedAmount" },
                }));
            },
            Err: () => {
                toast.error("Nie udało się pobrac kursu walut!");
            },
        });
        handleSearchWindowClose();
    };

    const handleEditClick = (id: GridRowId) => async () => {
        const row = rows.find(it => it.id == id);
        if (row != null && row.exchangeRate == null) {
            const exchangeRate = await props.fetchExchangeRate(
                row.date,
                props.document.summary.exchangeRate?.currency ?? "PLN",
                row.currency,
            );
            if (exchangeRate.isOk()) {
                setRows(
                    rows.map(r => (r.id == id ? { ...r, exchangeRate: exchangeRate.unwrapOr(new BigNumber(1)) } : r)),
                );
            } else {
                throw Error("Can't fetch exchange rate!");
            }
        }
        if (row != null) {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            rows.filter(row => row.id === id).forEach(
                r =>
                    (editModeRowValuesRef.current[r.id] = {
                        assignedValue: r.assignedAmount,
                        assignedValueInDocCurrency: r.amountInDocumentCurrency,
                    }),
            );
        }
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        const row = rows.find(it => it.id == id);
        if (row != null && row.documentPaymentId != null) {
            const res = await props.deleteDocumentPayment(row.documentPaymentId);
            if (res.isOk()) {
                delete editModeRowValuesRef.current[id];
                setRows(rows.filter(row => row.id !== id));
            } else {
                toast.error("Nie udało się usunąć płatności!");
            }
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        validationErrorsRef.current[id] = false;
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        delete editModeRowValuesRef.current[id];
        const editedRow = rows.find(row => row.id == id);
        if (editedRow && editedRow.isNew) {
            setRows(rows.filter(row => row.id !== id));
        }
        props.onSumOfPaymentsChanged(getCurrentSumOfPayments());
    };

    function AssignedAmountEditInputCell(props2: GridRenderEditCellParams<string>) {
        const { id, value } = props2;
        const apiRef = useGridApiContext();
        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            const val = event.target.value;

            const newTempValueInDocumentCurrency = new BigNumber(Number(val));

            const row = apiRef.current.getRow(id);
            const newTempValueInTransactionCurrency = row.exchangeRate?.multipliedBy(newTempValueInDocumentCurrency);

            editModeRowValuesRef.current[id] = {
                assignedValue: newTempValueInTransactionCurrency,
                assignedValueInDocCurrency: newTempValueInDocumentCurrency,
            };

            const isValid = isAssignedAmountValid(newTempValueInTransactionCurrency, row);
            validationErrorsRef.current = { ...validationErrorsRef.current, [id]: !isValid };

            await apiRef.current.setEditCellValue({
                id,
                field: "amountInDocumentCurrency",
                value: val,
            });

            props.onSumOfPaymentsChanged(getCurrentSumOfPayments());
        };
        return apiRef.current.getRow(id).currency != (props.document.summary.exchangeRate?.currency ?? "PLN") ? (
            <AssignedAmountEditableCellWithCurrency
                value={value}
                id={id}
                documentCurrency={props.document.summary.exchangeRate?.currency ?? "PLN"}
                onAmountChanged={handleChange}
                hasError={validationErrorsRef.current[id]}
            />
        ) : (
            <AssignedAmountEditableCell
                value={value}
                onAmountChanged={handleChange}
                hasError={validationErrorsRef.current[id]}
            />
        );
    }

    const renderSelectEditInputCell: GridColDef["renderCell"] = params => {
        return <AssignedAmountEditInputCell {...params} />;
    };

    const columns: GridColumns<Row> = [
        { field: "date", headerName: "Data przelewu", editable: false, width: 120 },
        { field: "description", headerName: "Opis przelewu", editable: false, flex: 1 },
        {
            field: "wiredBy",
            headerName: "Przypisano przez",
            editable: false,
            width: 120,
            renderCell: p => {
                return p.row.wiredBy
                    .map(e => <EmployeeAvatar key={p.id} sx={{ width: "35px", height: "35px" }} employee={e} />)
                    .unwrapOr("N/A");
            },
        },
        {
            field: "totalAmount",
            headerName: "Kwota przelewu",
            editable: false,
            width: 120,
            renderCell: p => {
                const isInEditMode = editModeRowValuesRef.current[p.id];
                if (isInEditMode) {
                    return (
                        <WireTransferAmountValueCell
                            amount={p.value}
                            currency={p.row.currency}
                            leftAmount={p.row.totalAmount
                                .minus(p.row.amountAssignedToOtherDocs)
                                .minus(editModeRowValuesRef.current[p.id].assignedValue)}
                        />
                    );
                } else {
                    return (
                        <WireTransferAmountValueCell
                            amount={p.row.totalAmount}
                            currency={p.row.currency}
                            leftAmount={p.row.totalAmount
                                .minus(p.row.amountAssignedToOtherDocs)
                                .minus(p.row.assignedAmount)}
                        />
                    );
                }
            },
        },
        {
            field: "amountInDocumentCurrency",
            headerName: "Przypisano",
            editable: true,
            width: 120,
            renderEditCell: a => renderSelectEditInputCell(a),
            renderCell: p =>
                formatMoneyForView(
                    Number(p.row.amountInDocumentCurrency),
                    props.document.summary.exchangeRate?.currency ?? "PLN",
                ),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Akcje",
            width: 80,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const isSaveDisabled = validationErrorsRef.current[id];

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key={id.toString() + "save"}
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            disabled={isSaveDisabled}
                        />,
                        <GridActionsCellItem
                            key={id.toString() + "cancel"}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={id.toString() + "edit"}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={id.toString() + "delete"}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const processRowUpdate = async (newRow: Row) => {
        let docPaymentId = 0;
        const amountAssignedInDocCurrency =
            editModeRowValuesRef.current[newRow.id.toString()].assignedValueInDocCurrency;
        const amountAssignedInTransactionCurrency = editModeRowValuesRef.current[newRow.id.toString()].assignedValue;
        const paymentUnusedAmount = newRow.totalAmount
            .minus(newRow.amountAssignedToOtherDocs)
            .minus(amountAssignedInTransactionCurrency);
        let isError = false;
        if (newRow.documentPaymentId == null) {
            (
                await props.createDocumentPayment({
                    paymentId: newRow.id,
                    documentId: props.document.id,
                    amountToAssignInDocCurrency: amountAssignedInDocCurrency.toNumber().toFixed(2),
                })
            ).match({
                Ok: res => {
                    docPaymentId = res.documentPaymentId;
                    toast.success("Utworzono!");
                },
                Err: () => {
                    toast.error("Nie udało się zapisać płatności");
                    isError = true;
                },
            });
        } else {
            (
                await props.updateDocumentPayment({
                    documentPaymentId: newRow.documentPaymentId,
                    amountInDocumentCurrency: amountAssignedInDocCurrency.toNumber().toFixed(2),
                })
            ).match({
                Ok: () => {
                    docPaymentId = newRow.documentPaymentId != null ? newRow.documentPaymentId : 0;
                    toast.success("Zaktualizowano!");
                },
                Err: () => {
                    isError = true;
                    toast.error("Nie udało się zapisać płatności");
                },
            });
        }
        if (!isError) {
            delete editModeRowValuesRef.current[newRow.id.toString()];
        } else {
            setRowModesModel({ ...rowModesModel, [newRow.id.toString()]: { mode: GridRowModes.Edit } });
            throw new Error("Failed to save payment");
        }

        const updatedRow = {
            ...newRow,
            assignedAmount: amountAssignedInTransactionCurrency,
            amountInDocumentCurrency: newRow.amountInDocumentCurrency,
            unusedAmount: paymentUnusedAmount,
            documentPaymentId: docPaymentId,
            isNew: false,
        };
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    return (
        <Box>
            <PaymentsSearchDialog
                open={searchWindowOpened}
                document={props.document}
                onClose={handleSearchWindowClose}
                onItemClicked={onPaymentSelected}
                getSearchResult={props.getPaymentSearchResult}
            ></PaymentsSearchDialog>
            <DataGrid
                sx={{
                    [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                        outline: "transparent",
                    },
                    [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                        outline: "none",
                    },
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                        outline: "none",
                    },
                    border: 0,
                }}
                localeText={{ noRowsLabel: "Brak przypisanych przelewów" }}
                disableColumnMenu={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableSelectionOnClick={true}
                hideFooter={true}
                processRowUpdate={processRowUpdate}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                autoHeight={true}
                rowModesModel={rowModesModel}
                rows={rows}
                editMode="row"
                experimentalFeatures={{ newEditingApi: true }}
                columns={columns}
            />
        </Box>
    );
});

export const DocumentPayments = React.memo(DocumentPaymentsImpl);
