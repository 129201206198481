import * as React from "react";
import { Maybe } from "true-myth";
import { map } from "ramda";
import { connect } from "react-redux";
import { isValid } from "redux-form";
import { GroupDef, MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import {
    WizardNavigationComponent,
    WizardNavigationElement,
} from "modules/Invoices/views/MonthlySettlement/view/WizardNavigation/WizardNavigationComponent";
import { AnyObject } from "components/common/types";
import { GlobalState, MONTHLY_SETTLEMENT_FORM } from "modules/Invoices/views/MonthlySettlement/domain/types";

interface StateProps {
    formDefinition: Maybe<MonthlySettlementFormDef>;
    isSettlementValid: boolean;
}

type Props = StateProps;

export const SUMMARY_PAGE_ID = "form/summary";

const groupDefToWizardNavigationElementMapper = map<GroupDef, WizardNavigationElement>(({ groupDefId, title }) => ({
    link: `form/${groupDefId}`,
    title,
}));

const wizardNavigationElementsFactory = ({ groups }: MonthlySettlementFormDef) => [
    ...groupDefToWizardNavigationElementMapper(groups),
    {
        link: SUMMARY_PAGE_ID,
        title: "Podsumowanie",
    },
];

const WizardNavigationImpl: React.FC<Props> = ({ formDefinition, isSettlementValid }) =>
    formDefinition
        .map(wizardNavigationElementsFactory)
        .map(justSections => (
            <WizardNavigationComponent key={0} sections={justSections} isSettlementValid={isSettlementValid} />
        ))
        .unwrapOr(<></>);

export const WizardNavigation = connect<StateProps, AnyObject, unknown, GlobalState>(state => ({
    formDefinition: state.app.formDefinition,
    isSettlementValid: isValid(MONTHLY_SETTLEMENT_FORM)(state),
}))(WizardNavigationImpl);
