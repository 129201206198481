import { PaletteOptions } from "@mui/material";

export const invoices = {
    billingCreated: "#c8c8c8",
    notPaidValid: "#319db5",
    notPaidExpired: "#ef5350",
    paid: "#4caf50",
    url: "#757575",
    contrast: "white",
};

export const custom: PaletteOptions["custom"] = {
    // "light" & "dark" are shaded of "active", generated with https://mdigi.tools/color-shades/
    light: "#3d7bac",
    dark: "#314268",
    active: "#15476e",
    dim: "rgba(0, 0, 0, 0.05)",
    add: "#55b686",
    subtitle: "#a8a8a8",
    hover: "rgb(34, 109, 126)",
};

export const navigate: PaletteOptions["navigate"] = {
    main: "rgba(53,73,85,0.6)",
    list: "rgba(255, 255, 255, 0.6)",
    active: "rgba(49,157,181,0.4)",
    hover: "rgba(63,67,79,0.4)",
    divider: "rgba(255, 255, 255, 0.4)",
};

export const home: PaletteOptions["home"] = {
    green: "#0D8907",
    purple: "#904FAA",
    blue: "#3C78BD",
};
