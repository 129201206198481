import { Done, DoneAll } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import * as React from "react";
import { RegisteredWork } from "modules/Billing/types";

interface Props {
    registeredWork: RegisteredWork[];
}

export const RegisteredWorkStatus: React.FC<Props> = ({ registeredWork }) => {
    const statuses = registeredWork.map(rw => rw.registeredWorkState);
    if (statuses.find(status => status === "NOT_REGISTERED")) {
        return <></>;
    } else if (statuses.find(status => status === "REGISTERED")) {
        return (
            <Tooltip title="Wszyscy wysłali rozliczenia, niektórzy mogą edytować">
                <Done color="primary" />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title="Wszyscy wysłali rozliczenia i nie mogą już ich edytować">
                <DoneAll color="primary" />
            </Tooltip>
        );
    }
};
