import { Divider, Grid, Typography } from "@mui/material";
import { TWO_MINUTES, useHealthcheck } from "components/common/hooks/useHealthcheck";
import { apiGet, apiPost, AsyncResult } from "components/common/infractructure";
import { MonthsList, toDateString, YearMonth } from "components/common/types";
import NotFoundRoute from "components/common/ui-kit/components/NotFound";
import { createYearMonthFromString, noop, rethrow } from "components/common/utils";
import Loader from "components/Loader/Loader";
import { AppActions } from "modules/Invoices/views/MonthlySettlement/domain/actions";
import { GroupDef, MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import {
    GlobalState,
    MONTHLY_SETTLEMENT_FORM,
    MonthlySettlement,
    YearMonthWithRate,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import {
    getFormSummary,
    saveMonthlySettlementFormData,
} from "modules/Invoices/views/MonthlySettlement/service/apiService";
import { MonthlySettlementForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/MonthlySettlementForm";
import Rate from "modules/Invoices/views/MonthlySettlement/view/Rate/Rate";
import { SummaryWrapper } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/SummaryWrapper";
import { WizardNavigation } from "modules/Invoices/views/MonthlySettlement/view/WizardNavigation";
import { isEmpty, pipe } from "ramda";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Maybe } from "true-myth";
import { useEffectOnce } from "usehooks-ts";
import WorkingDays from "./Rate/WorkingDays";
import ReportedDays from "./Rate/ReportedDays";
import {
    formValuesToMonthlySettlement,
    MonthlySettlementFormValues,
    monthlySettlementToFormValues,
} from "../service/formService";
import { FormSubmitHandler, reduxForm } from "redux-form";
import { toast } from "react-hot-toast";
import { bindResult, DoResult } from "../../../../utils";

const loadFormDefAndData =
    (
        getMonthlySettlementForm: (yearMonth: YearMonth) => AsyncResult<MonthlySettlementFormDef>,
        getMonthlySettlementFormData: (yearMonth: YearMonth) => AsyncResult<MonthlySettlement>,
    ) =>
    async (yearMonth: YearMonth) => {
        const results = await Promise.all([
            getMonthlySettlementForm(yearMonth),
            getMonthlySettlementFormData(yearMonth),
        ]);
        return pipe(DoResult, bindResult("formDef", results[0]), bindResult("formData", results[1]))();
    };

interface Props {
    getMonthlySettlementForm: (yearMonth: YearMonth) => AsyncResult<MonthlySettlementFormDef>;
    getMonthlySettlementFormData: (yearMonth: YearMonth) => AsyncResult<MonthlySettlement>;
}

const throwFormGroupDefListEmptyError = () => {
    throw new Error("Received empty list of form groups definitions.");
};

const getSettlementMonth = ({ month, year }: YearMonth) => `${MonthsList[month - 1]} ${year}`;

const createReduxForm = reduxForm<MonthlySettlementFormValues>({
    form: MONTHLY_SETTLEMENT_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
});

/*
    We need this component because we want to avoid unmounting the  MONTHLY_SETTLEMENT_FORM upon route change when going to Summary.
    Upon route change, the WizardNavigation component triggers a submit, which works fine
    as long as we are staying inside the '/form/:activeWizardPageId' route, because the ReduxForm never gets unmounted
    and the submit actions are processed correctly. However, when going to '/form/summary', the ReduxForm component
    for the MONTHLY_SETTLEMENT_FORM gets unmounted and the submit action gets lost and changes are never saved to the server.
 */
const FormsWrapper = createReduxForm(({ initialize, handleSubmit, dirty, valid }) => {
    const initialFormData = useSelector<GlobalState, Maybe<MonthlySettlement>>(state => state.app.initialFormData);

    React.useEffect(() => {
        initialFormData.match({
            Just: data => initialize(monthlySettlementToFormValues(data)),
            Nothing: noop,
        });
    }, [initialFormData]);

    return (
        <Routes>
            <Route
                path="/form/:activeWizardPageId"
                element={<MonthlySettlementForm handleSubmit={handleSubmit} valid={valid} dirty={dirty} />}
            />
            <Route path="/form/summary" element={<SummaryWrapper getFormSummary={getFormSummary(apiGet)} />} />
            <Route path="*" element={<NotFoundRoute />} />
        </Routes>
    );
});

export const MonthlySettlementPage = ({ getMonthlySettlementForm, getMonthlySettlementFormData }: Props) => {
    const dispatch = useDispatch();
    const reportedDays = useSelector<GlobalState, number>(state => state.app.reportedDays);
    const yearMonthWithRate = useSelector<GlobalState, Maybe<YearMonthWithRate>>(state => state.app.yearMonthWithRate);

    const storeFormDefinition = (formDef: MonthlySettlementFormDef) => {
        dispatch(AppActions.setFormDefinition(formDef));
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [settlementMonth, setSettlementMonth] = useState<string>("");

    const navigate = useNavigate();
    const { year, month } = useParams<{ year: string; month: string }>();

    const activeWizardPageId = useMemo(() => {
        const [_, possiblePageId] = window.location.pathname.split("form/");
        const pageId = possiblePageId ? parseInt(possiblePageId) : null;
        return pageId !== null && !isNaN(pageId) ? pageId : null;
    }, [window.location]);

    const pushFirstGroupDefIdToUrl = ({ groupDefId }: GroupDef) =>
        navigate(`/invoices/add/${year}/${month}/form/${groupDefId}`, { replace: true });

    const setFirstGroupAsActiveWizardFormPage = (groups: GroupDef[]) => {
        if (isEmpty(groups)) {
            throwFormGroupDefListEmptyError();
        } else if (activeWizardPageId === null) {
            pushFirstGroupDefIdToUrl(groups[0]);
        }
    };

    const load = async (yearMonth: YearMonth) => {
        const result = await loadFormDefAndData(getMonthlySettlementForm, getMonthlySettlementFormData)(yearMonth);
        result.match({
            Ok: ({ formDef, formData }) => {
                storeFormDefinition(formDef);
                dispatch(AppActions.setInitialFormData(formData));
                setFirstGroupAsActiveWizardFormPage(formDef.groups);
            },
            Err: err => {
                if (err?.response?.status === 418) {
                    // TODO it would be good if that route worked only with year and month, day is redundant
                    const invoiceDate = new Date(yearMonth.year, yearMonth.month - 1, 1);
                    navigate(`/invoices/${toDateString(invoiceDate)}`);
                } else if (!isLoading) {
                    rethrow(err);
                }
            },
        });
        setIsLoading(false);
    };

    useEffectOnce(() => {
        Maybe.all(Maybe.of(year), Maybe.of(month))
            .flatMap(([yearValue, monthValue]) => createYearMonthFromString(yearValue, monthValue))
            .match({
                Just: async selectedYearMonth => {
                    setSettlementMonth(getSettlementMonth(selectedYearMonth));
                    await load(selectedYearMonth);
                },
                Nothing: noop,
            });
    });

    useHealthcheck(TWO_MINUTES);

    const saveFormData: FormSubmitHandler<MonthlySettlementFormValues> = async (formValues, _, { invalid, dirty }) => {
        if (!invalid && dirty) {
            const pageIdCache = activeWizardPageId;
            const result = await saveMonthlySettlementFormData(apiPost)(formValuesToMonthlySettlement(formValues));

            result.match({
                Ok: data => {
                    dispatch(AppActions.setInitialFormData(data));
                },
                Err: error => {
                    toast.error(`Coś poszło nie tak: ${error.message}`);
                    activeWizardPageId && navigate(`/invoices/add/${year}/${month}/form/${pageIdCache}`);
                },
            });
        }
    };
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container justifyContent="space-between" item>
                            {settlementMonth && (
                                <Typography variant="h4">
                                    Faktura za <strong>{settlementMonth}</strong>
                                </Typography>
                            )}
                            <Rate yearMonthWithRate={yearMonthWithRate} />
                        </Grid>
                        <Divider />
                        <Grid container justifyContent="space-between" item>
                            <WorkingDays yearMonthWithRate={yearMonthWithRate} />
                            <ReportedDays
                                workingDays={yearMonthWithRate.map(ymwr => ymwr.workingDays)}
                                reportedDays={reportedDays}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <WizardNavigation />
                    </Grid>
                    <Grid item>
                        <FormsWrapper onSubmit={saveFormData} />
                        <Outlet />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
