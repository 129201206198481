import { Box, Grid, Paper } from "@mui/material";
import { MUI_INPUT_YEAR_MONTH_STRING_FORMAT, Nullable } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { filterPanel } from "components/common/ui-kit/styles/utils";
import { doIfPresent } from "components/common/utils";
import { Dayjs } from "dayjs";
import { Maybe } from "true-myth";
import { BillingFilterSelectValues, getBillingStatusFilterTranslations } from "../../types";

const SELECT_VALUES: BillingFilterSelectValues[] = ["ALL", "PENDING", "BOOKED", "CANCELED"];

interface Props {
    sumInPln: number;
    currentDateSelection: Date;
    currentTypeSelection: BillingFilterSelectValues;
    onChangeYearMonth: (date: Date) => void;
    onChangeBillingType: (type: string) => void;
}

export const BillingFilter: React.FC<Props> = ({
    currentDateSelection,
    onChangeYearMonth,
    currentTypeSelection,
    onChangeBillingType,
}) => {
    const handleChangeDate = (date: Nullable<Dayjs>) =>
        doIfPresent(
            Maybe.of(date).map(dayJsDate => dayJsDate.toDate()),
            newDate => {
                if (newDate !== currentDateSelection) {
                    onChangeYearMonth(newDate);
                }
            },
        );

    const handleChangeType = (selectedType: string) => {
        if (selectedType !== currentTypeSelection) {
            onChangeBillingType(selectedType);
        }
    };
    return (
        <Paper sx={filterPanel} style={{ display: "flex" }}>
            <Grid item container xs={8}>
                <Box sx={{ marginRight: "0.5rem" }}>
                    <CommonDatePicker
                        datePicker={{
                            disableFuture: true,
                            views: ["year", "month"],
                            label: "Rok i miesiąc",
                            inputFormat: MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
                            value: currentDateSelection,
                            onChange: handleChangeDate,
                        }}
                    />
                </Box>
                <Box sx={{ marginRight: "0.5rem" }}>
                    <GenericSelect
                        label="Status"
                        value={currentTypeSelection}
                        values={[
                            ...SELECT_VALUES.map(value => ({
                                value,
                                display: getBillingStatusFilterTranslations(value),
                            })),
                        ]}
                        onChange={({ target: { value } }) => handleChangeType(value as string)}
                    />
                </Box>
            </Grid>
        </Paper>
    );
};
