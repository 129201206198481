import { AnyObject } from "components/common/types";
import { NonVatPayer } from "components/common/ui-kit-business/types";
import { SimpleTable, SimpleTableProps } from "components/common/ui-kit/components/SimpleTable";
import { PendingPaymentPosition } from "modules/types/types";
import * as React from "react";
import { Box } from "@mui/material";

export interface CostsSummaryTableProps {
    isVatPayer: boolean;
    rowData: CostsSummaryTableRow[];
    sumNet: number;
    sumTax: number;
    sumGross: number;
    nonVatPayerData: NonVatPayer;
}

export type CostsRelevantPendingPaymentFields = Pick<
    PendingPaymentPosition,
    "unitPrice" | "quantity" | "netAmount" | "taxAmount" | "grossAmount" | "note"
>;
export type CostsSummaryTableRow = CostsRelevantPendingPaymentFields & {
    category: string;
    projectName: string;
};

const colDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Projekt",
        rowDataKey: "projectName",
    },
    {
        header: "Opis",
        rowDataKey: "note",
    },
    {
        header: "Cena jednostkowa",
        rowDataKey: "unitPrice",
    },
    {
        header: "Netto",
        rowDataKey: "netAmount",
    },
    {
        header: "VAT",
        rowDataKey: "taxAmount",
    },
    {
        header: "Brutto",
        rowDataKey: "grossAmount",
    },
];

export const CostsSummaryTable: React.FC<CostsSummaryTableProps> = ({
    isVatPayer,
    rowData,
    sumNet,
    sumTax,
    sumGross,
    nonVatPayerData,
}) => {
    const lastRow = {
        netAmount: sumNet,
        taxAmount: sumTax,
        grossAmount: sumGross,
        projectName: "Razem",
    };
    const { definition, data } = isVatPayer
        ? { definition: colDefs, data: [...rowData, lastRow] }
        : nonVatPayerData<typeof lastRow, AnyObject>(colDefs, [...rowData, lastRow]);

    return (
        <Box
            sx={{
                "& table th": {
                    paddingTop: 1,
                    paddingLeft: 1,
                },

                "& table tr:last-child": {
                    backgroundColor: "primary.light",
                    border: 0,
                    color: "#6e6e6e",
                    borderRadius: "10px",
                },

                "& table tr:last-child td:first-of-type": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                },

                "& table tr:last-child td:last-child": {
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                },
            }}
        >
            <SimpleTable columnDefs={definition} rowData={data} />
        </Box>
    );
};
