import { createStore, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as appReducer } from "./reducer";

export const rootReducer = combineReducers({
    // ...your other reducers here
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    app: appReducer,
    form: formReducer,
});

export type GlobalState = ReturnType<typeof rootReducer>;

export const store = createStore(
    rootReducer,
    /* In order to Redux tools to work */
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);
