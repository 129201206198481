import { Column } from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import { AsyncResult } from "components/common/infractructure";
import { localizationMaterialTable } from "components/common/localization/localization";
import { formatForView } from "components/common/utils/numberUtils";
import { AssignmentBillingData, BillingPosition, CostBillingData } from "modules/Billing/types";
import { remove } from "ramda";
import * as React from "react";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import { Table } from "components/common/ui-kit/components/Table";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-hot-toast";

interface BillingPositionsTable {
    assignmentBillings: AssignmentBillingData[];
    costBillings: CostBillingData[];
    onUpdateCosts: (costBillings: CostBillingData[]) => AsyncResult<void>;
    readOnly: boolean;
    accepted: boolean;
}

interface TableRowData extends BillingPosition {
    isAssignment?: boolean;
    id: number;
}

const toRowData = (assignmentPositions: AssignmentBillingData[], costPositions: CostBillingData[]): TableRowData[] => {
    return [
        ...assignmentPositions.map(position => ({
            ...position,
            isAssignment: true,
        })),
        ...costPositions.map(position => ({
            ...position,
        })),
    ];
};

const findMaxId = (items: TableRowData[]): number => {
    return items.reduce((max, item) => {
        return item.id > max ? item.id : max;
    }, -Infinity);
};

type Props = BillingPositionsTable;

const columnDefs: Array<Column<TableRowData>> = [
    {
        title: "Nazwa pozycji",
        field: "description",
        initialEditValue: "",
        validate: r => r.description !== null && r.description.trim().length > 0,
    },
    {
        title: "Konto księgowe",
        field: "account",
        initialEditValue: "",
    },
    {
        title: "Ilość",
        field: "quantity",
        type: "numeric",
        align: "right",
        initialEditValue: 1,
        validate: r => !isNaN(r.quantity) && r.quantity > 0,
    },
    {
        title: "Cena jednostkowa",
        field: "unitPrice",
        type: "numeric",
        align: "right",
        validate: r => !isNaN(r.quantity) && r.quantity > 0,
        render: ({ unitPrice }) => <>{formatForView(unitPrice)}</>,
    },
    {
        title: "Jednostka",
        field: "unitType",
        lookup: {
            HOUR: "Godzina",
            DAY: "Dzień",
            HALFDAY: "Połowa dnia",
            MONTH: "Miesiąc",
            SERVICE: "Usługa",
            OTHER: "Inne",
        },
        initialEditValue: "SERVICE",
    },
    {
        title: "Łącznie",
        editable: "never",
        align: "right",
        render: (data?) => (data ? <b>{formatForView(data.unitPrice * data.quantity)}</b> : <></>),
    },
    {
        title: "Powtarzająca",
        field: "recurring",
        lookup: {
            true: "Tak",
            false: "Nie",
        },
        initialEditValue: "false",
    },
    {
        title: "IP-Box",
        field: "ipBox",
        lookup: {
            true: "Tak",
            false: "Nie",
        },
        initialEditValue: "false",
    },
];

export const BillingPositionsTable: React.FC<Props> = ({
    assignmentBillings,
    costBillings,
    onUpdateCosts,
    readOnly,
    accepted,
}) => {
    const tableRows = toRowData(assignmentBillings, costBillings);

    const handleUpdatePositions = (data: TableRowData[]) =>
        toast.promise(onUpdateCosts(data.filter(e => !e.isAssignment)), {
            error: err => `Nie udało się zaktualizować pozycji: ${err?.message ?? "Niespodziewany błąd"}`,
            success: res =>
                res.match({
                    Ok: () => "Zaktualizowano pozycję",
                    Err: err => `Nie udało się zaktualizować pozycji ${err.message}`,
                }),
            loading: "Aktualizacja pozycji",
        });

    const acceptanceComponent = accepted ? (
        <Tooltip key={0} title="Pozycje zaakceptowane">
            <DoneIcon />
        </Tooltip>
    ) : (
        <></>
    );

    return (
        <Table
            options={{
                showTitle: false,
                search: false,
                selection: false,
                showTextRowsSelected: false,
                paging: false,
                actionsColumnIndex: -1,
                toolbar: !readOnly,
                padding: "dense",
                headerStyle: boldHeaderStyle(),
            }}
            sx={{
                /*
WHY THIS HACK?!
For some reason, the icons from @material-ui/icons are not visible even when then package is installed or they are included in the document head (see https://material-table.com/#/docs/install)
The editable rows feature simply does not work (https://material-table.com/#/docs/features/editable) - it might be because we 're using a fork of material-table (https://www.npmjs.com/package/@material-table/core).
This is the only way I have found so far to show some icons here, I simply copied the svg paths from https://mui.com/material-ui/material-icons/
*/
                'tr[mode|="update"] td:last-of-type button.MuiIconButton-root span': {
                    clipPath: "none !important",
                    backgroundColor: "transparent",
                },
                'tr[mode|="delete"] td:last-of-type button.MuiIconButton-root span': {
                    clipPath: "none !important",
                    backgroundColor: "transparent",
                },
                'tr[mode|="add"] td:last-of-type button.MuiIconButton-root span': {
                    clipPath: "none !important",
                    backgroundColor: "transparent",
                },
                "td:last-of-type button.MuiIconButton-root": {
                    width: "2rem",
                    height: "2rem",
                },
                "td:last-of-type button.MuiIconButton-root:hover": {
                    backgroundColor: "transparent",
                },
                "td:last-of-type button.MuiIconButton-root:first-of-type": {
                    marginRight: "0.5rem",
                },
                "td:last-of-type button.MuiIconButton-root span": {
                    backgroundColor: "rgba(0,0,0,0.54)",
                },
                "td:last-of-type button.MuiIconButton-root:first-of-type span": {
                    clipPath:
                        'path("M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z")',
                },
                "td button.MuiIconButton-root:last-of-type span": {
                    clipPath: 'path("M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z")',
                },
            }}
            columns={columnDefs}
            data={tableRows}
            editable={{
                isDeleteHidden: rowData => !!rowData.isAssignment || readOnly,
                isEditHidden: rowData => !!rowData.isAssignment || readOnly,

                onRowAdd: newData =>
                    handleUpdatePositions([...tableRows, { ...newData, id: findMaxId(tableRows) + 1 }]),

                onRowUpdate: (newData, oldData) => {
                    let data = [...tableRows];
                    if (oldData) {
                        data = data.map(row => (row.id == oldData.id ? newData : row));
                    }
                    return handleUpdatePositions(data);
                },

                onRowDelete: oldData => handleUpdatePositions(remove(tableRows.indexOf(oldData), 1, tableRows)),
            }}
            icons={{
                Add: React.forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
            }}
            localization={localizationMaterialTable}
            components={{
                Container: props =>
                    React.Children.toArray(props.children).length > 1 ? (
                        <>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={6}>
                                    <Typography variant="h5">Pozycje na fakturze {acceptanceComponent}</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    {React.Children.toArray(props.children)[0]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>{React.Children.toArray(props.children)[1]}</Paper>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Pozycje na fakturze {acceptanceComponent}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                                <Paper>{React.Children.toArray(props.children)[0]}</Paper>
                            </Grid>
                        </>
                    ),
            }}
        />
    );
};
