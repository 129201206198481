import * as React from "react";
import { Maybe } from "true-myth";
import { complement, isEmpty } from "ramda";
import { Typography } from "@mui/material";

interface Props {
    label: string;
    text: string;
}

const isNotEmpty = complement(isEmpty);
const getWithDefault = (value: string): string =>
    Maybe.of(value)
        .map(val => (val === "undefined" ? "" : val))
        .map(isNotEmpty)
        .unwrapOr(false)
        ? value
        : "Brak danych";

export const DetailRow: React.FC<Props> = ({ label, text }) => {
    return (
        <div>
            <Typography variant="caption">{label}</Typography>
            <Typography variant="body1" gutterBottom style={{ wordWrap: "break-word" }}>
                {getWithDefault(text)}
            </Typography>
        </div>
    );
};
