import { Box, Button, Drawer, Grid, Tooltip, Typography } from "@mui/material";
import {
    specializationAccountLabel,
    fromProject,
    fromVacancy,
    getNewVacancyFormDataDefaults,
    ProjectFormData,
    Vacancy,
    VacancyFormData,
} from "modules/Projects/types";
import ProjectForm from "modules/Projects/views/components/ProjectForm";
import { VacancyForm } from "modules/Projects/views/components/VacancyForm";
import React, { useState } from "react";
import { Maybe } from "true-myth";
import { useUserContext } from "components/common/contexts";
import { isAllowed, ROLES_PROJECTS_EDIT_CREATE } from "components/RBAC/RBACutils";

import ProjectHeader from "../components/ProjectHeader";
import { VacancyCard } from "../components/VacancyCard";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import WarningAmber from "@mui/icons-material/WarningAmberTwoTone";
import { getProjectData, ShowProjectData } from "./data";
import { useAsyncReducer } from "../../../useAsyncReducer";
import { projectStateReducer } from "./reducer";
import { onKeyboardOrMouseEvent } from "components/common/ui-kit/components/Drawer";

interface UIState {
    selectedTab: string;
    showProjectEdit: boolean;
    isCreatingVacancy: boolean;
    editVacancy: Maybe<Vacancy>;
}

export const ShowProject = ({ project, vacancies, config, fetchActiveUsers }: ShowProjectData) => {
    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();
    const hasEditRights = React.useMemo(() => isAllowed(roles, ROLES_PROJECTS_EDIT_CREATE), [roles]);

    const [uiState, setUIState] = useState<UIState>({
        editVacancy: Maybe.nothing(),
        isCreatingVacancy: false,
        selectedTab: "1",
        showProjectEdit: false,
    });

    const setSelectedTab = (tab: string) => setUIState(state => ({ ...state, selectedTab: tab }));
    const setShowProjectEdit = (show: boolean) => setUIState(state => ({ ...state, showProjectEdit: show }));
    const setIsCreatingVacancy = (creating: boolean) =>
        setUIState(state => ({ ...state, isCreatingVacancy: creating }));
    const setEditVacancy = (vacancy: Maybe<Vacancy>) => setUIState(state => ({ ...state, editVacancy: vacancy }));

    const [{ projectDetails, allVacancies }, dispatch] = useAsyncReducer(projectStateReducer, {
        projectDetails: project,
        allVacancies: vacancies,
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault();
        setSelectedTab(newValue);
    };

    const submitProjectUpdateHandle = (data: ProjectFormData) =>
        dispatch(
            {
                type: "UPDATE_PROJECT",
                payload: data,
            },
            {
                success: "Zaktualizowano.",
                error: err => `Nie udało się zaktualizować projektu: ${err.message}`,
                loading: "Aktualizowanie projektu...",
            },
        );

    const submitVacancyCreateHandle = (data: VacancyFormData) =>
        dispatch(
            {
                type: "CREATE_VACANCY",
                payload: data,
            },
            {
                success: () => {
                    setIsCreatingVacancy(false);
                    return "Dodano nowy etat";
                },
                error: err => `Nie udało się zapisać etatu: ${err.message}`,
                loading: "Dodawanie etatu...",
            },
        );

    const submitVacancyUpdateHandle = (vacancy: Vacancy, data: VacancyFormData) =>
        dispatch(
            {
                type: "UPDATE_VACANCY",
                payload: {
                    current: vacancy,
                    updated: data,
                },
            },
            {
                success: () => {
                    setEditVacancy(Maybe.nothing());
                    return "Zaktualizowano etat.";
                },
                error: err => `Nie udało się zaktualizować etatu: ${err.message}`,
                loading: "Aktualizowanie etatu...",
            },
        );

    const submitVacancyRemoveHandle = (vacancy: Vacancy) =>
        dispatch(
            {
                type: "REMOVE_VACANCY",
                payload: {
                    id: vacancy.id,
                },
            },
            {
                success: () => {
                    setEditVacancy(Maybe.nothing());
                    return "Usunięto etat.";
                },
                error: err => `Nie udało się usunąć etatu: ${err.message}`,
                loading: "Usuwanie etatu...",
            },
        );

    const toggleVacancyDrawer = (vacancy: Maybe<Vacancy>) => onKeyboardOrMouseEvent(() => setEditVacancy(vacancy));

    const toggleCreateVacancyDrawer = (isCreatingVacancy: boolean) =>
        onKeyboardOrMouseEvent(() => setIsCreatingVacancy(isCreatingVacancy));

    const toggleDrawer = (open: boolean) => onKeyboardOrMouseEvent(() => setShowProjectEdit(open));

    const { currentTeamGroups, currentTeam, formerTeamGroups, oldVacancies, hasFormerTeam, formerTeam } = React.useMemo(
        () => getProjectData(allVacancies),
        [allVacancies],
    );

    return (
        <>
            <ProjectHeader
                projectDefinition={projectDetails}
                hasEditRights={hasEditRights}
                onEdit={toggleDrawer(true)}
            />
            <TabContext value={uiState.selectedTab}>
                <Grid
                    container
                    sx={{
                        margin: "2rem 0 1rem 0",
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                    justifyContent={"baseline"}
                >
                    <Grid item xs={8}>
                        <TabList onChange={handleTabChange}>
                            <Tab label={`Zespół (${currentTeam.length})`} value="1" />
                            {hasFormerTeam && <Tab label={`Byli członkowie (${formerTeam.length})`} value="2" />}
                        </TabList>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end">
                        {hasEditRights ? (
                            <Box>
                                <Button
                                    onClick={() => setIsCreatingVacancy(true)}
                                    variant="contained"
                                    disabled={uiState.isCreatingVacancy}
                                >
                                    Dodaj etat
                                </Button>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
                <TabPanel value="1">
                    {currentTeamGroups.map((group, index) => (
                        <Box key={`${group}-${index}-current-team-group`} sx={{ mb: "3rem" }}>
                            <Typography variant={"h6"}>{specializationAccountLabel[group]}</Typography>
                            {currentTeam
                                .filter(ft => ft.account === group)
                                .map((eg, index) => (
                                    <VacancyCard
                                        key={`${eg.employee.id}-${index}-current-team-vacancy-card`}
                                        sx={{ margin: "1rem 0" }}
                                        project={projectDetails}
                                        employee={eg.employee}
                                        vacancies={allVacancies.filter(v => v.employee.id === eg.employee.id)}
                                        hasEditRights={hasEditRights}
                                        onEdit={v => toggleVacancyDrawer(Maybe.of(v))}
                                    />
                                ))}
                        </Box>
                    ))}
                    {currentTeam.length == 0 && (
                        <>
                            <Box display={"flex"} justifyContent={"center"}>
                                <Typography variant={"h3"}>Brak osób przypisanych do projektu.</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"center"}>
                                <Typography variant={"subtitle1"}>Aby dodać osobę utwórz nowy etat.</Typography>
                            </Box>
                        </>
                    )}
                </TabPanel>
                {hasFormerTeam && (
                    <TabPanel value="2">
                        <Box sx={{ mb: "3rem" }}>
                            {formerTeamGroups.map((group, index) => (
                                <Box key={`${group}-${index}-former-team-group`} sx={{ mb: "3rem" }}>
                                    <Typography variant={"h6"}>{specializationAccountLabel[group]}</Typography>
                                    {formerTeam
                                        .filter(ft => ft.account === group)
                                        .map((eg, index) => (
                                            <VacancyCard
                                                key={`${eg.employee.id}-${index}-former-team-vacancy-card`}
                                                sx={{ margin: "1rem 0" }}
                                                project={projectDetails}
                                                employee={eg.employee}
                                                vacancies={oldVacancies.filter(v => v.employee.id === eg.employee.id)}
                                                hasEditRights={hasEditRights}
                                                onEdit={v => toggleVacancyDrawer(Maybe.of(v))}
                                            />
                                        ))}
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                )}
            </TabContext>
            {hasEditRights && uiState.isCreatingVacancy ? (
                <Drawer anchor={"right"} open={uiState.isCreatingVacancy} onClose={toggleCreateVacancyDrawer(false)}>
                    <Typography variant="h3" sx={{ mb: "0.75rem" }}>
                        Edycja etatu
                    </Typography>
                    <VacancyForm
                        isInternal={projectDetails.isInternal}
                        mode="CREATE"
                        project={projectDetails}
                        projectConfig={config}
                        onCancel={() => setIsCreatingVacancy(false)}
                        onSubmit={submitVacancyCreateHandle}
                        vacancyFormData={getNewVacancyFormDataDefaults(new Date(), projectDetails.rate.unwrapOr(0))}
                    />
                </Drawer>
            ) : (
                <></>
            )}
            {hasEditRights &&
                uiState.editVacancy
                    .map(ev => (
                        <Drawer
                            key={`${ev.id}-vacancy-edit`}
                            anchor={"right"}
                            open={uiState.editVacancy.isJust()}
                            onClose={toggleVacancyDrawer(Maybe.nothing())}
                        >
                            <Box display={"inline-flex"}>
                                <Typography variant="h3" sx={{ mb: "0.75rem" }}>
                                    Edycja etatu
                                </Typography>
                                {!ev.isEditable && (
                                    <Tooltip title="Etat wykorzystany - zmiana niektórych danych jest niemożliwa.">
                                        <WarningAmber color="warning" />
                                    </Tooltip>
                                )}
                            </Box>
                            <VacancyForm
                                key={`${ev.id}-edit`}
                                isInternal={projectDetails.isInternal}
                                isVacancyEditable={ev.isEditable}
                                mode={hasEditRights ? "EDIT" : "SHOW"}
                                project={projectDetails}
                                projectConfig={config}
                                onCancel={() => setIsCreatingVacancy(false)}
                                onSubmit={data => submitVacancyUpdateHandle(ev, data)}
                                onRemove={() => submitVacancyRemoveHandle(ev)}
                                vacancyFormData={fromVacancy(ev)}
                            />
                        </Drawer>
                    ))
                    .unwrapOr(<></>)}
            <Drawer anchor={"right"} open={uiState.showProjectEdit} onClose={toggleDrawer(false)}>
                <Typography variant="h3" sx={{ mb: "0.75rem" }}>
                    Edycja projektu
                </Typography>
                <ProjectForm
                    fetchActiveUsers={fetchActiveUsers}
                    mode={hasEditRights ? "EDIT" : "SHOW"}
                    projectConfig={config}
                    projectFormData={fromProject(projectDetails)}
                    onSubmit={submitProjectUpdateHandle}
                />
            </Drawer>
            {/* uncomment for debugging purposes */}
            {/* <pre>{JSON.stringify(allVacancies, null, 2)}</pre> */}
        </>
    );
};
