import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Switch,
    SxProps,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import { Nullable, YupObjectShape } from "components/common/types";
import { Contractor, ContractorInput } from "modules/UserProfile/types";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { CommonDatePicker } from "../../../../components/common/ui-kit/components/DatePicker/DatePicker";
import { Dayjs } from "dayjs";
import { Maybe } from "true-myth";
import { noop } from "../../../../components/common/utils";

const inputStyling: SxProps<Theme> = {
    minWidth: "40%",
    mr: "3rem",
    width: "80%",
};

interface Props {
    contractor: Contractor;
    onSubmit: (formData: ContractorInput) => void;
}

export const ContractorForm: React.FC<Props> = ({ contractor, onSubmit }) => {
    const {
        companyName,
        addressLine1,
        addressLine2,
        nip,
        accountNumber,
        isVatPayer,
        isInvoiceAttached,
        isMonthlyCharged,
        contactAddressLine1,
        contactAddressLine2,
        parcelLocker,
        passportExpirationDate,
        gitHubHandle,
        twitterHandle,
        discordHandle,
    } = contractor;

    const schema = yup
        .object<YupObjectShape<ContractorInput>>({
            contactAddressLine1: yup.string().required(),
            contactAddressLine2: yup.string().required(),
            parcelLocker: yup.string().required(),
            passportExpirationDate: yup.date().required(),
            gitHubHandle: yup.string().nullable(),
            twitterHandle: yup.string().nullable(),
            discordHandle: yup.string().nullable(),
            companyName: yup.string().nullable(),
            addressLine1: yup.string().nullable(),
            addressLine2: yup.string().nullable(),
            nip: yup.string().nullable(),
            accountNumber: yup.string().nullable(),
            isVatPayer: yup.boolean().required(),
            isInvoiceAttached: yup.boolean().required(),
            isMonthlyCharged: yup.boolean().required(),
        })
        .required();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm<ContractorInput>({
        defaultValues: {
            contactAddressLine1: contactAddressLine1.or(addressLine1).unwrapOr(""),
            contactAddressLine2: contactAddressLine2.or(addressLine2).unwrapOr(""),
            parcelLocker: parcelLocker.unwrapOr(""),
            passportExpirationDate: passportExpirationDate.map(e => new Date(e)).unwrapOr(new Date()),
            gitHubHandle: gitHubHandle.unwrapOr(""),
            twitterHandle: twitterHandle.unwrapOr(""),
            discordHandle: discordHandle.unwrapOr(""),
            companyName: companyName.unwrapOr(""),
            addressLine1: addressLine1.unwrapOr(""),
            addressLine2: addressLine2.unwrapOr(""),
            nip: nip.unwrapOr(""),
            accountNumber: accountNumber.unwrapOr(""),
            isVatPayer,
            isInvoiceAttached,
            isMonthlyCharged,
        },
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const onDateChange = (hiringDate: Nullable<Dayjs>) =>
        Maybe.of(hiringDate).match({
            Just: date => setValue("passportExpirationDate", date.toDate(), { shouldTouch: true }),
            Nothing: noop,
        });

    return (
        <div>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography sx={{ margin: "2rem 0 1rem 0rem" }} variant="h5">
                            Adres korespondencyjny
                        </Typography>
                    </Grid>
                </Grid>
                <form
                    name="contractor data form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    data-testid="ContractorForm"
                >
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box sx={{ px: 3 }}>
                                    <Controller
                                        name="contactAddressLine1"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="contactAddressLine1"
                                                label="Ulica"
                                                margin="normal"
                                                sx={inputStyling}
                                                error={!!errors.contactAddressLine1}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="contactAddressLine2"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="contactAddressLine2"
                                                label="Kod pocztowy i miasto"
                                                margin="normal"
                                                sx={inputStyling}
                                                error={!!errors.contactAddressLine2}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <Box sx={{ px: 3 }}>
                                    <Controller
                                        name="parcelLocker"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="parcelLocker"
                                                label="Numer Paczkomatu"
                                                type="text"
                                                margin="normal"
                                                sx={inputStyling}
                                                error={!!errors.parcelLocker}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ margin: "2rem 0 1rem 0rem" }} variant="h5">
                                Dane firmy
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box sx={{ px: 3 }}>
                                    <Controller
                                        name="companyName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="companyName"
                                                label="Nazwa Firmy"
                                                margin="normal"
                                                sx={inputStyling}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="addressLine1"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="addressLine1"
                                                label="Ulica"
                                                margin="normal"
                                                sx={inputStyling}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="addressLine2"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="addressLine2"
                                                label="Kod pocztowy i miasto"
                                                margin="normal"
                                                sx={inputStyling}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <Controller
                                        name="nip"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="nip"
                                                label="NIP"
                                                type="number"
                                                margin="normal"
                                                sx={inputStyling}
                                                error={!!errors.nip}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="accountNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="accountNumber"
                                                label="Nr konta bankowego"
                                                margin="normal"
                                                sx={inputStyling}
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ my: 2, px: 3, textAlign: "center" }}>
                                    <FormGroup row sx={{ display: "flow-root", alignItems: "center" }}>
                                        <Controller
                                            name="isVatPayer"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    data-testid="isVatPayer"
                                                    control={
                                                        <Switch {...field} id="isVatPayer" checked={field.value} />
                                                    }
                                                    label="Płatnik VAT"
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="isInvoiceAttached"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    data-testid="isInvoiceAttached"
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            id="isInvoiceAttached"
                                                            checked={field.value}
                                                        />
                                                    }
                                                    label="Dołączanie pliku z fakturą"
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="isMonthlyCharged"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    data-testid="isMonthlyCharged"
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            id="isMonthlyCharged"
                                                            checked={field.value}
                                                        />
                                                    }
                                                    label="Rozliczenie miesięczne"
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ margin: "2rem 0 1rem 0rem" }} variant="h5">
                                Pozostałe informacje
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box sx={{ px: 3 }}>
                                    <Controller
                                        name="passportExpirationDate"
                                        control={control}
                                        render={({ field }) => (
                                            <CommonDatePicker
                                                datePicker={{
                                                    ...field,
                                                    label: "Data ważności paszportu",
                                                    views: ["year", "month", "day"],
                                                    onChange: onDateChange,
                                                }}
                                                textField={field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="gitHubHandle"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                {...field}
                                                data-testid="gitHubHandle"
                                                label="GitHub"
                                                margin="normal"
                                                sx={inputStyling}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "center" }}>
                                    <FormGroup row sx={{ display: "flow-root", alignItems: "center" }}>
                                        <Controller
                                            name="twitterHandle"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="standard"
                                                    {...field}
                                                    data-testid="twitterHandle"
                                                    label="Twitter"
                                                    margin="normal"
                                                    sx={inputStyling}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="discordHandle"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="standard"
                                                    {...field}
                                                    data-testid="discordHandle"
                                                    label="Discord"
                                                    margin="normal"
                                                    sx={inputStyling}
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid sx={{ my: 2, px: 3, display: "flex", justifyContent: "center" }}>
                        <Button type="submit" variant="contained" color="primary" disabled={!isValid || !isDirty}>
                            Zapisz
                        </Button>
                    </Grid>
                </form>
            </Box>
        </div>
    );
};
