import { SectionDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { GenericSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GenericSection";
import {
    ClientCostsSectionFormInput,
    ClientCostsSectionFormInputData,
    ClientCostsSectionFormInputProps,
} from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/ClientCostsSection/ClientCostsSectionFormInput";
import { SectionBaseProps } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/types";
import * as React from "react";

type Props = SectionDef & SectionBaseProps;

export const ClientCostsSection: React.FC<Props> = ({ question, instructions, sectionDefId, sectionName }) => (
    <GenericSection<ClientCostsSectionFormInputProps, ClientCostsSectionFormInputData>
        title={question}
        instructions={instructions}
        fieldArrayName={sectionName}
        sectionRowProps={{
            inputProps: {
                sectionDefId,
            },
            Component: ClientCostsSectionFormInput,
        }}
    />
);
