import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Divider, Grid, Icon, IconButton, Link, SxProps, Theme, Typography } from "@mui/material";
import { AnyObject } from "components/common/types";
import { ColumnDef, SimpleTableMini, SimpleTableProps } from "components/common/ui-kit/components/SimpleTable";
import { formatForView, sum } from "components/common/utils/numberUtils";
import { isEmpty } from "ramda";
import { Maybe } from "true-myth";
import { InvoiceData, PaymentData } from "modules/SalesSummary/domain/types";
import { getBillingUrl } from "modules/SalesSummary/service/salesSummaryService";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

const invoicePositionColDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Opis",
        rowDataKey: "description",
    },
    {
        header: "Jednostka",
        rowDataKey: "unitType",
    },
    {
        header: "Cena jednostkowa",
        rowDataKey: "unitPrice",
    },
    {
        header: "Ilość",
        rowDataKey: "quantity",
    },
    {
        header: "Netto",
        rowDataKey: "netPrice",
    },
    {
        header: "Brutto",
        rowDataKey: "grossPrice",
    },
    {
        header: "Waluta",
        rowDataKey: "currency",
    },
];

const paymentsColDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Opis",
        rowDataKey: "description",
    },
    {
        header: "Zaksięgowano",
        rowDataKey: "date",
    },
    {
        header: "Wartość",
        rowDataKey: "amount",
    },
    {
        header: "Waluta",
        rowDataKey: "currency",
    },
];

const getBillingHeader = (billingId?: number): JSX.Element => (
    <Box display={"flex"} alignItems={"center"}>
        Billing nr &nbsp;
        <Link target="_blank" rel="noreferrer" href={getBillingUrl(billingId as number)}>
            {billingId}
            <Icon>
                <OpenInNewIcon fontSize={"small"} />
            </Icon>
        </Link>
    </Box>
);
const getInvoiceHeader = (invoiceNo?: string): JSX.Element => <>Faktura VAT nr {invoiceNo}</>;

const areAllPositionInSameCurrency = (positions: PaymentData[]) =>
    positions.every(p => positions[0].currency === p.currency);

interface Props {
    invoiceData: InvoiceData;
    sx?: SxProps<Theme>;
    onCLose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const PositionsPreview = ({ invoiceData, sx, onCLose }: Props) => {
    const { invoicePositions, payments, invoiceNo, billingId, unpaidAmount, currency, paidDate, dueDate } = invoiceData;

    const invoicePositionsComponent = Maybe.of(isEmpty(invoicePositions) ? null : invoicePositions)
        .map(invoicePositions => {
            const currency = invoiceData.currency;
            const positions = invoicePositions.map(p => ({ ...p, currency }));
            const lastInvoicePositionRow: AnyObject = {
                description: "",
                unitType: "",
                unitPrice: "",
                quantity: "Razem",
                netPrice: sum(positions.map(({ netPrice }) => netPrice)),
                grossPrice: sum(positions.map(({ grossPrice }) => grossPrice)),
                currency: invoiceData.currency,
            };

            return (
                <Grid key={0} item xs={12}>
                    <SimpleTableMini
                        columnDefs={invoicePositionColDefs as ColumnDef<AnyObject>[]}
                        rowData={[...positions, lastInvoicePositionRow]}
                    />
                </Grid>
            );
        })
        .unwrapOr(<></>);

    const paymentPositionsComponent = Maybe.of(isEmpty(payments) ? null : payments)
        .map(payments => {
            const lastPaymentRow: AnyObject = {
                description: "",
                date: "Razem",
                amount: areAllPositionInSameCurrency(payments)
                    ? sum(payments.map(({ amount }) => amount))
                    : "Przelewy w wielu walutach.",
                currency: areAllPositionInSameCurrency(payments) ? payments[0].currency : "",
            };
            return (
                <>
                    <Grid item xs={12} sx={{ mt: "1rem" }}>
                        <Typography variant="h6">Przypisane płatności</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTableMini key={0} columnDefs={paymentsColDefs} rowData={[...payments, lastPaymentRow]} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{ mt: "1rem" }}>
                            Pozostało do zapłaty <b>{`${formatForView(unpaidAmount)} ${currency}`}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography variant="h6" sx={{ mt: "1rem" }}>
                            {paidDate && (
                                <>
                                    Opłacono <b>{paidDate}</b>
                                </>
                            )}
                        </Typography>
                    </Grid>
                </>
            );
        })
        .unwrapOr(<></>);

    const headerComponent = Maybe.of(invoiceNo)
        .map(getInvoiceHeader)
        .unwrapOrElse(() =>
            Maybe.of(billingId)
                .map(getBillingHeader)
                .unwrapOr(<></>),
        );
    return (
        <Grid container sx={{ ...sx }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant={"h1"}>
                            <b>{invoiceData.projectName}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                        <IconButton onClick={onCLose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider orientation="horizontal" />
                <Typography variant={"subtitle1"}>{invoiceData.clientName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: "2rem" }}>
                    <Typography variant="h6">{headerComponent}</Typography>
                </Box>
            </Grid>
            {invoicePositionsComponent}
            {paymentPositionsComponent}
            <Grid item xs={12} style={{ textAlign: "right" }}>
                {dueDate && (
                    <Typography variant="h6" sx={{ mt: "1rem" }}>
                        Termin zapłaty <b>{dueDate}</b>
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
