import * as React from "react";
import { MouseEvent, useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

interface Props {
    items: MenuItems[];
}

interface MenuItems {
    name: string;
    onClick: () => void;
}

export const ActionContextMenu: React.FC<Props> = ({ items }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const wrapWithClose = (onClick: () => void): (() => void) => {
        return () => {
            onClick();
            handleClose();
        };
    };

    const menuItems = items.map((el, idx) => (
        <MenuItem key={idx} onClick={wrapWithClose(el.onClick)}>
            {el.name}
        </MenuItem>
    ));
    return (
        <div>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                {menuItems}
            </Menu>
        </div>
    );
};
