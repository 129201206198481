import { DocumentSearchResult } from "../../types";

type PaymentId = number;
type DocumentId = number;
type DocumentPaymentId = number;

export type DraftDocumentAssignmentsState = Record<
    PaymentId,
    {
        removed: DocumentPaymentId[];
        draftAdded: DocumentSearchResult[];
    }
>;

export type DraftDocumentAssignmentsAction =
    | {
          action: "addDraftAssignment";
          payload: {
              paymentId: PaymentId;
              document: DocumentSearchResult;
          };
      }
    | {
          action: "removeDocumentAssignment";
          payload: {
              paymentId: PaymentId;
              documentPaymentId: DocumentPaymentId;
          };
      }
    | {
          action: "removeDraftAssignment";
          payload: {
              paymentId: PaymentId;
              documentId: DocumentId;
          };
      };

export const draftDocumentAssignmentsReducer = (
    state: DraftDocumentAssignmentsState,
    action: DraftDocumentAssignmentsAction,
): DraftDocumentAssignmentsState => {
    switch (action.action) {
        case "addDraftAssignment": {
            const currentPaymentDraftState = state[action.payload.paymentId];
            if (currentPaymentDraftState) {
                const documentAlreadyAdded = currentPaymentDraftState.draftAdded.find(
                    doc => doc.documentID === action.payload.document.documentID,
                );
                return documentAlreadyAdded
                    ? state
                    : {
                          ...state,
                          [action.payload.paymentId]: {
                              ...state[action.payload.paymentId],
                              draftAdded: [...currentPaymentDraftState.draftAdded, action.payload.document],
                          },
                      };
            } else {
                return {
                    ...state,
                    [action.payload.paymentId]: {
                        draftAdded: [action.payload.document],
                        removed: [],
                    },
                };
            }
        }
        case "removeDocumentAssignment": {
            const currentPaymentDraftState = state[action.payload.paymentId];

            if (currentPaymentDraftState) {
                return {
                    ...state,
                    [action.payload.paymentId]: {
                        ...currentPaymentDraftState,
                        removed: [...currentPaymentDraftState.removed, action.payload.documentPaymentId],
                    },
                };
            } else {
                return {
                    ...state,
                    [action.payload.paymentId]: {
                        draftAdded: [],
                        removed: [action.payload.documentPaymentId],
                    },
                };
            }
        }

        case "removeDraftAssignment": {
            const currentPaymentDraftState = state[action.payload.paymentId];
            if (currentPaymentDraftState) {
                const currentlyAdded = state[action.payload.paymentId]?.draftAdded ?? [];
                return {
                    ...state,
                    [action.payload.paymentId]: {
                        ...currentPaymentDraftState,
                        draftAdded: currentlyAdded.filter(doc => doc.documentID !== action.payload.documentId),
                    },
                };
            } else {
                return state;
            }
        }
    }
};
