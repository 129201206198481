import { noop } from "components/common/utils";
import { useState } from "react";
import { Maybe, Result } from "true-myth";
import { useBoolean } from "usehooks-ts";

interface Params<T, R = void> {
    loader: (payload: R) => Promise<Result<T, Error>>;
    onDataLoaded?: (data: T) => void;
    onError?: (error: Error) => void;
}

export const useData = <T, R = void>({ loader, onDataLoaded = noop, onError = noop }: Params<T, R>) => {
    const [data, setData] = useState<Maybe<T>>(Maybe.nothing());
    const { value: isLoading, setValue: setIsLoading } = useBoolean(false);
    const [error, setError] = useState<Maybe<Error>>(Maybe.nothing());

    const setRawData = (rawData: T) => setData(Maybe.of(rawData));

    const setRawError = (err: Error) => setError(Maybe.of(err));

    const loadData = async (payload: R) => {
        setIsLoading(true);

        const res = await loader(payload);
        res.match({
            Ok: resolvedData => {
                onDataLoaded(resolvedData);
                setRawData(resolvedData);
            },
            Err: err => {
                onError(err);
                setRawError(err);
            },
        });

        setIsLoading(false);
    };

    return {
        data,
        setData: setRawData,
        isLoading,
        setIsLoading,
        error,
        setError: setRawError,
        loadData,
    };
};
