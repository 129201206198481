import { TransactionSearchParams } from "../../service/apiService";
import { isEmpty } from "ramda";
import { Maybe } from "true-myth";
import { formatForDataTransfer } from "../../../../components/common/utils/numberUtils";
import { useTransactionFiltersState } from "./TransactionFiltersContext";
import React from "react";

export const useTransactionSearchParams = (): TransactionSearchParams => {
    const transactionFilters = useTransactionFiltersState();

    const { transactionDescription, minAmount, maxAmount, dateRange, smlAccountIds } = transactionFilters;

    return React.useMemo(
        () => ({
            ...(isEmpty(transactionDescription) ? {} : { transactionDescription }),
            ...(isEmpty(smlAccountIds) ? {} : { smlAccountIds }),
            minAmount: Maybe.of(minAmount).map(formatForDataTransfer).unwrapOr(""),
            maxAmount: Maybe.of(maxAmount).map(formatForDataTransfer).unwrapOr(""),
            fromDate: dateRange.from,
            toDate: dateRange.to,
        }),
        [transactionDescription, minAmount, maxAmount, dateRange.from, dateRange.to, smlAccountIds],
    );
};
