import { DocumentDetails } from "../../types";
import BigNumber from "bignumber.js";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import React from "react";

interface Props {
    data: DocumentDetails;
    unpaidAmount: BigNumber;
}

export const DocumentTotalAmountSummary: React.FC<Props> = props => {
    return (
        <Box
            sx={{
                marginTop: "15px",
                marginBottom: "15px",
                marginRight: "15px",
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
            }}
        >
            <Tooltip title={formatMoneyForView(props.data.summary.totalNet.plnValue.toNumber(), "PLN")}>
                <Typography>
                    Wartość netto:{" "}
                    {formatMoneyForView(
                        props.data.summary.totalNet.value.toNumber(),
                        props.data.summary.exchangeRate?.currency ?? "PLN",
                    )}
                </Typography>
            </Tooltip>
            <Tooltip title={formatMoneyForView(props.data.summary.totalTax.plnValue.toNumber(), "PLN")}>
                <Typography>
                    Podatek VAT:{" "}
                    {formatMoneyForView(
                        props.data.summary.totalTax.value.toNumber(),
                        props.data.summary.exchangeRate?.currency ?? "PLN",
                    )}
                </Typography>
            </Tooltip>
            <Tooltip title={formatMoneyForView(props.data.summary.totalGross.plnValue.toNumber(), "PLN")}>
                <Typography>
                    Wartość brutto:{" "}
                    {formatMoneyForView(
                        props.data.summary.totalGross.value.toNumber(),
                        props.data.summary.exchangeRate?.currency ?? "PLN",
                    )}
                </Typography>
            </Tooltip>
            <Divider orientation="horizontal" flexItem sx={{ ml: "1rem", mr: "1rem" }} />

            <Typography variant={"h5"}>
                Pozostało do zapłaty:{" "}
                <b>
                    {" "}
                    {formatMoneyForView(
                        props.unpaidAmount.toNumber(),
                        props.data.summary.exchangeRate?.currency ?? "PLN",
                    )}
                </b>
            </Typography>
        </Box>
    );
};
