import * as React from "react";
import { Field } from "redux-form";
import { useEffect } from "react";

type FieldValue = number | string;

export interface ConstantFieldProps {
    fieldValue: FieldValue;
    fieldName: string;
}

interface Props {
    input: {
        onChange(value: FieldValue): void;
    };
}

export const createConstantInput =
    (fieldValue: FieldValue): React.FC<Props> =>
    ({ input: { onChange } }: Props) => {
        useEffect(() => onChange(fieldValue), []);
        return <></>;
    };

export const ConstantField: React.FC<ConstantFieldProps> = ({ fieldValue, fieldName }) => {
    const ConstantInput: React.FC<Props> = createConstantInput(fieldValue);
    return <Field name={fieldName} component={ConstantInput} />;
};
