import { DetailRow } from "./DetailRow";
import { Paper, Grid } from "@mui/material";
import { PendingPayment } from "modules/types/types";

interface Props {
    pendingPayment: PendingPayment;
}

export const InvoiceDetail = ({ pendingPayment }: Props) => {
    const { invoiceNumber, invoiceIssueDate } = pendingPayment;
    const { companyName, addressLine1, addressLine2, nip, accountNumber } = pendingPayment.contractorData;

    return (
        <Paper>
            <Grid container sx={{ padding: "1rem" }}>
                <Grid item xs={4}>
                    <DetailRow label="Nazwa firmy" text={companyName} />
                    <DetailRow label="Adres" text={addressLine1} />
                    <DetailRow label="" text={addressLine2} />
                </Grid>
                <Grid item xs={4}>
                    <DetailRow label="Nip" text={nip} />
                    <DetailRow label="Numer rachunku" text={accountNumber} />
                </Grid>
                <Grid item xs={4}>
                    <DetailRow label="Nr faktury" text={invoiceNumber.unwrapOr("Brak danych")} />
                    <DetailRow label="Data wystawienia" text={invoiceIssueDate.unwrapOr("Brak danych")} />
                </Grid>
            </Grid>
        </Paper>
    );
};
