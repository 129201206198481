import { calculateTax, plus, sum } from "components/common/utils/numberUtils";
import { SimpleTable } from "components/common/ui-kit/components/SimpleTable";
import {
    calculateMonthlySettlementCostsOffset,
    calculateRegularCostsOffset,
} from "modules/Invoices/views/MonthlySettlement/service/summaryService";
import { SimpleTableProps } from "components/common/ui-kit/components/SimpleTable/types";
import { Box } from "@mui/material";
import { NonVatPayer } from "components/common/ui-kit-business/types";
import { AnyObject } from "components/common/types";
import { TAX_RATE } from "../../../../../../../components/common/ui-kit-business/InvoiceSummary/constants/costs";

export interface InvoiceSummaryTable {
    rowData: InvoiceSummaryTableRow[];
}

export interface InvoiceSummaryTableRow {
    unit: string;
    quantity: number;
    unitPrice: number;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    invoicePosition: string;
    currency: string;
}

type Props = InvoiceSummaryTable & {
    isVatPayer: boolean;
    isMonthlyCharged: boolean;
    notVatPayerData: NonVatPayer;
    currency: string;
};

const colDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Pozycja",
        rowDataKey: "invoicePosition",
    },
    {
        header: "Jednostka miary",
        rowDataKey: "unit",
    },
    {
        header: "Ilość",
        rowDataKey: "quantity",
    },
    {
        header: "Cena jednostkowa",
        rowDataKey: "unitPrice",
    },
    {
        header: "Razem netto",
        rowDataKey: "totalNet",
    },
    {
        header: "Razem VAT",
        rowDataKey: "totalTax",
    },
    {
        header: "Razem brutto",
        rowDataKey: "totalGross",
    },
    {
        header: "",
        rowDataKey: "currency",
    },
];

export const InvoiceSummaryTable = ({ isVatPayer, isMonthlyCharged, rowData, notVatPayerData, currency }: Props) => {
    const totalNet = sum(rowData.map(({ totalNet }) => totalNet)).toNumber();
    const totalTax = calculateTax(totalNet, isVatPayer ? TAX_RATE : 0).toNumber();
    const lastRow: Omit<InvoiceSummaryTableRow, "unitPrice" | "quantity" | "unit"> | { totalGross: JSX.Element } = {
        totalNet: totalNet,
        totalTax: totalTax,
        totalGross: plus(totalNet, totalTax).toNumber(),
        invoicePosition: "Razem",
        currency: currency,
    };
    const rowsOffseted = isMonthlyCharged
        ? calculateMonthlySettlementCostsOffset(rowData, isVatPayer)
        : calculateRegularCostsOffset(rowData, isVatPayer);
    const { definition, data } = isVatPayer
        ? { definition: colDefs, data: [...rowsOffseted, lastRow] }
        : notVatPayerData<typeof lastRow, AnyObject>(colDefs, [...rowsOffseted, lastRow], true);

    return (
        <Box
            sx={{
                "& table th": {
                    paddingTop: 1,
                    paddingLeft: 1,
                },

                "& table tr:last-child": {
                    backgroundColor: "primary.main",
                    borderColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                    // boxShadow: "2px 2px 10px 1px #319db5",
                },

                "& table tr:last-child td:first-of-type": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                },

                "& table tr:last-child td:last-child": {
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                },
            }}
        >
            <SimpleTable columnDefs={definition} rowData={data} />
        </Box>
    );
};
