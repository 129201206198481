import { Link, Paper, Tooltip } from "@mui/material";
import { OtherCost } from "modules/Billing/types";
import { Link as RouterLink } from "react-router-dom";
import { getInvoiceUrl } from "../../../../components/common/ui-kit-business/InvoiceSummary/service/summaryService";
import { getTaxxoDocumentURL } from "../../../AccountingManagement/service";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import { SimpleTable, SimpleTableProps } from "../../../../components/common/ui-kit/components/SimpleTable";
import { AnyObject } from "../../../../components/common/types";
import { sum } from "ramda";

interface OtherCostTable {
    otherCosts: OtherCost[];
}

interface Row {
    date: string;
    source: string;
    clientName: string;
    documentSymbol: JSX.Element;
    note: string;
    netAmount: JSX.Element;
}

type Props = OtherCostTable;

const colDefs: SimpleTableProps<AnyObject>["columnDefs"] = [
    {
        header: "Data",
        rowDataKey: "date",
    },
    {
        header: "Symbol faktury",
        rowDataKey: "documentSymbol",
    },
    {
        header: "Żródło",
        rowDataKey: "source",
    },
    {
        header: "Nazwa firmy",
        rowDataKey: "clientName",
    },
    {
        header: "Opis",
        rowDataKey: "note",
    },
    {
        header: "Kwota netto",
        rowDataKey: "netAmount",
    },
];

const renderNetAmount = (cost: OtherCost) => {
    const { netAmount, currency, netAmountInBillingCurrency, billingCurrency, exchangeRate, exchangeRateDate } = cost;
    const isDifferentCurrency = billingCurrency !== currency;

    return (
        <div>
            <div>{formatMoneyForView(netAmount, currency)}</div>
            {isDifferentCurrency && (
                <Tooltip
                    title={`kurs ${currency}/${billingCurrency} ${exchangeRate} z dnia ${exchangeRateDate}`}
                    placement="top"
                >
                    <div>{formatMoneyForView(netAmountInBillingCurrency, billingCurrency)}</div>
                </Tooltip>
            )}
        </div>
    );
};

const toRows = (otherCost: OtherCost): Row => ({
    ...otherCost,
    source: otherCost.pendingPaymentId != null ? "Pracownik" : "Zewnętrzne",
    documentSymbol:
        otherCost.pendingPaymentId != null ? (
            <Link
                key={`invoice-no-${otherCost.documentSymbol}`}
                component={RouterLink}
                to={"/accounting/" + getInvoiceUrl(otherCost.pendingPaymentId)}
            >
                {otherCost.documentSymbol}
            </Link>
        ) : (
            <Link
                key={`invoice-no-${otherCost.documentSymbol}`}
                component={RouterLink}
                to={getTaxxoDocumentURL(otherCost.taxxoDocumentId ?? 0)}
            >
                {otherCost.documentSymbol}
            </Link>
        ),
    netAmount: renderNetAmount(otherCost),
});

export const OtherCostTable = ({ otherCosts }: Props) => {
    const data = otherCosts.map(toRows);
    const lastRow = {
        note: "Razem",
        netAmount: formatMoneyForView(
            sum(otherCosts.map(r => r.netAmountInBillingCurrency)),
            otherCosts[0].billingCurrency,
        ),
    };
    return (
        <Paper>
            <SimpleTable columnDefs={colDefs} rowData={[...data, lastRow]} />
        </Paper>
    );
};
