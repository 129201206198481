import React from "react";
import { AxiosError } from "axios";
import { AsyncResult } from "../components/common/infractructure";
import { toast, DefaultToastOptions } from "react-hot-toast";

type Renderable = React.ReactElement | string | null;
type ValueOrFn<T> = Renderable | ((value: T) => Renderable);

export interface AsyncResultToastOptions<T> {
    loading: Renderable;
    success: ValueOrFn<T>;
    error: ValueOrFn<AxiosError>;
}

export const asyncResultToast = <T>(
    result: AsyncResult<T>,
    options: AsyncResultToastOptions<T>,
    opts?: DefaultToastOptions,
) =>
    toast.promise<PromiseValue<AsyncResult<T>>>(
        result,
        {
            loading: options.loading,
            success: result =>
                result.match({
                    Ok: data => (typeof options.success === "function" ? options.success(data) : options.success),
                    Err: error => (typeof options.error === "function" ? options.error(error) : options.error),
                }),
            error: () => {
                throw new Error("Niespodziewany błąd - AsyncResult nie powinien nigdy wyrzucić wyjątku");
            },
        },
        opts,
    );
