import { yupResolver } from "@hookform/resolvers/yup";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    SxProps,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import { Nullable, toDateString } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { Dayjs } from "dayjs";
import {
    BillingType,
    ProjectConfig,
    ProjectFormData,
    formatEmployeeLabel,
    projectFormDataSchema,
    rateTypeLabels,
    Employee,
} from "modules/Projects/types";
import { Mode, filterEmployees } from "modules/Projects/views/components/formUtils";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Maybe } from "true-myth";
import { AccountBalanceOutlined, AccountCircle, AttachMoneyOutlined, DescriptionOutlined } from "@mui/icons-material";
import { AsyncResult } from "../../../../components/common/infractructure";
import ListItemIcon from "@mui/material/ListItemIcon";
import { toAvatar } from "../../../AccountingManagement/views/Documents/AvatarMenu";

const billingTypeLabels: Record<BillingType, string> = {
    PER_DESCRIPTION: "Wg. opisu",
    PER_EMPLOYEE: "Per pracownik",
    PER_POSITION: "Per stanowisko",
};

const nonInternalProjectFormLayout: SxProps<Theme> = {
    gridTemplateAreas: `
    "clientId                       clientId                    clientId"
    "accountantId                   accountantId                accountantId"
    "rateDivider                    rateDivider                 rateDivider"
    "rateType                       rate                        currency"
    "provision                      .                           ."
    "paymentDivider                 paymentDivider              paymentDivider"
    "billingDetails_currency        billingDetails_accountId    billingDetails_accountId"
    "billingDetails_paymentDays     .                           ."
    "invoiceDivider                 invoiceDivider              invoiceDivider"
    "billingDetails_language        billingDetails_billingType  billingDetails_signerId"
    "billingDetails_notes           billingDetails_notes        billingDetails_notes"
    "billingDetails_slackChannel    billingDetails_slackChannel billingDetails_slackChannel"
`,
};

interface Props {
    projectConfig: ProjectConfig;
    projectFormData: ProjectFormData;
    onSubmit: (formData: ProjectFormData) => void;
    mode: Mode;
    fetchActiveUsers: () => AsyncResult<Employee[]>;
}

const ProjectForm: React.FC<Props> = ({ projectConfig, projectFormData, onSubmit, mode, fetchActiveUsers }) => {
    const {
        control,
        formState: { errors, isValid, isSubmitting, isDirty, isSubmitSuccessful },
        setValue,
        handleSubmit,
        setError,
        clearErrors,
        reset,
    } = useForm<ProjectFormData>({
        defaultValues: projectFormData,
        resolver: yupResolver(projectFormDataSchema),
        mode: "all",
    });

    const [allActiveUsers, setAllActiveUsers] = useState<Employee[]>([]);
    const [loadingActiveUsers, setLoadingActiveUsers] = useState(false);

    const initialIsAccountable = projectFormData.isAccountable;
    const isCreateMode = mode === "CREATE";
    const isShowMode = mode === "SHOW";
    const isEditMode = mode === "EDIT";

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful, reset]);

    const isInternal = useWatch({ control, name: "isInternal" });
    const nonInternalFieldsStyle = isInternal ? { display: "none" } : {};
    const internalFieldsStyle = !isInternal ? { display: "none" } : {};

    const onFetchActiveUsers = async () => {
        setLoadingActiveUsers(true);
        const fetchedUsers = await fetchActiveUsers();
        fetchedUsers.match({
            Ok: fetchedUsers => {
                setAllActiveUsers(fetchedUsers);
                setLoadingActiveUsers(false);
            },
            Err: () => {
                setLoadingActiveUsers(false);
            },
        });
    };

    const onDateChange = (fieldName: "start" | "end") => (pickedDate: Nullable<Dayjs>) =>
        Maybe.of(pickedDate).match({
            Just: date => {
                try {
                    const dateString = toDateString(date.toDate());
                    setValue(fieldName, dateString, { shouldDirty: true, shouldValidate: true });
                    clearErrors(fieldName);
                } catch (error) {
                    setError(fieldName, { type: "custom", message: "invalid date" });
                }
            },
            Nothing: () => setValue(fieldName, null, { shouldDirty: true, shouldValidate: true }),
        });

    return (
        <Box sx={{ margin: "1rem" }}>
            <form name="project form" onSubmit={handleSubmit(onSubmit)}>
                {isCreateMode && (
                    <Controller
                        name="isInternal"
                        control={control}
                        render={({ field: { value, ref, ...field } }) => (
                            <FormControlLabel
                                label="Projekt wewnętrzny"
                                disabled={isShowMode || isEditMode}
                                control={
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color={"primary"}
                                        size="small"
                                    />
                                }
                                sx={{ mb: "1.75rem" }}
                            />
                        )}
                    />
                )}
                <Box
                    sx={{
                        display: "grid",
                        gridGap: "1.75rem",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gridTemplateAreas: `
                            "name   name    name    isAccountable"
                            "start  start   end     end"
                        `,
                    }}
                >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                {...field}
                                sx={{ gridArea: "name" }}
                                label="Nazwa projektu"
                                error={!!errors.name}
                                size="small"
                                disabled={isShowMode}
                            />
                        )}
                    />
                    <Controller
                        name="isAccountable"
                        control={control}
                        render={({ field: { value, ref, ...field } }) => (
                            <FormControlLabel
                                sx={{ gridArea: "isAccountable", ...internalFieldsStyle }}
                                label="Osobne księgowanie"
                                disabled={isShowMode || (isEditMode && initialIsAccountable)}
                                control={
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color={"primary"}
                                        size="small"
                                    />
                                }
                            />
                        )}
                    />
                    <Controller
                        name="isIpBox"
                        control={control}
                        render={({ field: { value, ref, ...field } }) => (
                            <FormControlLabel
                                label="IPBox"
                                sx={{
                                    gridArea: "isAccountable",
                                    ...nonInternalFieldsStyle,
                                }}
                                disabled={!isCreateMode}
                                control={
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color={"primary"}
                                        size="small"
                                    />
                                }
                            />
                        )}
                    />
                    <Controller
                        name="start"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data rozpoczęcia",
                                    onChange: onDateChange("start"),
                                    disabled: isShowMode,
                                }}
                                textField={{
                                    sx: {
                                        gridArea: "start",
                                    },
                                    error: !!errors.start,
                                    size: "small",
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="end"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data zakończenia",
                                    onChange: onDateChange("end"),
                                    disabled: isShowMode,
                                }}
                                textField={{
                                    sx: {
                                        gridArea: "end",
                                    },
                                    error: !!errors.end,
                                    size: "small",
                                }}
                            />
                        )}
                    />
                </Box>
                {!isInternal && (
                    <>
                        <Box
                            sx={{
                                display: "grid",
                                gridGap: "1.75rem",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                ...nonInternalProjectFormLayout,
                                mt: "1.75rem",
                            }}
                        >
                            <Box
                                color={"primary"}
                                sx={{ display: "flex", alignItems: "center", gridArea: "rateDivider", mt: "2.25rem" }}
                            >
                                <AttachMoneyOutlined />
                                <Typography variant="h3">Stawka</Typography>
                            </Box>
                            <Controller
                                name="clientId"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        sx={{ gridArea: "clientId", ...nonInternalFieldsStyle }}
                                        getOptionLabel={option =>
                                            projectConfig.clients.find(client => client.id === option)?.companyName ??
                                            ""
                                        }
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={params => (
                                            <TextField {...params} variant="standard" label="Klient" />
                                        )}
                                        options={projectConfig.clients.map(client => client.id)}
                                        size="small"
                                        disabled={isShowMode}
                                        value={field.value}
                                    />
                                )}
                            />
                            <Controller
                                name="accountant"
                                control={control}
                                render={({ field }) => (
                                    <Box sx={{ display: "flex", alignItems: "flex-end", gridArea: "accountantId" }}>
                                        {field.value != null ? (
                                            toAvatar(field.value)
                                        ) : (
                                            <AccountCircle
                                                sx={{ color: "action.active", mr: 1, my: 0 }}
                                                fontSize={"large"}
                                            />
                                        )}

                                        <Autocomplete
                                            {...field}
                                            loadingText="Ładowanie..."
                                            sx={{ width: "100%", ml: 1, ...nonInternalFieldsStyle }}
                                            onChange={(_, value) => field.onChange(value)}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Opiekun projektu"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )}
                                            options={allActiveUsers.sort((a, b) =>
                                                a.lastName.localeCompare(b.lastName),
                                            )}
                                            size="small"
                                            disabled={isShowMode}
                                            loading={loadingActiveUsers}
                                            onOpen={onFetchActiveUsers}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <ListItemIcon>{toAvatar(option)}</ListItemIcon>
                                                    {option.lastName} {option.firstName}
                                                </li>
                                            )}
                                            getOptionLabel={option => `${option.lastName} ${option.firstName}`}
                                            value={field.value}
                                        />
                                    </Box>
                                )}
                            />

                            <Controller
                                name="rateType"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{ gridArea: "rateType", ...nonInternalFieldsStyle }}
                                        label="Sposób naliczania"
                                        values={projectConfig.rateTypes.map(rateType => ({
                                            value: rateType,
                                            display: rateTypeLabels[rateType],
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="rate"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        sx={{ gridArea: "rate", ...nonInternalFieldsStyle }}
                                        type="number"
                                        label="Stawka"
                                        error={!!errors.rate}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="currency"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{ gridArea: "currency", ...nonInternalFieldsStyle }}
                                        label="Waluta"
                                        values={projectConfig.currencies.map(currencyName => ({
                                            value: currencyName,
                                            display: currencyName,
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="provision"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        sx={{ gridArea: "provision", ...nonInternalFieldsStyle }}
                                        type="number"
                                        label="Prowizja (%)"
                                        error={!!errors.provision}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Box
                                color={"primary"}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gridArea: "paymentDivider",
                                    mt: "2.25rem",
                                }}
                            >
                                <AccountBalanceOutlined />
                                <Typography variant="h3">Płatność</Typography>
                            </Box>
                            <Controller
                                name="billingDetails.currency"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_currency",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        label="Waluta rozliczeń"
                                        values={projectConfig.currencies.map(currencyName => ({
                                            value: currencyName,
                                            display: currencyName,
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.accountId"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_accountId",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        label="Konto do przelewów"
                                        values={projectConfig.accounts.map(({ accountNumber, currency, id }) => ({
                                            value: id,
                                            display: `${currency} ${accountNumber}`,
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.paymentDays"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_paymentDays",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        type="number"
                                        label="Termin płatności (dni)"
                                        error={!!errors.billingDetails?.paymentDays}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.language"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_language",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        label="Język faktury"
                                        values={projectConfig.languages.map(language => ({
                                            value: language,
                                            display: language,
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.billingType"
                                control={control}
                                render={({ field }) => (
                                    <GenericSelect
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_billingType",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        label="Podział pozycji na fakturze"
                                        values={projectConfig.billingTypes.map(billingType => ({
                                            value: billingType,
                                            display: billingTypeLabels[billingType],
                                        }))}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.signerId"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_signerId",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        getOptionLabel={option =>
                                            Maybe.fromNullable(
                                                filterEmployees(projectConfig.employees, mode).find(
                                                    employee => employee.id === option,
                                                ),
                                            )
                                                .map(formatEmployeeLabel)
                                                .unwrapOr("")
                                        }
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={params => (
                                            <TextField {...params} variant="standard" label="Podpisujący fakturę" />
                                        )}
                                        options={filterEmployees(projectConfig.employees, mode).map(
                                            employee => employee.id,
                                        )}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Box
                                color={"primary"}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gridArea: "invoiceDivider",
                                    mt: "2.25rem",
                                }}
                            >
                                <DescriptionOutlined />
                                <Typography variant="h3">Faktura</Typography>
                            </Box>
                            <Controller
                                name="billingDetails.notes"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        sx={{
                                            gridArea: "billingDetails_notes",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        {...field}
                                        multiline
                                        minRows={1}
                                        maxRows={4}
                                        label="Uwagi do faktur"
                                        error={!!errors.billingDetails?.notes}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="billingDetails.slackChannel"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        sx={{
                                            gridArea: "billingDetails_slackChannel",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        label="Kanał Slack do powiadomień"
                                        error={!!errors.billingDetails?.slackChannel}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                        </Box>
                    </>
                )}
                <Box sx={{ margin: "2rem 0 0" }}>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        type={"submit"}
                        disabled={!isDirty || !isValid || isSubmitting}
                    >
                        Zapisz
                    </Button>
                </Box>
                {/* uncomment for debugging purposes */}
                {/* <pre>{isValid ? "valid" : "invalid"}</pre> */}
                {/* <pre>{isDirty ? "dirty" : "not dirty"}</pre> */}
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
                {/* <pre>{isSubmitting ? "submitting" : "not submitting"}</pre> */}
            </form>
        </Box>
    );
};

export default ProjectForm;
