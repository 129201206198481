import Loader from "components/Loader/Loader";
import { apiGet } from "components/common/infractructure";
import { getGDInvoiceThumbnail } from "components/common/ui-kit-business/InvoiceSummary/service/summaryService";
import { useEffect, useState } from "react";
import CloudOffIcon from "@mui/icons-material/CloudOff";

interface Props {
    sourceDocumentId: string;
}
export const InvoiceThumbnail = ({ sourceDocumentId }: Props) => {
    const [thumbnailSrc, setThumbnailSrc] = useState<string>("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchThumbnail = async (docId: string) => {
            setIsLoading(true);
            (await getGDInvoiceThumbnail(apiGet)(docId)).match({
                Ok: thumbnail => {
                    setThumbnailSrc(URL.createObjectURL(thumbnail));
                    setIsLoading(false);
                    setError(false);
                },
                Err: () => {
                    setError(true);
                    setIsLoading(false);
                },
            });
        };
        if (sourceDocumentId.length > 0) {
            fetchThumbnail(sourceDocumentId);
        }
    }, [sourceDocumentId]);

    if (isLoading && sourceDocumentId.length > 0) {
        return <Loader sx={{ padding: "2rem" }} />;
    } else if (!isLoading && !error && thumbnailSrc.length > 0) {
        return <img src={thumbnailSrc} />;
    } else if (error) {
        return (
            <p style={{ display: "flex", alignItems: "center" }}>
                <CloudOffIcon color="error" />
                <span style={{ marginLeft: "8px" }}>Nie udało się pobrać miniaturki faktury</span>
            </p>
        );
    } else {
        return <></>;
    }
};
