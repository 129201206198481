import { Box, FormControlLabel, Paper, Switch } from "@mui/material";
import { useUserContext } from "components/common/contexts";
import { AsyncResult } from "components/common/infractructure";
import { DateRange, MUI_INPUT_YEAR_MONTH_STRING_FORMAT, dateStringToStrYearMonth } from "components/common/types";
import { ActionContextMenu } from "components/common/ui-kit/components/ActionContextMenu";
import { DateRangePicker } from "components/common/ui-kit/components/DateRangePicker/DateRangePicker";
import { INVOICE_IMPORT_FAILED, INVOICE_IMPORT_SUCCEDED } from "modules/SalesSummary/messages";
import { TaxxoInvoice } from "modules/SalesSummary/service";
import * as React from "react";
import { toast } from "react-hot-toast";
import { filterPanel } from "components/common/ui-kit/styles/utils";

interface Props {
    dateRange: DateRange;
    showNonProjectSales: boolean;
    today: Date;
    onDateRangeChange: (range: DateRange) => void;
    onChangeShowNonProjectSales: (show: boolean) => void;
    importTaxxoInvoices: (from: string, to: string) => AsyncResult<TaxxoInvoice>;
}

export const SalesFilter: React.FC<Props> = ({
    dateRange,
    showNonProjectSales,
    today,
    onDateRangeChange,
    onChangeShowNonProjectSales,
    importTaxxoInvoices,
}) => {
    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();

    const isAccountant = roles.includes("ROLE_ACCOUNTANT_WRITE") && roles.includes("ROLE_ACCOUNTANT_READ");

    const handleChangeShowNonProjectSales = (_: React.ChangeEvent, checked: boolean) =>
        onChangeShowNonProjectSales(checked);

    const handleInvoicesImport = async () => {
        (
            await importTaxxoInvoices(dateStringToStrYearMonth(dateRange.from), dateStringToStrYearMonth(dateRange.to))
        ).match({
            Ok: () => toast.success(INVOICE_IMPORT_SUCCEDED),
            Err: () => toast.error(INVOICE_IMPORT_FAILED),
        });
    };

    return (
        <Paper sx={filterPanel}>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 220px) 1fr",
                    gridTemplateAreas: `
                        "date_range_select date_range_from date_range_to checkbox contextMenu"
                    `,
                    gridGap: ".75rem",
                }}
            >
                <DateRangePicker
                    rangeOptions={["last_90_days", "last_month", "custom_range"]}
                    initialRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    today={today}
                    views={["year", "month"]}
                    labelFrom="Od (rok-miesiąc)"
                    labelTo="Do (rok-miesiąc)"
                    inputFormat={MUI_INPUT_YEAR_MONTH_STRING_FORMAT}
                />

                <FormControlLabel
                    sx={{ marginTop: "0.5rem", gridArea: "checkbox" }}
                    control={
                        <Switch
                            checked={showNonProjectSales}
                            onChange={handleChangeShowNonProjectSales}
                            name="Pokaz faktury bez projektów"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            color="primary"
                        />
                    }
                    label="Pokazuj faktury bez projektów"
                />
                {isAccountant && (
                    <Box sx={{ gridArea: "contextMenu", textAlign: "right" }}>
                        <ActionContextMenu
                            items={[
                                {
                                    name: "Rozpocznij import faktur z wybranego okresu",
                                    onClick: handleInvoicesImport,
                                },
                            ]}
                        />
                    </Box>
                )}
            </Box>
        </Paper>
    );
};
