import { useEffect, useState } from "react";
import { AsyncResult } from "components/common/infractructure";
import { OpenInNew } from "@mui/icons-material";
import { PendingPayment } from "modules/types/types";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { Box } from "@mui/system";

interface Props {
    getGDInvoiceFile: (sourceDocumentId: string) => AsyncResult<Blob>;
    pendingPayment: PendingPayment;
}

const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const InvoiceFile = ({ getGDInvoiceFile, pendingPayment }: Props) => {
    const [fileSrc, setFileSrc] = useState<string | undefined>();
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

    useEffect(() => {
        const fetchFile = async (docId: string) => {
            (await getGDInvoiceFile(docId)).match({
                Ok: async file => {
                    const base64String = await blobToBase64(file);
                    setFileSrc(base64String);
                },
                Err: () => null,
            });
        };
        pendingPayment.sourceDocumentId.match({
            Just: value => {
                fetchFile(value);
                return "";
            },
            Nothing: () => "",
        });
    }, [pendingPayment]);

    return (
        <>
            {fileSrc && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Document file={fileSrc}>
                        <Page width={1000} renderTextLayer={false} renderAnnotationLayer={false} pageNumber={1} />
                    </Document>
                    <a target="_blank" href={fileSrc} rel="noreferrer">
                        <OpenInNew sx={{ color: "primary.main" }} />
                    </a>
                </Box>
            )}
        </>
    );
};
