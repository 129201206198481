import { Maybe } from "true-myth";
import { Reducer } from "redux";
import { GroupDef, MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { MonthlySettlement, YearMonthWithRate } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { ActionType, AllAppActions } from "modules/Invoices/views/MonthlySettlement/domain/actions";

export type AppState = typeof initialState;

const initialState = {
    formDefinition: Maybe.nothing<MonthlySettlementFormDef>(),
    initialFormData: Maybe.nothing<MonthlySettlement>(),
    activeFormGroup: Maybe.nothing<GroupDef>(),
    yearMonthWithRate: Maybe.nothing<YearMonthWithRate>(),
    reportedDays: 0,
};

export const reducer: Reducer<AppState, AllAppActions> = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.CLEAR_ACTIVE_FORM_GROUP:
            return {
                ...state,
                activeFormGroup: Maybe.nothing(),
            };

        case ActionType.SET_ACTIVE_FORM_GROUP:
            return {
                ...state,
                activeFormGroup: Maybe.just(action.payload),
            };

        case ActionType.SET_FORM_DEFINITION:
            return {
                ...state,
                formDefinition: Maybe.just(action.payload),
            };

        case ActionType.RESET_FORM_DEFINITION:
            return {
                ...state,
                formDefinition: Maybe.nothing(),
            };

        case ActionType.SET_INITIAL_FORM_DATA:
            return {
                ...state,
                initialFormData: Maybe.just(action.payload),
            };

        case ActionType.RESET_INITIAL_FORM_DATA:
            return {
                ...state,
                initialFormData: Maybe.nothing(),
            };

        case ActionType.SET_DATE_AND_RATE:
            return {
                ...state,
                yearMonthWithRate: Maybe.of(action.payload),
            };

        case ActionType.SET_REPORTED_DAYS:
            return {
                ...state,
                reportedDays: action.payload,
            };
        default:
            return state;
    }
};
