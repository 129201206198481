import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import {
    CommercialProjectFormDataDTO,
    InternalProjectFormDataDTO,
    Project,
    ProjectConfig,
    ProjectConfigDTO,
    ProjectDTO,
    ProjectFormData,
    Vacancy,
    VacancyDTO,
    VacancyFormData,
    mapProjectConfigDTO,
    mapProjectDTO,
    mapVacancyDTO,
    pickCommercialProjectFormDataDTO,
    pickInternalProjectFormDataDTO,
    toProjectFormDataDTO,
    ProjectDetailsDTO,
    ProjectDetails,
    mapProjectDetailsDTO,
    EmployeeDTO,
    Employee,
    mapEmployeeDTO,
} from "modules/Projects/types";

export const getProjectsList = (apiGet: ApiGet<ProjectDTO[]>) => (): AsyncResult<Project[]> =>
    apiGet("/projects").then(result => result.map(list => list.map(mapProjectDTO)));

export const getVacancies = (apiGet: ApiGet<VacancyDTO[]>, projectId: string) => (): AsyncResult<Vacancy[]> =>
    apiGet(`/projects/${projectId}/vacancy`).then(result => result.map(list => list.map(mapVacancyDTO)));

export const getAllActiveUsers = (apiGet: ApiGet<EmployeeDTO[]>) => (): AsyncResult<Employee[]> =>
    apiGet(`/users`).then(result => result.map(list => list.map(mapEmployeeDTO)));

export const getProject = (apiGet: ApiGet<ProjectDetailsDTO>, projectId: string) => (): AsyncResult<ProjectDetails> =>
    apiGet(`/projects/${projectId}`).then(result => result.map(p => mapProjectDetailsDTO(p)));

export const getProjectConfig = (apiGet: ApiGet<ProjectConfigDTO>) => (): AsyncResult<ProjectConfig> =>
    apiGet("/projects/config").then(result => result.map(mapProjectConfigDTO));

export const createProject =
    (apiPost: ApiUpdate<CommercialProjectFormDataDTO | InternalProjectFormDataDTO, ProjectDetailsDTO>) =>
    (data: ProjectFormData): AsyncResult<ProjectDetails> => {
        const dto = toProjectFormDataDTO(data);

        const pickedData: CommercialProjectFormDataDTO | InternalProjectFormDataDTO = data.isInternal
            ? pickInternalProjectFormDataDTO(dto)
            : pickCommercialProjectFormDataDTO(dto);

        return apiPost(`/projects/${data.isInternal ? "internal" : "external"}`, pickedData).then(result =>
            result.map(p => mapProjectDetailsDTO(p, data.isInternal)),
        );
    };

export const updateProject =
    (
        apiPatch: ApiUpdate<CommercialProjectFormDataDTO | InternalProjectFormDataDTO, ProjectDetailsDTO>,
        projectId: string,
    ) =>
    (data: ProjectFormData): AsyncResult<ProjectDetails> => {
        const dto = toProjectFormDataDTO(data);

        const pickedData: CommercialProjectFormDataDTO | InternalProjectFormDataDTO = data.isInternal
            ? pickInternalProjectFormDataDTO(dto)
            : pickCommercialProjectFormDataDTO(dto);

        return apiPatch(`/projects/${data.isInternal ? "internal" : "external"}/${projectId}`, pickedData).then(
            result => result.map(p => mapProjectDetailsDTO(p, data.isInternal)),
        );
    };

export const createProjectVacancy =
    (apiPost: ApiUpdate<VacancyFormData, VacancyDTO>, projectId: string) =>
    (data: VacancyFormData): AsyncResult<Vacancy> =>
        apiPost(`/projects/${projectId}/vacancy`, data).then(result => result.map(mapVacancyDTO));

export const updateProjectVacancy =
    (apiPatch: ApiUpdate<VacancyFormData, VacancyDTO>, vacancyId: string) =>
    (data: VacancyFormData): AsyncResult<Vacancy> =>
        apiPatch(`/projects/vacancy/${vacancyId}`, data).then(result => result.map(mapVacancyDTO));

export const deleteProjectVacancy =
    (apiDelete: ApiUpdate<void, void>) =>
    (vacancyId: number): AsyncResult<void> =>
        apiDelete(`/projects/vacancy/${vacancyId}`);
