import { Maybe } from "true-myth";
import { ContractorData, MoneyTransferEntry, PendingPayment, PendingPaymentStatus } from "modules/types/types";

const accountNumberToIBAN = (accountNr: string): string => {
    return Maybe.of(accountNr)
        .map(nr => nr.replace(/\s/g, ""))
        .map(nr => (nr.match("^([a-z]{2}|[A-Z]{2}).*") ? nr : `PL${nr}`))
        .unwrapOr("Brak numeru");
};

export const pendingPaymentToMoneyTransferEntry = (p: PendingPayment): MoneyTransferEntry => {
    return {
        companyName: p.contractorData.companyName,
        addressLine1: p.contractorData.addressLine1,
        addressLine2: p.contractorData.addressLine2,
        country: p.contractorData.country,
        bankAccount: accountNumberToIBAN(p.contractorData.accountNumber),
        swift: p.contractorData.swift,
        amount: p.totalGross,
        amountInPLN: p.totalGrossInPLN,
        currency: p.contractorData.currency.name,
        pendingPaymentId: p.id,
        title: p.invoiceNumber
            .map(nr => `/Faktura VAT nr ${nr}`)
            .map(nr => `NIP/${p.contractorData.nip}${nr}`)
            .unwrapOr("wypłata dla właściciela"),
    };
};

export const contractorDataToPaymentRecipient = (contractorData: ContractorData): string => {
    const { companyName, addressLine1, addressLine2 } = contractorData;
    return [companyName, addressLine1, addressLine2].filter(detail => detail).join(", ");
};

export const getPaymentStatusTranslation = (status: PendingPaymentStatus): string => {
    switch (status) {
        case "INVOICE_BOOKED":
            return "Zaksięgowana faktura";
        case "PAYMENT_BOOKED":
            return "Zaksięgowana płatność";
        case "PAID":
            return "Opłacone";
        case "WAITING_FOR_PAYMENT":
            return "Oczekujące";
        default:
            return "Brak statusu";
    }
};
