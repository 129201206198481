import { Button } from "@mui/material";
import * as React from "react";
import { Box } from "@mui/system";

interface Props {
    onSendBilling: () => Promise<void>;
    onAcceptBilling: () => Promise<void>;
    accepted: boolean;
    isAccountant: boolean;
}

export const BillingActionButton: React.FC<Props> = ({ onSendBilling, onAcceptBilling, accepted, isAccountant }) => {
    const buttonText = accepted ? "Wyślij do Taxxo" : "Akceptuj";
    const onClick = accepted ? onSendBilling : onAcceptBilling;
    const enabled = isAccountant || !accepted;

    return (
        <Box mt={3} mb={3} width={1}>
            <Button
                type="button"
                color="primary"
                variant="contained"
                title="Akceptuj"
                onClick={onClick}
                disabled={!enabled}
                sx={{ display: "block", m: "auto", width: "8rem" }}
            >
                {buttonText}
            </Button>
        </Box>
    );
};
