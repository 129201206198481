import { pipe } from "ramda";
import { bindResult, DoResult } from "../../../utils";
import { AxiosError } from "axios";
import { getProject, getProjectConfig, getVacancies } from "../../service/apiService";
import { apiGet, AsyncResult } from "../../../../components/common/infractructure";
import {
    Employee,
    filterByPendingCondition,
    getSpecializationFromAccount,
    SpecializationAccount,
    Vacancy,
} from "../../types";
import { uniqueByProperty } from "../components/formUtils";

export const loadData = async (projectId: string) =>
    pipe(
        DoResult<AxiosError>,
        bindResult("config", await getProjectConfig(apiGet)()),
        bindResult("project", await getProject(apiGet, projectId)()),
        bindResult("vacancies", await getVacancies(apiGet, projectId)()),
    )();

export type DataLoaderResult = PromiseValue<ReturnType<typeof loadData>>;
export type ShowProjectData = ResultValue<DataLoaderResult> & {
    fetchActiveUsers: () => AsyncResult<Employee[]>;
};

export const getFormerTeamVacancies = (currentTeam: Employee[], vacancies: Vacancy[]) => {
    const ids = new Set(currentTeam.map(e => e.id));
    return vacancies.filter(v => !ids.has(v.employee.id));
};

const OTHER_SPECIALIZATION = "OTHER";
export interface EmployeeGroup {
    account: SpecializationAccount;
    employee: Employee;
}

export const getEmployeeGroups = (vacancies: Vacancy[]): EmployeeGroup[] =>
    uniqueByProperty(
        vacancies.map(v => {
            const account = v.account.map(a => getSpecializationFromAccount(a)).unwrapOr(OTHER_SPECIALIZATION);
            const employee = v.employee;
            return { account, employee };
        }),
        e => e.employee.id,
    );

export const getGroups = (employeeGroups: EmployeeGroup[]) =>
    uniqueByProperty(
        employeeGroups.map(ft => ft.account),
        ft => ft,
    ).sort();

export const getActiveVacancies = (vacancies: Vacancy[]) =>
    vacancies.filter(v => filterByPendingCondition(v.end, new Date(), true));

export const getProjectData = (vacancies: Vacancy[]) => {
    const activeVacancies = getActiveVacancies(vacancies);
    const currentTeam = getEmployeeGroups(activeVacancies);
    const currentTeamGroups = getGroups(currentTeam);
    const oldVacancies = getFormerTeamVacancies(
        currentTeam.map(e => e.employee),
        vacancies,
    );
    const formerTeam = getEmployeeGroups(oldVacancies);
    const formerTeamGroups = getGroups(formerTeam);

    const hasFormerTeam = formerTeam.length > 0;

    return {
        activeVacancies,
        currentTeam,
        currentTeamGroups,
        oldVacancies,
        formerTeam,
        formerTeamGroups,
        hasFormerTeam,
    };
};
