import { Button, Grid, Paper, Typography } from "@mui/material";
import { toast } from "react-hot-toast";
import { AsyncResult } from "components/common/infractructure";
import { doIfPresent } from "components/common/utils";
import { formatForView } from "components/common/utils/numberUtils";
import { MONEY_TRANSFER_FAILED, MONEY_TRANSFER_SUCCEDED } from "modules/AccountingManagement/messages";
import {
    contractorDataToPaymentRecipient,
    pendingPaymentToMoneyTransferEntry,
} from "modules/AccountingManagement/service/moneyTransferService";
import { CollapsibleTableWrapper, RowData } from "modules/AccountingManagement/views/Payments/CollapsibleTableWrapper";
import { MoneyTransferForm } from "modules/AccountingManagement/views/Payments/MoneyTransferForm";
import { MoneyTransferRequest, PendingPayment, PendingPaymentChangeRequestParams } from "modules/types/types";
import { Maybe } from "true-myth";
import { TableActionIconButton } from "../../../../components/common/ui-kit/components/Table";
import { Remove, Visibility } from "@mui/icons-material";

interface Props {
    yearMonth: string;
    pendingPayments: PendingPayment[];
    setPendingPayments: (pendingPayments: PendingPayment[]) => void;
    handleSubmitPendingPayment: (pendingPaymentChangeRequest: PendingPaymentChangeRequestParams) => Promise<void>;
    sendMoneyTransferRequest: (moneyTransferRequest: MoneyTransferRequest) => AsyncResult<string[]>;
}

export const Transfers = ({
    yearMonth,
    pendingPayments,
    setPendingPayments,
    handleSubmitPendingPayment,
    sendMoneyTransferRequest,
}: Props) => {
    const openPreviewPage = (id: number) => window.open(`invoice-preview/${id}`);
    const pendingPaymentsToFulfill = pendingPayments.filter(p => p.sendTransfer);

    const onSubmitMoneyTransfer = async (moneyTransferRequest: MoneyTransferRequest) => {
        await toast.promise(sendMoneyTransferRequest(moneyTransferRequest), {
            error: MONEY_TRANSFER_FAILED,
            loading: "Wysyłanie przelewów...",
            success: MONEY_TRANSFER_SUCCEDED,
        });
    };

    const handleRemoveAllPayments = () => {
        setPendingPayments(
            pendingPayments.map(pp => {
                pp.sendTransfer = false;
                return pp;
            }),
        );
    };

    const handleRemovePaymentFromMoneyTransfer = (paymentId: number) => {
        doIfPresent(Maybe.of(pendingPayments.filter(p => p.id === paymentId)[0]), payment => {
            payment.sendTransfer = false;
            setPendingPayments([...pendingPayments]);
        });
    };

    const paymentToFulfillToRowData = (
        pendingPayments: PendingPayment[],
        onClick: (ppId: number) => void,
    ): RowData[] => {
        return pendingPayments.map((pp, idx) => {
            const moneyTransferEntry = pendingPaymentToMoneyTransferEntry(pp);
            const handleClickPreview = () => openPreviewPage(pp.id);
            const handleClickRemove = () => onClick(pp.id);
            return {
                sourceDocumentId: pp.sourceDocumentId,
                invoiceNumber: pp.invoiceNumber,
                invoiceIssueDate: pp.invoiceIssueDate,
                paymentId: pp.id,
                cols: [
                    ++idx,
                    contractorDataToPaymentRecipient(pp.contractorData),
                    moneyTransferEntry.title,
                    moneyTransferEntry.bankAccount,
                    formatForView(moneyTransferEntry.amount),
                    <div key={`wrap-${pp.id}`} style={{ minWidth: "100px" }}>
                        <TableActionIconButton tooltip="Pokaż fakture" onClick={handleClickPreview} Icon={Visibility} />
                        <TableActionIconButton tooltip="Usuń z koszyka" onClick={handleClickRemove} Icon={Remove} />
                    </div>,
                ],
            };
        });
    };

    const moneyTransferEntries = pendingPaymentsToFulfill.map(pendingPaymentToMoneyTransferEntry);

    return (
        <>
            <Typography variant="h4">Paczka przelewów</Typography>
            <Grid item xs={12} sx={{ m: "1rem 0" }}>
                <CollapsibleTableWrapper
                    onSubmitPendingPayment={handleSubmitPendingPayment}
                    rowData={paymentToFulfillToRowData(pendingPaymentsToFulfill, handleRemovePaymentFromMoneyTransfer)}
                    colDefs={["L.p.", "Odbiorca", "Tytuł", "Nr konta", "Kwota", "Akcja"]}
                />
                <div>
                    <Button
                        onClick={handleRemoveAllPayments}
                        variant="contained"
                        color="secondary"
                        sx={{ m: "1rem 0" }}
                    >
                        Usuń wszystkie
                    </Button>
                </div>
            </Grid>
            <Typography variant="h4">Podsumowanie</Typography>
            <Grid item xs={12} sx={{ m: "1rem 0" }}>
                <Paper sx={{ p: "1rem" }}>
                    <MoneyTransferForm
                        yearMonth={yearMonth}
                        onSubmit={onSubmitMoneyTransfer}
                        entries={moneyTransferEntries}
                    />
                </Paper>
            </Grid>
        </>
    );
};
