import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import TimelineItem from "@mui/lab/TimelineItem";
import { Maybe } from "true-myth";
import {
    getDepartmentTranslation,
    getSpecializationTranslation,
    toDateString,
} from "../../../../components/common/types";
import { AllInclusive, ArrowDropUp } from "@mui/icons-material";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import React from "react";
import { PositionAssignment } from "../../types";

interface Props {
    positions: PositionAssignment[];
}

export const EmployeePositionsList: React.FC<Props> = (props: Props) => {
    return (
        <List>
            {props.positions.map(v => (
                <ListItem key={v.id} sx={{ paddingLeft: 0 }}>
                    {
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.09,
                                },
                                margin: 0,
                                paddingLeft: 0,
                            }}
                        >
                            <TimelineItem key={`timeline-${v.id}`}>
                                <TimelineOppositeContent
                                    sx={{
                                        m: "0 0",
                                        paddingLeft: 0,
                                    }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            height: "100%",
                                        }}
                                    >
                                        {Maybe.of(v.endDate)
                                            .map(d => new Date(d))
                                            .map(toDateString)
                                            .map(d => <Box key={`${v.id}-vacancy-end-date`}>{d}</Box>)
                                            .unwrapOr(
                                                <Box>
                                                    <AllInclusive />
                                                </Box>,
                                            )}
                                        <Box>
                                            <ArrowDropUp />
                                        </Box>
                                        <Box>{toDateString(new Date(v.startDate))}</Box>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {Maybe.of(v.endDate).isJust() ? <TimelineDot color={"primary"} /> : <></>}
                                    <TimelineConnector
                                        sx={{
                                            backgroundColor: "primary.main",
                                        }}
                                    />
                                    <TimelineDot color={"primary"} />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: "12px", px: 2 }}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="h6" component="span">
                                                <span> {getDepartmentTranslation(v.department)}</span>
                                                {": "}
                                                {Maybe.of(v.specialization)
                                                    .map(s => getSpecializationTranslation(s))
                                                    .unwrapOr("")}{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs></Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Box display="flex">
                                                <Typography>
                                                    {v.level != null ? `Poziom ${v.level}` : "Brak poziomu"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    }
                </ListItem>
            ))}
        </List>
    );
};
