import { Paper } from "@mui/material";
import Loader from "components/Loader/Loader";
import { useData } from "components/common/hooks/useData";
import { AsyncResult, apiGet, apiPost } from "components/common/infractructure";
import { YearMonth } from "components/common/types";
import { createYearMonthFromString } from "components/common/utils";
import {
    GlobalState,
    MONTHLY_SETTLEMENT_FORM,
    MonthlySettlementSummary,
    PENDING_PAYMENT_FORM,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import {
    getExchangeRateForDate,
    savePendingPayment,
    uploadInvoice,
} from "modules/Invoices/views/MonthlySettlement/service/apiService";
import { PendingPaymentFormValues, Summary } from "modules/Invoices/views/MonthlySettlement/view/Summary/Summary";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { initialize } from "redux-form";
import { Maybe } from "true-myth";

interface Props {
    getFormSummary: ({ month, year }: YearMonth) => AsyncResult<MonthlySettlementSummary>;
}

export const SummaryWrapper: React.FC<Props> = ({ getFormSummary }) => {
    const dispatch = useDispatch();
    const initializePendingPaymentForm = (formValues: PendingPaymentFormValues) =>
        dispatch(initialize(PENDING_PAYMENT_FORM, formValues));
    const { year, month } = useParams<{ year: string; month: string }>();
    const isMonthlySettlementFormSubmitting = useSelector<GlobalState, boolean>(
        state => state.form[MONTHLY_SETTLEMENT_FORM]?.submitting ?? false,
    );
    const selectedYearMonth: YearMonth = Maybe.all(Maybe.of(year), Maybe.of(month))
        .flatMap(([yearValue, monthValue]) => createYearMonthFromString(yearValue, monthValue))
        .unwrapOr({ year: 0, month: 1 });

    const [rateValue, setRateValue] = useState<string>("");

    const initializeForm = (summary: MonthlySettlementSummary) => {
        const { invoiceAttachment, pendingPaymentData } = summary;
        const { contractorData, invoiceNumber, invoiceIssueDate } = pendingPaymentData;

        initializePendingPaymentForm({
            contractorData,
            invoiceNumber,
            invoiceIssueDate,
            invoiceAttachment,
        });
    };

    const {
        data: summary,
        setData: setSummary,
        isLoading: isSummaryLoading,
        error: summaryError,
        loadData: loadSummaryData,
    } = useData<MonthlySettlementSummary>({
        loader: () => getFormSummary(selectedYearMonth),
        onDataLoaded: (summary: MonthlySettlementSummary) => {
            Maybe.head(summary.pendingPaymentData.positions.filter(({ isMain }) => isMain)).match({
                Just: s => setRateValue(`${s.unitPriceInPLN}`),
                Nothing: () => setRateValue("0"),
            });

            initializeForm(summary);
        },
    });

    useEffect(() => {
        // Wait for the monthly settlement form to be successfully submitted before loading summary data.
        // The summary data is calculated based on the positions from monthly settlement, loading it too early
        // would end up in a race condition where we might end up fetching summary data that is out of date.
        if (summary.isNothing() && !isMonthlySettlementFormSubmitting) {
            loadSummaryData();
        }
    }, [summary, isMonthlySettlementFormSubmitting]);

    const isLoading = isMonthlySettlementFormSubmitting || isSummaryLoading;

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading &&
                summary
                    .map(summaryValue => (
                        <Summary
                            key={`${selectedYearMonth.year}/${selectedYearMonth.month}`}
                            summary={summaryValue}
                            setSummary={setSummary}
                            rateValue={rateValue}
                            setRateValue={setRateValue}
                            getExchangeRateForDate={getExchangeRateForDate(apiGet)}
                            uploadInvoice={uploadInvoice(apiPost)}
                            savePendingPayment={savePendingPayment(apiPost)}
                        />
                    ))
                    .unwrapOr(<></>)}
            {!isLoading &&
                summaryError.map(({ message }) => <Paper key={message}>Error: {message}</Paper>).unwrapOr(<></>)}
        </>
    );
};
