import * as React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import { InvoiceDetail } from "./InvoiceDetail";
import { PendingPayment } from "modules/types/types";
import { apiGet } from "components/common/infractructure";
import { InvoiceFile } from "components/common/ui-kit/components/InvoiceFile/InvoiceFile";
import { getGDInvoiceFile } from "components/common/ui-kit-business/InvoiceSummary/service/summaryService";

interface Props {
    pendingPayment: PendingPayment;
}

export const InvoicePreview: React.FC<Props> = ({ pendingPayment }) => {
    return pendingPayment.sourceDocumentId
        .map(docId => {
            return (
                <Box key={docId}>
                    <Grid container sx={{ marginBottom: ".5rem", pt: "1rem" }}>
                        <Typography variant="h6">Faktura</Typography>
                    </Grid>
                    <Box>
                        <InvoiceDetail pendingPayment={pendingPayment} />
                    </Box>
                    <Box sx={{ mb: "0.75rem", pt: "1rem" }}>
                        <Typography variant="h6">Załącznik</Typography>
                    </Box>
                    <Grid container spacing={1} justifyContent={"center"}>
                        <Grid item>
                            <Paper sx={{ backgroundColor: "white" }}>
                                <InvoiceFile
                                    getGDInvoiceFile={getGDInvoiceFile(apiGet)}
                                    pendingPayment={pendingPayment}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            );
        })
        .unwrapOr(<></>);
};
