import { Button, Grid, Typography } from "@mui/material";
import { doIfPresent } from "components/common/utils";
import { BookPaymentsRequest, PendingPayment } from "modules/types/types";
import { Maybe } from "true-myth";
import { localizationMaterialTable } from "components/common/localization/localization";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import { Column } from "@material-table/core";
import { Table, TableActionIconButton } from "components/common/ui-kit/components/Table";
import { Remove, Visibility } from "@mui/icons-material";

type RowData = Pick<PendingPayment, "totalGross"> & {
    paymentId: number;
    companyName: string;
    invoiceNumber: string;
    hasError: boolean;
};

const columns = (): Column<RowData>[] => [
    { title: "L.p", field: "idx" },
    { title: "Nazwa firmy", field: "companyName" },
    { title: "Nr faktury", field: "invoiceNumber" },
    { title: "Kwota brutto", field: "totalGross" },
    { title: "Akcja", field: "actions" },
];

interface Props {
    pendingPayments: PendingPayment[];
    setPendingPayments: (pendingPayments: PendingPayment[]) => void;
    sendBookPaymentsRequest: (bookPaymentsRequest: BookPaymentsRequest) => void;
}

export const PaymentsToBookList = ({ pendingPayments, setPendingPayments, sendBookPaymentsRequest }: Props) => {
    const pendingPaymentsToBook = pendingPayments.filter(p => p.sendToBook);
    const openPreviewPage = (id: number) => window.open(`/accounting/invoice-preview/${id}`);

    const handleRemoveAllPayments = () => {
        setPendingPayments(
            pendingPayments.map(pp => {
                pp.sendToBook = false;
                pp.failedBooking = false;
                return pp;
            }),
        );
    };

    const handleRemovePaymentFromToBookBasket = (paymentId: number) => {
        doIfPresent(Maybe.of(pendingPayments.filter(p => p.id === paymentId)[0]), payment => {
            payment.sendToBook = false;
            setPendingPayments([...pendingPayments]);
        });
    };

    const toRowData = (pendingPayments: PendingPayment[], onClick: (ppId: number) => void): RowData[] => {
        return pendingPayments.map((pp, idx) => {
            const handleClickRemove = () => onClick(pp.id);
            const handleClickPreview = () => openPreviewPage(pp.id);

            return {
                idx: ++idx,
                hasError: pp.failedBooking,
                companyName: pp.contractorData.companyName,
                sourceDocumentId: pp.sourceDocumentId,
                invoiceNumber: pp.invoiceNumber.unwrapOr("N/A"),
                invoiceIssueDate: pp.invoiceIssueDate,
                paymentId: pp.id,
                totalGross: pp.totalGross,
                actions: (
                    <div key={`wrap-${pp.id}`} style={{ minWidth: "50px" }}>
                        <TableActionIconButton tooltip="Pokaż fakture" onClick={handleClickPreview} Icon={Visibility} />
                        <TableActionIconButton tooltip="Usuń z koszyka" onClick={handleClickRemove} Icon={Remove} />
                    </div>
                ),
            };
        });
    };

    const handleSubmit = () => {
        sendBookPaymentsRequest({
            ids: pendingPaymentsToBook.map(it => it.id.toString()),
        });
    };

    return (
        <>
            <Typography variant="h4">Płatności do zaksięgowania</Typography>
            <Grid item xs={12} sx={{ m: "1rem 0" }}>
                <Table<RowData>
                    key={0}
                    options={{
                        rowStyle: rowData => {
                            return rowData.hasError ? { color: "red", fontWeight: "bold" } : {};
                        },
                        showTitle: false,
                        search: false,
                        selection: false,
                        tableLayout: "fixed",
                        showTextRowsSelected: false,
                        paging: false,
                        toolbar: false,
                        padding: "dense",
                        headerStyle: boldHeaderStyle(),
                    }}
                    columns={columns()}
                    data={toRowData(pendingPaymentsToBook, handleRemovePaymentFromToBookBasket)}
                    localization={localizationMaterialTable}
                />
                <Grid container sx={{ m: "1rem 0" }}>
                    <Grid item sx={{ marginRight: "20px" }}>
                        <Button onClick={handleRemoveAllPayments} variant="contained" color="secondary">
                            Usuń wszystkie
                        </Button>
                    </Grid>
                    <Grid item sx={{ marginRight: "20px" }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {`Księguj (${pendingPaymentsToBook.length})`}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
