import { Grid, Paper } from "@mui/material";
import {
    MISSING_DEPARTMENT_LABEL,
    MISSING_SPECIALIZATION_LABEL,
    getDepartmentTranslation,
    getSpecializationTranslation,
    toDateString,
} from "components/common/types";
import { EmploymentData } from "modules/UserProfile/types";
import * as React from "react";
import { DetailRow } from "components/common/ui-kit-business/InvoiceSummary/DetailRow";

interface Props {
    employmentData: EmploymentData;
}

export const EmploymentInfo: React.FC<Props> = ({
    employmentData: { department, specialization, rate, level, dateHired },
}) => (
    <Paper data-testid="EmploymentInfo">
        <Grid container sx={{ padding: "1rem 1.5rem" }}>
            <Grid item xs={6}>
                <DetailRow
                    label="Cech"
                    text={department.map(getDepartmentTranslation).unwrapOr(MISSING_DEPARTMENT_LABEL)}
                />
                <DetailRow
                    label="Stanowisko"
                    text={specialization.map(getSpecializationTranslation).unwrapOr(MISSING_SPECIALIZATION_LABEL)}
                />
                <DetailRow label="Data zatrudnienia" text={toDateString(dateHired)} />
            </Grid>
            <Grid item xs={6}>
                <DetailRow label="Aktualna stawka" text={rate.toString()} />
                {level.map(level => <DetailRow label="Poziom" text={level} key={level} />).unwrapOr(<></>)}
            </Grid>
        </Grid>
    </Paper>
);
