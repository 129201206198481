import { OperationType } from "../../types/types";

export const getDocumentTypeTranslation = (type: OperationType): string => {
    switch (type) {
        case "SALES":
            return "Sprzedaż";
        case "SALES_EMPLOYEE":
            return "Sprzedaż do podwykonawców";
        case "COSTS_EMPLOYEE":
            return "Koszty - podwykonawców";
        case "COSTS_DOMESTIC":
            return "Koszty - krajowe";
        case "COSTS_EU_IMPORT":
            return "Koszty - UE";
        case "COSTS_IMPORT":
            return "Koszy - poza UE";
        case "NON_ACCOUNTING":
            return "Dokument nieksięgowy";
        default:
            return "Dokument nieksięgowy";
    }
};
