import { Grid, Paper, Typography } from "@mui/material";
import { useUserContext } from "components/common/contexts";
import { AsyncResult } from "components/common/infractructure";
import { logError } from "components/common/infractructure/logger";
import { UserProfileData } from "modules/UserProfile/types";
import { FirstTimeLoginCommand, OnboardingPosition, WelcomeData } from "modules/Welcome/types";
import { FirstTimeLoginForm, FirstTimeLoginFormData } from "modules/Welcome/views/components/FirstTimeLoginForm";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { useEffectOnce } from "usehooks-ts";

interface Props {
    getUserProfileData: () => AsyncResult<UserProfileData>;
    fetchWelcomeData: () => AsyncResult<WelcomeData>;
    updateProfile: (data: FirstTimeLoginCommand) => AsyncResult<void>;
}

interface State {
    positions: OnboardingPosition[];
    firstName: Maybe<string>;
    isLoading: boolean;
}

export const Welcome: React.FC<Props> = ({ updateProfile, fetchWelcomeData, getUserProfileData }) => {
    const { userProfileData, setUserProfileData } = useUserContext();
    const navigate = useNavigate();
    const [state, setState] = useState<State>({
        positions: [],
        firstName: Maybe.just(userProfileData.user.firstName),
        isLoading: true,
    });

    useEffect(() => {
        if (!userProfileData.user.needsOnboarding) {
            navigate("/");
        }
    }, [userProfileData.user.needsOnboarding]);

    useEffectOnce(() => {
        const fetchData = async () => {
            const welcomeData: Result<WelcomeData, Error> = await fetchWelcomeData();
            welcomeData.match({
                Ok: ({ positions }) => setState(state => ({ ...state, positions, isLoading: false })),
                Err: err => setState(state => ({ ...state, error: Maybe.just(err) })),
            });
        };
        fetchData().catch(logError);
    });

    const submitFirstTimeLoginCommand = async (command: FirstTimeLoginCommand) => {
        setState(state => ({ ...state, isLoading: true }));
        (await updateProfile(command)).match({
            Ok: () => {
                console.log("submitted profile data!");
                setState(state => ({ ...state, isLoading: false }));
            },
            Err: error => {
                logError(`Error occured while submitting form in Welcome Component: ${error.message}`);
                toast.error(`Wystąpił błąd podczas aktualizacji profilu: ${error.message}`);
                setState(state => ({ ...state, isLoading: false }));
            },
        });
    };

    const updateToLatestProfile = async (): Promise<void> => {
        setState(state => ({ ...state, isLoading: true }));
        (await getUserProfileData()).match({
            Ok: data => {
                setState(state => ({ ...state, isLoading: false }));
                setUserProfileData(data);
                navigate("/");
            },
            Err: error => {
                logError(`Error occurred while fetching latest user profile data: ${error.message}`);
                toast.error(`Wystąpił błąd podczas pobierania informacji o profilu. ${error.message}`);
                setState(state => ({ ...state, isLoading: false }));
            },
        });
    };

    const handleSubmit = async ({ hiringDate, onboardingPositionId, level, rate }: FirstTimeLoginFormData) => {
        const command: FirstTimeLoginCommand = {
            dateHired: hiringDate,
            onboardingPositionId: onboardingPositionId,
            level: level,
            initialRate: rate,
        };
        await submitFirstTimeLoginCommand(command);
        updateToLatestProfile();
    };

    const { positions, firstName, isLoading } = state;

    return firstName
        .map(firstNameValue => {
            return (
                <Grid container spacing={3} key={0}>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Witaj na pokładzie, <b>{firstNameValue}</b>!
                        </Typography>
                        <Typography variant="h6">Zanim zaczniemy, musimy ustalić kilka szczegółów.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                padding: "1rem",
                            }}
                        >
                            {!state.isLoading && state.positions.length > 0 ? (
                                <FirstTimeLoginForm
                                    onSubmit={handleSubmit}
                                    availablePositions={positions}
                                    isLoading={isLoading}
                                />
                            ) : (
                                <></>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            );
        })
        .unwrapOr(<></>);
};
