import {
    MilitaryTech,
    AccountBalance,
    AccountBox,
    AccountTree,
    AttachMoney,
    Payments,
    Description,
    Checklist,
    Logout,
    Groups,
} from "@mui/icons-material";
import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, SxProps, Theme } from "@mui/material";
import { useUserContext } from "components/common/contexts";
import { isAllowed, Role, ROLES_ALL, ROLES_PROJECTS_VIEW } from "components/RBAC/RBACutils";
import { PARAM_PROJECT_TYPE, PARAM_SHOW_PENDING_ONLY } from "modules/Projects/views/ProjectsList/ProjectsList";
import { Link, useLocation } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";

interface BaseNavItem {
    id: string;
    title: string;
    allowedRoles: Role[];
}

interface NavigableNavItem extends BaseNavItem {
    href: string;
}

interface NestedView {
    display: string;
    href: string;
    icon: JSX.Element;
    allowedRoles: Role[];
}

interface WithNestedViewsNavItem extends BaseNavItem {
    nestedViews: NestedView[];
}

type NavItem = NavigableNavItem | WithNestedViewsNavItem;

const isWithNestedViewsNavItem = (navItem: NavItem): navItem is WithNestedViewsNavItem => "nestedViews" in navItem;

const navItems: NavItem[] = [
    {
        title: "Pracownik",
        id: "invoices",
        allowedRoles: ROLES_ALL,
        nestedViews: [
            { display: "Moje rozliczenia", href: "/invoices", allowedRoles: ROLES_ALL, icon: <AccountBox /> },
            { display: "Zgłoś badge'a", href: "/badge/register", allowedRoles: ROLES_ALL, icon: <MilitaryTech /> },
            { display: "Lista pracowników", href: "/employees", allowedRoles: ROLES_ALL, icon: <Groups /> },
        ],
    },
    {
        title: "Sprzedaż",
        id: "sales",
        nestedViews: [
            { display: "Faktury sprzedażowe", href: "/sales", allowedRoles: ROLES_ALL, icon: <AttachMoney /> },
            { display: "Oczekujące faktury", href: "/billing/all", allowedRoles: ROLES_ALL, icon: <Checklist /> },
        ],
        allowedRoles: ROLES_ALL,
    },
    {
        title: "Projekty",
        id: "projects",
        allowedRoles: ROLES_PROJECTS_VIEW,
        nestedViews: [
            {
                display: "Lista projektów",
                href: `/projects/list?${PARAM_SHOW_PENDING_ONLY}=true&${PARAM_PROJECT_TYPE}=external`,
                allowedRoles: ROLES_PROJECTS_VIEW,
                icon: <AccountTree />,
            },
        ],
    },
    {
        title: "Księgowość",
        id: "accounting",
        nestedViews: [
            { display: "Płatności", href: "/accounting/payments", allowedRoles: ROLES_ALL, icon: <Payments /> },
            { display: "Dokumenty", href: "/accounting/docs", allowedRoles: ROLES_ALL, icon: <Description /> },
        ],
        allowedRoles: ROLES_ALL,
    },
    {
        title: "Bank",
        id: "bank",
        href: "/bank/transactions",
        allowedRoles: ROLES_ALL,
        nestedViews: [
            {
                display: "Lista transakcji",
                href: "/bank/transactions",
                allowedRoles: ROLES_ALL,
                icon: <AccountBalance />,
            },
        ],
    },
];

const filterNavItemsByRoles = (userRoles: Role[], items: NavItem[]): NavItem[] =>
    items
        .filter(({ allowedRoles }) => isAllowed(userRoles, allowedRoles))
        .map(navItem => ({
            ...navItem,
            ...(isWithNestedViewsNavItem(navItem)
                ? {
                      nestedViews: navItem.nestedViews.filter(({ allowedRoles }) => isAllowed(userRoles, allowedRoles)),
                  }
                : {}),
        }));

const userLink: SxProps<Theme> = {
    color: "primary.light",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
};

const highlight: SxProps<Theme> = {
    color: "#fff",
};

const selection: SxProps<Theme> = {
    bgcolor: "navigate.active",
};

const navLink: SxProps<Theme> = {
    padding: "0 1.5rem",
    width: "100%",
    fontWeight: 300,
    marginTop: "1rem",
    cursor: "inherit",
    "&:hover": {
        bgcolor: "transparent",
    },
};

const subLink: SxProps<Theme> = {
    width: "100%",
    padding: "0 1.5rem",
    "&:hover": {
        bgcolor: "custom.hover",
    },
};

const subLinkSelected: SxProps<Theme> = {
    ...subLink,
    ...highlight,
    ...selection,
};

const icons: SxProps<Theme> = {
    minWidth: "1.8rem",
    paddingRight: "1rem",
    svg: {
        fontSize: "1.2rem",
    },
};

interface NavListItemsProps {
    navItem: WithNestedViewsNavItem;
}

const sticky: SxProps<Theme> = {
    flexDirection: "column",
    flexShrink: 0,
    paddingBottom: "0.75rem",
};

export const NavListItems = ({ navItem }: NavListItemsProps) => {
    const { pathname } = useLocation();

    return (
        <Box key={navItem.title}>
            <ListItem sx={navLink}>
                <ListItemText>{navItem.title}</ListItemText>
            </ListItem>
            {navItem.nestedViews.map(({ display, href, icon }) => (
                <ListItem
                    key={display}
                    button
                    component={Link}
                    sx={pathname.startsWith(href.split("?")[0]) ? subLinkSelected : subLink}
                    to={href}
                >
                    <ListItemIcon sx={icons}>{icon}</ListItemIcon>
                    <ListItemText>{display}</ListItemText>
                </ListItem>
            ))}
        </Box>
    );
};

interface Navigator {
    user?: User;
}

export default function Navigator({ user }: Navigator) {
    const {
        userProfileData: {
            user: { roles },
        },
    } = useUserContext();

    const { logout } = useAuth0();
    const onLogout = () => logout({ returnTo: process.env.REACT_APP_URL });

    return (
        <Box
            sx={{
                gridArea: "nav",
                height: "100vh",
                background: "url(/images/navigation-background.jpg) no-repeat center center",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                color: "primary.light",
                svg: {
                    color: "primary.light",
                },
            }}
        >
            <Box
                sx={{
                    padding: "0 0.5rem",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "solid",
                    backgroundColor: "#2B303D",
                    borderBottomColor: "navigate.divider",
                    textAlign: "center",
                }}
            >
                <Link to="/">
                    <img src="/images/finanse-logo.svg" alt="finanse-logo" height="80px" />
                </Link>
            </Box>
            <List
                sx={{
                    flex: "1 0 auto",
                    backgroundColor: "navigate.main",
                    backdropFilter: "blur(10px)",
                }}
            >
                {filterNavItemsByRoles(roles, navItems).map(
                    navItem =>
                        !!isWithNestedViewsNavItem(navItem) && <NavListItems navItem={navItem} key={navItem.title} />,
                )}
            </List>

            <Box sx={sticky}>
                <Button component={Link} to={"/profile"} sx={userLink}>
                    {!!user && (
                        <>
                            <Avatar
                                src={user.picture}
                                alt={user.name}
                                sx={{ width: 36, height: 36, display: "block", mr: "1rem" }}
                            />
                            <Box>{user.name}</Box>
                        </>
                    )}
                </Button>
                <Button onClick={onLogout} sx={userLink}>
                    <Logout sx={{ mr: "1.75rem" }} />
                    <Box>Logout</Box>
                </Button>
            </Box>
        </Box>
    );
}
