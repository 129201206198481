import { yupResolver } from "@hookform/resolvers/yup";
import WarningRounded from "@mui/icons-material/WarningRounded";
import Save from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    IconButton,
    InputAdornment,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { Nullable, toDateString } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { noop } from "components/common/utils";
import { Dayjs } from "dayjs";
import {
    ProjectConfig,
    VacancyFormData,
    vacancyFormDataSchema,
    ProjectDetails,
    specializationAccountLabel,
    formatEmployeeLabelWithDetails,
    Employee,
    getSpecializationFromAccount,
    specializationAccount,
} from "modules/Projects/types";
import { Mode, filterEmployees } from "modules/Projects/views/components/formUtils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Maybe } from "true-myth";
import red from "@mui/material/colors/red";
import { AttachMoneyOutlined, AccountCircle, ComputerOutlined } from "@mui/icons-material";
import { EmployeeAvatar } from "./EmployeeAvatar";

const nonInternalProjectFormLayout: SxProps<Theme> = {
    gridTemplateAreas: `
        "employeeId employeeId" 
        "start end"
        "rateDivider rateDivider"
        "rate rate"
        "provision discount"
        "descriptionDivider descriptionDivider"
        "roleId fullTimeEquivalent"
        "description description"  
        "account account"
        "action action"
    `,
};

const internalProjectFormLayout: SxProps<Theme> = {
    gridTemplateAreas: `
        "employeeId employeeId" 
        "start end"
        "descriptionDivider descriptionDivider"
        "roleId fullTimeEquivalent"
        "description description"  
        "account account"
        "action action"
    `,
};

const saveButtonLabelsDict: Record<Extract<Mode, "EDIT" | "CREATE">, string> = {
    CREATE: "Dodaj",
    EDIT: "Aktualizuj",
};

interface Props {
    project: ProjectDetails;
    projectConfig: ProjectConfig;
    vacancyFormData: VacancyFormData;
    isInternal: boolean;
    isVacancyEditable?: boolean;
    onSubmit: (formData: VacancyFormData) => void;
    onCancel?: () => void;
    onRemove?: () => void;
    mode: Mode;
}

export const VacancyForm: React.FC<Props> = ({
    project,
    projectConfig,
    vacancyFormData,
    isInternal,
    isVacancyEditable = true,
    mode,
    onSubmit,
    onCancel = noop,
    onRemove = noop,
}) => {
    const {
        control,
        formState: { errors, isValid, isDirty, isSubmitting, isSubmitSuccessful, touchedFields },
        setValue,
        setError,
        handleSubmit,
        clearErrors,
        reset,
    } = useForm<VacancyFormData>({
        defaultValues: vacancyFormData,
        resolver: yupResolver(vacancyFormDataSchema),
        mode: "all",
    });

    const isShowMode = mode === "SHOW";

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful, reset]);

    const nonInternalFieldsStyle = isInternal ? { display: "none" } : {};

    const onDateChange = (fieldName: "start" | "end") => (pickedDate: Nullable<Dayjs>) =>
        Maybe.of(pickedDate).match({
            Just: date => {
                try {
                    const dateString = toDateString(date.toDate());
                    setValue(fieldName, dateString, { shouldDirty: true, shouldValidate: true });
                    clearErrors(fieldName);
                } catch (error) {
                    setError(fieldName, { type: "custom", message: "invalid date" });
                }
            },
            Nothing: () => setValue(fieldName, null, { shouldDirty: true, shouldValidate: true }),
        });

    const onEmployeeChange = (employee: Maybe<Employee>) =>
        employee.match({
            Just: e => {
                e.specialization.match({
                    Just: specialization => {
                        try {
                            const account = `501-XXXX-${specializationAccount[specialization]}`;
                            setValue("account", account, { shouldDirty: true, shouldValidate: true });
                            clearErrors("account");
                        } catch (error) {
                            setError("account", { type: "custom", message: "invalid account" });
                        }
                    },
                    Nothing: () => setValue("account", "", { shouldDirty: true, shouldValidate: true }),
                });
            },
            Nothing: () => setValue("account", "", { shouldDirty: true, shouldValidate: true }),
        });

    const findEmployeeById = (employeeId: number | null): Maybe<Employee> => {
        if (!employeeId) {
            return Maybe.nothing();
        }
        const employee = projectConfig.employees.find(e => e.id == employeeId);
        return employee ? Maybe.of(employee) : Maybe.nothing();
    };

    const [isRemoveAttempt, setIsRemoveAttempt] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState<Maybe<Employee>>(
        findEmployeeById(vacancyFormData.employeeId),
    );

    const onTriggerRemoveAttempt = () => setIsRemoveAttempt(true);
    const onCancelRemoveAttempt = () => setIsRemoveAttempt(false);

    return (
        <Box
            sx={{
                padding: "1rem",
                marginBottom: "3rem",
                backgroundColor: isRemoveAttempt ? red["50"] : "white",
            }}
        >
            <form name="project form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: "grid",
                        gap: "1rem",
                        gridTemplateColumns: "2fr 2fr",
                        ...(isInternal ? internalProjectFormLayout : nonInternalProjectFormLayout),
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "flex-end", gridArea: "employeeId" }}>
                        {selectedEmployee
                            .map(e => <EmployeeAvatar key={`employee-avatar-${e.id}`} employee={e} />)
                            .unwrapOr(
                                <AccountCircle sx={{ color: "action.active", mr: 1, my: 0 }} fontSize={"large"} />,
                            )}
                        <Controller
                            name="employeeId"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    sx={{ width: "100%", ml: 1 }}
                                    getOptionLabel={option =>
                                        Maybe.fromNullable(
                                            filterEmployees(projectConfig.employees, mode).find(
                                                employee => employee.id === option,
                                            ),
                                        )
                                            .map(formatEmployeeLabelWithDetails)
                                            .unwrapOr("")
                                    }
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                        const employee = findEmployeeById(value);
                                        onEmployeeChange(employee);
                                        setSelectedEmployee(employee);
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant="standard" label="Pracownik" />
                                    )}
                                    options={filterEmployees(projectConfig.employees, mode).map(
                                        employee => employee.id,
                                    )}
                                    size="small"
                                    disabled={isShowMode || !isVacancyEditable}
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        name="start"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data rozpoczęcia",
                                    onChange: onDateChange("start"),
                                    disabled: isShowMode,
                                }}
                                textField={{
                                    sx: {
                                        gridArea: "start",
                                    },
                                    error: !!errors.start,
                                    size: "small",
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="end"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data zakończenia",
                                    onChange: onDateChange("end"),
                                    disabled: isShowMode,
                                }}
                                textField={{
                                    sx: {
                                        gridArea: "end",
                                    },
                                    error: !!errors.end,
                                    size: "small",
                                }}
                            />
                        )}
                    />
                    {!isInternal && (
                        <>
                            <Box
                                color={"primary"}
                                sx={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    alignItems: "center",
                                    gridArea: "rateDivider",
                                    mt: "2.25rem",
                                }}
                            >
                                <AttachMoneyOutlined />
                                <Typography variant="h3">Stawka</Typography>
                            </Box>
                            <Controller
                                name="rate"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {project.currency.unwrapOr("")}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            gridArea: "rate",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        type="number"
                                        label="Stawka"
                                        error={!!errors.rate}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="discount"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        inputProps={{ min: 0, max: 100, style: { textAlign: "right" } }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        sx={{
                                            gridArea: "discount",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        type="number"
                                        label="Rabat"
                                        error={!!errors.discount}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                            <Controller
                                name="provision"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        {...field}
                                        inputProps={{ min: 0, max: 100, style: { textAlign: "right" } }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        sx={{
                                            gridArea: "provision",
                                            ...nonInternalFieldsStyle,
                                        }}
                                        type="number"
                                        label="Prowizja pośrednika"
                                        error={!!errors.provision}
                                        size="small"
                                        disabled={isShowMode}
                                    />
                                )}
                            />
                        </>
                    )}
                    <Box
                        color={"primary"}
                        sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            gridArea: "descriptionDivider",
                            mt: "2.25rem",
                        }}
                    >
                        <ComputerOutlined />
                        <Typography variant="h3">Stanowisko</Typography>
                    </Box>
                    <Controller
                        name="roleId"
                        control={control}
                        render={({ field }) => (
                            <GenericSelect
                                {...field}
                                sx={{
                                    gridArea: "roleId",
                                }}
                                label="Stanowisko"
                                values={projectConfig.projectRoles.map(({ id, name }) => ({
                                    value: id,
                                    display: name,
                                }))}
                                size="small"
                                disabled={isShowMode || !isVacancyEditable}
                            />
                        )}
                    />
                    <Controller
                        name="fullTimeEquivalent"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                {...field}
                                inputProps={{ min: 0, max: 100, style: { textAlign: "right" } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    gridArea: "fullTimeEquivalent",
                                }}
                                type="number"
                                label="FTE"
                                error={!!errors.fullTimeEquivalent}
                                size="small"
                                disabled={isShowMode}
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                {...field}
                                sx={{
                                    gridArea: "description",
                                }}
                                label="Opis stanowiska"
                                size="small"
                                disabled={isShowMode}
                            />
                        )}
                    />
                    <Controller
                        name="account"
                        control={control}
                        render={params => (
                            <TextField
                                variant="standard"
                                {...params.field}
                                InputProps={{
                                    endAdornment: params.field.value && (
                                        <InputAdornment position="end">
                                            {Maybe.fromNullable(params.field.value)
                                                .map(a => getSpecializationFromAccount(a))
                                                .map(a => specializationAccountLabel[a])
                                                .map(a => (
                                                    <Chip
                                                        key={`account-chip-${a}`}
                                                        sx={{ mb: "1rem" }}
                                                        label={a}
                                                        variant="outlined"
                                                    />
                                                ))
                                                .unwrapOr("")}
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    gridArea: "account",
                                }}
                                label="Konto księgowe"
                                size="small"
                                disabled={isShowMode || !isVacancyEditable}
                                error={touchedFields.account && !!errors.account}
                            />
                        )}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            gridArea: "action",
                            justifyItems: "flex-end",
                            alignItems: "center",
                            alignContent: "center",
                            gap: "1rem",
                        }}
                    >
                        {!isShowMode && (
                            <>
                                {isRemoveAttempt ? (
                                    <>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            disabled={isSubmitting}
                                            onClick={onCancelRemoveAttempt}
                                        >
                                            Anuluj
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            onClick={onRemove}
                                        >
                                            <WarningRounded /> Usuń
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {mode === "CREATE" || (mode === "EDIT" && isDirty) ? (
                                            <>
                                                <Tooltip title="Porzuć zmiany bez zapisywania">
                                                    <Box>
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                            disabled={isSubmitting}
                                                            onClick={mode === "CREATE" ? onCancel : () => reset()}
                                                        >
                                                            Anuluj
                                                        </Button>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title={saveButtonLabelsDict[mode]}>
                                                    <Box>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={!isDirty || !isValid || isSubmitting}
                                                        >
                                                            <Save /> Zapisz
                                                        </Button>
                                                    </Box>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                {isVacancyEditable ? (
                                                    <Tooltip title="Usuń etat">
                                                        <Box>
                                                            <IconButton
                                                                aria-label="delete"
                                                                disabled={isSubmitting}
                                                                onClick={onTriggerRemoveAttempt}
                                                                sx={{
                                                                    ":hover": {
                                                                        backgroundColor: red["50"],
                                                                        color: red["900"],
                                                                    },
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </form>
            {/* uncomment for debugging purposes */}
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
            {/* <pre>{isValid ? "valid" : "invalid"}</pre> */}
        </Box>
    );
};
