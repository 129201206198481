import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import BigNumber from "bignumber.js";

interface Props {
    amount: BigNumber;
    leftAmount: BigNumber;
    currency: string;
}

export const WireTransferAmountValueCell: React.FC<Props> = (props: Props) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Tooltip title={"Całkowita kwota przelewu"}>
                <div>{formatMoneyForView(props.amount.toNumber(), props.currency)}</div>
            </Tooltip>

            {props.leftAmount.toNumber() != 0 && (
                <Tooltip title={"Kwota przelewu która nie została do niczego przypisana"}>
                    <Typography variant={"inherit"} sx={{ color: "custom.subtitle" }}>
                        {formatMoneyForView(props.leftAmount.toNumber(), props.currency)}
                    </Typography>
                </Tooltip>
            )}
        </Box>
    );
};
