import { Dayjs } from "dayjs";
import { isEmpty, isNil } from "ramda";

export const fieldNotEmpty = (val: unknown): string | undefined =>
    isNil(val) || isEmpty(val) ? "Wymagane pole" : undefined;

export const dateValidator = (date: string | Dayjs): string | undefined =>
    typeof date === "string" ? undefined : date.isValid() ? undefined : "Niepoprawna data";

export const numberFieldGreaterThanOrEqualZero = (value: number): string | undefined =>
    value < 0 ? "Wartość musi być większa od 0" : undefined;
