import { Maybe, Result } from "true-myth";

type SomeRecord = Record<string, unknown>;
type AnyKeyExcept<ReservedValues extends string | number | symbol, T extends string | number | symbol> = T &
    (T extends ReservedValues ? never : T);

type FnOrResult<T, E, I> = ((input: I) => Result<T, E>) | Result<T, E>;
// eslint-disable-next-line @typescript-eslint/ban-types
export function bindResult<K extends string, T = unknown, E = string, R extends SomeRecord = {}>(
    key: AnyKeyExcept<keyof R, K>,
    fnOrRes: FnOrResult<T, E, R>,
) {
    return (inputResult: Result<R, E>) =>
        inputResult.flatMap(inputData => {
            const res1 = typeof fnOrRes === "function" ? fnOrRes(inputData) : fnOrRes;
            return res1.map(
                data =>
                    ({
                        ...inputData,
                        [key]: data,
                    } as R & {
                        [key in K]: T;
                    }),
            );
        });
}

type FnOrMaybe<T, I> = ((input: I) => Maybe<T>) | Maybe<T>;
// eslint-disable-next-line @typescript-eslint/ban-types
export function bindMaybe<K extends string, T = unknown, R extends SomeRecord = {}>(
    key: AnyKeyExcept<keyof R, K>,
    fnOrMaybe: FnOrMaybe<T, R>,
) {
    return (inputMaybe: Maybe<R>) =>
        inputMaybe.flatMap(inputData => {
            const maybe1 = typeof fnOrMaybe === "function" ? fnOrMaybe(inputData) : fnOrMaybe;
            return maybe1.map(
                data =>
                    ({
                        ...inputData,
                        [key]: data,
                    } as R & {
                        [key in K]: T;
                    }),
            );
        });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DoResult = <E = string>() => Result.ok<{}, E>({});

// eslint-disable-next-line @typescript-eslint/ban-types
export const DoMaybe = () => Maybe.just<{}>({});
