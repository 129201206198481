import { Done } from "@mui/icons-material";
import { localizationMaterialTable } from "components/common/localization/localization";
import { formatMoneyForView } from "components/common/utils/numberUtils";
import { BillingFilterSelectValues, BillingOverview, getBillingStatusFilterTranslations } from "modules/Billing/types";
import * as React from "react";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import { Table } from "components/common/ui-kit/components/Table";
import { Column } from "@material-table/core";
import { EmptyListInfo } from "../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";

interface PendingBillingsTable {
    billingType: BillingFilterSelectValues;
    pendingBillings: BillingOverview[];
    onBillingSelected: (billingData: BillingOverview) => void;
}

type RowData = BillingOverview & {
    id: number;
    total: string;
    readyComponent: JSX.Element;
    acceptedComponent: JSX.Element;
    status: string;
};

type Props = PendingBillingsTable;

const getBillingStatus = (billing: BillingOverview): BillingFilterSelectValues => {
    if (billing.booked) {
        return "BOOKED";
    } else if (billing.done) {
        return "CANCELED";
    } else {
        return "PENDING";
    }
};

const toRowData = (pendingBillingData: BillingOverview[]): RowData[] => {
    return [
        ...pendingBillingData.map(p => ({
            ...p,
            id: p.billingId,
            total: formatMoneyForView(p.billingTotalNet, p.currency),
            totalInPln: formatMoneyForView(p.billingTotalNetInPln, "PLN"),
            readyComponent: p.ready ? <Done /> : <></>,
            acceptedComponent: p.accepted ? <Done /> : <></>,
            status: getBillingStatusFilterTranslations(getBillingStatus(p)),
        })),
    ];
};

export const PendingBillingsTable = ({ pendingBillings, onBillingSelected, billingType }: Props) => {
    const onRowClick = (_event?: React.MouseEvent, row?: RowData) => {
        if (row) {
            onBillingSelected(row);
        }
    };

    const columns = (billingType: BillingFilterSelectValues): Column<RowData>[] => [
        { title: "Nazwa projektu", field: "projectName", width: "20%" },
        { title: "Miesiąc", field: "billingMonth", width: "15%" },
        { title: "Wartość", field: "total", width: "20%" },
        { title: "Wartość w PLN", field: "totalInPln", width: "20%" },
        { title: "Uzupełnione godziny", field: "readyComponent", width: "25%", hidden: billingType == "BOOKED" },
        { title: "Zaakceptowane", field: "acceptedComponent", width: "20%", hidden: billingType == "BOOKED" },
        { title: "Status", field: "status", width: "20%", hidden: billingType != "ALL" },
    ];

    const table = (
        <Table
            options={{
                showTitle: false,
                search: false,
                selection: false,
                showTextRowsSelected: false,
                paging: pendingBillings.length > 50,
                pageSize: 50,
                toolbar: false,
                padding: "dense",
                rowStyle: {
                    height: "45px",
                },
                tableLayout: "fixed",
                headerStyle: boldHeaderStyle(),
            }}
            onRowClick={onRowClick}
            columns={columns(billingType)}
            data={toRowData(pendingBillings)}
            localization={localizationMaterialTable}
        />
    );

    return pendingBillings.length > 0 ? table : <EmptyListInfo />;
};
