import { Box, Dialog, DialogContent, SxProps, Theme, Typography } from "@mui/material";
import * as React from "react";
import { DocumentDetails, PaymentSearchResult } from "../../types";
import { AsyncResult } from "../../../../components/common/infractructure";
import { PaymentSelection } from "./PaymentSelection";

export interface Props {
    open: boolean;
    onClose: () => void;
    document: DocumentDetails;
    onItemClicked: (item: PaymentSearchResult) => Promise<void>;
    getSearchResult: (query: string) => AsyncResult<PaymentSearchResult[]>;
}

const description: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
};

export const PaymentsSearchDialog: React.FC<Props> = (props: Props) => {
    return (
        <Dialog onClose={props.onClose} open={props.open} maxWidth={"xl"} fullWidth={true}>
            <DialogContent>
                <Box sx={description}>
                    <Typography variant="h4" sx={{ textAlign: "center", fontSize: 24 }}>
                        {props.document.number}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ whiteSpace: "pre", pl: "1rem" }}>
                        {`${props.document.contractor.name} ${props.document.contractor.addressStreet} ${props.document.contractor.addressZipCode} ${props.document.contractor.addressCity} ${props.document.contractor.addressCountry}`}
                    </Typography>
                    <Typography variant="body1">
                        {` ${props.document.summary.totalGross.value} ${
                            props.document.summary.exchangeRate?.currency ?? "PLN"
                        }`}
                    </Typography>
                    <PaymentSelection
                        getSearchResult={props.getSearchResult}
                        onSelected={props.onItemClicked}
                    ></PaymentSelection>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
