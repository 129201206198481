import { Button, Grid, Link, Paper, Typography } from "@mui/material";
import { AsyncResult } from "components/common/infractructure";
import { YearMonth } from "components/common/types";
import { getInvoiceUrl } from "components/common/ui-kit-business/InvoiceSummary/service/summaryService";
import { OnChangeFn } from "components/common/ui-kit/components/GenericSelect";
import { dateToYearMonthString, doIfPresent, strToYearMonth } from "components/common/utils";
import { formatForView } from "components/common/utils/numberUtils";
import Loader from "components/Loader/Loader";
import { filter, isEmpty, pipe } from "ramda";
import {
    INVOICE_EXPORT_FAILED,
    INVOICE_EXPORT_SUCCEDED,
    INVOICES_CANNOT_BE_MOVED,
    INVOICES_EXPORT_PARTIALLY_FAILED,
    MOVING_INVOICES_FAILED,
    MOVING_INVOICES_SUCCEDED,
    PAYMENT_DATA_CHANGE_FAILED,
    PAYMENT_DOWNLOAD_FAILED,
    PAYMENT_SAVED,
    UNLOCK_CONFIRM,
} from "modules/AccountingManagement/messages";
import { EMPTY_SELECT_VALUE } from "modules/AccountingManagement/service/constants";
import {
    getPaymentStatusTranslation,
    pendingPaymentToMoneyTransferEntry,
} from "modules/AccountingManagement/service/moneyTransferService";
import { CollapsibleTableWrapper, RowData } from "modules/AccountingManagement/views/Payments/CollapsibleTableWrapper";
import { PaymentsFilter } from "modules/AccountingManagement/views/Payments/PaymentsFilter";
import { Transfers } from "modules/AccountingManagement/views/Payments/Transfers";
import {
    BookPaymentsRequest,
    BookPaymentsResponse,
    MoneyTransferRequest,
    PendingPayment,
    PendingPaymentChangeRequestParams,
    PendingPaymentStatus,
} from "modules/types/types";
import { mergeRight } from "ramda";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { toast } from "react-hot-toast";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Maybe } from "true-myth";
import { Box } from "@mui/system";
import { filterPanel } from "components/common/ui-kit/styles/utils";
import { ActionContextMenu } from "components/common/ui-kit/components/ActionContextMenu";
import { PaymentsToBookList } from "./PaymentsToBookList";
import { TableActionIconButton } from "../../../../components/common/ui-kit/components/Table";
import { Add, Book, LockOpen } from "@mui/icons-material";
import { subMonths } from "date-fns";

type PendingPaymentStatusSelectValue = PendingPaymentStatus | typeof EMPTY_SELECT_VALUE;

const actionsBox = {
    minWidth: "50px",
};

const filterByCompanyOwner =
    (owner: Maybe<string>) =>
    (payments: PendingPayment[]): PendingPayment[] =>
        owner
            .map(ownerName => payments.filter(({ contractorData: { ownerFullName } }) => ownerName === ownerFullName))
            .unwrapOr(payments);

const filterByPaymentStatus =
    (paymentStatus: Maybe<PendingPaymentStatus>) =>
    (payments: PendingPayment[]): PendingPayment[] =>
        paymentStatus
            .map(selectedStatus => payments.filter(({ status }) => selectedStatus === status))
            .unwrapOr(payments);

const pendingPaymentToRowData =
    (
        unlockInvoiceEdition: (pendingPaymentId: number) => AsyncResult<void>,
        onClick: (pendingPaymentId: number) => void,
        onAddToBook: (pendingPaymentId: number) => void,
    ) =>
    (pendingPayments: PendingPayment[]): RowData[] => {
        const handleUnlock = async (paymentId: number) => {
            if (confirm(UNLOCK_CONFIRM)) {
                await toast.promise(unlockInvoiceEdition(paymentId), {
                    error: "Nie udało się odwiązać faktury",
                    loading: "Odwiązywanie faktury...",
                    success: "Faktura została odwiązana",
                });
            }
        };

        const handleClickAddTooBookBasket = (paymentId: number) => onAddToBook(paymentId);

        const handleAdd = (paymentId: number) => onClick(paymentId);

        return pendingPayments.map(
            (
                {
                    id,
                    sourceDocumentId,
                    invoiceNumber,
                    invoiceIssueDate,
                    contractorData,
                    status,
                    totalGross,
                    failedBooking,
                },
                idx,
            ) => ({
                hasError: failedBooking,
                sourceDocumentId,
                invoiceNumber,
                invoiceIssueDate,
                paymentId: id,
                cols: [
                    ++idx,
                    contractorData.companyName,
                    getPaymentStatusTranslation(status),
                    invoiceNumber
                        .map(no => (
                            <Link
                                key={`invoice-no-${idx}`}
                                component={RouterLink}
                                to={"/accounting/" + getInvoiceUrl(id)}
                                target={"_blank"}
                            >
                                {no}
                            </Link>
                        ))
                        .unwrapOr(<>Brak</>),
                    formatForView(totalGross),

                    <Box key={`wrap-${id}`} sx={actionsBox}>
                        {status !== "WAITING_FOR_PAYMENT" && (
                            <TableActionIconButton
                                tooltip="Odblokuj fakture do edycji"
                                onClick={() => handleUnlock(id)}
                                Icon={LockOpen}
                            />
                        )}
                        {status === "PAYMENT_BOOKED" && (
                            <TableActionIconButton
                                tooltip="Dodaj do koszyka księgowania"
                                onClick={() => handleClickAddTooBookBasket(id)}
                                Icon={Book}
                            />
                        )}
                        {status === "WAITING_FOR_PAYMENT" && (
                            <TableActionIconButton
                                tooltip="Dodaj do koszyka płatności"
                                onClick={() => handleAdd(id)}
                                Icon={Add}
                            />
                        )}
                    </Box>,
                ],
            }),
        );
    };
const hasAttachment = (p: PendingPayment) => p.sourceDocumentId.isJust();
const statusWaitingForPayment = ({ status }: PendingPayment) => status === "WAITING_FOR_PAYMENT";
const statusPaymentBooked = ({ status }: PendingPayment) => status === "PAYMENT_BOOKED";

const initialDate = new Date().getDate() > 25 ? new Date() : subMonths(new Date(), 1);
const currentYearMonth = dateToYearMonthString(initialDate);

interface Props {
    getPendingPayments: (yearMonth: YearMonth) => AsyncResult<PendingPayment[]>;
    unlockInvoiceEdition: (pendingPaymentId: number) => AsyncResult<void>;
    exportPendingPayment: (
        pendingPaymentChangeRequestParams: PendingPaymentChangeRequestParams,
    ) => AsyncResult<PendingPayment>;
    sendMoneyTransferRequest: (moneyTransferRequest: MoneyTransferRequest) => AsyncResult<string[]>;
    updatePendingPayment: (
        pendingPaymentChangeRequest: PendingPaymentChangeRequestParams,
    ) => AsyncResult<PendingPayment>;
    moveInvoicesToBooked: (paymentIds: number[]) => AsyncResult<void>;
    sendBookInvoicesRequest: (bookInvoicesRequest: BookPaymentsRequest) => AsyncResult<BookPaymentsResponse>;
}

const PARAM_YEAR_MONTH = "yearMonth";
const PARAM_COMPANY_OWNER = "companyOwner";
const PARAM_PAYMENT_STATUS = "paymentStatus";

export const AccountingView: React.FC<Props> = ({
    getPendingPayments,
    unlockInvoiceEdition,
    sendMoneyTransferRequest,
    sendBookInvoicesRequest,
    updatePendingPayment,
    moveInvoicesToBooked,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [yearMonth, setYearMonth] = useState<Maybe<string>>(
        Maybe.of(searchParams.get(PARAM_YEAR_MONTH) || currentYearMonth),
    );

    const [companyOwner, setCompanyOwner] = useState<Maybe<string>>(() => {
        const value = searchParams.get(PARAM_COMPANY_OWNER) || EMPTY_SELECT_VALUE;
        return value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value);
    });

    const [paymentStatus, setPaymentStatus] = useState<Maybe<PendingPaymentStatus>>(() => {
        const value = searchParams.get(PARAM_PAYMENT_STATUS) || EMPTY_SELECT_VALUE;
        return value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value as PendingPaymentStatus);
    });

    const [companyOwners, setCompanyOwners] = useState<string[]>([]);
    const [pendingPayments, setPendingPayments] = useState<PendingPayment[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const pendingPaymentsToFulfill = pendingPayments.filter(({ sendTransfer }) => sendTransfer);
    const pendingPaymentsToBook = pendingPayments.filter(({ sendToBook }) => sendToBook);

    const paymentsThatCanBeAddedToFulfill = pendingPayments.filter(statusWaitingForPayment);
    const paymentsThatCanBeAddedToBookBasket = pendingPayments.filter(statusPaymentBooked);

    const dateYM = yearMonth.flatMap(strToYearMonth).unwrapOrElse(() => {
        throw new Error("Invalid Date");
    });

    const getPayments = async () => {
        setIsLoading(true);
        (await getPendingPayments(dateYM)).match({
            Ok: pendingPayments => {
                setPendingPayments(pendingPayments);
                const owners = pendingPayments.map(({ contractorData }) => contractorData.ownerFullName);
                owners.sort((e1, e2) => e1.localeCompare(e2));
                setCompanyOwners(owners);

                const currentCompanyOwnerFilter = companyOwner.flatMap(currentOwner =>
                    owners.includes(currentOwner) ? Maybe.of(currentOwner) : Maybe.nothing<string>(),
                );
                setCompanyOwner(currentCompanyOwnerFilter);
                currentCompanyOwnerFilter.match({
                    Just: owner =>
                        setSearchParams(params => {
                            params.set(PARAM_COMPANY_OWNER, owner);
                            return params;
                        }),
                    Nothing: () =>
                        setSearchParams(params => {
                            params.delete(PARAM_COMPANY_OWNER);
                            return params;
                        }),
                });
            },
            Err: () => {
                toast.error(PAYMENT_DOWNLOAD_FAILED);
            },
        });
        setIsLoading(false);
    };

    useEffect(() => {
        setSearchParams(params => {
            params.set(PARAM_YEAR_MONTH, yearMonth.unwrapOr(currentYearMonth));
            return params;
        });
        getPayments();
    }, [yearMonth]);

    const handleAddPaymentToMoneyTransfer = useCallback(
        (paymentId: number) =>
            setPendingPayments(
                pendingPayments.map(pendingPayment => ({
                    ...pendingPayment,
                    sendTransfer: pendingPayment.id === paymentId || pendingPayment.sendTransfer,
                })),
            ),
        [pendingPayments],
    );

    const handleSubmitPendingPayment = useCallback(
        async (pendingPaymentChangeRequest: PendingPaymentChangeRequestParams) => {
            setIsLoading(true);
            (await updatePendingPayment(pendingPaymentChangeRequest)).match({
                Ok: (pendingPayment: PendingPayment) => {
                    doIfPresent(Maybe.of(pendingPayments.findIndex(({ id }) => id === pendingPayment.id)), idx =>
                        setPendingPayments(
                            Object.assign([...pendingPayments], {
                                [idx]: mergeRight(pendingPayments[idx], pendingPayment),
                            }),
                        ),
                    );
                    toast.success(PAYMENT_SAVED);
                },
                Err: () => toast.error(PAYMENT_DATA_CHANGE_FAILED),
            });
            setIsLoading(false);
        },
        [updatePendingPayment, pendingPayments],
    );
    const removeAllPayments = () => {
        setPendingPayments(
            pendingPayments.map(pp => {
                pp.sendTransfer = false;
                pp.sendToBook = false;
                pp.failedBooking = false;
                return pp;
            }),
        );
    };

    const handleChangePaymentStatus: OnChangeFn = ({ target }, _child) => {
        const value = (target.value as PendingPaymentStatusSelectValue) || EMPTY_SELECT_VALUE;
        setSearchParams(params => {
            if (value === EMPTY_SELECT_VALUE) {
                params.delete(PARAM_PAYMENT_STATUS);
            } else {
                params.set(PARAM_PAYMENT_STATUS, value);
            }
            return params;
        });
        removeAllPayments();
        setPaymentStatus(value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value));
    };

    const handleChangeCompanyOwner: OnChangeFn = ({ target }, _child) => {
        const value = (target.value as string) || EMPTY_SELECT_VALUE;
        setSearchParams(params => {
            if (value === EMPTY_SELECT_VALUE) {
                params.delete(PARAM_COMPANY_OWNER);
            } else {
                params.set(PARAM_COMPANY_OWNER, value);
            }
            return params;
        });
        setCompanyOwner(value === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(value));
    };

    const handleChangeYearMonth = (date: Date) => setYearMonth(Maybe.of(date).map(dateToYearMonthString));

    const handleAddAllPayment = () =>
        setPendingPayments(
            pendingPayments.map(pendingPayment =>
                statusWaitingForPayment(pendingPayment)
                    ? {
                          ...pendingPayment,
                          sendTransfer: true,
                      }
                    : pendingPayment,
            ),
        );

    const handleAddInvoiceToBookBasket = useCallback(
        (paymentId: number) =>
            setPendingPayments(
                pendingPayments.map(pendingPayment => ({
                    ...pendingPayment,
                    sendToBook: pendingPayment.id === paymentId || pendingPayment.sendToBook,
                })),
            ),
        [pendingPayments],
    );
    const handleAddAllToBookBasket = () =>
        setPendingPayments(
            pendingPayments.map(pendingPayment =>
                statusPaymentBooked(pendingPayment)
                    ? {
                          ...pendingPayment,
                          sendToBook: true,
                      }
                    : pendingPayment,
            ),
        );

    const handleMoveInvoicesToBooked = async () => {
        const paymentsWithInvoices = pendingPayments.filter(p => hasAttachment(p) && !statusWaitingForPayment(p));
        if (isEmpty(paymentsWithInvoices)) {
            toast.error(INVOICES_CANNOT_BE_MOVED);
            return;
        }

        (await moveInvoicesToBooked(paymentsWithInvoices.map(p => p.id))).match({
            Ok: () => toast.success(MOVING_INVOICES_SUCCEDED),
            Err: () => toast.error(MOVING_INVOICES_FAILED),
        });
    };

    const moneyTransferEntries = pendingPaymentsToFulfill.map(pendingPaymentToMoneyTransferEntry);

    const rowData = useMemo(
        () =>
            pipe(
                filterByCompanyOwner(companyOwner),
                filterByPaymentStatus(paymentStatus),
                filter<PendingPayment>(({ sendTransfer, sendToBook }) => sendTransfer !== true && !sendToBook),
                pendingPaymentToRowData(
                    unlockInvoiceEdition,
                    handleAddPaymentToMoneyTransfer,
                    handleAddInvoiceToBookBasket,
                ),
            )(pendingPayments),
        [
            pendingPayments,
            companyOwner,
            paymentStatus,
            unlockInvoiceEdition,
            handleAddPaymentToMoneyTransfer,
            handleAddInvoiceToBookBasket,
        ],
    );

    const onSendMoney = React.useCallback(
        async (moneyTransferRequest: MoneyTransferRequest) => {
            const result = await sendMoneyTransferRequest(moneyTransferRequest);
            if (result.isOk()) {
                await getPayments();
            }
            return result;
        },
        [sendMoneyTransferRequest],
    );

    function processBookOperationResponse(bookingResponse: BookPaymentsResponse): PendingPayment[] {
        return pendingPayments
            .map(pp =>
                bookingResponse.successIds.includes(pp.id)
                    ? {
                          ...pp,
                          failedBooking: false,
                          sendToBook: false,
                          status: "INVOICE_BOOKED" as PendingPaymentStatus,
                      }
                    : pp,
            )
            .map(pp => (bookingResponse.failureIds.includes(pp.id) ? { ...pp, failedBooking: true } : pp));
    }

    const handleBookInTaxxo = async (pendingPaymentChangeRequest: BookPaymentsRequest) => {
        setIsLoading(true);
        (await sendBookInvoicesRequest(pendingPaymentChangeRequest)).match({
            Ok: (bookingResponse: BookPaymentsResponse) => {
                if (bookingResponse.failureIds.length == 0) {
                    toast.success(INVOICE_EXPORT_SUCCEDED);
                }
                if (bookingResponse.successIds.length == 0) {
                    toast.error(INVOICE_EXPORT_FAILED);
                } else {
                    toast.error(INVOICES_EXPORT_PARTIALLY_FAILED);
                }
                setPendingPayments(processBookOperationResponse(bookingResponse));
            },
            Err: () => toast.error(INVOICE_EXPORT_FAILED),
        });
        setIsLoading(false);
    };

    return (
        <Grid container>
            <Typography variant="h2">Zarządzanie płatnościami</Typography>
            <Grid item xs={12}>
                <Paper sx={filterPanel}>
                    <PaymentsFilter
                        companyOwner={companyOwner}
                        companyOwners={companyOwners}
                        yearMonth={yearMonth.unwrapOr("")}
                        paymentStatus={paymentStatus}
                        onChangeYearMonth={handleChangeYearMonth}
                        onChangeCompanyOwner={handleChangeCompanyOwner}
                        onChangePaymentStatus={handleChangePaymentStatus}
                    />
                </Paper>
            </Grid>

            <Grid container item xs={6}>
                <Typography variant="h5">Płatności</Typography>
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
                <ActionContextMenu
                    items={[
                        {
                            name: "Przenieś załączniki do zaksięgowanych",
                            onClick: handleMoveInvoicesToBooked,
                        },
                    ]}
                />
            </Grid>

            <Grid item xs={12} sx={{ m: "1rem 0" }}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <CollapsibleTableWrapper
                            onSubmitPendingPayment={handleSubmitPendingPayment}
                            rowData={rowData}
                            colDefs={["L.p.", "Nazwa firmy", "" + "Status płatności", "Nr faktury", "Kwota", "Akcja"]}
                        />
                        <Grid container sx={{ m: "1rem 0" }}>
                            <Grid item sx={{ marginRight: "20px" }}>
                                <Button
                                    onClick={handleAddAllPayment}
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !(
                                            paymentStatus
                                                .map(status => status == "WAITING_FOR_PAYMENT")
                                                .unwrapOr(true) &&
                                            paymentsThatCanBeAddedToFulfill.length > 0 &&
                                            pendingPaymentsToBook.length == 0
                                        )
                                    }
                                >
                                    Dodaj wszystkie do koszyka płatności ({paymentsThatCanBeAddedToFulfill.length})
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleAddAllToBookBasket}
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !(
                                            paymentStatus.map(status => status == "PAYMENT_BOOKED").unwrapOr(true) &&
                                            paymentsThatCanBeAddedToBookBasket.length > 0 &&
                                            moneyTransferEntries.length == 0
                                        )
                                    }
                                >
                                    Dodaj wszystkie do księgowania ({paymentsThatCanBeAddedToBookBasket.length})
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            {moneyTransferEntries.length > 0 && (
                <Transfers
                    yearMonth={yearMonth.unwrapOr("unknown")}
                    pendingPayments={pendingPayments}
                    setPendingPayments={setPendingPayments}
                    handleSubmitPendingPayment={handleSubmitPendingPayment}
                    sendMoneyTransferRequest={onSendMoney}
                />
            )}
            {pendingPaymentsToBook.length > 0 && (
                <PaymentsToBookList
                    pendingPayments={pendingPayments}
                    setPendingPayments={setPendingPayments}
                    sendBookPaymentsRequest={handleBookInTaxxo}
                />
            )}
        </Grid>
    );
};
