import { Box, Grid, Paper, Typography } from "@mui/material";
import {
    ContractorData,
    EligibleToInvoice,
    EligibleToInvoiceRequestData,
    MonthlySettlementSummary,
    PENDING_PAYMENT_FORM,
    PendingPayment,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import { WrappedDatePicker } from "modules/Invoices/views/MonthlySettlement/view/form-fields/DatePicker";
import { WrappedTextField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/WrappedTextField";
import { pipe } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Result } from "true-myth";

import { LoadingButton } from "@mui/lab";
import { AsyncResult } from "components/common/infractructure";
import { CostsSummaryTable } from "components/common/ui-kit-business/CostsSummaryTable/CostsSummaryTable";
import { getNonVatPayerData } from "components/common/ui-kit-business/CostsSummaryTable/utils";
import { TAX_RATE } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { calculateTax, plus } from "components/common/utils/numberUtils";
import { getPendingPaymentPreviewAddress } from "modules/Invoices/views/MonthlySettlement/service/summaryService";
import { ContractorDataView } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/ContractorDataView";
import { InvoiceSummaryTable } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/InvoiceSummaryTable";
import { InvoiceUploadArea } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/InvoiceUploadArea";
import { WorkSummaryTable } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/WorkSummaryTable";
import { dateValidator, fieldNotEmpty } from "modules/Invoices/views/MonthlySettlement/view/form-fields/fieldHelpers";
import {
    InvoiceExistsWarning,
    NegativeInvoiceTotalWarning,
} from "modules/Invoices/views/MonthlySettlement/view/warnings/Warnings";
import EligibilityWarning from "./components/EligibilityWarning";
import * as helpers from "./summaryViewHelpers";
import BigNumber from "bignumber.js";

export type PendingPaymentFormValues = Pick<PendingPayment, "totalGross" | "invoiceNumber" | "invoiceIssueDate">;

interface OwnProps {
    monthlySettlementSummary: MonthlySettlementSummary;
    contractorData: ContractorData;
    isPendingPaymentFormValid: boolean;
    onSubmitPendingPaymentForm: () => void;
    onInvoiceSubmit: (files: File) => void;
    handleInvoiceRemove: () => void;
    onUnitPriceChange: (value: string) => void;
    rateValue: string;
    invoiceIssueDate: string;
    isLoading: boolean;
    verifyIsEligibleToInvoice: (requestData: EligibleToInvoiceRequestData) => AsyncResult<EligibleToInvoice>;
}

type Props = OwnProps & InjectedFormProps<PendingPaymentFormValues, OwnProps>;

const createReduxForm = reduxForm<PendingPaymentFormValues, OwnProps, string>({
    form: PENDING_PAYMENT_FORM,
});

const SummaryViewImpl = ({
    monthlySettlementSummary,
    isPendingPaymentFormValid,
    onSubmitPendingPaymentForm,
    onInvoiceSubmit,
    contractorData,
    handleInvoiceRemove,
    onUnitPriceChange,
    rateValue,
    invoiceIssueDate,
    isLoading,
    verifyIsEligibleToInvoice,
}: Props) => {
    const [eligibleToInvoice, setEligibleToInvoice] = useState<Result<EligibleToInvoice, Error>>(
        Result.err(new Error("Eligibility not loaded yet")),
    );
    const costsSummaryTableRowData = helpers.pendingPaymentPositionToCostsSummaryTableRowData(
        helpers.filterCostPositions(monthlySettlementSummary.pendingPaymentData.positions),
    );
    const workSummaryTableRowData = helpers.pendingPaymentPositionToWorkSummaryTableRowData(
        helpers.filterWorkPositions(monthlySettlementSummary.pendingPaymentData.positions),
    );
    const { isMonthlyCharged, isVatPayer, isInvoiceAttached, accountNumber, nip } = contractorData;

    const taxRate = isVatPayer ? TAX_RATE : 0;

    const getInvoiceMainPositionData = useCallback(() => {
        return helpers.pendingPaymentPositionToWorkSummaryTableRowData(
            helpers.filterMainPositionCost(monthlySettlementSummary.pendingPaymentData.positions),
        );
    }, [monthlySettlementSummary]);

    const getInvoiceMainPositionNet = pipe(getInvoiceMainPositionData, helpers.getTotalNetAmount);

    const invoiceMainPositionNet = getInvoiceMainPositionNet();
    const invoiceMainPositionTax = calculateTax(invoiceMainPositionNet, taxRate);
    const invoiceMainPositionGross = useMemo(
        () => plus(invoiceMainPositionNet, invoiceMainPositionTax.toNumber()),
        [invoiceMainPositionNet, invoiceMainPositionTax],
    );

    const getInvoiceAdditionalPayPositionData = useCallback(() => {
        return helpers.pendingPaymentPositionToCostsSummaryTableRowData(
            helpers.filterAdditionalPayPositionCost(monthlySettlementSummary.pendingPaymentData.positions),
        );
    }, [monthlySettlementSummary]);

    const getInvoiceAdditionalPayPositionNet = pipe(getInvoiceAdditionalPayPositionData, helpers.getTotalNetAmount);

    const invoiceAdditionalPayPositionNet = getInvoiceAdditionalPayPositionNet();
    const invoiceAdditionalPayPositionTax = calculateTax(invoiceAdditionalPayPositionNet, taxRate);
    const invoiceAdditionalPayPositionGross = useMemo(
        () => invoiceAdditionalPayPositionTax.plus(invoiceAdditionalPayPositionNet),
        [invoiceAdditionalPayPositionNet, invoiceAdditionalPayPositionTax],
    );

    const workTotalNet = helpers.getTotalNetAmount(workSummaryTableRowData);
    const workTotalTax = calculateTax(workTotalNet, taxRate);
    const workTotalGross = workTotalTax.plus(workTotalNet);
    const workTotalAmountOfDays = helpers.getTotalAmountOfDays(workSummaryTableRowData);

    const costsTotalNet = helpers.getTotalNetAmount(costsSummaryTableRowData);
    const costsTotalTax = calculateTax(costsTotalNet, taxRate);
    const costsTotalGross = costsTotalTax.plus(costsTotalNet);

    const getMonthlyAmountOfDays = monthlySettlementSummary.workingDays - workTotalAmountOfDays;

    const shouldCheckEligibility = isVatPayer && isInvoiceAttached;
    const isEligibleToInvoice = eligibleToInvoice.map(e => e.isEligible).unwrapOr(!shouldCheckEligibility);
    const isInvoiceTotalNegative = 0 > workTotalGross.plus(costsTotalGross).toNumber();

    const loadEligibility = useCallback(async () => {
        if (shouldCheckEligibility && invoiceIssueDate) {
            const eligibility = await verifyIsEligibleToInvoice({
                accountNumber,
                invoiceIssueDate,
                nip,
                paysVat: isVatPayer,
            });
            setEligibleToInvoice(eligibility);
        } else {
            setEligibleToInvoice(Result.ok({ message: "", isEligible: true }));
        }
    }, [shouldCheckEligibility, invoiceIssueDate]);

    useEffect(() => {
        loadEligibility();
    }, [contractorData, invoiceIssueDate, accountNumber]);

    const invoiceExistsWarning = () => {
        const ref = getPendingPaymentPreviewAddress(monthlySettlementSummary);
        return monthlySettlementSummary.pendingPaymentData.id && <InvoiceExistsWarning link={ref} />;
    };
    const totalNet = plus(invoiceMainPositionNet, invoiceAdditionalPayPositionNet).toNumber();
    const totalTax = calculateTax(totalNet, isVatPayer ? TAX_RATE : 0).toNumber();

    const getInvoiceSummaryTableRowData = useCallback(() => {
        return [
            {
                unit: "usługa",
                quantity: 1,
                unitPrice: invoiceMainPositionNet + invoiceAdditionalPayPositionNet,
                invoicePosition: "Pozycja główna",
                totalGross: new BigNumber(totalNet).plus(totalTax).toNumber(),
                totalNet: totalNet,
                totalTax: totalTax,
                currency: monthlySettlementSummary.pendingPaymentData.contractorData.currency.name,
            },
        ];
    }, [
        invoiceAdditionalPayPositionNet,
        invoiceMainPositionNet,
        invoiceMainPositionGross,
        invoiceMainPositionTax,
        costsSummaryTableRowData,
        invoiceAdditionalPayPositionGross,
        invoiceAdditionalPayPositionTax,
    ]);

    return (
        <Grid container direction={"column"} spacing={2} data-testid="Summary/summary-view">
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                    Dane
                </Typography>
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                    }}
                >
                    <ContractorDataView
                        rateValue={rateValue}
                        onUnitPriceChange={onUnitPriceChange}
                        contractorData={contractorData}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                    Szczegóły
                </Typography>

                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                    }}
                >
                    <Typography variant="h6">Podsumowanie dniówek</Typography>
                    <WorkSummaryTable
                        isVatPayer={isVatPayer}
                        rowData={workSummaryTableRowData}
                        sumNet={workTotalNet}
                        sumTax={workTotalTax.toNumber()}
                        sumGross={workTotalGross.toNumber()}
                        sumDays={workTotalAmountOfDays}
                        notVatPayerData={getNonVatPayerData}
                        isMonthlyRate={contractorData.isMonthlyCharged}
                        getMonthlyRateData={helpers.getMonthlyRateData}
                    />
                    <Box display="flex" sx={{ justifyContent: "flex-end" }}>
                        <Typography sx={{ marginTop: "1rem" }}>
                            Dni urlopu w tym miesiącu: <strong>{getMonthlyAmountOfDays}</strong>
                        </Typography>
                    </Box>

                    <Typography variant="h6">Podsumowanie kosztów</Typography>
                    <CostsSummaryTable
                        isVatPayer={isVatPayer}
                        rowData={costsSummaryTableRowData}
                        sumNet={costsTotalNet}
                        sumTax={costsTotalTax.toNumber()}
                        sumGross={costsTotalGross.toNumber()}
                        nonVatPayerData={getNonVatPayerData}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" sx={{ padding: "1rem 0" }}>
                    Podsumowanie faktury
                </Typography>
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {isInvoiceTotalNegative ? (
                        <NegativeInvoiceTotalWarning />
                    ) : (
                        <>
                            <InvoiceSummaryTable
                                isVatPayer={isVatPayer}
                                isMonthlyCharged={isMonthlyCharged}
                                notVatPayerData={getNonVatPayerData}
                                rowData={getInvoiceSummaryTableRowData()}
                                currency={monthlySettlementSummary.pendingPaymentData.contractorData.currency.name}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
            {isInvoiceAttached && (
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ padding: "1rem 0" }}>
                        Faktura
                    </Typography>
                    <Paper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "1rem",
                        }}
                    >
                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <WrappedTextField
                                        key={0}
                                        name={"invoiceNumber"}
                                        textFieldProps={{
                                            type: "text",
                                            label: "Numer faktury",
                                            fullWidth: true,
                                            required: true,
                                        }}
                                        validate={[fieldNotEmpty]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <WrappedDatePicker
                                        key={1}
                                        name={"invoiceIssueDate"}
                                        label="Data wystawienia faktury"
                                        disableFuture
                                        required
                                        validate={[fieldNotEmpty, dateValidator]}
                                        storeFormat="yyyy-MM-dd"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <InvoiceUploadArea
                            name="invoiceAttachment"
                            validate={[fieldNotEmpty]}
                            invoiceUploadProps={{
                                monthlySettlementSummary,
                                onInvoiceSubmit,
                                onRemoveInvoice: handleInvoiceRemove,
                            }}
                        />
                    </Paper>
                </Grid>
            )}
            <Grid item xs={12}>
                {invoiceExistsWarning()}
            </Grid>
            <Grid item xs={12}>
                <EligibilityWarning eligibleToInvoice={eligibleToInvoice} />
            </Grid>

            <Grid item xs={12}>
                <Box mt={3} mb={3} width={1}>
                    <LoadingButton
                        sx={{ display: "block", m: "auto", width: "8rem" }}
                        type="button"
                        color="primary"
                        variant="contained"
                        title="Zapisz"
                        onClick={onSubmitPendingPaymentForm}
                        disabled={!isPendingPaymentFormValid || !isEligibleToInvoice || isInvoiceTotalNegative}
                        loading={isLoading}
                    >
                        Wyślij
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export const SummaryView = createReduxForm(SummaryViewImpl);
