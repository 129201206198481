import { TextField, TextFieldProps } from "@mui/material";
import { omit } from "ramda";
import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface TextFieldWrapperProps extends WrappedFieldProps {
    textFieldProps: TextFieldProps;
}

const TextFieldWrapper: React.FC<TextFieldWrapperProps> = ({ textFieldProps, input, meta: { touched, error } }) => (
    <TextField {...textFieldProps} error={touched && !!error} {...input} variant="standard" />
);

export interface WrappedTextFieldProps extends Partial<BaseFieldProps<TextFieldProps>> {
    name: string;
    sx?: SxProps<Theme>;
    textFieldProps: TextFieldProps;
}
export const WrappedTextField: React.FC<WrappedTextFieldProps> = React.memo(props => (
    <Field
        name={props.name}
        sx={props.sx}
        component={TextFieldWrapper}
        props={{ textFieldProps: props.textFieldProps }}
        {...(omit(["textFieldProps", "name", "component"], props) as Omit<
            WrappedTextFieldProps,
            "textFieldProps" | "name" | "component"
        >)}
    />
));
