import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";

interface GenericActivityFormProps {
    title: string;
    instructions: string;
}

export const GenericActivityForm: React.FC<React.PropsWithChildren<GenericActivityFormProps>> = ({
    title,
    instructions,
    children,
}) => (
    <Box
        sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: "divider",
        }}
    >
        <Grid container spacing={2} direction={"column"}>
            <Grid item>
                <Typography
                    sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                    }}
                >
                    {title}
                </Typography>
                {instructions && (
                    <Typography
                        sx={{
                            paddingLeft: "5px",
                        }}
                        variant="caption"
                        color="primary.inform"
                    >
                        {instructions}
                    </Typography>
                )}
            </Grid>
            <Grid item>
                <div>{children}</div>
            </Grid>
        </Grid>
    </Box>
);
