import { AvatarGroup } from "@mui/material";
import { Employee } from "modules/Projects/types";
import { EmployeeAvatar } from "modules/Projects/views/components/EmployeeAvatar";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface Props {
    team: Employee[];
    max?: number;
    sx?: SxProps<Theme>;
}

export const ProjectTeamAvatars: React.FC<Props> = ({ team, max, sx }) => {
    const avatars = team.map((e, index) => <EmployeeAvatar key={`${e.id}+${index}`} employee={e} />);
    return team.length > 0 ? (
        <AvatarGroup max={max ?? 3} sx={{ ...sx, display: "inline-flex" }}>
            {avatars}
        </AvatarGroup>
    ) : (
        <></>
    );
};
