import { Nullable, YearMonth } from "components/common/types";
import { always } from "ramda";
import { Maybe } from "true-myth";
import { parseNumber } from "./numberUtils";

export const parseMonth = (value: number): Maybe<YearMonth["month"]> =>
    value >= 1 && value <= 12 ? Maybe.of(value as YearMonth["month"]) : Maybe.nothing();

const createYearMonth =
    (year: number) =>
    (month: YearMonth["month"]): YearMonth => ({ year, month });

export const createYearMonthFromString = (year: string, month: string): Maybe<YearMonth> => {
    const parsedYear = Maybe.of(year).flatMap(parseNumber);
    const parsedMonth = Maybe.of(month).flatMap(parseNumber).flatMap(parseMonth);
    return Maybe.of(createYearMonth).ap(parsedYear).ap(parsedMonth);
};

export const strToYearMonth = (str: string): Maybe<YearMonth> => {
    const yearMonth = str.split("-");
    return createYearMonthFromString(yearMonth[0], yearMonth[1]);
};

export const noop = always(undefined);

export const doIfPresent = <T>(maybeT: Maybe<T>, fn: (val: T) => void): void =>
    maybeT.match({
        Just: fn,
        Nothing: noop,
    });

export const getValueOrDefault = <T, V>(value: Maybe<T>, fn: (val: T) => V, defaultValue: V): V => {
    return value.match({
        Just: fn,
        Nothing: () => defaultValue,
    });
};

export const dateToYearMonthString = (d: Date): string => {
    const mm = d.getMonth() + 1;
    return [d.getFullYear(), (mm > 9 ? "" : "0") + mm].join("-");
};

export const rethrow = (err: Error) => {
    throw err;
};

export const isError = (object: unknown): object is Error => object instanceof Error;

export const isNotBlank = (string: Nullable<string> | undefined): boolean => {
    return Maybe.of(string)
        .map(e => e.trim().length > 0)
        .unwrapOr(false);
};
