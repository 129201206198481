import { Box, Button, Typography } from "@mui/material";
import { BankAccount, SyncResult, TriggerSyncPayload } from "modules/Bank/types";
import { LoadingButton } from "@mui/lab";
import { useLoaderData } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DateRange, YupObjectShape, lastWeek, dateRangeSchema } from "components/common/types";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { useData } from "components/common/hooks/useData";
import { AsyncResult } from "components/common/infractructure";
import { Link } from "react-router-dom";
import { DateRangePicker } from "components/common/ui-kit/components/DateRangePicker/DateRangePicker";

interface FormData {
    accountIds: number[];
    dateRange: DateRange;
}

const triggerSyncFormDataPayload = yup.object<YupObjectShape<FormData>>({
    accountIds: yup.array().of(yup.number().integer()).min(1).required(),
    dateRange: dateRangeSchema,
});

const defaultValues: FormData = {
    accountIds: [],
    dateRange: lastWeek(new Date()),
};

interface Props {
    triggerSync: (payload: TriggerSyncPayload) => AsyncResult<SyncResult>;
}

export const BankFileImport: React.FC<Props> = ({ triggerSync }) => {
    const loaderResult = useLoaderData() as Result<BankAccount[], Error>;
    const {
        control,
        formState: { isValid, isSubmitting },
        handleSubmit,
    } = useForm<FormData>({
        defaultValues,
        resolver: yupResolver(triggerSyncFormDataPayload),
        mode: "all",
    });

    const { data, loadData } = useData<SyncResult, FormData>({
        loader: ({ dateRange, accountIds }: FormData) => {
            return triggerSync({
                accountIds,
                fromDate: dateRange.from,
                toDate: dateRange.to,
            });
        },
    });

    return (
        <>
            <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Import z banku (ING)
            </Typography>
            {loaderResult
                .map(bankAccounts => (
                    <Box key={0} display="flex">
                        <Box sx={{ marginRight: "5rem" }}>
                            <form name="bank-import" onSubmit={handleSubmit(loadData)}>
                                <Box
                                    sx={{
                                        marginBottom: "1rem",
                                        display: "grid",
                                        gridTemplateAreas: `
                                            "date_range_select date_range_from date_range_to"
                                        `,
                                        gridTemplateColumns: "repeat(3, 1fr)",
                                        gap: ".5rem",
                                        width: "500px",
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        name="dateRange"
                                        render={({ field: { onChange } }) => (
                                            <DateRangePicker
                                                initialRange={defaultValues.dateRange}
                                                onDateRangeChange={range => onChange(Maybe.of(range))}
                                                today={new Date()}
                                            />
                                        )}
                                    />
                                </Box>
                                <Controller
                                    control={control}
                                    name="accountIds"
                                    render={({ field }) => (
                                        <GenericSelect
                                            {...field}
                                            sx={{
                                                minWidth: "20rem",
                                            }}
                                            label="Konta (wybierz jedno lub wiele)"
                                            values={bankAccounts.map(({ iban, currency, id }) => ({
                                                value: id,
                                                display: `${currency.name} ${iban}`,
                                            }))}
                                            size="medium"
                                            multiple={true}
                                        />
                                    )}
                                />
                                <Box sx={{ marginTop: "1rem" }}>
                                    <LoadingButton
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={!isValid}
                                        loading={isSubmitting}
                                    >
                                        Importuj
                                    </LoadingButton>
                                </Box>
                            </form>
                        </Box>
                        <Box>
                            {!isSubmitting &&
                                data
                                    .map(syncResult => (
                                        <>
                                            <Typography variant="body1">rezultat synchronizacji:</Typography>
                                            <pre key={1}>{JSON.stringify(syncResult, null, 2)}</pre>
                                            <Button component={Link} to="/bank/transactions" variant="contained">
                                                Przejdź do banku
                                            </Button>
                                        </>
                                    ))
                                    .unwrapOr(<></>)}
                        </Box>
                    </Box>
                ))
                .unwrapOrElse(err => (
                    <Typography variant="body2" color="error">
                        Nie udało się wczytać listy kont bankowych: {err.message}
                    </Typography>
                ))}
        </>
    );
};
