import { Box, Tooltip } from "@mui/material";
import { MONTHLY_SETTLEMENT_FORM } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { last } from "ramda";
import { MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { submit } from "redux-form";

export interface WizardNavigationElement {
    link: string;
    title: string;
}

interface Props {
    sections: WizardNavigationElement[];
    isSettlementValid: boolean;
}

export const WizardNavigationComponent = ({ sections, isSettlementValid }: Props) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [activePathParam, setActivePathParam] = useState(last(pathname.split("/")));

    useEffect(() => {
        setActivePathParam(last(pathname.split("/")));
    }, [pathname]);

    const isSectionActive = (sectionLink: WizardNavigationElement["link"]) =>
        activePathParam === last(sectionLink.split("/"));

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (!isSettlementValid) {
            e.preventDefault();
        } else {
            dispatch(submit(MONTHLY_SETTLEMENT_FORM));
        }
    };

    return (
        <Box
            sx={{
                display: "grid",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gridTemplateColumns: "repeat(7, 1fr)",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                boxShadow: "4px 4px 10px 1px #b4b4b4",
            }}
        >
            {sections.map(({ link, title }, index) => (
                <Tooltip
                    key={index}
                    title={!isSettlementValid ? "Błąd walidacji - uzupełnij wymagane pola lub usuń pozycje" : ""}
                >
                    <Box
                        sx={{
                            a: {
                                textAlign: "center",
                                gridArea: "link",
                                color: "#fff",
                                transition: "all 0.2s linear",
                                borderRadius: "10px",
                                ...(isSectionActive(link)
                                    ? {
                                          backgroundColor: "custom.hover",
                                          boxShadow: "0px 0px 4px 0px #00000070",
                                      }
                                    : {}),
                                ...(isSettlementValid ? {} : { cursor: "not-allowed" }),
                            },
                        }}
                    >
                        <Link
                            onClick={handleClick}
                            key={index}
                            style={{
                                textDecoration: "none",
                                textAlign: "center",
                                height: "100%",
                                display: "flex",
                                padding: "0.75rem",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            to={link}
                        >
                            {title}
                        </Link>
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
};
