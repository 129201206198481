import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";
import { Switch, FormControlLabel } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface Props {
    name?: string;
    label?: string;
    sx?: SxProps<Theme>;
}

export const SwitcherStyled: React.FC<Props & WrappedFieldProps> = ({ input, label }) => {
    const handleChange = () => {
        input.onChange(!input.value);
    };

    return (
        <FormControlLabel
            control={<Switch onChange={handleChange} checked={!!input.value} color="primary" />}
            label={label ? label : input.value ? "TAK" : "NIE"}
        />
    );
};

export const Switcher: React.FC<Props> = React.memo(props => (
    <Field name={props.name} component={SwitcherStyled} props={{ ...props }} />
));
