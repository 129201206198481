import { YupObjectShape } from "components/common/types/types";
import {
    format,
    subDays,
    subMonths,
    parse,
    isAfter,
    addDays,
    isValid,
    intervalToDuration,
    formatDuration,
    addYears,
    formatDistanceToNowStrict,
} from "date-fns";
import { Maybe } from "true-myth";
import * as yup from "yup";
import { pl } from "date-fns/locale";

export interface YearMonth {
    month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    year: number;
}

export const MonthsList = [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień",
];

export type Year = number;
export type Month = "01" | "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10" | "11" | "12";
type Day = number;
export type DateString = `${Year}-${Month}-${Day}`;
const DATE_STRING_FORMAT = "yyyy-MM-dd";
export const MUI_INPUT_DATE_STRING_FORMAT = "YYYY-MM-DD";

export const MUI_INPUT_YEAR_STRING_FORMAT = "YYYY";

export type YearMonthString = `${Year}-${Month}`;
export const YEAR_MONTH_STRING_FORMAT = "yyyy-MM";
export const MUI_INPUT_YEAR_MONTH_STRING_FORMAT = "YYYY-MM";

export const dateStringSchema = yup.string().test({
    name: "is-date-string",
    message: "The value must be a valid DateString (yyyy-MM-dd)",
    test: value => {
        if (value === undefined) {
            return false;
        }

        const parsedDate = parse(value, DATE_STRING_FORMAT, new Date());

        if (!isValid(parsedDate)) {
            return false;
        }

        return format(parsedDate, DATE_STRING_FORMAT) === value;
    },
});

export interface DateRange {
    from: DateString;
    to: DateString;
}

export const dateRangeSchema = yup.object<YupObjectShape<DateRange>>({
    from: dateStringSchema.required(),
    to: dateStringSchema.required(),
});

export const maybeDateRangeSchema = yup.mixed<Maybe<DateRange>>().test({
    name: "is-maybe-date-range>",
    message: "The value must be a valid Maybe<Range>, precisely Just<DateRange>",
    test: maybeValue => {
        if (maybeValue === undefined) {
            return false;
        }

        return maybeValue.map(value => dateRangeSchema.isValidSync(value)).unwrapOr(false);
    },
});

export const toDateString = (date: Date, dateStringformat = DATE_STRING_FORMAT): DateString =>
    format(date, dateStringformat, { locale: pl }) as DateString;

const lastNumberOfDays = (today: Date, numberOfDays: number): DateRange => ({
    from: toDateString(subDays(today, numberOfDays)),
    to: toDateString(today),
});

const lastNumberOfMonths = (today: Date, numberOfMonths: number): DateRange => ({
    from: toDateString(subMonths(today, numberOfMonths)),
    to: toDateString(today),
});

export const lastWeek = (today: Date): DateRange => lastNumberOfDays(today, 7);

export const lastTwoWeeks = (today: Date): DateRange => lastNumberOfDays(today, 14);

export const lastNinetyDays = (today: Date): DateRange => lastNumberOfDays(today, 90);

export const lastMonth = (today: Date): DateRange => lastNumberOfMonths(today, 1);

export const dateRange = (from: Date, to: Date): DateRange => ({
    from: toDateString(from),
    to: toDateString(to),
});

export const adjustRange = (fieldName: keyof DateRange, value: DateString, currentDateRange: DateRange): DateRange => {
    const now = new Date();
    const newDateRange = { ...currentDateRange, [fieldName]: value };
    const newFrom = parse(newDateRange.from, DATE_STRING_FORMAT, now);
    const newTo = parse(newDateRange.to, DATE_STRING_FORMAT, now);

    if (!isAfter(newTo, newFrom)) {
        if (fieldName === "from") {
            return {
                ...newDateRange,
                to: toDateString(addDays(newFrom, 1)),
            };
        }

        if (fieldName === "to") {
            return {
                ...newDateRange,
                from: toDateString(subDays(newTo, 1)),
            };
        }
    }

    return newDateRange;
};

export const dateStringToStrYearMonth = (dateString: DateString): string => {
    const yearMonth = dateString.split("-");
    return `${yearMonth[0]}-${yearMonth[1]}`;
};

export const dateToYear = (date: Date): Year => {
    return date.getFullYear();
};

export const timeDiffInYearsAndDays = (startDate: string) => {
    const start = new Date(startDate);
    const interval = intervalToDuration({ end: new Date(), start: start });
    const years = interval.years !== 0 && interval.years !== undefined ? interval.years : 0;
    if (years === 0) {
        const daysDiff = formatDistanceToNowStrict(start, { locale: pl, unit: "day", addSuffix: true });
        return "(" + daysDiff + ")";
    }
    const yearsDiff = formatDuration(interval, { locale: pl, format: ["years"] });
    const newDate = addYears(start, years);
    const daysDiff = formatDistanceToNowStrict(newDate, { locale: pl, unit: "day", addSuffix: true });
    return "(" + yearsDiff + " " + daysDiff + ")";
};
