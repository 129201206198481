import * as React from "react";
import { Box, Grid, SxProps, Theme } from "@mui/material";

const boxStyle = {
    display: "inline-block",
    width: "14px",
    height: "14px",
};

const showcaseBox = {
    div: {
        marginRight: "1rem",
    },
};
interface Props {
    sx?: SxProps<Theme>;
}

export const InvoiceStatusColorShowcase: React.FC<Props> = ({ sx = {} }) => {
    return (
        <Box sx={sx}>
            <Grid container sx={showcaseBox}>
                <Grid item>
                    <Box component="span" sx={{ ...boxStyle, backgroundColor: "invoices.billingCreated" }} /> Oczekujące
                    faktury
                </Grid>
                <Grid item>
                    <Box component="span" sx={{ ...boxStyle, backgroundColor: "invoices.paid" }} /> Faktura zapłacona w
                    ponad 99%
                </Grid>
                <Grid item>
                    <Box component="span" sx={{ ...boxStyle, backgroundColor: "invoices.notPaidValid" }} /> Faktura
                    niezapłacona
                </Grid>
                <Grid item>
                    <Box component="span" sx={{ ...boxStyle, backgroundColor: "invoices.notPaidExpired" }} /> Faktura
                    niezapłacona (minął termin płatności)
                </Grid>
            </Grid>
        </Box>
    );
};
