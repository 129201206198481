import { LoaderFunction, useLoaderData } from "react-router-dom";
import { Typography } from "@mui/material";
import { DataLoaderResult, loadData } from "./data";
import { ShowProject } from "./ShowProject";
import React from "react";
import { AsyncResult } from "../../../../components/common/infractructure";
import { Employee } from "../../types";

interface Props {
    fetchActiveUsers: () => AsyncResult<Employee[]>;
}
const ShowProjectRoute = (props: Props) => {
    const loaderData = useLoaderData() as DataLoaderResult;
    const routeContent = React.useMemo(
        () =>
            loaderData
                .map(data => (
                    <ShowProject key={JSON.stringify(data)} {...data} fetchActiveUsers={props.fetchActiveUsers} />
                ))
                .unwrapOrElse(err => (
                    <Typography variant="body2" color="error">
                        coś poszło nie tak: {err.message}
                    </Typography>
                )),
        [loaderData],
    );
    return <>{routeContent}</>;
};

const loaderFn =
    (idParamName: string): LoaderFunction =>
    ({ params }) => {
        const projectId = params[idParamName] as string;

        return loadData(projectId);
    };

ShowProjectRoute.Loader = loaderFn;

export { ShowProjectRoute };
