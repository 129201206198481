import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Maybe } from "true-myth";

interface Props {
    workingDays: Maybe<number>;
    reportedDays: number;
}

const ReportedDays: React.FC<Props> = ({ workingDays, reportedDays }) => (
    <>
        {workingDays
            .map(workingDays => (
                <Box display={"inline-flex"} key={workingDays}>
                    <Typography variant="subtitle1">
                        dni zaraportowanych: <strong>{reportedDays}</strong>
                    </Typography>
                    <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
                    <Typography variant="subtitle1">
                        wykorzystany urlop: <strong>{workingDays - reportedDays}</strong>
                    </Typography>
                </Box>
            ))
            .unwrapOr(<></>)}
    </>
);

export default ReportedDays;
