import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AsyncResult } from "../../../../components/common/infractructure";
import toast from "react-hot-toast";
import { GmailToken, User, UserProfileData } from "../../types";
import { useUserContext } from "../../../../components/common/contexts";
import { Box, CircularProgress, Grid } from "@mui/material";

interface Props {
    addAuthCode: (code: string) => AsyncResult<GmailToken[]>;
}

function addGmailToken(userProfileData: UserProfileData, newTokens: GmailToken[]): UserProfileData {
    const newUser: User = {
        ...userProfileData.user,
        gmailTokens: newTokens,
    };

    return {
        ...userProfileData,
        user: newUser,
    };
}

export const GmailCallback: React.FC<Props> = (props: Props) => {
    const { userProfileData, setUserProfileData } = useUserContext();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get("code");

        const handleAuthCode = async () => {
            if (code) {
                try {
                    const result = await props.addAuthCode(code);
                    result.match({
                        Ok: res => {
                            setUserProfileData(addGmailToken(userProfileData, res));
                            navigate("/profile");
                            toast.success("Połączono konto gmail!");
                        },
                        Err: () => {
                            navigate("/profile");
                            toast.error("Nie udało się połączyć konta gmail!");
                        },
                    });
                } catch (error) {
                    navigate("/error");
                }
            } else {
                navigate("/");
            }
        };

        handleAuthCode();
    }, [location, navigate, props]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "200px" }}>
                    <CircularProgress sx={{ maxHeight: "100px", marginTop: "50px" }} />
                </Box>
            </Grid>
        </Grid>
    );
};
