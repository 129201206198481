import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import { DateString } from "components/common/types";
import {
    BankAccount,
    BankAccountDTO,
    DocumentPayment,
    DocumentPaymentAssignmentPayload,
    DocumentPaymentDTO,
    DocumentSearchResult,
    DocumentSearchResultDTO,
    mapBankAccountDTO,
    mapDocumentPaymentDTO,
    mapDocumentSearchResultDTO,
    mapPaymentDTO,
    mapSyncResultDTO,
    Payment,
    PaymentDTO,
    SyncResult,
    SyncResultDTO,
    TriggerSyncPayload,
} from "modules/Bank/types";

export type TransactionSearchParams = Partial<{
    fromDate: DateString;
    toDate: DateString;
    smlAccountIds: string[];
    transactionDescription: string;
    minAmount: string;
    maxAmount: string;
    withUnassignedAmountOnly: boolean;
    currency: string;
    documentId: string;
}>;

export const getTransactions =
    (apiGet: ApiGet<PaymentDTO[]>) =>
    (transactionSearchParams: TransactionSearchParams): AsyncResult<Payment[]> =>
        apiGet("/payment", {
            params: {
                ...transactionSearchParams,
                smlAccountIds: transactionSearchParams.smlAccountIds?.join(","),
            },
        }).then(result => result.map(list => list.map(mapPaymentDTO)));

export interface DocumentSearchParams {
    query: string;
}

export const searchDocuments =
    (apiGet: ApiGet<DocumentSearchResultDTO[]>) =>
    (documentSearchParams: DocumentSearchParams): AsyncResult<DocumentSearchResult[]> =>
        apiGet("/document/search", {
            params: {
                ...documentSearchParams,
            },
        }).then(result => result.map(list => list.map(mapDocumentSearchResultDTO)));

export const assignDocumentPayment =
    (apiPost: ApiUpdate<DocumentPaymentAssignmentPayload, DocumentPaymentDTO>) =>
    (payload: DocumentPaymentAssignmentPayload): AsyncResult<DocumentPayment> =>
        apiPost("/documentPayments", payload).then(result => result.map(mapDocumentPaymentDTO));

export const removeDocumentPayment =
    (apiDelete: ApiUpdate<void, void>) =>
    (documentPaymentId: number): AsyncResult<void> =>
        apiDelete(`/documentPayments/${documentPaymentId}`);

export const getAccountsConfig = (apiGet: ApiGet<BankAccountDTO[]>) => (): AsyncResult<BankAccount[]> =>
    apiGet("/accounts").then(result => result.map(list => list.map(mapBankAccountDTO)));

export const triggerSync =
    (apiPost: ApiUpdate<TriggerSyncPayload, SyncResultDTO>) =>
    (payload: TriggerSyncPayload): AsyncResult<SyncResult> =>
        apiPost("/accounts/sync", payload).then(result => result.map(mapSyncResultDTO));
