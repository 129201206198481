import { Box, Button, Paper, Typography } from "@mui/material";
import {
    ActivityDef,
    GroupDef,
    ProjectActivityDef,
} from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { handleTabOrWindowClose } from "modules/Invoices/views/MonthlySettlement/service/eventHandlers";
import {
    ACTIVITIES_KEY,
    ActivityFormValue,
    getKeyForActivity,
    MonthlySettlementFormValues,
    ProjectActivityFormValue,
    PROJECT_ACTIVITIES_KEY,
    PROJECT_ACTIVITY_KEY,
} from "modules/Invoices/views/MonthlySettlement/service/formService";
import { ConstantField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/ConstantField";
import { ActivityForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/ActivityForm";
import { ProjectActivityForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/ProjectActivityForm";
import {
    ActivitiesSelector,
    ProjectsSelector,
} from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/ProjectsSelector";
import { NoProjectsAssignedInfo } from "modules/Invoices/views/MonthlySettlement/view/warnings/Warnings";
import { isEmpty } from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormSection, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../service/store";
import { MONTHLY_SETTLEMENT_FORM } from "../../../domain/types";

export type Props = GroupDef & {
    monthlySettlementInitialFormValues: MonthlySettlementFormValues;
    monthlySettlementDefId: number;
    onPreviousPageClick?: () => void;
    onNextPageClick?: () => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    valid: boolean;
    dirty: boolean;
};

const ActionBuilder = {
    setProjectActivityFormValue: (key: string, value: ProjectActivityFormValue) =>
        change(MONTHLY_SETTLEMENT_FORM, `${PROJECT_ACTIVITIES_KEY}.${key}`, value),
    setActivityFormValue: (key: string, value: ActivityFormValue) =>
        change(MONTHLY_SETTLEMENT_FORM, `${ACTIVITIES_KEY}.${key}`, value),
    clearProjectActivityFormValue: (key: string) =>
        change(MONTHLY_SETTLEMENT_FORM, `${PROJECT_ACTIVITIES_KEY}.${key}`, null),
    clearActivityFormValue: (key: string) => change(MONTHLY_SETTLEMENT_FORM, `${ACTIVITIES_KEY}.${key}`, null),
};

export const GroupForm = ({
    projectActivities,
    activities,
    monthlySettlementInitialFormValues,
    monthlySettlementDefId,
    valid,
    dirty,
    onPreviousPageClick,
    onNextPageClick,
    handleSubmit,
}: Props) => {
    const anyTouched = useSelector<GlobalState, boolean>(
        state => state.form[MONTHLY_SETTLEMENT_FORM]?.anyTouched ?? false,
    );
    const dispatch = useDispatch();

    const [projectActivityDefs, setProjectActivityDefs] = useState<ProjectActivityDef[]>([]);
    const [activityDefs, setActivityDefs] = useState<ActivityDef[]>([]);

    const onSelectedProjectActivityDefsChange = (selected: ProjectActivityDef[] | ActivityDef[]) =>
        setProjectActivityDefs(selected as ProjectActivityDef[]);

    const onSelectedActivityDefsChanged = (selected: ProjectActivityDef[] | ActivityDef[]) =>
        setActivityDefs(selected as ActivityDef[]);

    const onActivityEnabled = (key: string, activity: ProjectActivityFormValue | ActivityFormValue) => {
        if (key.includes(PROJECT_ACTIVITY_KEY)) {
            dispatch(ActionBuilder.setProjectActivityFormValue(key, activity as ProjectActivityFormValue));
        } else {
            dispatch(ActionBuilder.setActivityFormValue(key, activity));
        }
    };

    useEffect(() => {
        const beforeUnload = (e: BeforeUnloadEvent) => handleTabOrWindowClose(e, dirty);
        window.addEventListener("beforeunload", beforeUnload);
        return () => window.removeEventListener("beforeunload", beforeUnload);
    }, [dirty]);

    return (
        <form onSubmit={handleSubmit} data-testid="GroupForm">
            {!isEmpty(projectActivities) && (
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 3,
                        paddingRight: 3,
                        paddingBottom: 0,
                        paddingLeft: 3,
                        marginBottom: 3,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: 0,
                        }}
                    >
                        Wybierz projekty, dla których pracowałeś w tym miesiącu
                    </Typography>
                    <ProjectsSelector
                        activities={projectActivities}
                        initialActivitiesFormValues={monthlySettlementInitialFormValues[PROJECT_ACTIVITIES_KEY]}
                        onSelectedActivitiesChange={onSelectedProjectActivityDefsChange}
                        onActivityDisabled={key => dispatch(ActionBuilder.clearProjectActivityFormValue(key))}
                        onActivityEnabled={onActivityEnabled}
                    />
                    <FormSection name={PROJECT_ACTIVITIES_KEY}>
                        {projectActivityDefs.map((projectActivityDef, index) => (
                            <FormSection key={index} name={getKeyForActivity(projectActivityDef)}>
                                <ProjectActivityForm {...projectActivityDef} />
                            </FormSection>
                        ))}
                    </FormSection>
                </Paper>
            )}

            {!isEmpty(activities) && (
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 3,
                        paddingRight: 3,
                        paddingBottom: 0,
                        paddingLeft: 3,
                        marginBottom: 3,
                    }}
                >
                    <ActivitiesSelector
                        activities={activities}
                        initialActivitiesFormValues={monthlySettlementInitialFormValues[ACTIVITIES_KEY]}
                        onSelectedActivitiesChange={onSelectedActivityDefsChanged}
                        onActivityDisabled={key => dispatch(ActionBuilder.clearActivityFormValue(key))}
                        onActivityEnabled={onActivityEnabled}
                    />
                    <FormSection name={ACTIVITIES_KEY}>
                        {activityDefs.map((activityDef, index) => (
                            <FormSection key={index} name={getKeyForActivity(activityDef)}>
                                <ActivityForm {...activityDef} />
                            </FormSection>
                        ))}
                    </FormSection>
                </Paper>
            )}

            {isEmpty(projectActivities) && isEmpty(activities) && (
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 3,
                        paddingRight: 3,
                        paddingBottom: 3,
                        paddingLeft: 3,
                        marginBottom: 3,
                    }}
                >
                    <NoProjectsAssignedInfo />
                </Paper>
            )}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    paddingLeft: 2,
                    paddingRight: 2,
                }}
            >
                <Button disabled={anyTouched && !valid} onClick={onNextPageClick}>
                    Następna strona &#10095;
                </Button>
                {onPreviousPageClick && (
                    <Button disabled={anyTouched && !valid} onClick={onPreviousPageClick}>
                        &#10094; Poprzednia strona
                    </Button>
                )}
            </Box>

            <ConstantField fieldName={"settlementDefId"} fieldValue={monthlySettlementDefId} />
        </form>
    );
};
