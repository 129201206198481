import * as React from "react";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import { Box, TextField } from "@mui/material";

interface DocumentProps {
    setSearchQuery: (value: string) => void;
}

export const DocumentSearch: React.FC<DocumentProps> = ({ setSearchQuery }) => {
    const handleSearchDocuments = useMemo(
        () => debounce((e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value), 500),
        [],
    );

    return (
        <Box sx={{ textAlign: "center", maxWidth: "20rem", margin: "auto" }}>
            <TextField
                sx={{ marginTop: 2 }}
                type="text"
                label="Szukaj dokumentów"
                size="medium"
                fullWidth
                onChange={handleSearchDocuments}
                variant="standard"
            />
        </Box>
    );
};
