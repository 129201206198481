import { map, omit, pick, propEq, reject } from "ramda";
import { CostsRelevantPendingPaymentFields, CostsSummaryTableRow } from "./CostsSummaryTable";
import { Maybe } from "true-myth";
import { NonVatPayer } from "../types";
import { PendingPaymentPosition } from "modules/types/types";

const pickCostsRelevantProperties = (ppp: PendingPaymentPosition): CostsRelevantPendingPaymentFields =>
    pick<PendingPaymentPosition, keyof PendingPaymentPosition>(
        ["unitPrice", "amountOfDays", "quantity", "netAmount", "taxAmount", "grossAmount", "note"],
        ppp,
    );

const pendingPaymentPositionToCostsSummaryRow = (ppp: PendingPaymentPosition): CostsSummaryTableRow => ({
    ...pickCostsRelevantProperties(ppp),
    category: ppp.category.name,
    projectName: Maybe.of(ppp.vacancy)
        .map(v => v.name)
        .unwrapOr("Brak projektu"),
});

export const pendingPaymentPositionToCostsSummaryTableRowData = map<PendingPaymentPosition, CostsSummaryTableRow>(
    pendingPaymentPositionToCostsSummaryRow,
);

export const getNonVatPayerData: NonVatPayer = (dataTable, rowData, isTotal) => ({
    definition: reject(
        item =>
            propEq("header", isTotal ? "Razem netto" : "Netto")(item as Record<"header", string>) ||
            propEq("header", isTotal ? "Razem VAT" : "VAT")(item as Record<"header", string>),
    )(dataTable),
    data: map(omit(isTotal ? ["totalNet", "totalTax"] : ["netAmount", "taxAmount"]), rowData),
});
