import { Box, Button, Divider, Grid, Paper, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useUserContext } from "components/common/contexts";
import { toDateString } from "components/common/types";
import { MonetizationOn, Person, List } from "@mui/icons-material";
import { differenceInDays } from "date-fns";
import { max } from "ramda";
import IframeResizer from "iframe-resizer-react";
import Intro from "./Intro";
import * as React from "react";
import {
    ContactDataMissing,
    PassportExpirationApproaching,
} from "../Invoices/views/MonthlySettlement/view/warnings/Warnings";

const tile: SxProps<Theme> = {
    flex: 1,
    color: "#fff",
    borderRadius: 4,
    width: "20rem",
    height: "4rem",
    textAlign: "center",
    alignItems: "center",
    marginRight: "20px",
    marginBottom: "15px",
    boxShadow: "4px 4px 20px 1px #b4b4b4",
};

const icon: SxProps<Theme> = {
    mr: "1rem",
    color: "#fff",
};

const blue: SxProps<Theme> = {
    backgroundColor: "#319db5",
    transition: "transform 0.2s",
    "&:hover": {
        backgroundColor: "#226D7EFF",
        transform: "scale(1.025) translateY(-2px)",
        boxShadow: "4px 4px 20px 1px #226D7EFF",
    },
};

const purple: SxProps<Theme> = {
    backgroundColor: "#7a31b5",
    transition: "transform 0.2s",
    "&:hover": {
        backgroundColor: "#58207e",
        transform: "scale(1.025) translateY(-2px)",
        boxShadow: "4px 4px 20px 1px #392381",
    },
};

const green: SxProps<Theme> = {
    backgroundColor: "#89b531",
    transition: "transform 0.2s",
    "&:hover": {
        backgroundColor: "#618022",
        transform: "scale(1.025) translateY(-2px)",
        boxShadow: "4px 4px 20px 1px #6d7e22",
    },
};

const GREETINGS = ["Witaj ponownie, ", "Cześć, ", "Hej, ", "Siemanko, ", "Dobrze Cię widzieć, "] as const;

const Home: React.FC = () => {
    const { userProfileData } = useUserContext();
    const greeting = GREETINGS[Math.floor(Math.random() * GREETINGS.length)];

    const theme = useTheme();
    const isXL = useMediaQuery(theme.breakpoints.up("xl"));

    const checkContactData = (): boolean => {
        return userProfileData.contractorData
            .map(
                data =>
                    data.contactAddressLine1.isNothing() ||
                    data.contactAddressLine2.isNothing() ||
                    data.parcelLocker.isNothing() ||
                    data.passportExpirationDate.isNothing(),
            )
            .unwrapOr(false);
    };

    const checkPassportDate = (): boolean => {
        return userProfileData.contractorData
            .flatMap(e => e.passportExpirationDate)
            .mapOrElse(
                () => false,
                date => differenceInDays(new Date(date), new Date()) < 180,
            );
    };

    return (
        <Grid container sx={{ marginBottom: "2rem" }}>
            <Grid item xs={12} xl={4} sx={{ marginBottom: "1rem" }}>
                <Typography variant="h1" sx={{ mb: "0.5rem" }}>
                    {greeting} {userProfileData.user.firstName}!
                </Typography>
                {userProfileData.employmentData
                    .map(({ dateHired }) => {
                        const daysSinceHired = max(differenceInDays(new Date(), dateHired), 1);
                        return (
                            <>
                                <Divider />
                                <Typography key={0} variant="subtitle1">
                                    Jesteś z nami <b>{daysSinceHired}</b> {daysSinceHired > 1 ? "dni" : "dzień"}, od{" "}
                                    <b>{toDateString(dateHired)}</b>.
                                </Typography>
                            </>
                        );
                    })
                    .unwrapOr(<> </>)}
            </Grid>

            {checkContactData() ? (
                <Grid item xs={12} margin="0px 0px 40px 0px">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <ContactDataMissing />
                    </Box>
                </Grid>
            ) : (
                <></>
            )}

            {checkPassportDate() ? (
                <Grid item xs={12} margin="0px 0px 40px 0px">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <PassportExpirationApproaching />
                    </Box>
                </Grid>
            ) : (
                <></>
            )}

            <Grid item xs={12} xl={8}>
                <Grid container justifyContent={isXL ? "flex-end" : "center"}>
                    <Button
                        variant={"contained"}
                        sx={{ ...tile, ...blue, minWidth: "300px", maxWidth: "300px" }}
                        href="/invoices/add"
                    >
                        <MonetizationOn sx={icon} />
                        Rozlicz bieżący miesiąc
                    </Button>
                    <Button
                        variant={"contained"}
                        sx={{ ...tile, ...purple, minWidth: "300px", maxWidth: "300px" }}
                        href="/invoices"
                    >
                        <List sx={icon} />
                        Moje rozliczenia
                    </Button>
                    <Button
                        variant={"contained"}
                        sx={{ ...tile, ...green, minWidth: "300px", maxWidth: "300px" }}
                        href="/profile"
                    >
                        <Person sx={icon} />
                        Moje Dane
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
                    Wynik finansowy
                </Typography>
                <Paper sx={{ bgcolor: "white" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <IframeResizer
                            autoResize={false}
                            checkOrigin={false}
                            src={`https://lookerstudio.google.com/embed/reporting/${userProfileData.user.dashboardId}/page/FCEWC`}
                            style={{
                                border: "0",
                                display: "flow",
                                width: "1370px",
                                height: "2450px",
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
            <Intro />
        </Grid>
    );
};

export default Home;
