import { TransactionList } from "modules/Bank/views/Transactions/TransactionList";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TransactionFiltersContextProvider } from "./TransactionFiltersContext";
import { TransactionFilter } from "./TransactionFilter";
import { TransactionsDataContextProvider } from "./TransactionsDataContext";

const Transactions = () => {
    return (
        <>
            <Box display="flex" justifyContent="space-between" sx={{ marginBottom: "2rem" }}>
                <Typography variant="h2">Transakcje</Typography>
                <Button component={Link} to="/bank/import" variant="contained">
                    Import z banku (ING)
                </Button>
            </Box>
            <TransactionFiltersContextProvider>
                <TransactionsDataContextProvider>
                    <TransactionFilter />
                    <TransactionList />
                </TransactionsDataContextProvider>
            </TransactionFiltersContextProvider>
        </>
    );
};

export default Transactions;
