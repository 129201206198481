import { Backdrop, Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import React, { useEffect, useState } from "react";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const move = keyframes`
    from {
        transform: scale(1);
    }
    to {
        top: -60px;
        left: -140px;
        transform: scale(0.41);
    }
`;

const fadeOutBackdrop = keyframes`
    from {
        background-color: #0000007F;
        backdrop-filter: blur("40px");
    }
    to {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0);
    }
`;

const Intro: React.FC = () => {
    const [open, setOpen] = useState(() => {
        const saved = localStorage.getItem("intro");
        console.log(saved);
        return saved ? JSON.parse(saved) : true;
    });

    const handleClose = () => {
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    useEffect(() => {
        localStorage.setItem("intro", JSON.stringify(open));
    }, [open]);

    return (
        <Backdrop
            open={open}
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(40px)",
                animation: `${fadeOutBackdrop} 2s forwards`,
                animationDelay: "8s",
            }}
            onAnimationEnd={handleClose}
        >
            <Typography
                variant={"h1"}
                sx={{
                    color: "white",
                    transform: "scale(2)",
                    position: "absolute",
                    top: "calc(50% + 160px)",
                    left: "calc(50% - 160px)",
                    borderTopStyle: "solid",
                    borderTopWidth: "2px",
                    animation: `${fadeOut} 3s forwards`,
                    animationDelay: "3s",
                }}
            >
                Witaj w nowych Finansach!
            </Typography>

            <Box
                sx={{
                    color: "white",
                    transform: "scale(2)",
                    position: "absolute",
                    top: "calc(90% + 0px)",
                    animation: `${fadeOut} 3s forwards`,
                    animationDelay: "4s",
                }}
            >
                <img src="/images/sml-logo.svg" alt="sml-logo" height="15px" />
            </Box>

            <Box
                sx={{
                    animation: `${fadeOut} 3s forwards`,
                    animationDelay: "3s",
                    position: "absolute",
                    top: "calc(50% - 125px)",
                    left: "calc(50% - 152px - 200px)",
                }}
            >
                <img src="/images/mckwacz.png" alt="finanse-logo" height="250" />
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    top: "calc(50% - 145px)",
                    left: "calc(50% - 210px)",
                    animation: `${move} 1s forwards`,
                    animationDelay: "6s",
                }}
            >
                <Box
                    sx={{
                        opacity: "0",
                        animation: `${fadeIn} 3s forwards`,
                        animationDelay: "3s",
                    }}
                >
                    <img src="/images/finanse-logo-hifi.svg" alt="finanse-logo" height="195px" />
                </Box>
            </Box>
        </Backdrop>
    );
};

export default Intro;
