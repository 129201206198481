import { Box } from "@mui/material";
import { GenericFormInputProps, Position } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { ConstantField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/ConstantField";
import {
    fieldNotEmpty,
    numberFieldGreaterThanOrEqualZero,
} from "modules/Invoices/views/MonthlySettlement/view/form-fields/fieldHelpers";
import { WrappedSelect } from "modules/Invoices/views/MonthlySettlement/view/form-fields/WrappedSelect";
import { WrappedTextField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/WrappedTextField";
import { sectionFormInputStyles } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/styles";

export interface ClientCostsSectionFormInputProps extends GenericFormInputProps {
    sectionDefId: number;
}

export interface ClientCostsSectionFormInputData extends Position {
    sectionDefId: number;
    unitPrice: number;
    currency: string;
    descriptionPL: string;
    descriptionEN: string;
}

export const ClientCostsSectionFormInput = ({ name, sectionDefId }: ClientCostsSectionFormInputProps) => (
    <Box sx={sectionFormInputStyles.root}>
        <WrappedTextField
            key={0}
            name={`${name}.unitPrice`}
            textFieldProps={{
                type: "number",
                label: "Cena jednostkowa",
                required: true,
            }}
            validate={[fieldNotEmpty, numberFieldGreaterThanOrEqualZero]}
        />

        <WrappedSelect
            name={`${name}.currency`}
            options={[
                { label: "EUR", value: "EUR" },
                { label: "USD", value: "USD" },
                { label: "PLN", value: "PLN" },
            ]}
            label={"Waluta"}
            selectProps={{}}
        />

        <WrappedTextField
            name={`${name}.quantity`}
            textFieldProps={{
                type: "number",
                label: "Liczba",
                required: true,
            }}
            validate={[fieldNotEmpty, numberFieldGreaterThanOrEqualZero]}
        />

        <Box sx={sectionFormInputStyles.wideFieldWrapper}>
            <WrappedTextField
                name={`${name}.descriptionPL`}
                textFieldProps={{
                    type: "text",
                    label: "Opis PL",
                    required: true,
                    fullWidth: true,
                }}
                validate={[fieldNotEmpty]}
            />
        </Box>
        <Box sx={sectionFormInputStyles.wideFieldWrapper}>
            <WrappedTextField
                name={`${name}.descriptionEN`}
                textFieldProps={{
                    type: "text",
                    label: "Opis EN",
                    required: true,
                    fullWidth: true,
                }}
                validate={[fieldNotEmpty]}
            />
        </Box>
        <Box sx={sectionFormInputStyles.wideFieldWrapper}>
            <WrappedTextField
                name={`${name}.comment`}
                textFieldProps={{
                    type: "text",
                    label: "Komentarz",
                    fullWidth: true,
                }}
            />
        </Box>
        <ConstantField fieldName={`${name}.sectionDefId`} fieldValue={sectionDefId} />
    </Box>
);
