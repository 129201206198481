import { Column } from "@material-table/core";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { localizationMaterialTable } from "components/common/localization/localization";
import { descend, pick, prop, sortWith } from "ramda";
import * as React from "react";
import { Link, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { Result } from "true-myth";
import { InvoiceOverview, formatInvoiceMonth, mapInvoiceMonthToWizardFormat } from "modules/Invoices/types";
import { Year, dateToYear, toDateString } from "components/common/types";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import { Table } from "components/common/ui-kit/components/Table";
import { formatMoneyForView } from "components/common/utils/numberUtils";
import { ShowOwnInvoicesHeader } from "./view/ShowOwnInvoicesHeader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const PARAM_YEAR = "year";

type RowData = Pick<
    InvoiceOverview,
    | "isEditable"
    | "invoiceMonth"
    | "totalNet"
    | "totalGross"
    | "totalNetInPln"
    | "totalGrossInPln"
    | "amountOfDays"
    | "amountOfDaysOff"
    | "currency"
> & {
    invoiceIssueDate: string;
};

const columns = (): Column<RowData>[] => [
    {
        title: "Miesiąc",
        field: "invoiceMonth",
        type: "date",
        defaultSort: "desc",
        render: ({ invoiceMonth }) => <span>{formatInvoiceMonth(invoiceMonth)}</span>,
    },
    { title: "Przepracowane dni", field: "amountOfDays" },
    { title: "Dni wolne", field: "amountOfDaysOff" },
    { title: "Data wystawienia", field: "invoiceIssueDate", sorting: false },
    { title: "Numer faktury", field: "invoiceNumber" },
    {
        title: "Kwota netto",
        field: "totalNet",
        render: ({ totalNet, currency }) => formatMoneyForView(totalNet, currency),
    },
    {
        title: "Kwota brutto",
        field: "totalGross",
        render: ({ totalGrossInPln, currency }) => formatMoneyForView(totalGrossInPln, currency),
    },
    {
        field: "isEditable",
        render: ({ isEditable, invoiceMonth }) =>
            isEditable ? (
                <Tooltip title={`Edytuj rozliczenie za miesiąc ${formatInvoiceMonth(invoiceMonth)}`}>
                    <Button
                        variant="outlined"
                        component={Link}
                        to={`/invoices/add/${mapInvoiceMonthToWizardFormat(invoiceMonth)}/form/6`}
                        onClick={event => {
                            event.stopPropagation();
                        }}
                    >
                        <ModeEditIcon /> Edytuj
                    </Button>
                </Tooltip>
            ) : (
                <></>
            ),
    },
];

const toRowData = (invoices: InvoiceOverview[]): RowData[] =>
    invoices.map(invoice => ({
        ...pick(
            [
                "id",
                "isEditable",
                "invoiceMonth",
                "totalNet",
                "totalNetInPln",
                "totalGross",
                "totalGrossInPln",
                "amountOfDays",
                "amountOfDaysOff",
                "invoiceNumber",
                "currency",
            ],
            invoice,
        ),
        invoiceIssueDate: invoice.invoiceIssueDate.map(toDateString).unwrapOr("N/A"),
    }));

export const ShowOwnInvoices: React.FC = () => {
    const navigate = useNavigate();

    const loadedDataResult = (useLoaderData() as Result<InvoiceOverview[], Error>).map(
        sortWith<InvoiceOverview>([descend(prop("invoiceNumber"))]),
    );

    const availableYears = Array.from(
        new Set([
            ...loadedDataResult
                .map(invoices =>
                    invoices.length == 0
                        ? [new Date().getFullYear()]
                        : invoices.map(invoice => new Date(invoice.invoiceMonth).getFullYear()),
                )
                .unwrapOr([]),
            new Date().getFullYear(),
        ]),
    ).sort((a, b) => a - b);

    const [searchParams, setSearchParams] = useSearchParams();

    const getYear = (availableYears: number[]) => {
        const yearQueryParam = searchParams.get(PARAM_YEAR);
        const yearParam = yearQueryParam ? parseInt(yearQueryParam) : Number.NaN;
        if (isNaN(yearParam)) {
            return dateToYear(new Date());
        } else {
            const min = availableYears[0];
            const max = availableYears[availableYears.length - 1];
            return yearParam >= min && yearParam <= max ? yearParam : yearParam < min ? min : max;
        }
    };

    const year = getYear(availableYears);

    const filteredData = loadedDataResult.map(item => item.filter(i => dateToYear(i.invoiceMonth) == year));

    const totalNetInYear = filteredData
        .map(invoicesInAYear => invoicesInAYear.reduce((sum, invoice) => sum + invoice.totalNetInPln, 0))
        .unwrapOr(0);
    const totalDays = filteredData
        .map(invoicesInAYear => invoicesInAYear.reduce((sum, invoice) => sum + invoice.amountOfDays, 0))
        .unwrapOr(0);
    const totalDaysOff = filteredData
        .map(invoicesInAYear => invoicesInAYear.reduce((sum, invoice) => sum + invoice.amountOfDaysOff, 0))
        .unwrapOr(0);

    const onRowClick = ({ invoiceMonth }: RowData) => {
        navigate(`./${toDateString(invoiceMonth)}`);
    };

    const handleChangeDate = (newYear: Year) => {
        setSearchParams(params => {
            params.set(PARAM_YEAR, newYear.toString());
            return params;
        });
    };

    return (
        <>
            <Grid container sx={{ marginBottom: "2rem" }}>
                <Grid item xs={12}>
                    <Typography variant="h2">Moje rozliczenia</Typography>
                </Grid>

                <Grid item xs={8}>
                    <ShowOwnInvoicesHeader
                        totalNetInYear={totalNetInYear}
                        totalDays={totalDays}
                        totalDaysOff={totalDaysOff}
                        currentYear={year}
                        availableYears={availableYears}
                        onYearChange={handleChangeDate}
                    ></ShowOwnInvoicesHeader>
                </Grid>
                <Grid item container xs={4} justifyContent={"end"} alignSelf={"center"}>
                    <Button variant="contained" component={Link} to={"/invoices/add"}>
                        Dodaj rozliczenie
                    </Button>
                </Grid>
            </Grid>
            {filteredData
                .map(unwrappedData => (
                    <Table<RowData>
                        key={0}
                        options={{
                            showTitle: false,
                            search: false,
                            selection: false,
                            tableLayout: "fixed",
                            showTextRowsSelected: false,
                            paging: false,
                            toolbar: false,
                            headerStyle: boldHeaderStyle(),
                        }}
                        onRowClick={(_, rowData) => rowData && onRowClick(rowData)}
                        columns={columns()}
                        data={toRowData(unwrappedData)}
                        localization={localizationMaterialTable}
                    />
                ))
                .unwrapOrElse(err => (
                    <p>An error occurred: {err.message}</p>
                ))}
        </>
    );
};
