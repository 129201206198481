import * as React from "react";
import MaterialTable, { Column, MaterialTableProps } from "@material-table/core";
import { Box } from "@mui/material";
import { omit } from "ramda";

interface Props<RowData extends object> extends MaterialTableProps<RowData> {
    actionsColumn?: ((rowData: RowData) => React.ReactNode) | Column<RowData>;
}

const actionsWrapperClassName = "actions-wrapper";

const Table = <RowData extends object>({ actionsColumn, ...tableProps }: Props<RowData>) => {
    const tableColumns = React.useMemo(() => {
        if (actionsColumn) {
            const placeholderBox = (
                <Box
                    // Placeholder for rows without actions
                    style={{
                        height: "2rem",
                        content: "",
                    }}
                ></Box>
            );
            const column: Column<RowData> = {
                render: data => (
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        className={actionsWrapperClassName}
                    >
                        {typeof actionsColumn === "function"
                            ? actionsColumn(data)
                            : actionsColumn.render && actionsColumn.render(data)}
                        {placeholderBox}
                    </div>
                ),
                ...(typeof actionsColumn === "function" ? {} : omit(["render"], actionsColumn)),
            };
            return [...tableProps.columns, column];
        }
        return tableProps.columns;
    }, [tableProps.columns, actionsColumn]);

    return (
        <MaterialTable
            {...tableProps}
            columns={tableColumns}
            sx={{
                [`tr:hover .${actionsWrapperClassName}`]: {
                    visibility: "visible",
                },
                [`tr .${actionsWrapperClassName}`]: {
                    visibility: "hidden",
                },
                ...tableProps.sx,
            }}
            style={{ ...tableProps.style }}
        />
    );
};

Table.actionsWrapperClassName = actionsWrapperClassName;

export { Table };
