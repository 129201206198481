import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import { YearMonth } from "components/common/types";
import { MonthlySettlementFormDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import {
    EligibleToInvoice,
    EligibleToInvoiceRequestData,
    InvoiceAttachment,
    MonthlySettlement,
    MonthlySettlementSummary,
    PendingPayment,
    PendingPaymentInput,
    YearMonthDto,
    YearMonthWithRate,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import { convertMonthlySettlementSummary } from "./factory";
import { parseYearMonth, pendingPaymentToPendingPaymentInput } from "./formService";
import { MonthlySettlementSummaryDto } from "./protocol";

export const getMonthlySettlementForm =
    (apiGet: ApiGet<MonthlySettlementFormDef>) =>
    ({ month, year }: YearMonth): AsyncResult<MonthlySettlementFormDef> =>
        apiGet(`/monthlySettlement/form?month=${month}&year=${year}`);

export const getMonthlySettlementFormData =
    (apiGet: ApiGet<MonthlySettlement>) =>
    ({ month, year }: YearMonth): AsyncResult<MonthlySettlement> =>
        apiGet(`/monthlySettlement/data?month=${month}&year=${year}`);

export const saveMonthlySettlementFormData =
    (apiPost: ApiUpdate<MonthlySettlement, MonthlySettlement>) =>
    (data: MonthlySettlement): AsyncResult<MonthlySettlement> =>
        apiPost(`/monthlySettlement/save`, data);

export const getFormSummary =
    (apiGet: ApiGet<MonthlySettlementSummaryDto>) =>
    ({ month, year }: YearMonth): AsyncResult<MonthlySettlementSummary> =>
        apiGet(`/monthlySettlement/summary?month=${month}&year=${year}`).then(res =>
            res.map(convertMonthlySettlementSummary),
        );

export const getAvailableMonthsWithRates =
    (apiGet: ApiGet<YearMonthDto[]>) =>
    ({ month, year }: YearMonth): AsyncResult<YearMonthWithRate[]> =>
        apiGet(`/monthlySettlement/months?month=${month}&year=${year}`).then(res =>
            res.map(monthsDto => monthsDto.map(parseYearMonth)),
        );

export const uploadInvoice =
    (apiPost: ApiUpdate<FormData, InvoiceAttachment>) =>
    (file: File): AsyncResult<InvoiceAttachment> => {
        const formData = new FormData();
        formData.append("invoiceFile", file);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return apiPost("/monthlySettlement/upload", formData, config);
    };

export const savePendingPayment =
    (apiPost: ApiUpdate<{ pendingPayment: PendingPaymentInput }, MonthlySettlementSummary>) =>
    (pendingPayment: PendingPayment): AsyncResult<MonthlySettlementSummary> =>
        apiPost("/monthlySettlement/confirm", {
            pendingPayment: pendingPaymentToPendingPaymentInput(pendingPayment),
        });

export const verifyIsEligibleToInvoice =
    (apiGet: ApiGet<EligibleToInvoice>) =>
    ({
        nip,
        accountNumber,
        paysVat,
        invoiceIssueDate,
    }: EligibleToInvoiceRequestData): AsyncResult<EligibleToInvoice> => {
        const query = `nip=${nip}&accountNumber=${accountNumber}&paysVat=${paysVat}&invoiceIssueDate=${invoiceIssueDate}`;
        return apiGet(`/invoiceSubmit/verifyIsEligibleToInvoice?${query}`);
    };

export const getExchangeRateForDate =
    (apiGet: ApiGet<number>) =>
    (currency: string, date: string): AsyncResult<number> =>
        apiGet(`/monthlySettlement/rate?currency=${currency}&date=${date}`);
