import { Box, Button, Container, Grid, Paper, Popover, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { EmployeeDetails, formatEmployeeSpecAndRate, PositionAssignment } from "../../types";
import { mapEmployeeDTO, mapVacancyDTO } from "../../../Projects/types";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import {
    MUI_INPUT_DATE_STRING_FORMAT,
    Nullable,
    timeDiffInYearsAndDays,
    toDateString,
} from "../../../../components/common/types";
import { ActionContextMenu } from "../../../../components/common/ui-kit/components/ActionContextMenu";
import { CommonDatePicker } from "../../../../components/common/ui-kit/components/DatePicker/DatePicker";
import { Dayjs } from "dayjs";
import { EmployeePositionsList } from "./EmployeePositionsList";
import { EmployeeVacanciesList } from "./EmployeeVacanciesList";
import { AsyncResult } from "components/common/infractructure";
import toast from "react-hot-toast";
import { MUI_INPUT_YEAR_MONTH_STRING_FORMAT } from "../../../../components/common/types";
import { dateToYearMonthString, isNotBlank } from "components/common/utils";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import DiscordIcon from "./DiscordIcon";

interface Props {
    promoteContractor: (username: string, yearMonthFrom: string) => AsyncResult<void>;
    fireContracotr: (username: string, endDate: string) => AsyncResult<void>;
}

export const EmployeeDetail: React.FC<Props> = props => {
    const today = toDateString(new Date());
    const loaderDataResult = useLoaderData() as Result<EmployeeDetails, Error>;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [fireAnchorEl, setFireAnchorEl] = React.useState<HTMLElement | null>(null);

    const [promoteDate, setPromoteDate] = React.useState(today);
    const [fireDate, setFireDate] = React.useState(today);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setSelectedTab(newValue);
    };

    const handlePromoteContractor = () => {
        const actionMenuElement = document.getElementById("actionContextMenuWrapper");
        setAnchorEl(actionMenuElement);
    };

    const handleFireContractor = () => {
        const actionMenuElement = document.getElementById("actionContextMenuWrapper");
        setFireAnchorEl(actionMenuElement);
    };

    const handleChangeDate = (date: Nullable<Dayjs>) => {
        setPromoteDate(
            Maybe.of(date)
                .map(d => d.toDate())
                .map(toDateString)
                .unwrapOr(today),
        );
    };

    const handleChangeFireDate = (date: Nullable<Dayjs>) => {
        setFireDate(
            Maybe.of(date)
                .map(d => d.toDate())
                .map(toDateString)
                .unwrapOr(today),
        );
    };

    const closePromoteDialog = () => {
        setAnchorEl(null);
    };

    const closeFireDialog = () => {
        setFireAnchorEl(null);
    };

    const openPromoteDialog = Boolean(anchorEl);
    const openFireDialog = Boolean(fireAnchorEl);

    const id = openPromoteDialog ? "simple-popover" : undefined;
    const id2 = openFireDialog ? "simple-popover2" : undefined;

    const handlePromoteClick = async (pa: PositionAssignment) => {
        setAnchorEl(null);
        const res = await props.promoteContractor(
            pa.employeeEmail.split("@")[0],
            dateToYearMonthString(new Date(promoteDate)),
        );
        if (res.isOk()) {
            toast.success("Awansowano!");
        } else {
            toast.error("Błąd podczas awansowania!");
        }
        window.location.reload();
    };

    const handleFireClick = async (pa: PositionAssignment) => {
        setAnchorEl(null);
        const res = await props.fireContracotr(pa.employeeEmail.split("@")[0], fireDate);
        if (res.isOk()) {
            toast.success("Zwolniono!");
        } else {
            toast.error("Błąd podczas zwalniania!");
        }
        window.location.reload();
    };

    return loaderDataResult
        .map(({ employee, vacancies, positions, contractorData }) => (
            <Container key={employee.id}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                            <Grid xs={8} item>
                                <Typography sx={{ marginBottom: "10px" }} variant="h6">
                                    Szczegóły pracownika
                                </Typography>
                            </Grid>
                        </Grid>
                        <Paper>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Box sx={{ display: "flex" }}>
                                        <>
                                            {
                                                <EmployeeAvatar
                                                    sx={{ width: "100px", height: "100px", marginRight: "20px" }}
                                                    employee={mapEmployeeDTO(employee)}
                                                />
                                            }
                                        </>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography variant="h4">
                                                {" "}
                                                {employee.firstName + " " + employee.lastName}
                                            </Typography>
                                            <Box>{formatEmployeeSpecAndRate(employee)} </Box>
                                            <Box
                                                sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}
                                            >
                                                <Box sx={{ my: 4, display: "flex", flexDirection: "column" }}>
                                                    {contractorData ? (
                                                        <>
                                                            <Typography variant={"h6"}>Dane Firmy:</Typography>
                                                            <Box>{contractorData.companyName}</Box>
                                                            <Box>NIP: {contractorData.nip}</Box>
                                                            <Box>Konto: {contractorData.accountNumber}</Box>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {}
                                                </Box>
                                                <Box sx={{ px: 2, my: 4, display: "flex", flexDirection: "column" }}>
                                                    <Typography variant={"h6"}>Adres korespondencyjny:</Typography>
                                                    <Box>{contractorData?.contactAddressLine1}</Box>
                                                    <Box>{contractorData?.contactAddressLine2}</Box>
                                                    {isNotBlank(contractorData?.parcelLocker) && (
                                                        <Box>Paczkomat: {contractorData?.parcelLocker}</Box>
                                                    )}
                                                </Box>
                                                <Box sx={{ my: 4, display: "flex", flexDirection: "column" }}>
                                                    <Typography variant={"h6"}>Pozostałe Informacje:</Typography>
                                                    <Box>
                                                        Data dołączenia: {employee.startDate}{" "}
                                                        {timeDiffInYearsAndDays(employee.startDate)}
                                                    </Box>
                                                    {isNotBlank(contractorData?.passportExpirationDate) && (
                                                        <Box>
                                                            Data ważnosci paszportu:{" "}
                                                            {contractorData?.passportExpirationDate}
                                                        </Box>
                                                    )}
                                                    {isNotBlank(contractorData?.gitHubHandle) && (
                                                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                                                            <GitHubIcon sx={{ marginRight: 1 }} />{" "}
                                                            {contractorData?.gitHubHandle}
                                                        </Box>
                                                    )}
                                                    {isNotBlank(contractorData?.twitterHandle) && (
                                                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                                                            <TwitterIcon sx={{ marginRight: 1 }} />{" "}
                                                            {contractorData?.twitterHandle}
                                                        </Box>
                                                    )}
                                                    {isNotBlank(contractorData?.discordHandle) && (
                                                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                                                            <DiscordIcon sx={{ marginRight: 1 }} />{" "}
                                                            {contractorData?.discordHandle}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            {}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{ display: "flex" }} justifyContent={"flex-end"}>
                                    <Popover
                                        id={id}
                                        open={openPromoteDialog}
                                        anchorEl={anchorEl}
                                        onClose={closePromoteDialog}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item>
                                                <CommonDatePicker
                                                    datePicker={{
                                                        disableFuture: false,
                                                        views: ["year", "month"],
                                                        label: "Data awansu",
                                                        inputFormat: MUI_INPUT_YEAR_MONTH_STRING_FORMAT,
                                                        value: promoteDate,
                                                        onChange: handleChangeDate,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    sx={{ marginTop: "15px" }}
                                                    onClick={() => handlePromoteClick(positions[0])}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Awansuj
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Popover>
                                    <Popover
                                        id={id2}
                                        open={openFireDialog}
                                        anchorEl={fireAnchorEl}
                                        onClose={closeFireDialog}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item>
                                                <CommonDatePicker
                                                    datePicker={{
                                                        disableFuture: false,
                                                        views: ["year", "month", "day"],
                                                        label: "Data zwolnienia",
                                                        inputFormat: MUI_INPUT_DATE_STRING_FORMAT,
                                                        value: fireDate,
                                                        onChange: handleChangeFireDate,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    sx={{ marginTop: "15px" }}
                                                    onClick={() => handleFireClick(positions[0])}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Zwolnij
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Popover>
                                    <div id="actionContextMenuWrapper">
                                        {employee.isActive && (
                                            <ActionContextMenu
                                                items={[
                                                    {
                                                        name: "Awansuj",
                                                        onClick: () => handlePromoteContractor(),
                                                    },
                                                    {
                                                        name: "Zwolnij",
                                                        onClick: () => handleFireContractor(),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ marginBottom: "10px" }} variant="h6">
                            Pozycje
                        </Typography>

                        <Paper>
                            <EmployeePositionsList positions={positions} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ marginBottom: "10px" }} variant="h6">
                            Etaty
                        </Typography>

                        <Paper>
                            <Tabs value={selectedTab} onChange={handleChangeActiveTab}>
                                <Tab label="Komercyjne" />
                                <Tab label="Wewnętrzne" />
                            </Tabs>

                            <Box>
                                <div hidden={selectedTab == 1}>
                                    <EmployeeVacanciesList
                                        vacancies={vacancies.map(v => mapVacancyDTO(v))}
                                        onlyCommercial={true}
                                    />
                                </div>
                                <div hidden={selectedTab == 0}>
                                    <EmployeeVacanciesList
                                        vacancies={vacancies.map(v => mapVacancyDTO(v))}
                                        onlyCommercial={false}
                                    />
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        ))
        .unwrapOrElse(err => <p>{err.message}</p>);
};
