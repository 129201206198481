import { AsyncResult } from "../components/common/infractructure";
import React from "react";
import { asyncResultToast, AsyncResultToastOptions } from "./asyncResultToast";
import { clone, equals } from "ramda";

export type AsyncReducer<State, Action> = (
    state: State,
    action: Action,
) => {
    optimisticState: State;
    syncRemoteState: () => AsyncResult<State>;
};

export const useAsyncReducer = <State, Action>(reducer: AsyncReducer<State, Action>, initialState: State) => {
    const [state, setState] = React.useState(initialState);

    const dispatch = (action: Action, withToast?: AsyncResultToastOptions<State>) => {
        const { syncRemoteState, optimisticState } = reducer(state, action);
        setState(optimisticState);
        const cachedState = clone(state);

        const dispatchAsyncAction = withToast
            ? () =>
                  asyncResultToast<State>(syncRemoteState(), {
                      loading: withToast.loading,
                      success: data => {
                          return typeof withToast.success === "function" ? withToast.success(data) : withToast.success;
                      },
                      error: error => {
                          return typeof withToast.error === "function" ? withToast.error(error) : withToast.error;
                      },
                  })
            : syncRemoteState;

        dispatchAsyncAction().then(result =>
            result.match({
                Ok: updatedState => {
                    if (!equals(updatedState, optimisticState)) {
                        setState(updatedState);
                    }
                },
                Err: () => setState(cachedState),
            }),
        );
    };

    return [state, dispatch] as const;
};
