import * as React from "react";
import { Box, Divider, Pagination, PaginationItem } from "@mui/material";
import { Typography } from "@mui/material";
import { formatMoneyForView } from "components/common/utils/numberUtils";
import { ChangeEvent } from "react";
import { Year } from "components/common/types";

interface Props {
    totalNetInYear: number;
    totalDays: number;
    totalDaysOff: number;
    currentYear: number;
    availableYears: number[];
    onYearChange: (newYear: Year) => void;
}

export const ShowOwnInvoicesHeader: React.FC<Props> = props => {
    const handleYearChange = (_event: ChangeEvent<unknown>, page: number) => {
        props.onYearChange(props.availableYears[page - 1]);
    };

    return (
        <Box
            display="flex"
            sx={{
                marginTop: "1rem",
            }}
        >
            <Pagination
                count={props.availableYears.length}
                page={
                    props.availableYears.indexOf(props.currentYear) >= 0
                        ? props.availableYears.indexOf(props.currentYear) + 1
                        : 1
                }
                renderItem={item =>
                    item.type == "next" ||
                    item.type == "previous" ||
                    item.page == props.availableYears.indexOf(props.currentYear) + 1 ? (
                        <PaginationItem {...item} page={props.currentYear}></PaginationItem>
                    ) : null
                }
                showFirstButton={false}
                showLastButton={false}
                onChange={handleYearChange}
            ></Pagination>

            <Typography variant={"subtitle1"}>
                Przepracowane dni <b>{props.totalDays}</b>
            </Typography>
            <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
            <Typography variant={"subtitle1"}>
                Wykorzystany urlop <b>{props.totalDaysOff}</b>
            </Typography>
            <Divider orientation="vertical" variant={"middle"} flexItem sx={{ ml: "1rem", mr: "1rem" }} />
            <Typography variant={"subtitle1"}>
                Suma netto <b>{formatMoneyForView(props.totalNetInYear, "PLN")}</b>
            </Typography>
        </Box>
    );
};
