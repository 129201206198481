export interface PendingBillingData {
    billingId: number;
    projectName: string;
    billingMonth: string;
    billingTotalNet: number;
    currency: string;
    ready: boolean;
    accepted: boolean;
}

export interface BillingOverview extends PendingBillingData {
    done: boolean;
    booked: boolean;
    billingTotalNetInPln: number;
    sumOfDays: number;
    sumOfPeople: number;
}

export interface BillingDetailsData {
    clientName: string;
    companyName: string;
    street: string;
    city: string;
    postCode: string;
    taxCode: string;
    country: string;
    notes: string;
    currency: string;
    account: string;
}

export interface BillingPosition {
    id: number;
    quantity: number;
    unitPrice: number;
    description: string;
    unitType: string;
    recurring?: boolean;
    ipBox?: boolean;
    account: string;
}

export type AssignmentBillingData = BillingPosition;
export type CostBillingData = BillingPosition;

export interface BillingData {
    projectName: string;
    billingMonth: string;
    done: boolean;
    doneBy?: string;
    accepted: boolean;
    acceptedBy?: string;
    billingDetailsData: BillingDetailsData;
    assignmentBilling: AssignmentBillingData[];
    costBilling: CostBillingData[];
}

export type RegisteredWorkState = "REGISTERED" | "REGISTERED_LOCKED" | "NOT_REGISTERED";

export interface RegisteredWork {
    employee: string;
    amountOfDays: number;
    position: string;
    unitPrice: number;
    registeredWorkState: RegisteredWorkState;
}

export interface OtherCost {
    currency: string;
    netAmount: number;
    netAmountInBillingCurrency: number;
    billingCurrency: string;
    date: string;
    note: string;
    documentSymbol: string;
    clientName: string;
    pendingPaymentId: number | null;
    taxxoDocumentId: number | null;
    exchangeRate: number;
    exchangeRateDate: string;
}

export interface MissingMonthlyStatement {
    firstName: string;
    lastName: string;
    companyName: number;
}

export interface BillingSummary {
    registeredWork: RegisteredWork[];
    billingData: BillingData;
    otherCosts: OtherCost[];
}

export const AllBillingFilterSelectValues = ["ALL", "PENDING", "BOOKED", "CANCELED"] as const;

export type BillingFilterSelectValues = typeof AllBillingFilterSelectValues[number];

export const getBillingStatusFilterTranslations = (status: BillingFilterSelectValues): string => {
    switch (status) {
        case "ALL":
            return "Wszystkie";
        case "PENDING":
            return "Oczekujące";
        case "BOOKED":
            return "Wystawione";
        case "CANCELED":
            return "Anulowane";
    }
};
