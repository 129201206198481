import { ApiGet, AsyncResult } from "components/common/infractructure";
import { TAX_RATE } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { PaymentsSummary } from "components/common/ui-kit-business/InvoiceSummary/service/types";
import { calculateTax, sum } from "components/common/utils/numberUtils";
import { PendingPaymentPosition } from "modules/types/types";

export const getInvoiceUrl = (invoiceId: number): string => `invoice-preview/${invoiceId}`;

export const getGDInvoiceFile =
    (apiGet: ApiGet<Blob>) =>
    (sourceDocumentId: string): AsyncResult<Blob> =>
        apiGet(`googleProxy/file?id=${sourceDocumentId}`, { responseType: "blob" });

export const getGDInvoiceThumbnail =
    (apiGet: ApiGet<Blob>) =>
    (sourceDocumentId: string): AsyncResult<Blob> =>
        apiGet(`googleProxy/thumbnail?id=${sourceDocumentId}`, { responseType: "blob" });

export const trimDay = (date: string): string => date.slice(0, -3);
export const getPaymentsSummary = (positions: PendingPaymentPosition[], isVatPayer: boolean): PaymentsSummary => {
    const totalNet = sum(positions.map(({ netAmount }) => netAmount));
    const totalTax = calculateTax(totalNet.toNumber(), isVatPayer ? TAX_RATE : 0);

    return {
        totalNet: totalNet.toNumber(),
        totalTax: totalTax.toNumber(),
        totalGross: totalNet.plus(totalTax).toNumber(),
    };
};
