import { AnyObject, Currency, YearMonth } from "components/common/types";
import { rootReducer } from "modules/Invoices/views/MonthlySettlement/service/store";
import { ProjectAssignment, SummaryPaymentCategory } from "modules/types/types";

export interface GenericFormInputProps {
    name?: string;
}

export interface SectionIdentifier {
    sectionDefId: number;
}

export interface ActivityIdentifier {
    activityDefId: number;
}

export interface SettlementIdentifier {
    settlementDefId: number;
}

export interface Position extends SectionIdentifier {
    quantity: number;
    comment: string;
}

export interface CostPosition extends Position {
    unitPrice: number;
    netValue: number;
    grossValue: number;
}

export interface ClientCostPosition extends Position {
    unitPrice: number;
    value: number;
    currency: string;
    descriptionPL: string;
    descriptionEN: string;
}

export interface Activity extends ActivityIdentifier {
    id: number | undefined;
    workPositions: Position[];
    costPositions: CostPosition[];
}

export interface ProjectActivity extends Activity {
    assignmentId: number;
    clientCostPositions: ClientCostPosition[];
}

export interface MonthlySettlement extends SettlementIdentifier {
    id: number;
    yearMonth: YearMonth;
    projectActivities: ProjectActivity[];
    activities: Activity[];
}

export const instanceOfProjectActivity = (obj: AnyObject): obj is ProjectActivity => "assignmentId" in obj;

export type GlobalState = ReturnType<typeof rootReducer>;

export const MONTHLY_SETTLEMENT_FORM = "monthlySettlementForm";
export const PENDING_PAYMENT_FORM = "pendingPaymentForm";

interface MonthlySettlementProjectAssigment extends ProjectAssignment {
    id: number;
}

export interface MonthlySettlementPendingPaymentPosition {
    id: number;
    isMain: boolean;
    unitPrice: number;
    unitPriceInPLN: number;
    quantity: number;
    amountOfDays: number;
    netAmount: number;
    taxAmount: number;
    grossAmount: number;
    netAmountInPLN: number;
    taxAmountInPLN: number;
    grossAmountInPLN: number;
    note: string;
    account: string;
    category: SummaryPaymentCategory;
    vacancy: MonthlySettlementProjectAssigment | null;
}

export interface PendingPaymentPositionInput extends Omit<MonthlySettlementPendingPaymentPosition, "vacancy"> {
    projectAssignmentId: number | null;
}

export interface ContractorData {
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    nip: string;
    accountNumber: string;
    isVatPayer: boolean;
    isInvoiceAttached: boolean;
    isMonthlyCharged: boolean;
    currency: Currency;
}

type PendingPaymentStatus = "WAITING_FOR_PAYMENT" | "PAID" | "BOOKED";

export interface PendingPayment {
    id: number;
    status: { name: PendingPaymentStatus; type: string };
    invoiceMonth: YearMonth;
    contractorData: ContractorData;
    invoiceIssueDate: string;
    invoiceNumber: string;
    sourceDocumentId: string;
    totalNet: number;
    totalTax: number;
    totalGross: number;
    totalNetInPLN: number;
    totalTaxInPLN: number;
    totalGrossInPLN: number;
    rate: number;
    positions: MonthlySettlementPendingPaymentPosition[];
}

export interface PendingPaymentInput extends Omit<PendingPayment, "positions" | "contractorData"> {
    contractor: ContractorData;
    positions: PendingPaymentPositionInput[];
}

type InvoiceAttachmentStatus = "TEMP" | "WAITING_FOR_PAYMENT" | "PAID";

export interface InvoiceAttachment {
    id: string;
    name: string;
    previewUrl: string;
    url: string;
    status: { name: InvoiceAttachmentStatus; type: string };
}

export interface MonthlySettlementSummary {
    yearMonth: YearMonth;
    workingDays: number;
    pendingPaymentData: PendingPayment;
    invoiceAttachment: Partial<InvoiceAttachment>;
}

export interface YearMonthDto {
    yearMonth: string;
    rate: number;
    workingDays: number;
    hasInvoice: boolean;
    rateRaise: boolean;
}

export interface YearMonthWithRate extends YearMonth {
    rate: number;
    workingDays: number;
    hasInvoice: boolean;
    rateRaise: boolean;
}

export interface EligibleToInvoiceRequestData {
    nip: string;
    accountNumber: string;
    paysVat: boolean;
    invoiceIssueDate: string;
}

export interface EligibleToInvoice {
    message: string;
    isEligible: boolean;
}
