import * as React from "react";
import IframeResizer from "iframe-resizer-react";
import { Box, Grid, Paper, Typography } from "@mui/material";

export const RegisterBadge: React.FC = () => {
    return (
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom: "2rem" }}>
                <Typography variant="h2">Zgłoś badge&apos;a</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ bgcolor: "white" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <IframeResizer
                            autoResize={false}
                            checkOrigin={false}
                            src="https://form.typeform.com/to/fKb7IlqN?typeform-embed-id=9760346791221819&amp;typeform-embed=embed-widget&amp;typeform-source=localhost&amp;typeform-medium=embed-sdk&amp;typeform-medium-version=next"
                            style={{
                                border: "0",
                                width: "100%",
                                maxWidth: "1000px",
                                height: "1000px",
                                display: "block",
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};
