import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { AnyObject } from "components/common/types";
import { GenericFormInputProps, Position } from "modules/Invoices/views/MonthlySettlement/domain/types";
import * as React from "react";
import { FieldArray, FieldArrayFieldsProps } from "redux-form";

interface GenericSectionRowProps<T extends GenericFormInputProps> {
    inputProps: T;
    Component: React.ComponentType<T>;
}

const getSectionRowRenderer = <T extends GenericFormInputProps, P extends Position>(
    params: GenericSectionRowProps<T>,
    fields: FieldArrayFieldsProps<P | AnyObject>,
) => {
    const { inputProps, Component } = params;

    return (name: string, index: number) => {
        const removePosition = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            fields.remove(index);
        };
        return (
            <Box
                sx={{
                    display: "flex",
                    backgroundColor: "background.paper",
                    marginTop: 0.5,
                    marginBottom: 0.5,
                    padding: "1rem",
                    borderRadius: "2px",
                    boxShadow: 1,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        marginRight: 1,
                    }}
                >
                    <Component name={name} {...inputProps} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                    }}
                >
                    <Tooltip title="Usuń pozycję">
                        <IconButton
                            type="button"
                            onClick={removePosition}
                            sx={{
                                color: "secondary.main",
                                "&:hover": {
                                    backgroundColor: red["50"],
                                    color: red["900"],
                                },
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    };
};

const getSectionContentRenderer = <T extends GenericFormInputProps, P extends Position>(
    sectionRowProps: GenericSectionRowProps<T>,
) => {
    return (param: { fields: FieldArrayFieldsProps<P | AnyObject> }) => {
        const addPosition = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            param.fields.push({});
        };
        const renderSectionRow = getSectionRowRenderer<T, P>(sectionRowProps, param.fields);
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {param.fields.map((name: string, index: number) => (
                    <React.Fragment key={index}>{renderSectionRow(name, index)}</React.Fragment>
                ))}
                <Box sx={{ marginTop: 1 }}>
                    <Tooltip title="Dodaj nową pozycję">
                        <IconButton
                            type="button"
                            onClick={addPosition}
                            sx={{
                                color: "white",
                                backgroundColor: "primary.main",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "custom.hover",
                                },
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    };
};

interface GenericSectionProps<T extends GenericFormInputProps> {
    title: string;
    instructions: string;
    fieldArrayName: string;
    sectionRowProps: GenericSectionRowProps<T>;
}

export const GenericSection = <T extends GenericFormInputProps, P extends Position>({
    sectionRowProps,
    title,
    instructions,
    fieldArrayName,
}: GenericSectionProps<T>) => {
    const renderSectionContent = getSectionContentRenderer<T, P>({
        ...sectionRowProps,
    });

    return (
        <Box
            sx={{
                backgroundColor: "custom.dim",
                padding: 2,
                marginBottom: 2,
                borderRadius: ".5rem",
            }}
        >
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <Typography variant="body1">{title}</Typography>
                    {instructions && <Typography variant="caption">{instructions}</Typography>}
                </Grid>
                <Grid item>
                    <FieldArray name={fieldArrayName} component={renderSectionContent} props={{}} />
                </Grid>
            </Grid>
        </Box>
    );
};
