import { Box, Grid, Typography } from "@mui/material";
import { useData } from "components/common/hooks/useData";
import { AsyncResult } from "components/common/infractructure";
import {
    BillingFilterSelectValues,
    BillingOverview,
    getBillingStatusFilterTranslations,
    PendingBillingData,
} from "modules/Billing/types";
import { CANNOT_RETRIEVE_PENDINGS } from "modules/Billing/messages";
import * as React from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { PendingBillingsTable } from "./PendingBillingsTable";
import { dateToYearMonthString, strToYearMonth } from "components/common/utils";
import { BillingFilter } from "./BillingFilter";
import { useEffect, useState } from "react";
import { BillingStats } from "./BillingStats";
import { MissingMonthlyStatements } from "./MissingMonthlyStatements";
import { Employee } from "../../../Projects/types";
import { subMonths } from "date-fns";
import { Maybe } from "true-myth";
import Loader from "components/Loader/Loader";

interface Props {
    getBillingsOverview: (yearMonth: string) => AsyncResult<BillingOverview[]>;
    getMissingMonthlySettlements: (yearMonth: string) => AsyncResult<Employee[]>;
    sendMissingSettlementNotification: (userId: number, yearMonth: string) => AsyncResult<void>;
}

const PARAM_YEAR_MONTH = "yearMonth";
const PARAM_TYPE = "type";

export const BillingsView: React.FC<Props> = ({
    getBillingsOverview,
    getMissingMonthlySettlements,
    sendMissingSettlementNotification,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialDate = new Date().getDate() > 25 ? new Date() : subMonths(new Date(), 1);
    const navigate = useNavigate();

    const [currentlySelectedDate, setCurrentDate] = useState(
        Maybe.of(searchParams.get(PARAM_YEAR_MONTH))
            .flatMap(p => strToYearMonth(p))
            .map(yearMonth => new Date(yearMonth.year, yearMonth.month - 1, 1))
            .unwrapOr(initialDate),
    );
    const [currentBillingType, setCurrentBillingType] = useState(
        (searchParams.get(PARAM_TYPE) as BillingFilterSelectValues) || ("PENDING" as BillingFilterSelectValues),
    );

    const billingMatchesFilter = (billing: BillingOverview): boolean => {
        if (currentBillingType == "CANCELED") {
            return billing.done && !billing.booked;
        } else if (currentBillingType == "BOOKED") {
            return billing.done && billing.booked;
        } else if (currentBillingType == "PENDING") {
            return !billing.done;
        }
        return true;
    };

    const {
        data: billings,
        isLoading,
        loadData,
    } = useData<BillingOverview[]>({
        loader: () => getBillingsOverview(dateToYearMonthString(currentlySelectedDate)),
        onError: () => toast.error(CANNOT_RETRIEVE_PENDINGS),
    });

    useEffectOnce(() => {
        loadData();
    });

    useEffect(() => {
        setSearchParams(params => {
            params.set(PARAM_YEAR_MONTH, dateToYearMonthString(currentlySelectedDate));
            params.set(PARAM_TYPE, currentBillingType);
            return params;
        });
    }, [currentlySelectedDate, currentBillingType]);

    useEffect(() => {
        loadData();
    }, [currentlySelectedDate]);

    let filteredBillings = billings.map(b => b.filter(bi => billingMatchesFilter(bi)));

    useEffect(() => {
        filteredBillings = billings.map(b => b.filter(bi => billingMatchesFilter(bi)));
    }, [currentBillingType]);

    const onBillingSelected = ({ billingId }: PendingBillingData) => {
        navigate(`/billing/summary/${billingId}`);
    };

    const onChangeYearMonth = (newDate: Date): void => {
        setCurrentDate(newDate);
    };

    const sumInPln = filteredBillings
        .map(billingsData => billingsData.reduce((sum, billing) => sum + billing.billingTotalNetInPln, 0))
        .unwrapOr(0);

    const sumOfDays = filteredBillings
        .map(billingsData => billingsData.reduce((sum, billing) => sum + billing.sumOfDays, 0))
        .unwrapOr(0);

    const billingsTable = filteredBillings
        .map(billingsData => (
            <Box sx={{ margin: "1rem 0" }} key={0}>
                <PendingBillingsTable
                    billingType={currentBillingType}
                    pendingBillings={billingsData}
                    onBillingSelected={onBillingSelected}
                />
            </Box>
        ))
        .unwrapOr(<></>);

    function onChangeBillingType(type: string): void {
        setCurrentBillingType(type as BillingFilterSelectValues);
    }

    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                <Typography variant="h2">
                    {getBillingStatusFilterTranslations(currentBillingType) + " faktury sprzedażowe"}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BillingFilter
                    currentTypeSelection={currentBillingType}
                    onChangeBillingType={onChangeBillingType}
                    currentDateSelection={currentlySelectedDate}
                    onChangeYearMonth={onChangeYearMonth}
                    sumInPln={sumInPln}
                ></BillingFilter>
            </Grid>
            <Grid item xs={12}>
                <BillingStats sumInPln={sumInPln} sumOfDays={sumOfDays}></BillingStats>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? <Loader /> : billingsTable}
            </Grid>
            <Grid item>
                <MissingMonthlyStatements
                    currentDate={currentlySelectedDate}
                    getMissingMonthlySettlements={getMissingMonthlySettlements}
                    sendMissingSettlementNotification={sendMissingSettlementNotification}
                ></MissingMonthlyStatements>
            </Grid>
        </Grid>
    );
};
