import { Drawer, Grid, Typography } from "@mui/material";
import Loader from "components/Loader/Loader";
import { useData } from "components/common/hooks/useData";
import { AsyncResult, apiPost } from "components/common/infractructure";
import {
    DateRange,
    DateString,
    Nullable,
    dateRange as getDateRange,
    dateStringToStrYearMonth,
} from "components/common/types";
import { subMonths } from "date-fns";
import { SalesFilter } from "modules/SalesSummary/SalesFilter";
import { SalesSummaryTable } from "modules/SalesSummary/SalesSummaryTable";
import { InvoiceData, SalesAggregation } from "modules/SalesSummary/domain/types";
import { LOADING_SALES_FAILED } from "modules/SalesSummary/messages";
import { importTaxxoInvoices } from "modules/SalesSummary/service";
import { filterSalesWithoutProjects } from "modules/SalesSummary/service/salesSummaryService";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { Maybe } from "true-myth";
import { PositionsPreview } from "./PositionsPreview";
import * as React from "react";
import { onKeyboardOrMouseEvent } from "components/common/ui-kit/components/Drawer";

const PARAM_FROM_DATE = "fromDate";
const PARAM_TO_DATE = "toDate";
const PARAM_SHOW_NON_PROJECT_INVOICES = "showNonProjectInvoices";

interface Props {
    getProjectSales: (salesFrom: string, salesTo: string) => AsyncResult<SalesAggregation>;
}

export const SalesSummaryView: React.FC<Props> = ({ getProjectSales }) => {
    const now = new Date();
    const defaultRange = getDateRange(subMonths(now, 5), now);

    const [searchParams, setSearchParams] = useSearchParams();

    const [dateRange, setDataRange] = useState<DateRange>(
        Maybe.all(
            Maybe.of<DateString>(searchParams.get(PARAM_FROM_DATE) as Nullable<DateString>),
            Maybe.of<DateString>(searchParams.get(PARAM_TO_DATE) as Nullable<DateString>),
        )
            .map(([from, to]) => ({
                from,
                to,
            }))
            .unwrapOr(defaultRange),
    );
    const [showNonProjectInvoices, setShowNonProjectInvoices] = useState<boolean>(
        searchParams.get(PARAM_SHOW_NON_PROJECT_INVOICES) === "true",
    );
    const [invoiceDetails, setInvoiceDetails] = useState<Maybe<InvoiceData>>(Maybe.nothing());

    const toggleInvoiceDrawer = (invoiceDataOp: Maybe<InvoiceData>) =>
        onKeyboardOrMouseEvent(() => setInvoiceDetails(invoiceDataOp));

    const {
        data: salesAggregation,
        isLoading,
        loadData,
    } = useData<SalesAggregation>({
        loader: () => getProjectSales(dateStringToStrYearMonth(dateRange.from), dateStringToStrYearMonth(dateRange.to)),
        onError: () => toast.error(LOADING_SALES_FAILED),
    });

    useEffect(() => {
        loadData();
        setSearchParams({
            [PARAM_SHOW_NON_PROJECT_INVOICES]: `${showNonProjectInvoices}`,
            [PARAM_FROM_DATE]: dateRange.from,
            [PARAM_TO_DATE]: dateRange.to,
        });
    }, [dateRange]);

    useEffect(() => {
        if (searchParams.get(PARAM_SHOW_NON_PROJECT_INVOICES) !== showNonProjectInvoices.toString()) {
            setSearchParams(params => {
                params.set(PARAM_SHOW_NON_PROJECT_INVOICES, showNonProjectInvoices.toString());
                return params;
            });
        }
    }, [showNonProjectInvoices, searchParams]);

    const salesTable = salesAggregation
        .map(({ salesYearMonthRange, clientSales }) => {
            const filteredClientSales = showNonProjectInvoices ? clientSales : filterSalesWithoutProjects(clientSales);
            return (
                <SalesSummaryTable
                    key={0}
                    salesYearMonthRange={salesYearMonthRange}
                    clientSales={filteredClientSales}
                    onShowDetails={invoiceData => toggleInvoiceDrawer(Maybe.just(invoiceData))}
                />
            );
        })
        .unwrapOr(<></>);

    return (
        <Grid container>
            <Typography variant="h2">Podsumowanie sprzedaży</Typography>

            <Grid item xs={12}>
                <SalesFilter
                    dateRange={dateRange}
                    today={now}
                    onDateRangeChange={setDataRange}
                    onChangeShowNonProjectSales={setShowNonProjectInvoices}
                    showNonProjectSales={showNonProjectInvoices}
                    importTaxxoInvoices={importTaxxoInvoices(apiPost)}
                />
            </Grid>
            <Grid item xs={12}>
                {isLoading ? <Loader /> : salesTable}
            </Grid>
            <Drawer anchor={"right"} open={invoiceDetails.isJust()} onClose={toggleInvoiceDrawer(Maybe.nothing())}>
                {invoiceDetails
                    .map(invoiceData => (
                        <>
                            <PositionsPreview
                                key={`invoice-details-${invoiceData.invoiceNo}`}
                                invoiceData={invoiceData}
                                sx={{ width: "1000px" }}
                                onCLose={toggleInvoiceDrawer(Maybe.nothing())}
                            />
                        </>
                    ))
                    .unwrapOr(<></>)}
            </Drawer>
        </Grid>
    );
};
