import { Grid } from "@mui/material";
import * as React from "react";
import { ExtendedPositionAssignment } from "../../types";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import { getDepartmentTranslation, getSpecializationTranslation } from "components/common/types";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import { mapEmployeeDTO } from "../../../Projects/types";

interface Props {
    positionAssignments: ExtendedPositionAssignment[];
}

const columns: GridColDef[] = [
    {
        field: "employee",
        flex: 1,
        headerName: "Osoba",
        renderCell: p => (
            <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
                onMouseDown={event => {
                    if (event.button === 1) {
                        window.open("/employees/" + p.row.employeeDto.id);
                    } else if (event.button === 0) {
                        window.open("/employees/" + p.row.employeeDto.id, "_self");
                    }
                }}
            >
                <EmployeeAvatar
                    sx={{ width: "35px", height: "35px", marginRight: "15px" }}
                    employee={mapEmployeeDTO(p.row.employeeDto)}
                />
                {p.row.employee}
            </div>
        ),
    },
    {
        field: "department",
        headerName: "Cech",
        flex: 1,
        renderCell: params => getDepartmentTranslation(params.value),
    },
    {
        field: "specialization",
        headerName: "Specjalizacja",
        flex: 1,
        renderCell: p => getSpecializationTranslation(p.value),
    },
    { field: "level", headerName: "Poziom", flex: 1 },
    { field: "overallStartDate", headerName: "Data zatrudnienia", flex: 1 },
];

export const EmployeesTable: React.FC<Props> = (props: Props) => {
    return (
        <>
            <Grid container>
                <DataGrid
                    localeText={{ noRowsLabel: "Brak pracowników w wybranym miesiącu" }}
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableSelectionOnClick={true}
                    hideFooter={true}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                    sx={{
                        minHeight: 200,

                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&.status-joined": {
                                color: "green",
                                fontWeight: "bold",
                            },
                            "&.status-fired": {
                                color: "red",
                                fontWeight: "bold",
                            },
                        },
                        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                            outline: "transparent",
                        },
                        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                            outline: "none",
                        },
                        "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                            outline: "none",
                        },
                        border: 0,
                    }}
                    getRowClassName={params => {
                        if (params.row.status === "Joined") {
                            return "status-joined";
                        } else if (params.row.status === "Fired") {
                            return "status-fired";
                        } else {
                            return "";
                        }
                    }}
                    autoHeight={true}
                    rows={props.positionAssignments}
                    columns={columns}
                />
            </Grid>
        </>
    );
};
