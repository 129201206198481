import {
    adjustRange,
    DateRange,
    lastMonth,
    lastNinetyDays,
    lastTwoWeeks,
    lastWeek,
    MUI_INPUT_DATE_STRING_FORMAT,
} from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { Dayjs } from "dayjs";
import { GenericSelect } from "components/common/ui-kit/components/GenericSelect";
import { Maybe } from "true-myth";
import React, { useState } from "react";
import { Nullable, toDateString } from "components/common/types";
import { SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { noop } from "components/common/utils";
import { CalendarPickerView } from "@mui/x-date-pickers";

const DateRangeOption = {
    last_7_days: "Ostatnie 7 dni",
    last_14_days: "Ostatnie 14 dni",
    last_month: "Ostatni miesiąc",
    last_90_days: "Ostatnie 90 dni",
    custom_range: "Zakres",
} as const;

type DateRangeOption = keyof typeof DateRangeOption;
const allRangeOptions: [DateRangeOption, ...DateRangeOption[]] = [
    "last_7_days",
    "last_14_days",
    "last_month",
    "last_90_days",
    "custom_range",
];

interface Props {
    onDateRangeChange: (range: DateRange) => void;
    initialRange?: DateRange;
    rangeOptions?: [DateRangeOption, ...DateRangeOption[]];
    today: Date;
    label?: string;
    views?: CalendarPickerView[];
    labelFrom?: string;
    labelTo?: string;
    inputFormat?: string;
    sx?: SxProps<Theme>;
}

const deriveDateRangeFromSelectOption = (today: Date, selectedDateRangeOption: DateRangeOption): Maybe<DateRange> => {
    switch (selectedDateRangeOption) {
        case "last_90_days":
            return Maybe.of(lastNinetyDays(today));
        case "last_month":
            return Maybe.of(lastMonth(today));
        case "last_14_days":
            return Maybe.of(lastTwoWeeks(today));
        case "last_7_days":
            return Maybe.of(lastWeek(today));
        case "custom_range":
        default:
            return Maybe.nothing();
    }
};

const getDefaultRangeOption = (rangeOptions: [DateRangeOption, ...DateRangeOption[]]): DateRangeOption =>
    rangeOptions.includes("custom_range") ? "custom_range" : rangeOptions[0];

export const DateRangePicker: React.FC<Props> = ({
    onDateRangeChange,
    label = "Zakres dat",
    initialRange,
    today,
    views = ["year", "month", "day"],
    labelFrom = "Od (rok-miesiąc-dzień)",
    labelTo = "Do (rok-miesiąc-dzień)",
    inputFormat = MUI_INPUT_DATE_STRING_FORMAT,
    rangeOptions = allRangeOptions,
}) => {
    const [rangeType, setRangeType] = useState<DateRangeOption>(() => {
        if (initialRange && rangeOptions.includes("custom_range")) {
            return "custom_range";
        }
        return getDefaultRangeOption(rangeOptions);
    });

    const [dateRange, setDateRange] = useState<DateRange>(() => {
        if (initialRange && rangeType === "custom_range") {
            return initialRange;
        }
        return deriveDateRangeFromSelectOption(today, rangeType).unwrapOr(lastWeek(today));
    });

    const onRangeOptionChange = (event: SelectChangeEvent<unknown>) => {
        const selectedDateRangeOption = event.target.value as DateRangeOption;

        setRangeType(selectedDateRangeOption);

        deriveDateRangeFromSelectOption(today, selectedDateRangeOption).match({
            Just: value => {
                setDateRange(value);
                onDateRangeChange(value);
            },
            Nothing: () => onDateRangeChange(dateRange),
        });
    };

    const onDateChange = (fieldName: keyof DateRange) => (pickedDate: Nullable<Dayjs>) =>
        Maybe.of(pickedDate).match({
            Just: date => {
                try {
                    const dateString = toDateString(date.toDate());
                    const newRange: DateRange = adjustRange(fieldName, dateString, dateRange);
                    setDateRange(newRange);
                    onDateRangeChange(newRange);
                } catch {
                    // on error (resulting from a call to `toDateString`) just ignore it
                    // e.g. the user decided to manually type the date into an input - until they are done with typing, the `toDateString` will error on invalid input
                }
            },
            Nothing: noop,
        });

    return (
        <>
            <GenericSelect
                sx={{
                    gridArea: "date_range_select",
                }}
                value={rangeType}
                label={label}
                values={rangeOptions.map(value => ({ value, display: DateRangeOption[value] }))}
                onChange={onRangeOptionChange}
            />
            {rangeType === "custom_range" ? (
                <>
                    <CommonDatePicker
                        datePicker={{
                            value: dateRange.from,
                            label: labelFrom,
                            onChange: onDateChange("from"),
                            views,
                            inputFormat,
                        }}
                        textField={{
                            sx: {
                                minWidth: 0,
                                gridArea: "date_range_from",
                            },
                        }}
                    />
                    <CommonDatePicker
                        datePicker={{
                            value: dateRange.to,
                            label: labelTo,
                            onChange: onDateChange("to"),
                            views,
                            inputFormat,
                        }}
                        textField={{
                            sx: {
                                minWidth: 0,
                                gridArea: "date_range_to",
                            },
                        }}
                    />
                </>
            ) : (
                <></>
            )}
            {/* uncomment for debug purposes */}
            {/* {<pre>{JSON.stringify(dateRange, null, 2)}</pre>} */}
        </>
    );
};
