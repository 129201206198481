import { Grid } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { Result } from "true-myth";
import DocumentDetailsView from "./DocumentDetailsView";
import {
    CreateOtherTypeDocumentPaymentRequest,
    DocumentDetails,
    DocumentOtherPaymentDetails,
    PaymentSearchResult,
    UpdateDocumentPaymentRequest,
    UpdateOtherTypeDocumentPaymentRequest,
} from "../../types";
import { AsyncResult } from "../../../../components/common/infractructure";
import { Employee } from "modules/Projects/types";
import { DocumentPayment, DocumentPaymentAssignmentPayload } from "../../../Bank/types";
import BigNumber from "bignumber.js";
import { useCallback } from "react";

interface Props {
    unassignEmployeeFromPosition: (invoiceEntryId: number) => AsyncResult<void>;
    assignEmployeeToPosition: (invoiceEntryId: number, employeeId: number) => AsyncResult<void>;
    getEmployees: () => AsyncResult<Employee[]>;
    fetchExchangeRate: (date: string, baseCurrency: string, quoteCurrency: string) => AsyncResult<BigNumber>;
    updateDocumentPayment: (updateData: UpdateDocumentPaymentRequest) => AsyncResult<void>;
    createDocumentPayment: (createData: DocumentPaymentAssignmentPayload) => AsyncResult<DocumentPayment>;
    getPaymentSearchResult: (query: string) => AsyncResult<PaymentSearchResult[]>;
    deleteDocumentPayment: (docPaymentId: number) => AsyncResult<void>;
    createDocumentOtherPayment: (
        data: CreateOtherTypeDocumentPaymentRequest,
    ) => AsyncResult<DocumentOtherPaymentDetails>;
    updateDocumentOtherPayment: (data: UpdateOtherTypeDocumentPaymentRequest) => AsyncResult<void>;
}

export const DocumentDetailsViewWrapper: React.FC<Props> = props => {
    const loaderData = useLoaderData() as Result<DocumentDetails, Error>;

    const memoizedUnassignEmployeeFromPosition = useCallback(props.unassignEmployeeFromPosition, []);
    const memoizedAssignEmployeeToPosition = useCallback(props.assignEmployeeToPosition, []);
    const memoizedGetEmployees = useCallback(props.getEmployees, []);
    const memoizedFetchExchangeRate = useCallback(props.fetchExchangeRate, []);
    const memoizedUpdateDocumentPayment = useCallback(props.updateDocumentPayment, []);
    const memoizedCreateDocumentPayment = useCallback(props.createDocumentPayment, []);
    const memoizedGetSearchResult = useCallback(props.getPaymentSearchResult, []);
    const memoizedDeleteDocumentPayment = useCallback(props.deleteDocumentPayment, []);
    const memoizedCreateOtherPayment = useCallback(props.createDocumentOtherPayment, []);
    const memoizedUpdateOtherPayment = useCallback(props.updateDocumentOtherPayment, []);

    return loaderData
        .map(documentDetails => {
            return (
                <Grid key={documentDetails.id} container>
                    <DocumentDetailsView
                        data={documentDetails}
                        unassignEmployeeFromPosition={memoizedUnassignEmployeeFromPosition}
                        assignEmployeeToPosition={memoizedAssignEmployeeToPosition}
                        getEmployees={memoizedGetEmployees}
                        fetchExchangeRate={memoizedFetchExchangeRate}
                        updateDocumentPayment={memoizedUpdateDocumentPayment}
                        createDocumentPayment={memoizedCreateDocumentPayment}
                        getSearchResult={memoizedGetSearchResult}
                        deleteDocumentPayment={memoizedDeleteDocumentPayment}
                        createOtherDocumentPayment={memoizedCreateOtherPayment}
                        updateOtherDocumentPayment={memoizedUpdateOtherPayment}
                    ></DocumentDetailsView>
                </Grid>
            );
        })
        .unwrapOrElse(e => <>{e.message}</>);
};
