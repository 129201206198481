import BigNumber from "bignumber.js";
import { TAX_RATE } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { calculateTax, dividedBy, plus, sum, sumWithPrecision, times } from "components/common/utils/numberUtils";
import {
    ContractorData,
    MonthlySettlementSummary,
    MonthlySettlementPendingPaymentPosition,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import { InvoiceSummaryTableRow } from "modules/Invoices/views/MonthlySettlement/view/Summary/components/InvoiceSummaryTable";
import { curry, map, multiply, pipe, update } from "ramda";
import { Maybe } from "true-myth";

import { updatePositionPrices } from "./formService";

export const updatePendingPaymentPositionForFixedMonthlyRate =
    (taxRate: number, sumWorkingDays: number, monthlyNetRate: number, exchangeRate: number) =>
    (pendingPayment: MonthlySettlementPendingPaymentPosition): MonthlySettlementPendingPaymentPosition => {
        const updatedQuantity = new BigNumber(pendingPayment.quantity)
            .dividedBy(sumWorkingDays)
            .decimalPlaces(6, BigNumber.ROUND_HALF_DOWN);
        const updatedNetAmount = updatedQuantity.times(monthlyNetRate).decimalPlaces(2, BigNumber.ROUND_HALF_EVEN);
        const updatedTaxAmount = updatedNetAmount.times(taxRate).decimalPlaces(2, BigNumber.ROUND_HALF_EVEN);
        const updatedGrossAmount = new BigNumber(
            (updatedTaxAmount.times(1000).integerValue().toNumber() +
                updatedNetAmount.times(1000).integerValue().toNumber()) /
                1000,
        )
            .decimalPlaces(2, BigNumber.ROUND_HALF_DOWN)
            .toNumber();

        return {
            ...pendingPayment,
            unitPrice: dividedBy(monthlyNetRate, exchangeRate).toNumber(),
            unitPriceInPLN: monthlyNetRate,
            quantity: updatedQuantity.toNumber(),
            netAmount: updatedNetAmount.dividedBy(exchangeRate).toNumber(),
            netAmountInPLN: updatedNetAmount.toNumber(),
            taxAmount: updatedTaxAmount.dividedBy(exchangeRate).toNumber(),
            taxAmountInPLN: updatedTaxAmount.toNumber(),
            grossAmount: dividedBy(updatedGrossAmount, exchangeRate).toNumber(),
            grossAmountInPLN: updatedGrossAmount,
        };
    };

export const updatePendingPaymentData = curry(
    (
        contractorData: ContractorData,
        invoiceNumber: string,
        invoiceIssueDate: string,
        updatedPositions: MonthlySettlementPendingPaymentPosition[],
        exchangeRate: number,
        summary: MonthlySettlementSummary,
    ): MonthlySettlementSummary => {
        const totalNet = sum(updatedPositions.map(({ netAmount }) => netAmount));
        const totalNetInPLN = sum(updatedPositions.map(({ netAmountInPLN }) => netAmountInPLN));
        const totalTax = calculateTax(totalNet.toNumber(), contractorData.isVatPayer ? TAX_RATE : 0);
        const totalTaxInPLN = calculateTax(totalNetInPLN.toNumber(), contractorData.isVatPayer ? TAX_RATE : 0);
        return {
            ...summary,
            pendingPaymentData: {
                ...summary.pendingPaymentData,
                contractorData,
                invoiceNumber,
                invoiceIssueDate,
                positions: updatedPositions,
                totalNet: totalNet.toNumber(),
                totalNetInPLN: totalNetInPLN.toNumber(),
                totalTax: totalTax.toNumber(),
                totalTaxInPLN: totalTaxInPLN.toNumber(),
                totalGross: totalNet.plus(totalTax).toNumber(),
                totalGrossInPLN: totalNetInPLN.plus(totalTaxInPLN).toNumber(),
                rate: exchangeRate,
            },
        };
    },
);

export const sumDaysOfWork = pipe(
    map(({ quantity }: MonthlySettlementPendingPaymentPosition) => quantity),
    sum,
    result => result.toNumber(),
);

export const updateMainPositionsUnitPrice = curry(
    (
        taxRate: number,
        unitPriceValue: number,
        rate: number,
        pendingPaymentPosition: MonthlySettlementPendingPaymentPosition,
    ) => {
        return pendingPaymentPosition.isMain
            ? updatePositionPrices(taxRate)(unitPriceValue)(rate)(pendingPaymentPosition)
            : pendingPaymentPosition;
    },
);

export const updateCostPositionsUnitPrice = (
    taxRate: number,
    exchangeRate: number,
    pendingPaymentPositions: MonthlySettlementPendingPaymentPosition[],
) => {
    return pendingPaymentPositions.map(pendingPaymentPosition =>
        !pendingPaymentPosition.isMain
            ? updatePositionPrices(taxRate)(pendingPaymentPosition.unitPriceInPLN)(exchangeRate)(pendingPaymentPosition)
            : pendingPaymentPosition,
    );
};

export const updateFixedMonthlyRateMainPositionsUnitPrices = curry(
    (
        taxRate: number,
        sumWorkingDays: number,
        unitPriceValue: number,
        exchangeRate: number,
        pendingPaymentPosition: MonthlySettlementPendingPaymentPosition,
    ) => {
        return pendingPaymentPosition.isMain
            ? updatePendingPaymentPositionForFixedMonthlyRate(
                  taxRate,
                  sumWorkingDays,
                  unitPriceValue,
                  exchangeRate,
              )(pendingPaymentPosition)
            : pendingPaymentPosition;
    },
);

export const addToNetAndUpdatePrices =
    (valueToAdd: number, taxRate: number, rate: number) =>
    (pendingPayment: MonthlySettlementPendingPaymentPosition): MonthlySettlementPendingPaymentPosition => {
        const scale = 100;
        const netMainPos = dividedBy(
            plus(times(pendingPayment.netAmount, scale).toNumber(), valueToAdd).toNumber(),
            scale,
        );

        const newTax = calculateTax(netMainPos.toNumber(), taxRate);
        const newGross = netMainPos.plus(newTax);
        const netMainPosInPLN = netMainPos.times(rate);
        const newTaxInPLN = calculateTax(netMainPosInPLN.toNumber(), taxRate);
        const newGrossInPLN = netMainPosInPLN.plus(newTaxInPLN);

        return {
            ...pendingPayment,
            netAmount: netMainPos.toNumber(),
            taxAmount: newTax.toNumber(),
            grossAmount: newGross.toNumber(),
            netAmountInPLN: netMainPosInPLN.toNumber(),
            taxAmountInPLN: newTaxInPLN.toNumber(),
            grossAmountInPLN: newGrossInPLN.toNumber(),
        };
    };

export const addDifferenceToFirstMainPosition = (
    valueToAdd: number,
    positions: MonthlySettlementPendingPaymentPosition[],
    taxRate: number,
    rate: number,
) => {
    return Maybe.head(positions.filter(({ isMain }) => isMain))
        .map(addToNetAndUpdatePrices(valueToAdd, taxRate, rate))
        .match({
            Just: payment => {
                const firstMainPositionIndex = positions.findIndex(pos => pos.id == payment.id);
                return update(firstMainPositionIndex, payment, positions);
            },
            Nothing: () => positions,
        });
};

export const scaleCurrencyToInteger = multiply(100);

export const updatePendingPaymentPositionsWithFixedMonthlyRate = curry(
    (
        taxRate: number,
        monthlyNetRate: number,
        exchangeRate: number,
        positions: MonthlySettlementPendingPaymentPosition[],
    ) => {
        const totalWorkDays = sumDaysOfWork(positions.filter(({ isMain }) => isMain));
        const toPendingPaymentPosition =
            updateFixedMonthlyRateMainPositionsUnitPrices(taxRate)(totalWorkDays)(monthlyNetRate)(exchangeRate);
        const updatedPendingPaymentPositions = positions.map(toPendingPaymentPosition);
        const updatedMainPositionsNetSum = sumWithPrecision(
            updatedPendingPaymentPositions.filter(({ isMain }) => isMain).map(({ netAmount }) => netAmount),
            2,
        ).toNumber();

        const monthlyRateInPln = dividedBy(monthlyNetRate, exchangeRate);
        const sumOfPositionsInPln = new BigNumber(updatedMainPositionsNetSum);

        const diffBetweenMonthlyRateAndPositionsSum = monthlyRateInPln.minus(sumOfPositionsInPln).times(100);
        return diffBetweenMonthlyRateAndPositionsSum.isLessThan(0.5)
            ? updatedPendingPaymentPositions
            : addDifferenceToFirstMainPosition(
                  diffBetweenMonthlyRateAndPositionsSum.toNumber(),
                  updatedPendingPaymentPositions,
                  taxRate,
                  exchangeRate,
              );
    },
);

interface MainAndConsulting {
    main: InvoiceSummaryTableRow;
    consulting: Maybe<InvoiceSummaryTableRow>;
}

const extractMainAndConsultingData = (rowData: InvoiceSummaryTableRow[]): MainAndConsulting => ({
    main: rowData[0],
    consulting: Maybe.of(rowData[1]),
});

export const calculateMonthlySettlementCostsOffset = (rowData: InvoiceSummaryTableRow[], isVatPayer: boolean) => {
    const { main, consulting } = extractMainAndConsultingData(rowData);
    const mainTotalNet = consulting.mapOrElse(
        () => main.totalNet,
        c => new BigNumber(main.totalNet).plus(c.totalNet).toNumber(),
    );
    return consulting.match({
        Just: c => {
            if (c.totalNet >= 0) {
                return [main, c];
            }
            const totalTax = calculateTax(mainTotalNet, isVatPayer ? TAX_RATE : 0).toNumber();
            return [
                {
                    ...main,
                    totalNet: mainTotalNet,
                    totalTax: totalTax,
                    totalGross: new BigNumber(totalTax).plus(mainTotalNet).toNumber(),
                    unitPrice: main.unitPrice + (isVatPayer ? c.totalNet : c.totalGross),
                    quantity: mainTotalNet === 0 ? 0 : main.quantity,
                },
                {
                    ...c,
                    unitPrice: 0,
                    totalNet: 0,
                    totalTax: 0,
                    totalGross: 0,
                    quantity: 0,
                },
            ];
        },
        Nothing: () => [
            {
                ...main,
                quantity: mainTotalNet === 0 ? 0 : main.quantity,
            },
        ],
    });
};

export const calculateRegularCostsOffset = (
    rowData: InvoiceSummaryTableRow[],
    isVatPayer: boolean,
): InvoiceSummaryTableRow[] => {
    const { main, consulting } = extractMainAndConsultingData(rowData);
    const taxRate = isVatPayer ? TAX_RATE : 0;
    const workPositionsToDeductCount = consulting.mapOrElse(
        () => 0,
        c => Math.ceil(Math.abs(c.totalNet) / main.unitPrice),
    );
    const mainTotalNet = main.totalNet - main.unitPrice * workPositionsToDeductCount;
    const mainTotalTax = calculateTax(mainTotalNet, taxRate);

    const consultingTotalNet = consulting.mapOrElse(
        () => main.totalNet - mainTotalNet,
        c => main.totalNet - mainTotalNet + c.totalNet,
    );
    const consultingTotalTax = calculateTax(consultingTotalNet, taxRate);
    const mainEntry: InvoiceSummaryTableRow = {
        ...main,
        totalNet: mainTotalNet,
        totalTax: mainTotalTax.toNumber(),
        totalGross: mainTotalTax.plus(mainTotalNet).toNumber(),
        quantity: main.quantity - workPositionsToDeductCount,
    };

    return consulting.match({
        Just: c => {
            if (c.totalNet >= 0) {
                return [main, c];
            }
            return [
                mainEntry,
                {
                    ...c,
                    quantity: consultingTotalNet ? 1 : 0,
                    unitPrice: consultingTotalNet,
                    totalNet: consultingTotalNet,
                    totalGross: consultingTotalTax.plus(consultingTotalNet).toNumber(),
                    totalTax: consultingTotalTax.toNumber(),
                },
            ];
        },
        Nothing: () => [mainEntry],
    });
};

export const getPendingPaymentPreviewAddress = ({ yearMonth: { year, month } }: MonthlySettlementSummary) => {
    const paddedMonth = month < 10 ? "0" + month : month;
    return `/invoices/${year}-${paddedMonth}-01`;
};
