import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import App from "App";
import Auth0ProviderWithHistory from "auth/Auth0ProviderWithHistory";
import * as serviceWorker from "components/common/infractructure";
import { ErrorBoundary } from "components/common/ui-kit/components/ErrorBoundary";
import { appConfig, AppConfigContext } from "config";
import "index.css";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
// eslint-disable-next-line
const root = createRoot(container!);

root.render(
    <ErrorBoundary>
        <AppConfigContext.Provider value={appConfig}>
            <Auth0ProviderWithHistory>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <App />
                </LocalizationProvider>
            </Auth0ProviderWithHistory>
        </AppConfigContext.Provider>
    </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
