import { Switcher } from "components/common/ui-kit/components/Switcher";
import { ContractorData } from "modules/Invoices/views/MonthlySettlement/domain/types";
import { WrappedTextField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/WrappedTextField";
import * as React from "react";
import { Box, InputAdornment, SxProps, TextField, Theme } from "@mui/material";

interface Props {
    contractorData: ContractorData;
    rateValue: string;
    onUnitPriceChange: (value: string) => void;
}

const staffDataFormLayout: SxProps<Theme> = {
    gridTemplateAreas: `
            "isInvoiceAttached  isInvoiceAttached"
            "accountNumber      rate"
        `,
};

const contractorDataFormLayout: SxProps<Theme> = {
    gridTemplateAreas: `
            "companyName        addressLine1"
            "nip                addressLine2"
            "isInvoiceAttached  isInvoiceAttached"
            "accountNumber      rate"
        `,
};

export const ContractorDataView: React.FC<Props> = ({ contractorData, rateValue, onUnitPriceChange }) => {
    const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => onUnitPriceChange(e.currentTarget.value);

    return (
        <Box
            sx={{
                padding: "1rem",
                display: "grid",
                gridGap: "1.75rem",
                gridTemplateColumns: "1fr 1fr",
                ...(contractorData.isInvoiceAttached ? contractorDataFormLayout : staffDataFormLayout),
            }}
        >
            {contractorData.isInvoiceAttached ? (
                <>
                    <Box sx={{ gridArea: "companyName" }}>
                        <WrappedTextField
                            textFieldProps={{
                                label: "Nazwa firmy",
                                type: "text",
                                fullWidth: true,
                                required: true,
                                size: "small",
                                variant: "standard",
                            }}
                            name="contractorData.companyName"
                        />
                    </Box>
                    <Box sx={{ gridArea: "nip" }}>
                        <WrappedTextField
                            textFieldProps={{
                                label: "NIP",
                                type: "text",
                                fullWidth: true,
                                required: true,
                                size: "small",
                                variant: "standard",
                            }}
                            name="contractorData.nip"
                        />
                    </Box>
                    <Box sx={{ gridArea: "addressLine1" }}>
                        <WrappedTextField
                            textFieldProps={{
                                label: "Ulica",
                                type: "text",
                                fullWidth: true,
                                required: true,
                                size: "small",
                                variant: "standard",
                            }}
                            name="contractorData.addressLine1"
                        />
                    </Box>
                    <Box sx={{ gridArea: "addressLine2" }}>
                        <WrappedTextField
                            textFieldProps={{
                                label: "Kod pocztowy i miasto",
                                type: "text",
                                fullWidth: true,
                                required: true,
                                size: "small",
                                variant: "standard",
                            }}
                            name="contractorData.addressLine2"
                        />
                    </Box>
                </>
            ) : (
                <></>
            )}
            <Box sx={{ gridArea: "isInvoiceAttached", display: "flex", justifyContent: "center" }}>
                <Switcher label="Płatnik VAT" name="contractorData.isVatPayer" />
                <Switcher label="Dołączony plik z fakturą" name="contractorData.isInvoiceAttached" />
                <Switcher label="Stałe miesięczne wynagrodzenie - ryczałt" name="contractorData.isMonthlyCharged" />
            </Box>
            <Box sx={{ gridArea: "accountNumber", display: "flex" }}>
                <WrappedTextField
                    textFieldProps={{
                        label: "Numer rachunku bankowego",
                        type: "text",
                        fullWidth: true,
                        required: true,
                        size: "small",
                        variant: "standard",
                    }}
                    name="contractorData.accountNumber"
                />
            </Box>
            <Box sx={{ gridArea: "rate", display: "flex" }}>
                <TextField
                    sx={{ pr: "1.75rem" }}
                    label={`${contractorData.isMonthlyCharged ? "Pensja miesięczna" : "Stawka dzienna"}`}
                    type="number"
                    size="small"
                    fullWidth={true}
                    inputProps={{ style: { textAlign: "right" } }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
                    }}
                    value={rateValue}
                    onChange={handleUnitPriceChange}
                    variant="standard"
                    name="contractorData.rate"
                />
                <WrappedTextField
                    textFieldProps={{
                        label: "Waluta faktury",
                        type: "text",
                        disabled: true,
                        fullWidth: true,
                        required: false,
                        size: "small",
                        variant: "standard",
                    }}
                    name="contractorData.currency.name"
                />
            </Box>
        </Box>
    );
};
