import React, { useCallback, useRef, useState } from "react";
import {
    Typography,
    Divider,
    Chip,
    Container,
    Box,
    Grid,
    TableContainer,
    Paper,
    Tabs,
    Tab,
    IconButton,
    Collapse,
} from "@mui/material";
import {
    Allocation,
    CreateOtherTypeDocumentPaymentRequest,
    DocumentDetails,
    DocumentOtherPaymentDetails,
    InvoiceEntry,
    PaymentSearchResult,
    UpdateDocumentPaymentRequest,
    UpdateOtherTypeDocumentPaymentRequest,
} from "../../types";
import { CollapsibleTable } from "components/common/ui-kit/components/CollapsibleTable";
import { Row } from "../../../../components/common/ui-kit/components/CollapsibleTable/CollapsibleTable";
import { DocumentPayments, DocumentPaymentsHandle } from "./DocumentPayments";
import { DocumentOtherPayments, DocumentOtherPaymentsHandle } from "./DocumentOtherPayments";
import { Table } from "../../../../components/common/ui-kit/components/Table";
import { Column } from "@material-table/core";
import { formatMoneyForView } from "../../../../components/common/utils/numberUtils";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Employee } from "../../../Projects/types";
import { boldHeaderStyle } from "../../../../components/common/ui-kit/components/MaterialTable/utils";
import { AvatarMenu } from "./AvatarMenu";
import { Maybe } from "true-myth";
import { AsyncResult } from "../../../../components/common/infractructure";
import { DocumentPayment, DocumentPaymentAssignmentPayload } from "../../../Bank/types";
import BigNumber from "bignumber.js";
import { useBoolean } from "usehooks-ts";
import { getDocumentTypeTranslation } from "../../service/documentService";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import { DocumentTotalAmountSummary } from "./DocumentTotalAmountSummary";

type PositionsRowData = Omit<InvoiceEntry, "unit" | "employee"> & {
    unit: string;
    employee: JSX.Element;
};

interface Props {
    unassignEmployeeFromPosition: (invoiceEntryId: number) => AsyncResult<void>;
    assignEmployeeToPosition: (invoiceEntryId: number, employeeId: number) => AsyncResult<void>;
    data: DocumentDetails;
    getEmployees: () => AsyncResult<Employee[]>;
    fetchExchangeRate: (date: string, baseCurrency: string, quoteCurrency: string) => AsyncResult<BigNumber>;
    updateDocumentPayment: (updateData: UpdateDocumentPaymentRequest) => AsyncResult<void>;
    createDocumentPayment: (createData: DocumentPaymentAssignmentPayload) => AsyncResult<DocumentPayment>;
    getSearchResult: (query: string) => AsyncResult<PaymentSearchResult[]>;
    deleteDocumentPayment: (docPaymentId: number) => AsyncResult<void>;
    createOtherDocumentPayment: (
        data: CreateOtherTypeDocumentPaymentRequest,
    ) => AsyncResult<DocumentOtherPaymentDetails>;
    updateOtherDocumentPayment: (data: UpdateOtherTypeDocumentPaymentRequest) => AsyncResult<void>;
}

const toColumns = (documentCurrency: string): Column<PositionsRowData>[] => [
    { title: "Pracownik", field: "employee" },
    { title: "Opis", field: "description", width: 200 },
    { title: "Jednostka", field: "unit" },
    { title: "Ilość", field: "quantity" },
    {
        title: "Cena jedn.",
        field: "unitPrice",
        render: rowData => formatMoneyForView(rowData.unitPrice, documentCurrency),
    },
    {
        title: "Netto",
        field: "netPrice",
        render: rowData => formatMoneyForView(rowData.netPrice, documentCurrency),
    },
    {
        title: "Vat",
        field: "taxPrice",
        render: rowData => formatMoneyForView(rowData.taxPrice, documentCurrency),
    },
    {
        title: "Brutto",
        field: "grossPrice",
        render: rowData => formatMoneyForView(rowData.grossPrice, documentCurrency),
    },
];

const toRowData = (allocations: Allocation[]): Row[] => {
    const accountingRowData: Row[] = allocations.map((allocation, index) => {
        const collapseContent = (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
                {allocation.account.tags.map((tag, tagIndex) => (
                    <Chip key={tagIndex} label={tag} variant="outlined" size="small" />
                ))}
            </Box>
        );
        return {
            id: index,
            cellData: [allocation.account.account, allocation.description, formatMoneyForView(allocation.value, "PLN")],
            collapseContent: collapseContent,
        };
    });
    return accountingRowData;
};

export const sumNumbers = (rows: number[]): BigNumber =>
    rows.reduce((accumulator: BigNumber, currentObject) => {
        return accumulator.plus(new BigNumber(currentObject));
    }, new BigNumber("0"));

const DocumentDetailsView: React.FC<Props> = props => {
    const accountingCols = ["Konto", "Opis", "Kwota netto"];
    const [selectedTab, setSelectedTab] = useState(0);
    const [sumOfOtherPayments, setSumOfOtherPayments] = useState(
        sumNumbers(props.data.otherPayments.map(r => r.amountInDocumentCurrency)),
    );
    const [sumOfPayments, setSumOfPayments] = useState(
        sumNumbers(props.data.payments.map(r => r.amountInDocumentCurrency.toNumber())),
    );
    const documentOtherPaymentsRef = useRef<DocumentOtherPaymentsHandle>(null);
    const documentPaymentsRef = useRef<DocumentPaymentsHandle>(null);

    const { value: openPositions, toggle: togglePositions } = useBoolean(true);
    const { value: openAccountings, toggle: toggleAccountings } = useBoolean(true);

    const onArrowClickedPositions = () => togglePositions();
    const onArrowClickedAccountings = () => toggleAccountings();

    const handleOpenInTaxxo = () => {
        window.open("https://platforma2.taxxo.pl/TX20/Dokument.aspx?ID=" + props.data.taxxoId, "_blank");
    };

    const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setSelectedTab(newValue);
    };

    const toPositionsRowData = (invoiceEntries: InvoiceEntry[]): PositionsRowData[] => {
        return [
            ...invoiceEntries.map(ie => ({
                ...ie,
                unit: ie.unit.name,
                employee: (
                    <AvatarMenu
                        unassignEmployeeFromPosition={props.unassignEmployeeFromPosition}
                        invoiceEntryId={ie.id}
                        currentEmployee={Maybe.of(ie.employee)}
                        assignEmployeeToPosition={props.assignEmployeeToPosition}
                        getEmployees={props.getEmployees}
                    />
                ),
            })),
        ];
    };

    const recalculateUnpaidAmount = () => {
        const total = props.data.operationType.name.includes("COST")
            ? props.data.summary.totalGross.value.times(new BigNumber("-1"))
            : props.data.summary.totalGross.value;
        return total.minus(sumOfPayments).minus(sumOfOtherPayments);
    };

    const unpaidAmount = recalculateUnpaidAmount();

    const onSumOfPaymentsChanged = useCallback((newAmount: BigNumber) => {
        setSumOfPayments(newAmount);
    }, []);

    const onSumOfOtherPaymentsChanged = useCallback((netAmount: BigNumber): void => {
        setSumOfOtherPayments(netAmount);
    }, []);

    const addNewOtherPayment = () => {
        documentOtherPaymentsRef.current?.addNewRow();
    };

    const addNewPayment = () => {
        documentPaymentsRef.current?.addNewRow();
    };

    return (
        <Container>
            <Grid container sx={{ maxWidth: "1100px" }}>
                <Grid container justifyContent={"space-between"}>
                    <Typography variant="h3" gutterBottom>
                        {props.data.number}
                        <IconButton onClick={handleOpenInTaxxo}>
                            <OpenInNewIcon />
                        </IconButton>
                    </Typography>
                    <Typography>Typ dokumentu: {getDocumentTypeTranslation(props.data.operationType.name)}</Typography>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography gutterBottom>{props.data.contractor.name}</Typography>
                        <Typography>NIP: {props.data.contractor.identifier}</Typography>
                        <Typography>
                            {`${props.data.contractor.addressStreet}, ${props.data.contractor.addressCity}, ${props.data.contractor.addressZipCode}, ${props.data.contractor.addressCountry}`}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                        <Typography>Data wystawienia: {props.data.issueDate}</Typography>
                        <Typography>Data sprzedaży: {props.data.salesDate}</Typography>
                        <Typography>Termin płatności: {props.data.paymentDate}</Typography>
                    </Grid>
                </Grid>
                {props.data.invoiceEntries.length > 0 && (
                    <>
                        <Typography variant="h5" sx={{ marginBottom: "15px", marginTop: "30px" }}>
                            <IconButton aria-label="expand row" size="small" onClick={onArrowClickedPositions}>
                                {openPositions ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>{" "}
                            Pozycje{" "}
                        </Typography>
                        <Collapse in={openPositions} timeout="auto" unmountOnExit={false}>
                            <Table
                                options={{
                                    showTitle: false,
                                    search: false,
                                    selection: false,
                                    showTextRowsSelected: false,
                                    paging: false,
                                    toolbar: false,
                                    padding: "dense",
                                    rowStyle: {
                                        height: "45px",
                                    },
                                    tableLayout: "fixed",
                                    headerStyle: boldHeaderStyle(),
                                }}
                                columns={toColumns(props.data.summary.exchangeRate?.currency ?? "PLN")}
                                data={toPositionsRowData(props.data.invoiceEntries)}
                            ></Table>
                        </Collapse>
                    </>
                )}
                <Grid container>
                    <Grid container>
                        <Typography variant="h5" sx={{ marginBottom: "15px", marginTop: "15px" }}>
                            <IconButton aria-label="expand row" size="small" onClick={onArrowClickedAccountings}>
                                {openAccountings ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>{" "}
                            Księgowanie
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Collapse in={openAccountings} timeout="auto" unmountOnExit={false} sx={{ width: "100%" }}>
                            <CollapsibleTable
                                colDefs={accountingCols}
                                rows={toRowData(props.data.accounting.allocations)}
                            ></CollapsibleTable>
                        </Collapse>
                    </Grid>
                </Grid>

                <Grid container justifyContent={"end"} alignItems={"end"}>
                    <DocumentTotalAmountSummary data={props.data} unpaidAmount={unpaidAmount} />
                </Grid>
                <Typography variant="h5" sx={{ marginBottom: "15px" }}>
                    Płatności
                </Typography>
                <TableContainer component={Paper}>
                    <Box sx={{ width: "100%", minHeight: "250px" }}>
                        <Tabs value={selectedTab} onChange={handleChangeActiveTab}>
                            <Tab label="Przelewy" />
                            <Tab label="Inne" />
                            <Grid container justifyContent={"end"} alignItems={"end"}>
                                <IconButton
                                    type="button"
                                    onClick={selectedTab == 1 ? addNewOtherPayment : addNewPayment}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Tabs>
                        <Box>
                            <div hidden={selectedTab == 1}>
                                <DocumentPayments
                                    ref={documentPaymentsRef}
                                    getPaymentSearchResult={props.getSearchResult}
                                    document={props.data}
                                    assignedPayments={props.data.payments}
                                    fetchExchangeRate={props.fetchExchangeRate}
                                    updateDocumentPayment={props.updateDocumentPayment}
                                    createDocumentPayment={props.createDocumentPayment}
                                    deleteDocumentPayment={props.deleteDocumentPayment}
                                    onSumOfPaymentsChanged={onSumOfPaymentsChanged}
                                />
                            </div>
                            <div hidden={selectedTab == 0}>
                                <DocumentOtherPayments
                                    onSumOfOtherPaymentsChanged={onSumOfOtherPaymentsChanged}
                                    ref={documentOtherPaymentsRef}
                                    document={props.data}
                                    createOtherDocumentPayment={props.createOtherDocumentPayment}
                                    deleteDocumentPayment={props.deleteDocumentPayment}
                                    updateOtherDocumentPayment={props.updateOtherDocumentPayment}
                                />
                            </div>
                        </Box>
                    </Box>
                </TableContainer>
            </Grid>
        </Container>
    );
};

export default DocumentDetailsView;
