import { Alert, AlertTitle, Link } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

export const BookedInvoiceWarning: React.FC = () => {
    return (
        <Alert severity="error" data-testid="Summary/booked-invoice-warning">
            Uwaga! Faktura którą próbujesz edytować jest już przypisana do przelewu i edycja nie jest możliwa. Jeśli na
            pewno musisz coś z nią zrobić napisz na kanale #finanse-aplikacja
        </Alert>
    );
};

export const PaidInvoiceWarning: React.FC = () => {
    return (
        <Alert severity="warning">
            Uwaga! Dokument tej faktury został już przeniesiony do faktur zapłaconych. Nie można go już podmienić. Jeśli
            na pewno musisz coś z tym zrobić napisz na kanale #finanse-aplikacja
        </Alert>
    );
};

export const NegativeInvoiceTotalWarning: React.FC = () => {
    return (
        <Alert severity="error">
            Uwaga! Suma z podanych pozycji jest liczbą ujemną. Nie jest możliwe wystawienie faktury. Dodaj pozycje
            przychodowe lub zmiejsz zwroty.
        </Alert>
    );
};

export const InvoiceExistsWarning: React.FC<{ link: string }> = ({ link }) => {
    return (
        <Alert severity="warning">
            Uwaga! Faktura na dany miesiąc istnieje już w bazie dodanie nowej spowoduje jej nadpisanie!
            <p>
                <a href={link} target="_blank" rel="noreferrer">
                    Podgląd
                </a>
            </p>
        </Alert>
    );
};

export const NoProjectsAssignedInfo: React.FC = () => {
    return <Alert severity="info">Nie jesteś obecnie przypisany/a do żadnego projektu</Alert>;
};

export const ContactDataMissing: React.FC = () => {
    return (
        <Alert severity="warning">
            <AlertTitle>Brakujące dane w ustawieniach profilu</AlertTitle>
            W ustawieniach swojego profilu uzupełnij dane swojej firmy oraz dane kontaktowe.
            <br />
            <Link component={RouterLink} to={"/profile"} underline="none">
                Uzupełnij swoje dane
            </Link>
        </Alert>
    );
};

export const PassportExpirationApproaching: React.FC = () => {
    return (
        <Alert severity="error">
            <AlertTitle>Zbliża się koniec ważności Twojego paszportu</AlertTitle>
            Twój paszport wygaśnie za mniej niz 180 dni. Wyrób nowy paszport i zaktualizuj dane w ustawieniach profilu.
            <br />
            <Link component={RouterLink} to={"/profile"} underline="none">
                Zaktualizuj datę ważności swojego paszportu.
            </Link>
        </Alert>
    );
};
