import { Box, Grid, Typography } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import * as React from "react";

export const EmptyListInfo: React.FC = () => {
    return (
        <>
            <Grid container justifyContent={"center"} direction={"column"} alignItems={"center"}>
                <Grid item>
                    <SearchOffIcon style={{ fontSize: "8rem", opacity: 0.3 }} />
                </Grid>
                <Grid item>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Typography variant={"h3"}>Nie znaleziono wyników spełniających kryteria.</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Typography variant={"subtitle1"}>Zmień kryteria wyszukiwania i spróbuj ponownie.</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
