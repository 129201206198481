export const localizationMaterialTable = {
    body: {
        emptyDataSourceMessage: "Brak rekordów",
        addTooltip: "Dodaj",
        deleteTooltip: "Usuń",
        editTooltip: "Edytuj",
        editRow: {
            deleteText: "Czy na pewno chcesz usunąć pozycje?",
            cancelTooltip: "Anuluj zmiany",
            saveTooltip: "Zapisz",
        },
    },
    header: {
        actions: "",
    },
    grouping: {
        groupedBy: "Grupowanie według:",
        placeholder: "Przeciągnij i upuść nagłówek aby grupować",
    },
};
