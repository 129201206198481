import * as React from "react";
import { CostsSectionsDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { GenericSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GenericSection";
import { SectionBaseProps } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/types";
import {
    CostsSectionFormInput,
    CostsSectionFormInputData,
    CostsSectionFormInputProps,
} from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/CostsSection/CostsSectionFormInput";

type Props = CostsSectionsDef & SectionBaseProps;

export const CostsSection: React.FC<Props> = ({ sectionDefId, question, instructions, sectionName }) => (
    <GenericSection<CostsSectionFormInputProps, CostsSectionFormInputData>
        title={question}
        instructions={instructions}
        fieldArrayName={sectionName}
        sectionRowProps={{
            inputProps: {
                sectionDefId,
            },
            Component: CostsSectionFormInput,
        }}
    />
);
