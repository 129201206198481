import { Box, Typography } from "@mui/material";
import React from "react";

const NotAllowedRoute: React.FC = () => (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} data-testid="NotAllowedRoute">
        <Box
            sx={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "> img": {
                    borderRadius: "1rem",
                },
            }}
        >
            <img src="/images/mad_mckwacz.png" alt="tu-nie-wolno" width="65%" />
        </Box>
        <Typography variant="h2">Nie masz wystarczających uprawnień.</Typography>
        <Typography variant="body1">
            Aby uzyskać dostęp, skontaktuj się z zespołem odpowiedzialnym za aplikację Finanse.
        </Typography>
    </Box>
);

export default NotAllowedRoute;
