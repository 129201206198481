import * as React from "react";
import { FormSection } from "redux-form";
import { ActivityDef } from "modules/Invoices/views/MonthlySettlement/domain/formDefTypes";
import { WorkSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/WorkSection/WorkSection";
import { getKeyForSection } from "modules/Invoices/views/MonthlySettlement/service/formService";
import { GenericActivityForm } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/GenericActivityForm";
import { ConstantField } from "modules/Invoices/views/MonthlySettlement/view/form-fields/ConstantField";
import {
    COSTS_SECTION_NAME,
    WORK_SECTION_NAME,
} from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/activities/types";
import { CostsSection } from "modules/Invoices/views/MonthlySettlement/view/MonthlySettlementForm/sections/CostsSection/CostsSection";

const ActivityFormImpl = ({ activityDefId, title, workSections, costsSections, instructions }: ActivityDef) => {
    const workSectionComponents = workSections.map((workSectionDef, index) => (
        <WorkSection key={index} {...workSectionDef} sectionName={getKeyForSection(workSectionDef)} />
    ));
    const costsSectionComponents = costsSections.map((costsSectionDef, index) => (
        <CostsSection key={index} {...costsSectionDef} sectionName={getKeyForSection(costsSectionDef)} />
    ));
    return (
        <GenericActivityForm title={title} instructions={instructions}>
            <FormSection name={WORK_SECTION_NAME}>{workSectionComponents}</FormSection>
            <FormSection name={COSTS_SECTION_NAME}>{costsSectionComponents}</FormSection>
            <ConstantField fieldValue={activityDefId} fieldName={"activityDefId"} />
        </GenericActivityForm>
    );
};
export const ActivityForm = React.memo(ActivityFormImpl);
