import * as React from "react";
import { AppState } from "modules/Invoices/views/MonthlySettlement/service/reducer";
import { Box, Tooltip, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

interface Props {
    yearMonthWithRate: AppState["yearMonthWithRate"];
}

const Rate: React.FC<Props> = ({ yearMonthWithRate }) => (
    <>
        {yearMonthWithRate.mapOrElse(
            () => (
                <></>
            ),
            ({ rate, rateRaise }) => (
                <Tooltip title={rateRaise ? "Twoja stawka dzienna uległa zmianie w bieżącym miesiąciu" : ""}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography variant="h6">
                            Twoja stawka dzienna: <strong>{rate}</strong> PLN{" "}
                            {rateRaise && <TrendingUpIcon color="success" />}
                        </Typography>
                    </Box>
                </Tooltip>
            ),
        )}
    </>
);

export default Rate;
