import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
    DataGrid,
    GridColumns,
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    GridActionsCellItem,
    GridRowId,
    GridRenderEditCellParams,
    useGridApiContext,
    GridColDef,
    gridClasses,
} from "@mui/x-data-grid";
import { Box, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Employee } from "modules/Projects/types";
import { useUserContext } from "../../../../components/common/contexts";
import { userToEmployee } from "./DocumentPayments";
import BigNumber from "bignumber.js";
import { User } from "../../../UserProfile/types";
import {
    CreateOtherTypeDocumentPaymentRequest,
    DocumentDetails,
    DocumentOtherPaymentDetails,
    UpdateOtherTypeDocumentPaymentRequest,
} from "../../types";
import { AsyncResult } from "../../../../components/common/infractructure";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import { toDateString } from "components/common/types";
import { toast } from "react-hot-toast";
import { formatMoneyForView } from "components/common/utils/numberUtils";
import { DatePickerEditCell } from "./DatePickerEditCell";

interface OtherPaymentRow {
    id: number;
    documentPaymentId: number | null;
    date: string;
    notes: string;
    wiredBy: Employee;
    isNew: boolean;
    assignedAmount: BigNumber;
}

const toRow = (p: DocumentOtherPaymentDetails): OtherPaymentRow => {
    return {
        id: p.documentPaymentId,
        documentPaymentId: p.documentPaymentId,
        date: p.date,
        notes: p.notes,
        wiredBy: p.assignedBy,
        isNew: false,
        assignedAmount: new BigNumber(p.amountInDocumentCurrency),
    };
};

export interface DocumentOtherPaymentsHandle {
    addNewRow: () => void;
}

interface EditModeRowValues {
    [key: string]: BigNumber;
}

interface ValidationData {
    [key: GridRowId]: boolean;
}

const createNewPayment = (user: User, amount: number): OtherPaymentRow => {
    return {
        id: new Date().getTime(),
        documentPaymentId: null,
        date: toDateString(new Date()),
        notes: "Notatki",
        wiredBy: userToEmployee(user),
        isNew: true,
        assignedAmount: new BigNumber(amount),
    };
};

const isAssignedValueValid = (value: any) => {
    return !isNaN(value) && Number(value) != 0;
};

interface Props {
    document: DocumentDetails;
    createOtherDocumentPayment: (
        data: CreateOtherTypeDocumentPaymentRequest,
    ) => AsyncResult<DocumentOtherPaymentDetails>;
    updateOtherDocumentPayment: (data: UpdateOtherTypeDocumentPaymentRequest) => AsyncResult<void>;
    deleteDocumentPayment: (documentPaymentId: number) => AsyncResult<void>;
    onSumOfOtherPaymentsChanged: (newSum: BigNumber) => void;
}

const DocumentOtherPaymentsImpl = forwardRef<DocumentOtherPaymentsHandle, Props>((props, ref) => {
    const [rows, setRows] = useState<GridRowsProp<OtherPaymentRow>>(props.document.otherPayments.map(p => toRow(p)));
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const editModeRowValuesRef = useRef<EditModeRowValues>({});
    const {
        userProfileData: { user },
    } = useUserContext();
    const validationErrorsRef = useRef<ValidationData>({});

    const getCurrentSumOfPayments = (): BigNumber => {
        return rows.reduce((sum, row) => {
            const editValue = editModeRowValuesRef.current[row.id]
                ? editModeRowValuesRef.current[row.id]
                : new BigNumber(row.assignedAmount);
            return sum.plus(editValue);
        }, new BigNumber(0));
    };

    useEffect(() => {
        props.onSumOfOtherPaymentsChanged(getCurrentSumOfPayments());
    }, [rows]);

    const addNewRow = () => {
        const newPayment = createNewPayment(user, new BigNumber("0").toNumber());
        setRows([...rows, newPayment]);
        setRowModesModel(oldModel => ({
            ...oldModel,
            [newPayment.id]: { mode: GridRowModes.Edit, fieldToFocus: "assignedAmount" },
        }));
    };

    useImperativeHandle(ref, () => ({
        addNewRow: () => {
            addNewRow();
        },
    }));

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        rows.filter(row => row.id === id).forEach(r => (editModeRowValuesRef.current[r.id] = r.assignedAmount));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
        const row = rows.find(it => it.id == id);
        if (row != null && row.documentPaymentId != null) {
            const res = await props.deleteDocumentPayment(row.documentPaymentId);
            if (res.isOk()) {
                delete editModeRowValuesRef.current[id];
                setRows(rows.filter(row => row.id !== id));
            } else {
                toast.error("Wystąpił błąd podczas usuwania płatności");
            }
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        validationErrorsRef.current[id] = false;
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        delete editModeRowValuesRef.current[id];
        const editedRow = rows.find(row => row.id === id);
        if (editedRow && editedRow.isNew) {
            setRows(rows.filter(row => row.id !== id));
        }
        props.onSumOfOtherPaymentsChanged(getCurrentSumOfPayments());
    };

    function EditInputCell(prop: GridRenderEditCellParams<number>) {
        const { id, value, field } = prop;
        const apiRef = useGridApiContext();

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            const val = event.target.value;
            const isValid = isAssignedValueValid(val);
            validationErrorsRef.current = { ...validationErrorsRef.current, [id]: !isValid };
            await apiRef.current.setEditCellValue({ id, field: field, value: val });
            editModeRowValuesRef.current[id] = new BigNumber(Number(val));
            props.onSumOfOtherPaymentsChanged(getCurrentSumOfPayments());
        };

        const inputStyle = validationErrorsRef.current[id] ? { color: "red" } : {};
        return (
            <TextField
                type="number"
                inputProps={{ style: inputStyle, step: "0.01" }}
                value={value || ""}
                onChange={handleChange}
            />
        );
    }

    const renderSelectEditInputCell: GridColDef["renderCell"] = (params: GridRenderEditCellParams<number>) => {
        return <EditInputCell {...params} />;
    };

    const columns: GridColumns<OtherPaymentRow> = [
        {
            field: "date",
            headerName: "Data przypisania",
            editable: true,
            width: 120,
            renderEditCell: p => <DatePickerEditCell {...p} />,
        },
        {
            field: "notes",
            headerName: "Notatki",
            editable: true,
            flex: 1,
        },
        {
            field: "wiredBy",
            headerName: "Przypisano przez",
            editable: false,
            width: 130,
            renderCell: p => {
                return <EmployeeAvatar sx={{ width: "35px", height: "35px" }} employee={p.row.wiredBy} />;
            },
        },
        {
            field: "assignedAmount",
            headerName: "Przypisano",
            editable: true,
            width: 130,
            renderEditCell: a => renderSelectEditInputCell(a),
            renderCell: p => formatMoneyForView(p.value, props.document.summary.exchangeRate?.currency ?? "PLN"),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Akcje",
            width: 80,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const isSaveDisabled = validationErrorsRef.current[id];

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key={id.toString() + "save"}
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            disabled={isSaveDisabled}
                        />,
                        <GridActionsCellItem
                            key={id.toString() + "cancel"}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={id.toString() + "edit"}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={id.toString() + "delete"}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const processRowUpdate = async (newRow: OtherPaymentRow) => {
        delete editModeRowValuesRef.current[newRow.id.toString()];

        let docPaymentId = 0;
        let isError = false;

        if (newRow.documentPaymentId == null) {
            (
                await props.createOtherDocumentPayment({
                    amountInDocumentCurrency: newRow.assignedAmount.toString(),
                    documentId: props.document.id,
                    notes: newRow.notes,
                    date: newRow.date,
                })
            ).match({
                Ok: res => {
                    docPaymentId = res.documentPaymentId;
                    return toast.success("Utworzono!");
                },
                Err: () => toast.error("Nie udało się zapisać płatności"),
            });
        } else {
            (
                await props.updateOtherDocumentPayment({
                    amountInDocumentCurrency: newRow.assignedAmount.toString(),
                    documentPaymentId: newRow.documentPaymentId,
                    notes: newRow.notes,
                    date: newRow.date,
                })
            ).match({
                Ok: () => {
                    docPaymentId = newRow.documentPaymentId != null ? newRow.documentPaymentId : 0;
                    toast.success("Zaktualizowano!");
                },
                Err: () => {
                    isError = true;
                    toast.error("Nie udało się zapisać płatności");
                },
            });
        }

        if (!isError) {
            delete editModeRowValuesRef.current[newRow.id.toString()];
        } else {
            setRowModesModel({ ...rowModesModel, [newRow.id.toString()]: { mode: GridRowModes.Edit } });
            throw new Error("Failed to save payment");
        }

        const updatedRow = { ...newRow, documentPaymentId: docPaymentId, isNew: false };
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const grid = (
        <Box>
            <DataGrid
                localeText={{ noRowsLabel: "Brak przypisanych innych przelewów" }}
                sx={{
                    [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                        outline: "transparent",
                    },
                    [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                        outline: "none",
                    },
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                        outline: "none",
                    },
                    border: 0,
                }}
                disableColumnMenu={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableSelectionOnClick={true}
                hideFooter={true}
                processRowUpdate={processRowUpdate}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                autoHeight={true}
                rowModesModel={rowModesModel}
                rows={rows}
                editMode="row"
                experimentalFeatures={{ newEditingApi: true }}
                columns={columns}
            />
        </Box>
    );

    return grid;
});

export const DocumentOtherPayments = React.memo(DocumentOtherPaymentsImpl);
