import { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "components/common/ui-kit/styles/dark";
import { lightTheme } from "components/common/ui-kit/styles/light";
import { noop } from "components/common/utils";
import { createContext, useContext, useState } from "react";

interface ColorContextSchema {
    toggleColorMode: () => void;
    mode: PaletteMode;
}

const ColorModeContext = createContext<ColorContextSchema>({
    toggleColorMode: noop,
    mode: "light",
});

const ColorModeContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [mode, setMode] = useState<PaletteMode>("light");

    const toggleColorMode = () => {
        setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
    };

    const theme = createTheme(mode === "light" ? lightTheme : darkTheme, {
        typography: {
            h1: { fontSize: 32, fontWeight: 300, fontFamily: "Lato" },
            h2: { fontSize: 28, fontWeight: 300, fontFamily: "Lato" },
            h3: { fontSize: 28, fontWeight: 300, fontFamily: "Lato" },
            h4: { fontSize: 24, fontWeight: 400, fontFamily: "Lato" },
            h5: { fontSize: 24, fontWeight: 300, fontFamily: "Lato" },
            h6: { fontSize: 22, fontWeight: 300, fontFamily: "Lato" },
        },
        palette: {
            text: {
                primary: "#555",
            },
        },
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        "& .MuiTableCell-head": {
                            backgroundColor: "rgba(255, 255, 255, 0)",
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiGrid-container": {
                            backgroundColor: "rgba(255, 255, 255, 0)",
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    rounded: { borderRadius: 10 },
                    root: {
                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                        backdropFilter: "blur(20px)",
                        padding: "1rem 1rem",
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        padding: "0",
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        padding: "0",
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        padding: "0",
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                    },
                    container: {
                        backdropFilter: "blur(5px)",
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                    root: {
                        "& .MuiModal-backdrop": {
                            backdropFilter: "blur(5px)",
                        },
                    },
                },
            },
        },
    });

    return (
        <ColorModeContext.Provider
            value={{
                toggleColorMode,
                mode,
            }}
        >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export const useAppTheme = () => useContext(ColorModeContext);

export default ColorModeContextProvider;
