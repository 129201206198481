import * as React from "react";

const shouldIgnoreEvent = (event: React.KeyboardEvent | React.MouseEvent) =>
    event.type === "keydown" &&
    ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift");

export const onKeyboardOrMouseEvent = (callback: () => void) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (!shouldIgnoreEvent(event)) {
        callback();
    }
};
