import * as React from "react";
import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { EmployeesTable } from "./EmployeesTable";
import {
    EmployeePromotion,
    ExtendedPositionAssignment,
    PositionAssignment,
    PrefilledEmail,
    PromotionEmailNotification,
    SendPromotionEmailRequest,
} from "../../types";
import { useLoaderData } from "react-router-dom";
import { Maybe, Result } from "true-myth";
import { EmployeesFilter } from "./EmployeesFilter";
import { dateToYearMonthString } from "../../../../components/common/utils";
import { useState } from "react";
import { OnChangeFn } from "../../../../components/common/ui-kit/components/GenericSelect";
import { EMPTY_SELECT_VALUE } from "../../../AccountingManagement/service/constants";
import { Department, Nullable, Specialization } from "../../../../components/common/types";
import { filterPanel } from "../../../../components/common/ui-kit/styles/utils";
import { EmployeeStats } from "./EmployeesStats";
import { formatInvoiceMonth } from "../../../Invoices/types";
import { AsyncResult } from "components/common/infractructure";
import { PromotionsList } from "./PromotionsList";

interface Props {
    notifyAboutPromotion: (requestBody: SendPromotionEmailRequest) => AsyncResult<PromotionEmailNotification>;
    getPromotions: (promotionMonth: string) => AsyncResult<EmployeePromotion[]>;
    getPrefilledEmail: (positionAssignmentId: number, promotionMonth: string) => AsyncResult<PrefilledEmail>;
}

const toExtendedAssignments = (assignments: PositionAssignment[], yearMonth: string): ExtendedPositionAssignment[] => {
    const employeeMap = new Map<string, PositionAssignment[]>();

    assignments.forEach(assignment => {
        const group = employeeMap.get(assignment.employeeEmail) || [];
        group.push(assignment);
        employeeMap.set(assignment.employeeEmail, group);
    });

    const result: ExtendedPositionAssignment[] = [];

    employeeMap.forEach(group => {
        group.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        const firstAssignment = group[0];
        const lastAssignment = group[group.length - 1];

        group.forEach(assignment => {
            const extendedAssignment: ExtendedPositionAssignment = {
                ...assignment,
                overallStartDate: firstAssignment.startDate,
                overallEndDate: lastAssignment.endDate ? lastAssignment.endDate : null,
                status: "Continuing",
            };

            if (formatInvoiceMonth(new Date(assignment.startDate)) === yearMonth && assignment === firstAssignment) {
                extendedAssignment.status = "Joined";
            } else if (
                assignment.endDate != null &&
                formatInvoiceMonth(new Date(assignment.endDate)) === yearMonth &&
                assignment === lastAssignment
            ) {
                extendedAssignment.status = "Fired";
            }

            result.push(extendedAssignment);
        });
    });

    return result;
};

export const EmployeesList: React.FC<Props> = props => {
    const currentYearMonth = dateToYearMonthString(new Date());
    const loadedDataResult = useLoaderData() as Result<PositionAssignment[], Error>;
    const pa = loadedDataResult.unwrapOr([]);
    const [yearMonth, setYearMonth] = useState(currentYearMonth);
    const [projectType, setProjectType] = useState(EMPTY_SELECT_VALUE);
    const [level, setLevel] = useState(EMPTY_SELECT_VALUE);
    const [status, setStatus] = useState(EMPTY_SELECT_VALUE);
    const [specialization, setSpecialization] = useState<Maybe<Specialization>>(Maybe.nothing());
    const [department, setDepartment] = useState<Maybe<Department>>(Maybe.nothing());
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setSelectedTab(newValue);
    };

    const yearMonthDate = new Date(yearMonth);
    yearMonthDate.setHours(0, 0, 0, 0);

    const currentMonthAssignments = toExtendedAssignments(pa, yearMonth).filter(pap => {
        const startDate = new Date(pap.startDate);
        startDate.setHours(0, 0, 0, 0);

        const endDate = pap.endDate ? new Date(pap.endDate) : new Date("9999-12-31");
        endDate.setHours(0, 0, 0, 0);
        return startDate <= yearMonthDate && endDate >= yearMonthDate;
    });

    const layOffsCount = currentMonthAssignments.filter(l => l.status === "Fired").length;
    const hiresCount = currentMonthAssignments.filter(l => l.status === "Joined").length;

    const specializations = Array.from(
        new Set(
            currentMonthAssignments.filter(s => s.specialization != null).map(s => s.specialization as Specialization),
        ),
    );
    const departments = Array.from(new Set(currentMonthAssignments.map(s => s.department)));
    const levels = Array.from(new Set(currentMonthAssignments.map(s => s.level).filter(s => s != null))) as string[];

    const filteredExt = currentMonthAssignments
        .filter(pa => (specialization.isJust() ? pa.specialization == specialization.unsafelyUnwrap() : true))
        .filter(pa => (department.isJust() ? pa.department === department.unsafelyUnwrap() : true))
        .filter(pa => (level != EMPTY_SELECT_VALUE ? pa.level === level : true))
        .filter(pa => (status != EMPTY_SELECT_VALUE ? pa.status == status : true))
        .sort((a, b) => a.employee.localeCompare(b.employee));

    const handleChangeDocumentType: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as string;
        setProjectType(value);
    };

    const handleChangeYearMonth = (date: Nullable<Date>) => {
        setYearMonth(Maybe.of(date).map(dateToYearMonthString).unwrapOr(currentYearMonth));
    };

    const handleChangeDepartment: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as Department | typeof EMPTY_SELECT_VALUE;
        setDepartment(
            Maybe.of(value).flatMap(selectedValue =>
                selectedValue === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(selectedValue),
            ),
        );
    };

    const handleChangeSpecialization: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as Specialization | typeof EMPTY_SELECT_VALUE;
        setSpecialization(
            Maybe.of(value).flatMap(selectedValue =>
                selectedValue === EMPTY_SELECT_VALUE ? Maybe.nothing() : Maybe.of(selectedValue),
            ),
        );
    };

    const handleChangeLevel: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as string;
        setLevel(value);
    };

    const handleChangeStatus: OnChangeFn = ({ target }, _child) => {
        const value = (target.value || EMPTY_SELECT_VALUE) as string;
        setStatus(value);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Pracownicy</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={filterPanel}>
                        <EmployeesFilter
                            statusType={status}
                            yearMonth={yearMonth}
                            projectType={projectType}
                            level={level}
                            specialization={specialization}
                            department={department}
                            specializations={specializations}
                            departments={departments}
                            levels={levels}
                            onChangeProjectType={handleChangeDocumentType}
                            onChangeYearMonth={handleChangeYearMonth}
                            onChangeDepartment={handleChangeDepartment}
                            onChangeSpecialization={handleChangeSpecialization}
                            onChangeLevel={handleChangeLevel}
                            onChangeStatus={handleChangeStatus}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                    <EmployeeStats
                        layOffs={layOffsCount}
                        newJoiners={hiresCount}
                        totalAtEndOfMonth={currentMonthAssignments.length}
                    ></EmployeeStats>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Tabs value={selectedTab} onChange={handleChangeActiveTab}>
                            <Tab label="Pracownicy" />
                            <Tab label="Podwyżki" />
                        </Tabs>
                        <div hidden={selectedTab == 1}>
                            <EmployeesTable positionAssignments={filteredExt} />
                        </div>
                        <div hidden={selectedTab == 0}>
                            <PromotionsList
                                getPromotions={props.getPromotions}
                                yearMonth={yearMonth}
                                notifyAboutPromotion={props.notifyAboutPromotion}
                                getPrefilledEmail={props.getPrefilledEmail}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
