import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import Loader from "components/Loader/Loader";
import { Nullable, YupObjectShape, toDateString } from "components/common/types";
import { CommonDatePicker } from "components/common/ui-kit/components/DatePicker/DatePicker";
import { noop } from "components/common/utils";
import { Dayjs } from "dayjs";
import { PendingPaymentChangeRequestParams } from "modules/types/types";
import { pipe } from "ramda";
import { Controller, useForm } from "react-hook-form";
import { Maybe } from "true-myth";
import { useBoolean } from "usehooks-ts";
import * as yup from "yup";

interface FormValues {
    invoiceNumber: string;
    invoiceIssueDate: string;
}

interface Props {
    paymentId: number;
    formValues?: FormValues;
    onSubmit: (pendingPaymentChangeRequest: PendingPaymentChangeRequestParams) => Promise<void>;
}

const formDataSchema = yup.object<YupObjectShape<FormValues>>({
    invoiceNumber: yup.string().required(),
    invoiceIssueDate: yup.string().required(),
});

const mapFormValuesToRequestParams =
    (paymentId: number) =>
    (formValues: FormValues): PendingPaymentChangeRequestParams => ({
        ...formValues,
        paymentId,
    });

export const PendingPaymentForm = ({ formValues, paymentId, onSubmit }: Props) => {
    const {
        control,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<FormValues>({
        defaultValues: formValues,
        mode: "all",
        resolver: yupResolver(formDataSchema),
    });

    const { value: isFormEditable, setFalse: disableEdit, setTrue: enableEdit } = useBoolean(false);

    const handleChangeIssueDate = (pickedDate: Nullable<Dayjs>) =>
        Maybe.of(pickedDate).match({
            Just: date => {
                try {
                    const dateString = toDateString(date.toDate());
                    setValue("invoiceIssueDate", dateString, { shouldDirty: true });
                    clearErrors("invoiceIssueDate");
                } catch (error) {
                    setError("invoiceIssueDate", { type: "custom", message: "invalid date" });
                }
            },
            Nothing: noop,
        });

    const handleCancel = () => {
        reset();
        disableEdit();
    };

    const editButton = (
        <Button variant="contained" color="primary" onClick={enableEdit} data-testid="PaymentEditButton">
            Edytuj
        </Button>
    );

    const saveButton = (
        <>
            <Button
                variant="contained"
                color="warning"
                onClick={handleCancel}
                data-testid="PaymentCancelEditButton"
                sx={{ marginRight: ".5rem" }}
            >
                Anuluj
            </Button>
            <Button type="submit" variant="contained" color="primary">
                Wyślij
            </Button>
        </>
    );

    const form = (
        <form
            name="pending payment details form"
            onSubmit={handleSubmit(pipe(mapFormValuesToRequestParams(paymentId), onSubmit))}
        >
            <Box sx={{ display: "flex", alignItems: "center", padding: "1rem", margin: "1rem 0" }}>
                <Controller
                    name="invoiceNumber"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            sx={{ marginRight: "1rem" }}
                            label="Nr faktury"
                            InputProps={{
                                readOnly: !isFormEditable,
                            }}
                            error={!!errors.invoiceNumber}
                            data-testid="PaymentInvoiceNoEditField"
                            disabled={!isFormEditable}
                            variant="standard"
                        />
                    )}
                />
                <Box sx={{ marginRight: "1rem" }}>
                    <Controller
                        name="invoiceIssueDate"
                        control={control}
                        render={({ field }) => (
                            <CommonDatePicker
                                datePicker={{
                                    ...field,
                                    label: "Data wystawienia",
                                    onChange: handleChangeIssueDate,
                                    disabled: !isFormEditable,
                                    readOnly: !isFormEditable,
                                    views: ["year", "month", "day"],
                                }}
                                textField={{ error: !!errors.invoiceIssueDate }}
                            />
                        )}
                    />
                </Box>
                <Box>{isFormEditable ? saveButton : editButton}</Box>
            </Box>
        </form>
    );

    return isSubmitting ? <Loader /> : form;
};
