import { intersection, isEmpty } from "ramda";

const KnownRoles = [
    "ROLE_ACCOUNTANT_READ",
    "ROLE_ACCOUNTANT_WRITE",
    "ROLE_ADMIN",
    "ROLE_BIZDEV_READ",
    "ROLE_BIZDEV_WRITE",
    "ROLE_EMPLOYEE_READ",
    "ROLE_EMPLOYEE_WRITE",
    "ROLE_USER_READ",
    "ROLE_USER_WRITE",
    "ROLE_PROMOTION_NOTIFIER",
] as const;

export type Role = typeof KnownRoles[number];

// how to read this: the user needs to have at least one of the roles from the list to be authorized for certain view/action.
export const ROLES_ALL: Role[] = [...KnownRoles];
export const ROLES_PROJECTS_EDIT_CREATE: Role[] = ["ROLE_BIZDEV_WRITE"];
export const ROLES_PROMOTION_NOTIFICATION: Role[] = ["ROLE_PROMOTION_NOTIFIER"];
export const ROLES_PROJECTS_VIEW: Role[] = ["ROLE_USER_READ"];

export const isAllowed = (userRoles: Role[], allowedRoles: Role[]) => !isEmpty(intersection(userRoles, allowedRoles));
